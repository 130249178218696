import * as React from "react";
import { Route, withRouter } from "react-router-dom";
import { AppRoute } from "../appLanguages";
import { LocalizedSwitch } from "../localizedSwitch";
import { HomePrivate } from "../../components/home/private/private";
import { PrivacyPolicies } from "../../components/legalTexts/privacyPolicies/privacyPolicies";
import { CookiesPolicy } from "../../components/legalTexts/cookiesPolicy/cookiesPolicy";
import { TermConditions } from "../../components/legalTexts/termConditions/termConditions";
import { LegalAdvice } from "../../components/legalTexts/legalAdvice/legalAdvice";
import { AgentsRegister } from "../../components/agentsRegister/agentsRegister";
import { Regularization } from "../../components/regularization/regularization";
import { News } from '../../components/news/news';
import { RewardsPerNight } from "../../components/rewardsPerNight/rewardsPerNight";
import { DetailNews } from '../../components/news/detailNews/detailNews';
import { CreateNews } from '../../components/news/cuNews/createNews'
import { Rewards } from "../../components/rewards/rewards";
import { Faqs } from "../../components/faqs/faqs";
import { Config } from '../../components/config/config';
import { AdvancedRetribution } from "src/components/advancedRetribution/advancedRetribution";
import { TargetsComponent } from '../../components/targets/TargetsComponent'; 
import { Statistics } from "src/components/statistics/statistics";
import { BillingInfoComponent } from "src/components/billing/billing";

const RoutesContentPrivate = () => {
    return (
        <LocalizedSwitch>
            <Route
                exact
                path={AppRoute.HomePrivate}
                component={HomePrivate}
            />
            <Route
                exact
                path={AppRoute.FaqsPrivate}
                component={Faqs}
            />
            <Route
                exact
                path={AppRoute.AgentsRegister}
                component={AgentsRegister}
            />
            <Route
                exact
                path={AppRoute.PrivacyPoliciesPrivate}
                component={PrivacyPolicies}
            />
            <Route
                exact
                path={AppRoute.CookiesPolicyRPrivate}
                component={CookiesPolicy}
            />
            <Route
                exact
                path={AppRoute.TermConditionsRPrivate}
                component={TermConditions}
            />
            <Route
                exact
                path={AppRoute.LegalAdvicePrivate}
                component={LegalAdvice}
            />
            <Route
                exact
                path={AppRoute.Regularization}
                component={Regularization}
            />
            <Route
                exact
                path={AppRoute.News}
                component={News}
            />
            <Route
                exact
                path={AppRoute.DetailNews}
                component={DetailNews}
            />
            <Route
                exact
                path={AppRoute.Statistics}
                component={Statistics}
            />
            <Route
                exact
                path={AppRoute.Rewards}
                component={Rewards}
            />
            <Route
                exact
                path={ AppRoute.CreateNews }
                component={ CreateNews }
            />
            <Route
                exact
                path= { `${AppRoute.EditNews}` }
                component={ CreateNews }
            />
            <Route
                exact
                path={ AppRoute.RewardsPerNight }
                component={ RewardsPerNight }
            />
            <Route
                exact
                path={ AppRoute.Config }
                component={ Config }
            />
             <Route
                exact
                path={AppRoute.AdvancedRetribution}
                component={AdvancedRetribution}
            />
            <Route
                exact
                path={AppRoute.Targets}
                component={TargetsComponent}
            />
            <Route
                exact
                path={AppRoute.Billing}
                component={BillingInfoComponent}
            />
        </LocalizedSwitch>
    );
};

export default withRouter(RoutesContentPrivate);
