import React from "react";
import { Popover } from "antd";
import "./popovers.sass";

// Componente popover
export const PopoverCustomComponent = (param) => {
  return (
    <Popover
      title={param.title}
      content={param.content}
      placement={param.placement}
      trigger={param.trigger}
    >
      <div className={param.classBody}>
        {param.body}
      </div>
    </Popover>
  );
};
