import * as React from "react";
import { useContext } from "react";
import { Tabs } from 'antd';
import { ListCustomPro, ListItemCustomPro, ListItemMetaCustomPro, AvatarCustom } from '../index';
import { NotificationsType } from '../../../constants/enums';
import { useTranslation } from "react-i18next";
import { ListItem } from "../lists/interfaceLists";
import { Link } from "react-router-dom";
import { LinkActiveContext } from "../../../hooks/context/linkActive";

const { TabPane } = Tabs;

// Componente custom de Tabs
export const TabsCustomComponent = (param) => {

    // Libreria i18n para traducir
    const { t } = useTranslation(["notifications", "urls"]);
    const linkActive = useContext(LinkActiveContext);

    return (
        <Tabs
            className={param.class}
            defaultActiveKey="0"
            onChange={param.onChange}
        >
            {
                param.tabs.map((e, i) => {
                    return (
                        <TabPane
                            tab={e === NotificationsType.Agent ? t("agents") : t("reservations")}
                            key={i}
                        >
                            <ListCustomPro
                                itemLayout={"horizontal"}
                                classBox="event-notification"
                                dataSource={param.data.filter((m) => m.notificationType === e).reduce((a, m) => (a.push(m.notificationData), a), [])}
                                renderItem={(item: ListItem) => (
                                    <Link
                                        className="link-list"
                                        // key="" // TODO - ¿Manejar el key? ¿Que la API nos devuelva un key o id?
                                        to={e === NotificationsType.Agent ? t("urls:private.agents-register") : ""}
                                        onClick={() => linkActive.updateValueUrl(e === NotificationsType.Agent ? t("urls:private.agents-register") : "")}
                                    >
                                        <ListItemCustomPro className={"p-0 not-line-down"}>
                                            <ListItemMetaCustomPro
                                                className={"box-list-agents-style"}
                                                avatar={
                                                    item.avatar &&
                                                        <AvatarCustom
                                                            className={"avatar-list-agents"}
                                                            src={item.avatar}
                                                        />
                                                }
                                                title={item.title}
                                                description={item.description}
                                            />
                                        </ListItemCustomPro>
                                    </Link>
                                )}>
                            </ListCustomPro>
                        </TabPane>
                    )
                })
            }
        </Tabs>
    );
};
