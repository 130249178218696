import React, { useState, useContext, useEffect } from "react";
import "./directSalesConfig.sass";
import {
    ParagraphCustom,
    TextCustom,
    DrawerCustom,
    RadioGroupCustom,
    RadioCustom,
    InputCustom,
    ButtonsCustom,
    FormCustom,
    FormItemsContact
} from "../../custom";
import { useTranslation } from "react-i18next";
import { RestApiClientContext } from "../../../hooks/context/pageContext";
import { DirectSales, Amount, CurrencyConversion, DataDirectSalesDrawer } from '../interfaces/directSalesConfigInterface';
import { DIRECT_SALE_FIXED_VALUE, DIRECT_SALE_PERCENTAGE } from '../../../constants/constants';
import { Form } from "antd";
import { NO_CHART_REPITED, NO_CHART_REPITED_AND_NUMBER_DECIMAL, ONLY_NUMBER_D } from "../../../constants/regex";
import { convertStringToNumber } from "../../../utils/convertStringToNumber";
import { numberFormat } from '../../../utils/formatAmount';
import { LangContext } from "../../../hooks/context/lang";


export const DrawerDirectSales = (props: DataDirectSalesDrawer) => {

    const {
        amounts,
        dataDirectSales,
        setVisibleDrawer
    } = props;

	const userLangContext = useContext(LangContext);
    const restApiClient = useContext(RestApiClientContext);

    const { t } = useTranslation(["rewardsPerNight"]);
    const [form] = Form.useForm();

    const [inputDisabledPercentage, setInputDisabledPercentage] = useState<boolean>(dataDirectSales.activeType !== DIRECT_SALE_PERCENTAGE);
    const [inputDisabledFixedValue, setInputDisabledFixedValue] = useState<boolean>(dataDirectSales.activeType !== DIRECT_SALE_FIXED_VALUE);
    const [inputPercentage, setInputPercentage] = useState<number>(dataDirectSales.percentage);
    const [dataAmount, setDataAmount] = useState<Amount[]>(amounts);

    const closeDrawerDirectSales = () => {
        setVisibleDrawer(false);
    };

    useEffect(() => {
        if (dataDirectSales) {
            setInputPercentage(dataDirectSales.percentage);
        }
    }, []);

    const convertCurrency = async (listAmounts: Amount[]) => {

        const arrayex: Amount[] = listAmounts;

        arrayex.map(async (e, i) => {
            if (i > 0) {
                const conversion: CurrencyConversion = {
                    currencyFrom: arrayex[0].currencyId,
                    amount: arrayex[0].value,
                    currencyTo: e.currencyId
                }

                await restApiClient.getCurrencyConversion(conversion)
                    .then((data) => {
                        e.value = data;
                        valores[i] = data;
                    })
                    .finally(() => {
                        setDataAmount([]);
                        setDataAmount(arrayex);
                    })
            }
        })
    }

    const handleChangePercentage = (e: any) => {
        setInputPercentage(convertStringToNumber(e.target.value));
    };

    const handleChangeFixedValue = (e: any, idCurrency: string, index: number) => {
        dataAmount.filter(cur => cur.currencyId === idCurrency)[0].value = convertStringToNumber(e.target.value);
        valores[index] = e.target.value;
    };

    const changeDirectSalesType = (e: any) => {
        if (e.target.value === DIRECT_SALE_PERCENTAGE) {
            setInputDisabledFixedValue(true);
            setInputDisabledPercentage(false);
        } else {
            setInputDisabledPercentage(true);
            setInputDisabledFixedValue(false);
        }
    };

    const setDirectSales = async () => {
        const directSales: DirectSales = {
            activeType: !inputDisabledPercentage ? DIRECT_SALE_PERCENTAGE : DIRECT_SALE_FIXED_VALUE,
            percentage: inputPercentage,
            amounts: dataAmount
        }

        restApiClient.putDirectSales(directSales)
            .then((data) => {
                if (data) {
                    closeDrawerDirectSales();
                }
            })
    }

    const valores: any[] = [];

    return (
        <DrawerCustom
            title={t('rewardsPerNight:label-direct-sale')}
            visible={true}
            info={""}
            width={360}
            placement="right"
            closable={true}
            onClose={closeDrawerDirectSales}
            content={
                <>
                    <ParagraphCustom
                        text={t('rewardsPerNight:detail-direct-sale')}
                        class="direct-sale-description"
                    />
                    <FormCustom
                        form={form}
                        onFinish={setDirectSales}
                    >
                        <FormItemsContact
                            name="radio-group"
                            className="row-form-item"
                        >
                            <RadioGroupCustom
                                className="groups-type-direct-sale"
                                onChange={changeDirectSalesType}
                                defaultValue={amounts && dataDirectSales.activeType}
                            >
                                <RadioCustom
                                    key={1}
                                    value={DIRECT_SALE_PERCENTAGE}
                                >
                                    <TextCustom
                                        text={t('rewardsPerNight:percent')}
                                    />
                                    <FormItemsContact
                                        name="percent"
                                        className="row-form-item"
                                    >
                                        <InputCustom
                                            className="direct-sale-input main-input"
                                            suffix="%"
                                            placeholder="0"
                                            id="percent"
                                            disabled={inputDisabledPercentage}
                                            onChange={handleChangePercentage}
                                            defaultValue={numberFormat(inputPercentage, userLangContext.lang)}
                                        />
                                    </FormItemsContact>
                                </RadioCustom>
                                <RadioCustom
                                    key={2}
                                    value={DIRECT_SALE_FIXED_VALUE}
                                >
                                    <TextCustom
                                        text={t('rewardsPerNight:matter-fixed')}
                                    />
                                    {
                                        dataAmount.map((e, i) => {
                                            return (
                                                <FormItemsContact
                                                    className="margin-b-inputs"
                                                    name={e.currencyId}
                                                    rules={
                                                        [
                                                            {
                                                                validator: (_, value) => (value
                                                                    ?
                                                                    ONLY_NUMBER_D.test(value)
                                                                        ?
                                                                        NO_CHART_REPITED.test(value)
                                                                            ?
                                                                            NO_CHART_REPITED_AND_NUMBER_DECIMAL.test(value)
                                                                                ?
                                                                                Promise.resolve()
                                                                                :
                                                                                Promise.reject(t('reward:modal.validations.num-decimals'))
                                                                            :
                                                                            Promise.reject(t('reward:modal.validations.formated'))
                                                                        :
                                                                        Promise.reject(t('reward:modal.validations.number'))
                                                                    :
                                                                    Promise.resolve())
                                                            }
                                                        ]
                                                    }
                                                >
                                                    <div key={i}>
                                                        <InputCustom
                                                            id={e.currencyId}
                                                            defaultValue={numberFormat(e.value, userLangContext.lang)}
                                                            addonBefore={`${e.currencyId}`}
                                                            className="direct-sale-input main-input-currency"
                                                            placeholder="0.00"
                                                            disabled={inputDisabledFixedValue}
                                                            onChange={(event: Event) => { handleChangeFixedValue(event, e.currencyId, i); }}
                                                        />
                                                        {
                                                            i === 0 &&
                                                            <div className="primary-button submit-btn-direct-sale" onClick={() => convertCurrency(dataAmount)}>
                                                                <ParagraphCustom
                                                                    class="convert-currency clicable"
                                                                    text={t('rewardsPerNight:convert-currency')}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </FormItemsContact>
                                            );
                                        })
                                    }
                                </RadioCustom>
                            </RadioGroupCustom>
                            <ParagraphCustom
                                class="direct-sale-description convert-currency fixed-amount"
                                text={t('rewardsPerNight:direct-sale-description')}
                            />
                        </FormItemsContact>
                        <FormItemsContact>
                            <div className="direct-sale-btn-align">
                                <ButtonsCustom
                                    htmlType="submit"
                                    class="primary-button submit-btn-direct-sale"
                                    content={t('rewardsPerNight:saved')}
                                />
                                <ButtonsCustom
                                    onClick={closeDrawerDirectSales}
                                    class="default-button cancel-btn-direct-sale"
                                    content={t('rewardsPerNight:cancel')}
                                />
                            </div>
                        </FormItemsContact>
                    </FormCustom>
                </>
            }
        />
    );
};
