import { Button, Form, Input } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { RestApiClientContext } from 'src/hooks/context/pageContext';
import { TargetDto } from 'src/models/targets/TargetModels';
import { LoadingOutlined } from '@ant-design/icons'

import './TargetsAddComponent.sass'

interface TargetsAddComponentProps{
    isClosed: boolean,
    onFinish: () => void
}

const TargetsAddComponent = (props: TargetsAddComponentProps) : JSX.Element => {
    const { t } = useTranslation(["targets"]);
    const restApiClient = useContext(RestApiClientContext);

    const [form] = Form.useForm();
    const [savingData, setSavingData] = useState<boolean>(false);

    const onFinish = () : void => {

        let targetData : TargetDto = {
            description: form.getFieldValue("description"),
            entities:[],
            isActive: true,
            id: 0,
            creationDate: new Date(),
            groups:[]
        }


        setSavingData(true);
        restApiClient.createTarget(targetData).then((r: any)=>{
            if (r && r == true){
                props.onFinish();
            }
        }).finally(()=>{
            setSavingData(false);
        })
    }

    useEffect(()=>{
        if (props.isClosed){
            form.resetFields();
        }
    },[props.isClosed])

    return (
        <div className="targets-add-component">
            <Form layout='vertical' form={form} onFinish={onFinish}>
                <Form.Item
                    label={t("text-labels.description")}
                    name="description"
                    rules={[
                        {
                        whitespace: false,
                        message: t("validations.description"),
                        required: true
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item>
                    <Button htmlType='submit' type='primary' className='primary-button col-12'>
                        {savingData ? <LoadingOutlined /> : ''} {t("button-labels.save")}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default TargetsAddComponent;