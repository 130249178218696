import React, { useState, useEffect } from "react";
import moment from "moment";
import { CalendarHeader } from "./header";
import "./styles.sass";
import { PickerLocale } from "antd/es/date-picker/generatePicker";
import { getLocaleDatePicker } from "../../../utils/datePicker";
import { useTranslation } from "react-i18next";
import { FontIconsCustom, ModalCustomPro } from "../../custom";
import { SwapRightOutlined } from "@ant-design/icons";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

interface DateInfoCalendar {
  input: any;
  value: any;
  onChange: (day: any) => void;
  dateInputChange: (day: any) => void;
}

export const DatepickerOwn = (props: DateInfoCalendar) => {

  const {
    input,
    value,
    onChange,
    dateInputChange
  } = props;

  const { t } = useTranslation(["rewardsPerNight", "translation"]);
  const local: PickerLocale = getLocaleDatePicker();

  const [calendar, setCalendar] = useState<any>([]);
  const [valueDateInit, setValueDateInit] = useState<any>(input[0]);
  const [valueDateEnd, setValueDateEnd] = useState<any>(input[input.length - 1]);
  const [clickSelectorInit, setClickSelectorInit] = useState<boolean>(false);
  const [clickSelectorEnd, setClickSelectorEnd] = useState<boolean>(false);
  const [calendarVisible, setCalendarVisible] = useState<boolean>(false);

  useEffect(() => {
    setCalendar(buildCalendar(value));
  }, [valueDateInit, valueDateEnd, value]);

  const buildCalendar = (date: any[]) => {
    const calendarArray = [];

    date.map((day: any) => calendarArray.push(constructorCalendar(day)));

    return calendarArray;
  };

  const constructorCalendar = (date: any) => {
    const week = [];
    const startDay = date.clone().startOf("month");
    const endDay = date.clone().endOf("month");
    const _date = startDay.clone().subtract(1, "day");

    while (_date.isBefore(endDay, "day")) {
      week.push(
        Array(7)
          .fill(0)
          .map(() =>
            _date.isBefore(endDay, "day") ? _date.add(1, "day").clone() : ""
          )
      );
    }

    return week;
  };

  const isSelected = (day: any) => {

    return (
      moment(day).isSame(moment(valueDateInit._isValid ? valueDateInit : value)) ||
      moment(day).isSame(moment(valueDateEnd._isValid ? valueDateEnd : null))
    );

  }

  const isBetween = (day: any) => {
    if (moment(valueDateInit).isValid() && moment(valueDateEnd).isValid()) {
      if (moment(valueDateEnd).isAfter(valueDateInit, "day")) {
        return (
          moment(day).isAfter(valueDateInit, "day") &&
          moment(day).isBefore(valueDateEnd, "day")
        );
      } else {
        return (
          moment(day).isBefore(valueDateEnd, "day") ||
          moment(day).isAfter(valueDateInit, "day")
        );
      }
    }

    return false;
  }

  const dayStyles = (day: any) => {
    if (isBetween(day)) return "between";
    if (isSelected(day)) return "selected";

    return "";
  }

  const changeSelect = (day: any) => {
    if (clickSelectorInit) {
      setValueDateInit(day);
      dateInputChange([moment(day), moment(null)]);
      setValueDateEnd(moment(null));
      changeSelectorInputEnd();
    } else {
      setValueDateEnd(day);
      dateInputChange([moment(valueDateInit), day]);
      setCalendarVisible(false);
      changeSelectorInputInit();
    }
  };

  const changeSelectorInputInit = () => {
    setClickSelectorInit(true);
    setClickSelectorEnd(false);
  };

  const changeSelectorInputEnd = () => {
    setClickSelectorInit(false);
    setClickSelectorEnd(true);

  };

  const openModal = () => {
    setClickSelectorInit(true);
    setClickSelectorEnd(false);
    setCalendarVisible(true);
  }

  const handleCancel = () => {
    setCalendarVisible(false);
  };

  return (
    <>
      <div className="input-datepicker-box col-12 p-0" onClick={() => openModal()}>
        <div
          className={"col-4 month-box"}
        >
          {moment(input[0]).isValid()
            ?
              moment(input[0]).format("D") +
              " " +
              t("rewardsPerNight:graphic.month-list-large." +(moment(input[0]).month() + 1))
            :
              local.weekFormat
          }
        </div>
        <div className="col-2 position-icon-date">
          <SwapRightOutlined />
        </div>
        <div
          className={"col-4 month-box last-month-box"}
        >
          {moment(input[input.length - 1]).isValid()
            ?
              moment(input[input.length - 1]).format("D") +
              " " +
              t("rewardsPerNight:graphic.month-list-large." +(moment(input[input.length - 1]).month() + 1))
            :
              local.weekFormat
          }
        </div>
        <div className="col-2 position-icon-date">
          <FontIconsCustom
            classIcon={"icons-list-show"}
            icon={faCalendarAlt}
          />
        </div>
      </div>
      {calendar.length > 0 && (
        <ModalCustomPro
          className="modal-datepicker"
          visible={calendarVisible}
          closable={true}
          footer={null}
          onCancel={handleCancel}
        >
          <div className="col-12 calendar">
            <div className="input-datepicker-box inner-modal-input col-12 p-0">
              <div
                className={
                  (clickSelectorInit ? "input-focus " : "") + "col-md-5 col-4 month-box"
                }
                onClick={() => changeSelectorInputInit()}
              >
                {moment(input[0]).isValid()
                  ?
                    moment(input[0]).format("D") +
                    " " +
                    t("rewardsPerNight:graphic.month-list-large." +(moment(input[0]).month() + 1))
                  :
                    local.weekFormat}
              </div>
              <div className="col-md-1 col-2 position-icon-date">
                <SwapRightOutlined />
              </div>
              <div
                className={
                  (clickSelectorEnd ? "input-focus " : "") + "col-md-5 col-4 month-box"
                }
                onClick={() => changeSelectorInputEnd()}
              >
                {moment(input[input.length - 1]).isValid()
                  ?
                    moment(input[input.length - 1]).format("D") +
                    " " +
                    t("rewardsPerNight:graphic.month-list-large." +(moment(input[input.length - 1]).month() + 1))
                  :
                    local.weekFormat
                }
              </div>
              <div className="col-md-1 col-2 position-icon-date">
                <FontIconsCustom
                  classIcon={"icons-list-show"}
                  icon={faCalendarAlt}
                />
              </div>
            </div>
            <div className="col-12 p-0 box-calendar">
              <CalendarHeader
                value={value}
                onChange={onChange}
              />
              <hr />
              <div className="body col-12 p-0 display-calendar-month">
                {calendar.map((month) => (
                  <div className="col-md-6 col-12 ">
                    {month.map((week, wi) => (
                      <div className="size-box-days" key={wi}>
                        {week.map((day, di) => (
                          <div
                            key={di}
                            className="day"
                            onClick={() => changeSelect(day)}
                          >
                            <div
                              className={"day-hover " + dayStyles(day)}
                            >
                              {day !== "" ? day.format("D").toString() : ""}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </ModalCustomPro>
      )}
    </>
  );
};
