import { RestApiClientContext } from '../../../hooks/context/pageContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Skeleton } from 'antd';
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import './editConfiguration.sass';
import { DrawerCustom, FormCustom, FormItemsContact, ButtonsCustom, InputCustom, TagCheckableCustom, ParagraphCustom, TagInfoCustom, FontIconsCustom } from '../../../components/custom';
import { Form } from "antd";
import { Establishments } from '../../../components/regularization/interfaces/establishments';
import { LanguageManagement } from '../../../models/language/languageUserManagement';
import { LangContext } from '../../../hooks/context/lang';
import { EditConfData } from '../interfaces/configSelect';
import { ModalConfirmationsActions } from '../modalConfirm/modalConfirm';

export const EditConfiguration = (props: EditConfData) => {

    const {
        optionSelectedInput,
        editConfig,
        setEditNewConfig,
        setShowDrawerEditConfiguration,
        deleteConfiguration
    } = props

    const restApiClient = useContext(RestApiClientContext);
    const userLangContext = useContext<LanguageManagement>(LangContext);

    const { t } = useTranslation(["rewardsPerNight"]);
    const [form] = Form.useForm();

    const [listOptionsEstablishments, setListOptionsEstablishments] = useState<Establishments[]>([]);
    const [lng, setLng] = useState<string>(userLangContext.GetLangContext());
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedTags, setSelectedTags] = useState<Establishments[]>([]);
    const [selectedTagsInit, setSelectedTagsInit] = useState<Establishments[]>([]);
    const [inputName, setInputName] = useState<string>(optionSelectedInput.name)
    const [inputNameOld] = useState<string>(optionSelectedInput.name)
    const [showModalConfirmDeleteModifications, setShowModalConfirmDeleteModifications ] = useState<boolean>(false)

    useEffect(() => {
        if (userLangContext.GetLangContext() !== lng) {
            setLng(userLangContext.GetLangContext());
        }
    });

    useEffect(() => {
        GetEstablishmentsSelect();
        GetEstablishmentsSelectedInConfiguration();
    }, [lng]);

    const handleChangeName = (e) => {
        setInputName(e.target.value);
    }

    const setEditConfiguration = () => {
        setShowModalConfirmDeleteModifications(false);
        const listId: string[] = [];

        selectedTags.map((e) => {
            listId.push(e.id)
        })

        restApiClient.SetEditConfiguration(optionSelectedInput.id, inputName, listId)
            .then((data) => {
                if (data) {
                    setShowDrawerEditConfiguration(false);
                    setEditNewConfig(!editConfig);
                }
            })
    }

    const closeDrawerDirectSales = () => {
        if (inputNameOld !== inputName || selectedTagsInit.length !== selectedTags.length) {
            setShowModalConfirmDeleteModifications(true);
        } else {
            setShowDrawerEditConfiguration(false);
        }
    }

    const closedAllWindowsOpen = () => {
        setShowModalConfirmDeleteModifications(false);
        setShowDrawerEditConfiguration(false);
    }

    const GetEstablishmentsSelect = () => {
        restApiClient.getEstablishments()
            .then((data) => {
                setListOptionsEstablishments(data);
            })
    };

    const GetEstablishmentsSelectedInConfiguration = () => {
        setLoading(true);

        restApiClient.GetConfiguration(optionSelectedInput.id)
            .then((data) => {
                setSelectedTags(data.establishments);
                setSelectedTagsInit(data.establishments);
                setLoading(false);
            })
    };

    const onAddAllEstablishments = () : void => {
        var selectedTags = [];
        listOptionsEstablishments.forEach((e: Establishments) => {
            selectedTags = [...selectedTags, e] ;
        });
        setSelectedTags(selectedTags);

    }

    const onRemoveAllEstablishments = () : void => {
        setSelectedTags([]);
    }

    const handleChange = (tag: Establishments, checked: any) => {
        const nextSelectedTags = selectedTags.some(t => t.id == tag.id) ? selectedTags.filter(t => t.id !== tag.id) : [...selectedTags, tag];
        setSelectedTags(nextSelectedTags);
    }

    return (
        <>
            <DrawerCustom
                title={t("rewardsPerNight:edit-configuration",{0: optionSelectedInput.name})}
                visible={true}
                info={""}
                width={360}
                placement="right"
                closable={true}
                onClose={closeDrawerDirectSales}
                content={
                        <Skeleton active loading={loading}>
                            <div className="col-12 p-0 row">
                                <div className="col-12 p-0" style={{textAlign:"right"}}>
                                    <Button danger>
                                        <div className="trash-delete-config" onClick={() => deleteConfiguration(optionSelectedInput.id)}>
                                            <FontIconsCustom icon={faTrashAlt} />
                                            <ParagraphCustom text={t("rewardsPerNight:delete")}
                                            />
                                        </div>
                                    </Button>
                                </div>
                                <div className="col-12">
                                    <FormCustom
                                        form={form}
                                        onFinish={setEditConfiguration}
                                        layout="vertical"
                                    >
                                        <FormItemsContact
                                            name="name-config"
                                            label={t("rewardsPerNight:name-configuration-label")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("rewardsPerNight:edit-add-configuration.error-form.required")
                                                },
                                                {
                                                    whitespace: true,
                                                    message: t("rewardsPerNight:edit-add-configuration.error-form.white-spaces")
                                                },
                                            ]}
                                            initialValue={inputName}
                                        >
                                            <InputCustom
                                                className="input-add-configuration main-input"
                                                id="name-config"
                                                disabled={optionSelectedInput.isDefault}
                                                defaultValue={optionSelectedInput.name}
                                                onChange={handleChangeName}
                                            />
                                        </FormItemsContact>
                                        <FormItemsContact className="tags-display">
                                            <ParagraphCustom
                                                class="texto-number-info"
                                                text={
                                                    <>
                                                        <strong> {t("rewardsPerNight:to-assing-establishment")} </strong> {`${" " + selectedTags.length + "/" + listOptionsEstablishments.length}`}
                                                    </>
                                                }
                                            />
                                            <Row gutter={16} style={{margin: "5px 0 10px 0"}}>
                                                <Col xs={24} sm={24} md={12}>
                                                    <Button style={{width: "100%"}} type="primary" className='btn-primary' onClick={onAddAllEstablishments}>{t('btn-add-all-establishments')}</Button>
                                                </Col>
                                                <Col xs={24} sm={24} md={12}>
                                                    <Button style={{width: "100%"}} type="primary" className='btn-primary' onClick={onRemoveAllEstablishments}>{t('btn-remove-all-establishments')}</Button>
                                                </Col>
                                            </Row>
                                            {listOptionsEstablishments.map((e: any, index: number) => {
                                                return (
                                                    <TagCheckableCustom
                                                        key={index}
                                                        checked={selectedTags.some((es: Establishments) => e.id === es.id)}
                                                        onChange={checked => handleChange(e, checked)}
                                                    >
                                                        {e.name}
                                                    </TagCheckableCustom>
                                                );
                                            })}
                                        </FormItemsContact>
                                        <FormItemsContact>
                                            <div className="direct-sale-btn-align">
                                                <ButtonsCustom
                                                    htmlType="submit"
                                                    class="primary-button submit-btn-direct-sale"
                                                    content={t("rewardsPerNight:saved")}
                                                />
                                                <ButtonsCustom
                                                    onClick={closeDrawerDirectSales}
                                                    class="default-button cancel-btn-direct-sale"
                                                    content={t("rewardsPerNight:cancel")}
                                                />
                                            </div>
                                        </FormItemsContact>
                                    </FormCustom>
                                </div>
                            </div>
                        </Skeleton>
                }
            />
            {showModalConfirmDeleteModifications &&
                <ModalConfirmationsActions
                    title={t("rewardsPerNight:delete-configuration-modal.title", {0: optionSelectedInput.name})}
                    description={t("rewardsPerNight:delete-configuration-modal.description")}
                    id={optionSelectedInput.id}
                    functionModal={setEditConfiguration}
                    setShowModalConfirmAction={closedAllWindowsOpen}
                    buttonOk={t("rewardsPerNight:saved")}
                    buttonCancel={t("rewardsPerNight:cancel")}
                />
            }
        </>
    );
}
