import EndPointConfig from '../../models/api/endPointConfig';

const controller : string = 'Configurations';

/// Define all EndPoints for Rewards per night
export const RewardsPerNightEP : EndPointConfig[] = [

    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetConfigurations'),

    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetConfiguration')
        .setPattern('{id}'),

    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('directsales')
        .setAlias('GetDirectSales'),

    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)
        .setActionName('directsales')
        .setAlias('PutDirectSales'),

    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setAlias('AddConfigurations'),

    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)
        .setPattern('{id}')
        .setAlias('SetEditConfiguration'),

    new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller)
        .setPattern('{id}')
        .setAlias('DeleteConfiguration'),

    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)
        .setPattern('{id}/seasons/{seasonId}')
        .setAlias('EditSeason'),

    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setPattern('{id}/seasons')
        .setAlias('AddSeasons'),

    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setActionName("CloneConfigurationToTarget")
        .setAlias('CloneConfigurationToTarget'),
];
