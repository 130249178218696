import EndPointConfig from "../../models/api/endPointConfig";

const controller = "RoomTypes";

/// Define all EndPoints for channels section.
export const RoomsEP: EndPointConfig[] = [
  // Envio de formulario a la API
  new EndPointConfig().setMethod("GET").setController(controller).setAlias("GetRoomTypes"),

  new EndPointConfig().setMethod("GET").setController(controller).setPattern("{id}").setAlias("GetRoomTypeById"),

  new EndPointConfig().setMethod("POST").setController(controller).setAlias("CreateRoomType"),

  new EndPointConfig().setMethod("PUT").setController(controller).setAlias("UpdateRoomType"),

  new EndPointConfig().setMethod("DELETE").setController(controller).setPattern("{id}").setAlias("DeleteRoomType")
];
