import React, { useContext, useEffect, useState } from "react";
import { Upload, message } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';


declare type UploadListType = 'text' | 'picture' | 'picture-card';


interface Parameters {
    accept?: string,
    name: string,
    listType: UploadListType,
    className: string,
    showUploadList: boolean,
    action?: string,
    alt?: string,
    width: string,
    height: string,
    beforeUpload: any,
    handleChange: any,
    image?:string,
}

export const UploadCustomComponent = (params : Parameters) => {
    const [image, setImage] = useState<string>('');

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Subir imagen</div>
        </div>
    )

    useEffect(()=>{
        if (params.image){
            setImage(params.image);
        }
    },[params.image]);


    return(
        <Upload
            accept={params.accept}
            name={params.name}
            listType={params.listType}
            className={params.className}
            showUploadList={params.showUploadList}
            action={params.action}
            beforeUpload={params.beforeUpload}
            onChange={params.handleChange}
        >
            { image.length > 0 ? <img src={image}/> : uploadButton }
        </Upload>
    )
}

