import * as React from "react";
import { Button } from "antd";

export const ButtonsCustomComponent = (param) => {
  return (
    <Button
      id={param.id}
      className={param.class || param.className}
      type={param.type}
      htmlType={param.htmlType}
      onClick={param.onClick}
      disabled={param.disabled}
      size={param.size ?? 'middle'}
      loading={param.loading}
      danger={param.danger}
    >
      {param.content}
    </Button>
  );
};
