import React from 'react';
import { IntlProvider } from 'react-intl';
import { Route, Redirect } from 'react-router-dom';
import { AppLanguage } from '../appLanguages';
import { LanguageStrings } from '../localizations/contentLocalization';

interface Props {
  RouterComponent: React.ComponentClass<any>;
  languages: { [k: number]: string };
  appStrings: { [prop: string]: LanguageStrings };
  defaultLanguage: string;
  children: any;
}

export const LocalizedRouter: React.FC<Props> = ({
  children,
  RouterComponent,
  appStrings,
  defaultLanguage
}) => (
  <RouterComponent>
    <Route path="/:lang([a-z]{2})">
      {({ match, location }) => {
        // Obtener el idioma actual
        const params = match ? match.params : {};
        const { lang = defaultLanguage || AppLanguage.es } = params;

        // Si el idioma no está en la ruta de la ruta, redirija a la raíz del idioma
        const { pathname } = location;
        if (!pathname.includes(`/${lang}/`)) {
          return <Redirect to={`/${lang}/`} />;
        }

        // Devolvemos con IntlProvider con idioma predeterminado establecido
        return (
          <IntlProvider
            locale={ lang }
            messages={ appStrings[lang] }
          >
            { children }
          </IntlProvider>
        );
      }}
    </Route>
  </RouterComponent>
);
