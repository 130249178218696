import EndPointConfig from '../../models/api/endPointConfig';

const controller = 'Notifications';

/// Define all EndPoints for notifications section.
export const NotificationsEP: EndPointConfig[] = [
    // Obtención de datos de notificaciones

    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetNotifications'),
];