import React, { useEffect, useState } from "react";
import { CreateUpdateNews } from './cuNews'

import { useParams } from "react-router-dom";

export const CreateNews = () => {

        const queryParams = useParams();
        const [newsId, setNewsId] = useState('');

        useEffect(()=>{
                if (queryParams.id){
                        setNewsId(queryParams.id);
                }
        }, [queryParams])

        return(
                <div className="special-mb">
                    <CreateUpdateNews id={ newsId }/>
                </div>
        )
};
