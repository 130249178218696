// Referencia de los nombre de rutas que usaremos para dinamizar el enrutador
export enum AppRoute {
    Home = 'routes.home',
    Faqs = 'routes.Faqs',
    Contact = 'routes.contact',
    RecoverPassword = 'routes.recoverPassword',
    LegalAdvice = 'routes.legalAdvice',
    CookiesPolicyR = 'routes.cookiesPolicy',
    PrivacyPolicies = 'routes.privacyPolicies',
    TermConditionsR = 'routes.termConditions',
    HomePrivate = 'routes.homePrivate',
    AgentsRegister = 'routes.agentsRegister',
    PrivacyPoliciesPrivate = 'routes.privacyPoliciesPrivate',
    CookiesPolicyRPrivate = 'routes.cookiesPolicyPrivate',
    TermConditionsRPrivate = 'routes.termConditionsPrivate',
    LegalAdvicePrivate = 'routes.legalAdvicePrivate',
    Regularization = 'routes.regularization',
    News = 'routes.news',
    DetailNews = 'routes.news.detail',
    CreateNews = 'routes.news.create',
    EditNews = 'routes.news.edit',
    Rewards = 'routes.rewards',
    RewardsPerNight = 'routes.rewardsPerNight',
    FaqsPrivate = 'routes.FaqsPrivate',
    Config = 'routes.config',
    AdvancedRetribution = 'routes.advanced',
    Statistics = 'routes.statistics',
    Targets = 'routes.targets',
    Billing = 'routes.billing'
}
