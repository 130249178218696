import { useLanguages } from "../api/config";
import {
	DEFAULT_LANGUAGE,
	LANGUAGE_SUPPORT_SYSTEM,
} from "../constants/constants";

// Funcion que obtiene los idiomas de la API y comparamos con el del navegador para saber si esta soportado por el sistema
export const useUserLang = () => {
	const url = window.location.pathname;
	let userLang;

	// Obtenemos los idiomas de la API
	const apiAllLanguages = useLanguages()
	let param = false;

	if (
		url.includes(`/${LANGUAGE_SUPPORT_SYSTEM[0]}/`) ||
		url.includes(`/${LANGUAGE_SUPPORT_SYSTEM[1]}/`)
	) {
		userLang = url.split("/")[1];
		param = true;
	} else {
		// Lenguaje obtenido del navegador del cliente
		userLang = navigator.language;
	}

	// Comprobamos que existe en el sistema
	const existLangInSystem = apiAllLanguages.filter((e) =>
		e.id.includes(userLang)
	);

	// Si no existe, abstraemos la parte del idioma del CultureCode para mostrar ese idioma aunque no sea del pais
	if (!existLangInSystem.length || param) {
		const languageCompare = apiAllLanguages.filter(
			(e) =>
				e.id.split("-")[0].includes(userLang.split("-")[0]) ||
				e.id
					.split("-")[0]
					.includes(userLang.lenght > 3 ? userLang.split("-")[0] : userLang)
		);

		if (languageCompare.length) {
			userLang = languageCompare[0].id;
		} else {
			userLang = DEFAULT_LANGUAGE;
		}
	}

	return userLang;
};
