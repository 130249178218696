import EndPointConfig from '../../models/api/endPointConfig';

const controller = 'contacts';

/// Define all EndPoints for contact section.
export const ContactEP : EndPointConfig[] = [
    // Envio de formulario a la API

    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setActionName('backoffice/form')
        .setAlias('SendBackOfficeForm'),

        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('backoffice/types')
        .setAlias('GetContactEmailTypes'),
];
