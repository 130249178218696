// Modelo de los Faqs
export class ClientDataModel {
    constructor (
        public url: string = '',
        public emailContact: string = '',
        public socialName: string = '',
        public incentiveSiteName: string = '',
        public identityCode: string = '',
        public socialAddress: string = '',
        public mercantileRegistry: string = '',
        public telephone: string = '',
        public ownURLPrivacyPolicy: string = '',
    ) {

    }
}