import EndPointConfig from "../../models/api/endPointConfig";

const controller = "Customizations";

/// Define all EndPoints for customizations section.
export const CustomizationsEP: EndPointConfig[] = [
  // Envio de formulario a la API
  new EndPointConfig()
    .setMethod("GET")
    .setController(controller)
    .setActionName("GetOtherConfigurations")
    .setAlias("GetOtherConfigurations"),
  new EndPointConfig()
    .setMethod("GET")
    .setController(controller)
    .setActionName("mandatoryCompanyName")
    .setAlias("GetMandatoryName"),

  new EndPointConfig()
    .setMethod("PUT")
    .setController(controller)
    .setActionName("mandatoryCompanyName")
    .setAlias("UpdateMandatoryName"),

  new EndPointConfig()
    .setMethod("GET")
    .setController(controller)
    .setActionName("allowReservationWithSameLocator")
    .setAlias("GetAllowReservations"),

  new EndPointConfig()
    .setMethod("GET")
    .setController(controller)
    .setActionName("powerBIUrl")
    .setAlias("GetPowerBIURL"),

  new EndPointConfig()
    .setMethod("GET")
    .setController(controller)
    .setActionName("GetAllowGeneralRanking")
    .setAlias("GetAllowGeneralRanking"),

  new EndPointConfig()
    .setMethod("GET")
    .setController(controller)
    .setActionName("GetAllowPersonalRanking")
    .setAlias("GetAllowPersonalRanking"),

  new EndPointConfig()
    .setMethod("PUT")
    .setController(controller)
    .setActionName("UpdateAllowGeneralRanking")
    .setAlias("UpdateAllowGeneralRanking"),

  new EndPointConfig()
    .setMethod("POST")
    .setController(controller)
    .setActionName("UpdateRankingConfig")
    .setAlias("UpdateRankingConfig"),

  new EndPointConfig()
    .setMethod("PUT")
    .setController(controller)
    .setActionName("UpdateAllowPersonalRanking")
    .setAlias("UpdateAllowPersonalRanking"),
    
  new EndPointConfig()
    .setMethod("PUT")
    .setController(controller)
    .setActionName("allowReservationWithSameLocator")
    .setAlias("UpdateAllowReservations"),

    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('mindatecheckin')
        .setAlias('GetMinDateCheckin'),

      new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)
        .setActionName('updatemincheckin')
        .setAlias('UpdateMinDateCheckin'),


      new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)
        .setActionName('SetNoIndexNoFollowConfiguration')
        .setAlias('SetNoIndexNoFollowConfiguration'),


      new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('GetNoIndexNoFollowConfiguration')
        .setAlias('GetNoIndexNoFollowConfiguration'),

      new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('GetHideBillingAletsAndDetailsConfiguration')
        .setAlias('GetHideBillingAletsAndDetailsConfiguration'),

      new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('GetGtmIdConfig')
        .setAlias('GetGtmIdConfig'),

      new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setActionName('SetGtmIdConfig')
        .setAlias('SetGtmIdConfig'),
];
