import ReactHtmlParser from 'react-html-parser';
import { API_URL_TEST } from '../constants/constants';

import EndPointConfig from '../models/api/endPointConfig';

import { RegisterEndPoints } from './endpoints/';
import { notification } from 'antd';
import { ParamsApi } from '../models/api/paramsApi'

import { ApiResponseStatus } from '../constants/enums'

let t: any = null;

interface APIResponse{
    additionalMessages: string[],
    code: string,
    data: any,
    message: string,
    title: string,
    type: string
}

export const init = (translations: any): void => {
    t = translations;
}


export const doAction = (alias: string, translations: any, params: ParamsApi = null): Promise<APIResponse> => {
    t = translations;
    const endPointInfo: EndPointConfig = getEndPointInfo(alias);

    if (!endPointInfo) {
        notification.error({
            message: t('ajaxApi:error-notification'),
            description: ReactHtmlParser(t("ajaxApi:end-point-undefined", { 0: alias })),
        })
        return Promise.reject(null);
    }

    const actionURL = getUrlEndPoint(endPointInfo, params);
    const init = generateInitFetch(endPointInfo, params);
    try {
        console.warn(`calling to: ${actionURL}`);
        return fetch(actionURL, init)
            .then(response => checkStatus(response))
            .then(response => response.json())
            .then(response => showMessages(response))
    } catch (error) {
        notification.error({
            message: t('generic-error-notification'),
            description: error.message
        })
    }
}



export const doActionWithoutNotification = (alias: string, translations: any, params: ParamsApi = null): Promise<APIResponse> => {
    t = translations;
    const endPointInfo: EndPointConfig = getEndPointInfo(alias);

    const actionURL = getUrlEndPoint(endPointInfo, params);
    const init = generateInitFetch(endPointInfo, params);
    try {
        console.warn(`calling to: ${actionURL}`);
        return fetch(actionURL, init)
            .then(response => checkStatus(response))
            .then(response => response.json())
    } catch (error) {
 
    }
}


const showMessages = (response: APIResponse) : Promise<APIResponse> => {
    if (response.message && response.message.length > 0){
        switch(response.type){
            case ApiResponseStatus.Success:
            case ApiResponseStatus.Information:
                notification.success({
                    message: response.title ?? "",
                    description: response.message,
                })
                break;

            case ApiResponseStatus.Warning:
                notification.warning({
                    message: response.title?? "",
                    description: response.message,
                })
                break;
            case ApiResponseStatus.Error:
                notification.error({
                    message: response.title?? "",
                    description: response.message,
                })
                break;
            case ApiResponseStatus.NoShow:
            case ApiResponseStatus.Validation:
                // No muestra mensaje de alerta
                break;
            default:
                notification.error({
                    message: t('generic-error-notification'),
                    description: t('generic-error-message'),
                })
                break;
        }
    }
    return Promise.resolve(response);
}

const checkStatus = (response: Response): Promise<Response> => {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
    } else if (response.status === 401) {
        sessionStorage.removeItem("token");
        document.cookie = "userToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        window.location.reload();
    } else {
        return response.text().then((text) => {
            throw new Error(`${response.status}: ${text}`);
        });
    }
}

/*
description:
    Get the URL to call API EndPoint
Params:
    endPointInfo : Info about end point
Result:
    url : String with URL
*/
const getUrlEndPoint = (endPointInfo: EndPointConfig, params: ParamsApi = null): string => {
    let url = `${API_URL_TEST}/${endPointInfo.controller}`;
    if (endPointInfo.usingActionName) {
        url += `/${endPointInfo.actionName}`;
    }

    if (params && params.path) {
        // Si tengo params.pattern los seteo
        url = setPatternParams(params.path, endPointInfo.pattern, url);
    }

    if (params && params.query) {
        // Si tengo params.url los seteo
        url = setUrlParams(params.query, url);
    }

    return url;
}

/*
* description:
*       Set the url params
* Params:
*       method: method used to call the api
*       params:
*/
const setPatternParams = (patternParams: any, pattern: string, url: string) => {

    let patternVal = "";
    Object.keys(patternParams).map((v, i) => {
        // v está definido como key en pattern
        if (pattern.indexOf(v) !== -1) {
            // reemplazamos {v} por {patternParams[v]}
            patternVal = pattern.replace(`{${v}}`, patternParams[v]);
            pattern = patternVal;
            // pattern.replace(`{${v}}`, patternParams[v]);
        } else {
            // v NO está definido como key en pattern => PROBLEMA
            console.error("Pattern doesn't match with model");
        }
    });

    return `${url}/${patternVal}`;
}

/*
* description:
*       Set the url params
* Params:
*       method: method used to call the api
*       params:
*/
const setUrlParams = (urlParams: any, url: string, useSeparator: boolean = true, urlBase: string = '') => {

    Object.keys(urlParams).map((v, i) => {
        const separator = useSeparator ? (i === 0 ? '?' : '&') : '';

        if (urlParams[v] instanceof Object) {
            const base = `${urlBase}${separator}${v}.`;

            url += setUrlParams(urlParams[v], '', false, base);
        } else {
            url += `${urlBase}${separator}${v}=${urlParams[v]}`;
        }
    });

    return url;
}

/*
description:
    Get the endpoint info that matches the action name
Params:
    actionName : Action name to search
Result:
    EndPointConfig : Info about the end point.
*/
const getEndPointInfo = (alias: string) => {
    const endPoints: EndPointConfig[] = RegisterEndPoints;
    let result: EndPointConfig;

    const coincidences: EndPointConfig[] = endPoints.filter((i: EndPointConfig) => {
        return i.alias.toLowerCase() === alias.toLowerCase();
    });

    if (coincidences.length > 1) {
        console.error("Multiple end point found");
    } else {
        result = coincidences.pop();
    }

    return result;
}

/*
description:
    Create the headers to call API
Params:
    void
Result:
    Headers : Header info
*/
const getHeaders = (): Headers => {
    const headers = new Headers();

    // TODO : Añadir aquí el token de la sesión que está guardado en la Cookie @Angel:
    if (sessionStorage.getItem('token') !== null || sessionStorage.getItem('token') !== undefined)
        headers.append('X-Authorization', sessionStorage.getItem('token'));
    // TODO : Añadir aquí el idioma en la que se está comunicando la aplicación con el usuario final (Está guardado en un contexto @Angel)
    // headers.append('User-Language', getcontext('xxxx'))

    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    return headers;
}

/*
description:
    Init the fetch params
Params:
    endPointInfo : Info about the end point to get the method
    params : Parameters that we can send to EndPoint
Result:
    Headers : Header info
*/
const generateInitFetch = (endPointInfo: EndPointConfig, params: ParamsApi) => {
    return {
        method: endPointInfo.method,
        headers: getHeaders(),
        body: params && JSON.stringify(params.body)
    };
}
