import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import "./pop-up-cookies.sass";
import "../modalCookies/modal-cookies.sass";
import { CookiePopUp } from "../../../../hooks/context/cookies";
import { useCookies } from "react-cookie";
import {ModalCookies} from "../modalCookies/modalCookies";
import {
	LIST_COOKIES_DEFAULT,
	DURATION_COOKIE,
} from "../../../../constants/constants";
import { Link } from "react-router-dom";
import { LinkActiveContext } from "../../../../hooks/context/linkActive";
import { ButtonsCustom } from "../../../custom";

// Componente popup de informacion de las cookies
export const PopUpCookies = () => {
	// Lenguaje para los textos
	const { t } = useTranslation(["urls", "cookies"]);

	// Context para mostrar o ocultar el componente popup
	const popupCookies = useContext(CookiePopUp);

	// Estado para mostrar o ocultar modal cookie
	const [modalCookie, setmodalCookie] = useState(false);
	// Estado con la lista de cookies aceptadas por el sistema
	const [cookiesSave] = useState(LIST_COOKIES_DEFAULT);

	// Cookie comprobador si se aceptaron o no las cookies
	const [, setCookies] = useCookies(["cookies"]);
	// Lista de cookies que se pueden modificar en la aplicacion
	const [, setCookiesList] = useCookies(["cookiesList"]);

	// Manejador del boton aceptar del popup
	const validateCookies = () => {
		const date = Date.now() + DURATION_COOKIE;
		setCookies("cookies", "true", { path: "/", expires: new Date(date) });
		setCookiesList("cookiesList", cookiesSave, {
			path: "/",
			expires: new Date(date),
		});
		popupCookies.UpdateVisible(false);
	};

	const linkActive = useContext(LinkActiveContext);

	return (
		<>
			{popupCookies.GetVisible() && (
				<div className="col-lg-12 container-cookies">
					<div className="col-lg-8 col-md-9 col-sm-8 col-12 max-height-pop-up">
						<p className="title-popup-switch">
							{t("cookies:pop-up.title")}
						</p>
						<p>
							{t("cookies:pop-up.text")}
							<Link
								id="link-cookies-policy"
								className="primary-links"
								to={t("urls:public.cookies-policy")}
								onClick={() =>
									linkActive.updateValueUrl(
										t("urls:public.cookies-policy")
									)
								}
							>
								{t("cookies:pop-up.link")}
							</Link>
						</p>
					</div>
					<div className="row col-lg-4 col-md-3 col-sm-4 col-12 align-vertical">
						<div className="col-xl-6 col-12 align-button">
							<ButtonsCustom
								id="btn-cookie-settings"
								class="width-button default-button-transparent"
								onClick={() => setmodalCookie(true)}
								content={t("cookies:pop-up.button-settings")}
							/>
						</div>
						<div className="col-xl-6 col-12 align-button">
							<ButtonsCustom
								id="btn-cookie-accept"
								class="width-button primary-button"
								type="primary"
								onClick={validateCookies}
								content={t("cookies:pop-up.button-accept")}
							/>
						</div>
					</div>
					<div>
						{modalCookie && (
							<ModalCookies setmodalCookie={setmodalCookie} />
						)}
					</div>
				</div>
			)}
		</>
	);
};

