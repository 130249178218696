import React, { useState, useContext, useEffect } from "react";
import "./limitAmountConfig.sass";
import {
    ParagraphCustom,
    TextCustom,
    DrawerCustom,
    InputCustom,
    ButtonsCustom,
    FormCustom,
    FormItemsContact
} from "../../custom";
import { useTranslation } from "react-i18next";
import { RestApiClientContext } from "../../../hooks/context/pageContext";
import { DirectSales, Amount, CurrencyConversion, DataLimitAmountsDrawer, LimitedAmount } from '../interfaces/directSalesConfigInterface';
import { DIRECT_SALE_FIXED_VALUE, DIRECT_SALE_PERCENTAGE } from '../../../constants/constants';
import { Form } from "antd";
import { NO_CHART_REPITED, NO_CHART_REPITED_AND_NUMBER_DECIMAL, ONLY_NUMBER_D } from "../../../constants/regex";
import { convertStringToNumber } from "../../../utils/convertStringToNumber";
import { numberFormat } from '../../../utils/formatAmount';
import { LangContext } from "../../../hooks/context/lang";


export const DrawerLimitAmount = (props: DataLimitAmountsDrawer) => {

	const userLangContext = useContext(LangContext);
    const restApiClient = useContext(RestApiClientContext);

    const { t } = useTranslation(["rewardsPerNight"]);
    const [form] = Form.useForm();

    const [limitAmounts, setLimitAmounts] = useState<LimitedAmount[]>([]);
    const [visible, setVisible] = useState<boolean>(false);

    const closeDrawerDirectSales = () => {
        props.handleVisible(false);
    };

    useEffect(() => {
       setVisible(props.visible);
    }, [props.visible]);

    useEffect(()=>{
        if(props.limitedamounts){
            setLimitAmounts(props.limitedamounts.amounts);
        }
    }
    ,[props.limitedamounts]);


    const convertCurrency = async () => {

        if (limitAmounts && limitAmounts.length > 0){
            const finalResult : LimitedAmount[] = [];
            const currencyFrom : string = limitAmounts[0].currencyId;
            const amountFrom: number = limitAmounts[0].limitAmount;
            finalResult.push(limitAmounts[0]);
            for(let i=1; i<limitAmounts.length; i++){
                const conversion: CurrencyConversion = {
                    currencyFrom: currencyFrom,
                    amount: amountFrom,
                    currencyTo: limitAmounts[i].currencyId
                }

                await restApiClient.getCurrencyConversion(conversion)
                    .then((data) => {
                        const newValue : LimitedAmount = {
                            currencyId: conversion.currencyTo,
                            limitAmount: data
                        }
                        finalResult.push(newValue)
                    })
                    .finally(() => {
                    })
            }
            setLimitAmounts([]);
            setLimitAmounts(finalResult);
        }
    }

    const saveValues = async () => {
        
        restApiClient.UpdateLimitAmountRewards(limitAmounts)
            .then((data) => {
                if (data) {
                    props.onchange();
                    closeDrawerDirectSales();
                }
            })
    }
    
    const changeValue = (currencyId: string, value: number)=>{
        let actualValues : LimitedAmount[] = limitAmounts;
        const index = actualValues.findIndex(a=>a.currencyId === currencyId);

        actualValues[index].limitAmount = Number.parseFloat(value.toString().replace(",","."));

        setLimitAmounts(actualValues);

    }

    return (
        <DrawerCustom
            title={t('rewardsPerNight:label-limit-amount')}
            visible={visible}
            info={""}
            width={360}
            placement="right"
            closable={true}
            onClose={closeDrawerDirectSales}
            content={
                <>
                    <ParagraphCustom
                        text={t('rewardsPerNight:detail-limit-amount')}
                        class="direct-sale-description"
                    />
                    <FormCustom
                        form={form}
                        onFinish={saveValues}
                    >
                        <FormItemsContact
                            name="radio-group"
                            className="row-form-item"
                        >
                            <TextCustom
                                        text={t('rewardsPerNight:matter-fixed')}
                                    />
                                    {
                                        limitAmounts.map((e, i) => {
                                            return (
                                                <FormItemsContact
                                                    className="margin-b-inputs"
                                                    name={e.currencyId}
                                                    rules={
                                                        [
                                                            {
                                                                validator: (_, value) => (value
                                                                    ?
                                                                    ONLY_NUMBER_D.test(value)
                                                                        ?
                                                                        NO_CHART_REPITED.test(value)
                                                                            ?
                                                                            NO_CHART_REPITED_AND_NUMBER_DECIMAL.test(value)
                                                                                ?
                                                                                Promise.resolve()
                                                                                :
                                                                                Promise.reject(t('reward:modal.validations.num-decimals'))
                                                                            :
                                                                            Promise.reject(t('reward:modal.validations.formated'))
                                                                        :
                                                                        Promise.reject(t('reward:modal.validations.number'))
                                                                    :
                                                                    Promise.resolve())
                                                            }
                                                        ]
                                                    }
                                                >
                                                    <div key={i}>
                                                        <InputCustom
                                                            id={e.currencyId}
                                                            defaultValue={numberFormat(e.limitAmount, userLangContext.lang)}
                                                            addonBefore={`${e.currencyId}`}
                                                            className="direct-sale-input main-input-currency"
                                                            onChange={(v:any)=>{changeValue(e.currencyId, v.target.value)}}
                                                            placeholder="0.00"
                                                        />
                                                        {
                                                            i === 0 &&
                                                            <div className="primary-button submit-btn-direct-sale" onClick={convertCurrency}>
                                                                <ParagraphCustom
                                                                    class="convert-currency clicable"
                                                                    text={t('rewardsPerNight:convert-currency')}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </FormItemsContact>
                                            );
                                        })
                                    }
                            <ParagraphCustom
                                class="direct-sale-description convert-currency fixed-amount"
                                text={t('rewardsPerNight:direct-sale-description')}
                            />
                        </FormItemsContact>
                        <FormItemsContact>
                            <div className="direct-sale-btn-align">
                                <ButtonsCustom
                                    htmlType="submit"
                                    class="primary-button submit-btn-direct-sale"
                                    content={t('rewardsPerNight:saved')}
                                />
                                <ButtonsCustom
                                    onClick={closeDrawerDirectSales}
                                    class="default-button cancel-btn-direct-sale"
                                    content={t('rewardsPerNight:cancel')}
                                />
                            </div>
                        </FormItemsContact>
                    </FormCustom>
                </>
            }
        />
    );
};
