import Language from './languagesManagement';

// Modelo para el manejo de la lista de lenguajes soportados por el sistema
export class AllLanguagesManagement {
    public Languages: Language[]

    constructor() {
        this.Languages = [];
    }

    UpdateAllLangContext = (value: Language[]) => {
        this.Languages = value;
    }

    GetLanguage = (): Language[] => {
        return this.Languages;
    }
}
