import React, { useState, useContext, useEffect } from "react";
import * as AjaxAPI from "../../../api/ajaxApi";
import "./privacy-policies.sass";
import { Helmet } from "react-helmet-async";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../hooks/context/config";
import ReactHtmlParser from "react-html-parser";
import scrollLib from "react-scroll";
import { URL_BASE } from "../../../constants/constants";
import { showNotification } from "../../../utils/notificationsGeneric";
import { ClientDataModel } from "../../../models/config/clientData";
import { TitleCustom } from "../../custom";
import { useLegalTexts } from "../legalTexts";

const scroll = scrollLib.animateScroll;

// Componente de información de Política de privacidad de la app
export const PrivacyPolicies = () => {
	const infoClient = useContext(ConfigContext); // TODO: Actualizar cuando obtengamos los datos de infoClient confirmados
	const { t } = useTranslation(["privacyPolicies", "seo", "urls"]);

	const clientData = useLegalTexts(t)

	useEffect(() => {

		scroll.scrollToTop();

	}, []);


	return (
		<>
			<Helmet>
				<title>
					{infoClient.incentiveSiteName} |{" "}
					{t("seo:title.privacy-policies")}
				</title>
				<meta
					name="description"
					content={t("seo:description.privacy-policies", {
						0: infoClient.incentiveSiteName,
					})}
				/>
				<meta name="robots" content="noindex,nofollow" />
				<link
					rel="canonical"
					href={URL_BASE + t("urls:public.privacy-policies")}
				/>
				<link
					rel="icon"
					type="image/ico"
					href={infoClient.favicon}
					sizes="32x32"
				/>
				<link
					href={URL_BASE + t("urls:public.privacy-policies")}
					hrefLang="es-ES"
					rel="alternate"
				/>
			</Helmet>

			<div className="container mt-3 special-mb">
				<TitleCustom
					level={4}
					class="main-title-color"
					text={t("privacyPolicies:main-title")}
				/>
				{ReactHtmlParser(
					t("privacyPolicies:main-paragraph", {
						0: clientData.ownURLPrivacyPolicy,
						1: clientData.socialName,
						2: clientData.incentiveSiteName,
						3: clientData.identityCode,
						4: clientData.socialAddress,
						5: clientData.mercantileRegistry,
					})
				)}

				{ReactHtmlParser(t("privacyPolicies:section.title.A"))}
				{ReactHtmlParser(
					t("privacyPolicies:section.paragraph.A", {
						0: clientData.identityCode,
					})
				)}

				{ReactHtmlParser(t("privacyPolicies:section.title.B"))}
				{ReactHtmlParser(t("privacyPolicies:section.paragraph.B"))}

				{ReactHtmlParser(t("privacyPolicies:section.title.C"))}
				{ReactHtmlParser(t("privacyPolicies:section.paragraph.C"))}

				{ReactHtmlParser(t("privacyPolicies:section.title.D"))}
				{ReactHtmlParser(t("privacyPolicies:section.paragraph.D"))}

				{ReactHtmlParser(t("privacyPolicies:section.title.E"))}
				{ReactHtmlParser(t("privacyPolicies:section.paragraph.E"))}

				{ReactHtmlParser(t("privacyPolicies:section.title.F"))}
				{ReactHtmlParser(t("privacyPolicies:section.paragraph.F"))}

				{ReactHtmlParser(t("privacyPolicies:section.title.G"))}
				{ReactHtmlParser(
					t("privacyPolicies:section.paragraph.G", {
						0: clientData.socialAddress,
						1: clientData.emailContact,
					})
				)}

				{ReactHtmlParser(t("privacyPolicies:section.title.H"))}
				{ReactHtmlParser(t("privacyPolicies:section.paragraph.H"))}

				{ReactHtmlParser(t("privacyPolicies:section.title.I"))}
				{ReactHtmlParser(t("privacyPolicies:section.paragraph.I"))}

				{ReactHtmlParser(t("privacyPolicies:section.title.J"))}
				{ReactHtmlParser(t("privacyPolicies:section.paragraph.J"))}
			</div>
		</>
	);
};