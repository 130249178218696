import EndPointConfig from "../../models/api/endPointConfig";

const controller = "AdvancedConfiguration";

/// Define all EndPoints for Reset Password contact.
export const AdvancedConfigurationEP: EndPointConfig[] = [
  // Obtiene el listado de agentes con los parametros de busquda que se apliquen
  new EndPointConfig().setMethod("GET").setController(controller).setAlias("GetAdvancedConfiguration"),

  new EndPointConfig().setMethod("POST").setController(controller).setAlias("CreateAdvancedConfiguration"),

  new EndPointConfig().setMethod("PUT").setController(controller).setPattern("{id}").setAlias("UpdateAdvancedConfiguration"),

  new EndPointConfig().setMethod("PUT").setController(controller).setActionName('orders').setAlias("UpdateAdvancedConfigurationOrder"),

  new EndPointConfig().setMethod("DELETE").setController(controller).setPattern("{id}").setAlias("DeleteAdvancedConfiguration"),

  new EndPointConfig().setMethod("PATCH").setController(controller).setPattern("{id}/ChangeAdvancedConfigurationStatus/{status}").setAlias("ChangeAdvancedConfigurationStatus"),
];
 