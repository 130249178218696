import React, { useState, useContext, useEffect, useSyncExternalStore } from "react";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";
import "./structureShowInfoAgent.sass";
import { faUser, faGlobe, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import {
	FontIconsCustom,
	ButtonsCustom,
	ParagraphCustom, TextAreaCustomPro, TextCustom
} from "../../custom";
import moment from "moment";
import { getLocaleDatePicker } from "../../../utils/datePicker";
import { PickerLocale } from "antd/es/date-picker/generatePicker";
import { showNotification } from "../../../utils/notificationsGeneric";
import { RestApiClientContext } from "../../../hooks/context/pageContext";

export const StructureShowInfoAgent = (param) => {

	const [agentAddress, setAgentAddress] = useState<string>('');
	const [agencyName, setAgencyName] = useState<JSX.Element>(<></>);
	useEffect(()=>{
		if (param){
			var agentData = param.data;
			var agentData = param.data;
			if (agentData.idAgentType == 2){
				// Agente independiente
				try{
					if (agentData.companyName && agentData.companyName.length > 0){
						setAgencyName(<>{agentData.companyName}</>)
					}else{
						setAgencyName(<>{t("agentsRegister:list.independent")}</>);
					}
				}catch(ex){
					console.log("EX", ex.message)
					setAgencyName(<>N/D</>)
				}

				try{
					setAgentAddress(agentData.location.formattedAddress);
				}catch(ex){
					console.log("EX", ex.message)
					setAgentAddress('N/D')
				}
			}else{
				// Agente de agencia
				try{
					setAgencyName(<>{agentData.agency.commercialName} <small className='agency-brand-name'>{agentData.agency.brandName}</small></>)
				}catch(ex){
					console.log("EX", ex.message)
					setAgentAddress('N/D')
				}

				try{
					setAgentAddress(agentData.agency.address);
				}catch(ex){
					console.log("EX", ex.message)
					setAgentAddress('N/D')
				}
			}
		}
	},[param])

	const restApiClient = useContext(RestApiClientContext);

	const { t } = useTranslation([
		"contact",
		"seo",
		"urls",
		"agentsRegister",
		"translation",
	]);

	const local: PickerLocale = getLocaleDatePicker();

	const [commentStateOld, setCommenStatetOld] = useState<string>(param.data.comments);

	const [commentStateNew, setCommentStateNew] = useState<string>(param.data.comments);

	const [disabledButtonCancel, setDisabledButtonCancel] = useState<boolean>(param.data.comments !== "" ? true : false);

	const [agentInfo] = useState(param.data);


	const [haveComment, setHaveComment] = useState<boolean>(true);

	const ValueComment = (e) => {
		setCommentStateNew(e.target.value);
		param.setCommentL(e.target.value);
		if (e.target.value !== "") {
			setDisabledButtonCancel(false)
		}
		setDisabledButtonCancel(true)
	};

	const SaveComment = (idAgent, commentsAgent) => {
		restApiClient.updateAgentComments(idAgent, commentsAgent)
			.then((data) => {
				if (data) {
					setCommenStatetOld(commentsAgent)
					setCommentStateNew(commentsAgent)
					setHaveComment(true);
				} else {
					showNotification(
						"error",
						t("translation:error.title"),
						t("translation:error.description"),
						"topRight"
					);
				}
				setDisabledButtonCancel(commentsAgent !== "" ? true : false)
			})
	};

	const CancelSaveComment = () => {
		setHaveComment(true)
		setDisabledButtonCancel(false)
		setCommentStateNew(commentStateNew === "" && param.data.comments === commentStateOld ? param.data.comments : commentStateOld)
		setDisabledButtonCancel(commentStateNew !== "" && commentStateOld !== "" ? true : false)
	}

	return (
		<div>
			<div className="display-elements-list">
				<div>
					<FontIconsCustom
						classIcon={"icons-list-show color-title-show-agent"}
						icon={faUser}
					/>
				</div>
				<div>
					<ParagraphCustom
						class="mb-0"
						text={
							<strong className="color-text-info">
								{t("agentsRegister:drawer.name-surname")}
							</strong>
						}
					/>
					<ParagraphCustom
						class="mb-2 color-text-info"
						text={agentInfo.nameSurname}
					/>
					<ParagraphCustom
						class="mb-0 "
						text={
							<strong className="color-text-info">
								{t("agentsRegister:drawer.charge")}
							</strong>
						}
					/>
					<ParagraphCustom
						class="mb-2 color-text-info"
						text={agentInfo.position}
					/>
					
					
					<ParagraphCustom
						class="mb-0"
						text={
							<strong className="color-text-info">
								{t("agentsRegister:drawer.date-birth")}
							</strong>
						}
					/>
					<ParagraphCustom
						class="mb-2 color-text-info"
						text={moment(agentInfo.bornDate).format(
							local.dateFormat
						)}
					/>
					<ParagraphCustom
						class="mb-0 "
						text={
							<strong className="color-text-info">
								{t("agentsRegister:drawer.document")}
							</strong>
						}
					/>
					<ParagraphCustom
						class="mb-2 color-text-info"
						text={
							agentInfo.idDocumentType + " " + agentInfo.document
						}
					/>
					<ParagraphCustom
						class="mb-0 color-text-info"
						text={
							<strong className="color-text-info">
								{t("agentsRegister:drawer.email")}
							</strong>
						}
					/>
					<ParagraphCustom
						class="mb-0 color-text-info"
						text={
							<TextCustom
                            copyable={true}
							class={"mb-2 color-text-info"}
							text={agentInfo.email}
						/>
							
						}
					/>
					<ParagraphCustom
						class="mb-0"
						text={
							<strong className="color-text-info">
								{t("agentsRegister:drawer.phone")}
							</strong>
						}
					/>
					<ParagraphCustom
						class={"mb-2 color-text-info"}
						text={agentInfo.telephone}
					/>
					<ParagraphCustom
						class="mb-0"
						text={
							<strong className="color-text-info">
								{t("agentsRegister:drawer.lang")}
							</strong>
						}
					/>
					<ParagraphCustom
						class={"color-text-info"}
						text={agentInfo.idLanguage}
					/>
				</div>
			</div>
			<div className="display-elements-list mt-2">
				<div>
					<FontIconsCustom
						classIcon={"icons-list-show"}
						icon={faGlobe}
					/>
				</div>
				<div>
					<ParagraphCustom
						class="mb-0"
						text={
							<strong className="color-text-info">
								{
									agentInfo.idAgentType == 1 ? t("agentsRegister:drawer.agency") : t("agentsRegister:list.independent")
								}
							</strong>
						}
					/>
					<ParagraphCustom
						class={"mb-1 color-text-info"}
						text={agencyName}
					/>
					<ParagraphCustom
						class="mb-0"
						text={
							<strong className="color-text-info">
								{t("agentsRegister:drawer.address")}
							</strong>
						}
					/>
					<ParagraphCustom
						class={"color-text-info"}
						text={agentAddress}
					/>
				</div>
			</div>
			<div className="display-elements-list mt-2">
				<div>
					<FontIconsCustom
						classIcon={"icons-list-show"}
						icon={faFileAlt}
					/>
				</div>
				<div>
					<ParagraphCustom
						class={"mb-0"}
						text={
							<strong className="color-text-info">
								{t("agentsRegister:drawer.observation")}
							</strong>
						}
					/>
					<ParagraphCustom
						class={"text-obs"}
						text={t("agentsRegister:drawer.obs-text")}
					/>
					{haveComment ?
						(
							<>
								{commentStateOld !== "" &&
									<div className="input-simulate">
										<ParagraphCustom
											class={"mb-0"}
											text={commentStateOld}
										/>
									</div>
								}
								<div className="mt-2">
									<Row>
										<Col
											span={24}
											className="align-text-buttons"
										>
											<ButtonsCustom
												id="btn-accept"
												class={"no-border-button"}
												type=""
												htmlType={null}
												onClick={() =>
													setHaveComment(false)
												}
												disabled={false}
												size={null}
												content={t(
													"agentsRegister:form-buttons.edit"
												)}
											/>
										</Col>
									</Row>
								</div>
							</>
						) : (
							<div>
								<TextAreaCustomPro
									style={{ width: 245 }}
									onChange={ValueComment}
									id={agentInfo.id}
									className={"col-12 main-input"}
									placeholder={""}
									rows={4}
									value={commentStateNew}
									rules={[
										{
											whitespace: true,
											message: t(
												"contact:form.errors.message"
											),
										},
									]}
								/>
								<div className="mt-2">
									<Row>
										<Col
											span={24}
											className="align-text-buttons"
										>
											<ButtonsCustom
												id="btn-accept"
												class={"no-border-button"}
												type=""
												htmlType={null}
												onClick={() =>
													SaveComment(
														agentInfo.id,
														commentStateNew
													)
												}
												disabled={false}
												size={null}
												content={t(
													"agentsRegister:drawer.button"
												)}
											/>
											{disabledButtonCancel && <ButtonsCustom
												id="btn-cancel"
												class={
													"no-border-button-cancel mr-3"
												}
												type=""
												htmlType={null}
												onClick={CancelSaveComment}
												disabled={false}
												size={null}
												content={t(
													"agentsRegister:form-buttons.cancel"
												)}
											/>
											}
										</Col>
									</Row>
								</div>
							</div>
						)
					}
				</div>
			</div>
		</div>
	);
};

