import { Skeleton } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { RestApiClientContext } from 'src/hooks/context/pageContext';

import './statistics.sass'

interface Props{}


export const Statistics = (props: Props) : JSX.Element => {
    const restApiClient = useContext(RestApiClientContext);

    const [powerBIURL, setPowerBIURL] = useState<string>('');
    const [loadingData, setLoadingData] = useState<boolean>(false);
    useEffect(()=>{
        setLoadingData(true);
        restApiClient.getPowerBIUrl().then((url:any)=>{
            if (url){
                setPowerBIURL(url);
            }
        })
        .finally(()=>{
            setLoadingData(false);
        });
    },[])

    return(
        <Skeleton active loading={loadingData}>
            {
                powerBIURL.length > 0 
                ?
                <iframe className='statistics-iframe' src={powerBIURL} />
                :
                'No URL'
            }
        </Skeleton>
    )
}
