import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import "./combo-language.sass";
import "../../i18n";
import { AllLanguages, LangContext } from "../../hooks/context/lang";
import { NavLink, useLocation } from "react-router-dom";
import { AppLanguage } from "../../routes/appLanguages/index";
import { appStrings } from "../../routes/localizations/index";
import { useIntl } from "react-intl";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	DropdownCustom,
	MenusCustomPro,
	MenusItemsCustomPro,
	ButtonsCustom,
} from "../../components/custom";

// Componente que recoge la lista de idiomas a seleccionar en la aplicación
export const MenuLanguages = (param) => {
	// Libreria i18n para traducir
	const { i18n } = useTranslation();
	// Contexto de los idiomas soportados por el sistema
	const allLang = useContext(AllLanguages);
	// Contexto con el idioma del usuario
	const userLangContext = useContext(LangContext);
	// Obtenemos la lista de idimas del contexto
	let userLang = userLangContext.GetLangContext();
	// Filtro para marcar en el combo el idioma seleccionado por el usuario
	const apiAllLanguages = allLang.GetLanguage();
	const allLangUserSelect = apiAllLanguages.filter((e) =>
		e.id.includes(userLang)
	);
	// Estado para el combo y poder actualizarlo si se cambia
	const [langComboUser, setLangComboUser] = useState(allLangUserSelect);

	// Hooks para contruir las rutas y poder actualizar las url si se cambia de idioma
	const { pathname } = useLocation();
	const { locale, messages } = useIntl();

	// Funcion para el cambio de idioma
	function changeLanguage(key) {
		// Actualiza el contexto del idioma del usuario
		userLangContext.UpdateLangContext(key);
		// Actualiza el idima en el que se visualiza la app
		i18n.changeLanguage(key);
		// Actualiza el combo de la seleccion del idima con el nuevo idima seleccionado por el usuario
		setLangComboUser(
			allLang.GetLanguage().filter((e) => e.id.includes(key))
		);
		userLang = userLangContext.GetLangContext();
	}

	// Monta las rutas que se pondran en cada una de las opciones del combo
	function getMatchingRoute(language: string) {
		// Obtenemos la clave de la ruta en la que se encuentra el usuario actualmente
		const [, route] = pathname.split("/" + locale);
		const routeKey = Object.keys(messages).find(
			(key) => messages[key] === route
		);

		// Filtramos la ruta correspondiente para el nuevo idioma
		const matchingRoute = appStrings[language][routeKey];

		// Devolvemos la url
		return `/${language}` + matchingRoute;
	}

	const menu = (
		<MenusCustomPro className={"combo-lang"}>
			{allLang.GetLanguage().map((element, index) => {
				return (
					<MenusItemsCustomPro
						key={element.id}
						className={param.class}
					>
						<NavLink
							className={"secondary-links"}
							to={getMatchingRoute(
								AppLanguage[
									element.id.split("-")[0].toLowerCase()
								]
							)}
							onClick={() => changeLanguage(element.id)}
						>
							<div className="display-flag-menu">
								<div className="flag-combo">{element.name}</div>
							</div>
						</NavLink>
					</MenusItemsCustomPro>
				);
			})}
		</MenusCustomPro>
	);

	return (
		<LangContext.Provider value={userLangContext}>
			<div className="padding-link secondary-links p-0">
				<DropdownCustom className="icon-down-lang" overlay={menu}>
					<div>
						<ButtonsCustom
							id="btn-languages"
							className="public-mobile no-shadow"
							content={
								<>
									<div>
										{allLangUserSelect[0].name}&nbsp;
									</div>
									<FontAwesomeIcon
										className="icons-color-menu icons-color-marging chevron-language"
										icon={faChevronDown}
									/>
								</>
							}
						/>
					</div>
				</DropdownCustom>
			</div>
		</LangContext.Provider>
	);
};

