import React, { useEffect, useState } from "react";
import "../../../i18n";
import { Radio } from "antd";

export const RadioCustomComponent = (props) => {
    return (
        <>
            <Radio
                {...props}
            >
                {props.children}
            </Radio>
        </>
    );
};

export const RadioGroupCustomComponent = (props) => {
    return (
        <>
            <Radio.Group
                {...props}
            >
                {props.children}
            </Radio.Group>
        </>
    );
};

export const RadioGroupButtonCustomComponent = (props) => {
    return (
        <>
            <Radio.Button
                {...props}
            >
                {props.children}
            </Radio.Button>
        </>
    );
};
