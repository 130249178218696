import React, { useContext, useEffect, useState } from "react";
import "./news.sass";
import { Spin } from 'antd';
import { Helmet } from "react-helmet-async";
import { ConfigContext } from "../../hooks/context/config";
import { useTranslation } from "react-i18next";
import { URL_BASE } from "../../constants/constants";
import {
	ParagraphCustom,
	TitleCustom,
	ButtonsCustom,
	PopoverCustom,
	FontIconsCustom
} from "../custom";
import {
	LoadingOutlined
} from "@ant-design/icons";
import {
	faEllipsisV
} from "@fortawesome/free-solid-svg-icons";
import { Skeleton } from "antd";
import { AlternateComponent } from "../../components/utils/alternateComponent/alternateComponent";
import { NotResults } from "../../components/noResultGeneric/notResults";
import { SearchNews } from "./searchNews/searchNews";
import { Pagination, DefaultPagination } from "../../models/pagination/pagination";
import { StatusNews } from "../../constants/enums";
import { ListItem } from "../custom/lists/interfaceLists";
import { LangContext } from "../../hooks/context/lang";
import { ListNews } from "./listNews/listNews";
import { getLocaleDatePicker } from "../../utils/datePicker";
import { PickerLocale } from "antd/es/date-picker/generatePicker";
import { BodyPanelListNews } from "./listNews/bodyPanelListNews";
import { showNotification } from "../../utils/notificationsGeneric";
import { useHistory, Link } from "react-router-dom";
import { ParamsSearchNews } from "./interfaceNews";
import { RestApiClientContext } from "../../hooks/context/pageContext";

export const News = () => {
	const restApiClient = useContext(RestApiClientContext);

	const { t } = useTranslation(["seo", "urls", "news"]);

	const listParseComponent: ListItem[] = [];

	const scrollLib = require("react-scroll");
	const scroll = scrollLib.animateScroll;

	const infoClient = useContext(ConfigContext);
	const userLangContext = useContext(LangContext);

	const [loadingSkeleton, setLoadingSkeleton] = useState<boolean>(true);
	const [loadingListNews, setLoadingListNews] = useState<boolean>(true);
	const [disabledSearch, setDisabledSearch] = useState<boolean>(false);
	const [messageApi, setMessageApi] = useState<string>("");
	const [searching, setSearching] = useState<boolean>(false);

	const [dataNews, setDataNews] = useState<ListItem[]>([]);

	const [searchTermTitleInput, setSearchTermTitleInput] = useState<string>("");
	const [searchTermStatusInput, setSearchTermStatusInput] = useState<string>(StatusNews.All);

	const [pageResults, setPageResults] = useState<Pagination>(DefaultPagination);
	const [totalElements, setTotalElements] = useState<number>(0);

	const [changeDataNews, setChangeDataNews] = useState([]);

	const local: PickerLocale = getLocaleDatePicker();

	const desktopVersion = () => {
		return (
			<Link
				id="btn-create-news"
				className="button-create-news button-transparent-line"
				to={t('urls:private.create-news')}
			>
				{t("news:button.create-news")}
			</Link>
		);
	};

	const tabletVersion = () => {
		return desktopVersion();
	};

	const mobileVersion = () => {
		return (
			<PopoverCustom
				classBody={"position-float-right"}
				body={
					<ButtonsCustom
						id={"btn-ellipsis-news"}
						class={"btn-ellipsis-news"}
						type=""
						htmlType={null}
						disabled={false}
						size={null}
						content={
							<FontIconsCustom
								classIcon={"icon-ellipsis-opts"}
								icon={faEllipsisV}
							/>
						}
					/>
				}
				content={

					<Link
						id="btn-mobile-create-news"
						className="button-container-create"
						to={t('urls:private.create-news')}
					>
						<div className="button-create-news">
							<ParagraphCustom
								class={"mb-0 ml-1"}
								text={t("news:button.create-news")}
							/>
						</div>
					</Link>
				}
				placement={"bottomLeft"}
				title=""
				trigger="focus"
			/>
		);
	};

	const deleteNews = (idNews) => {

		setLoadingListNews(true);

		restApiClient.deleteNews(idNews)
			.then((r) => {
				setLoadingListNews(false);
				setChangeDataNews([idNews]);
			})
			.catch((r: any)=>{
				setLoadingListNews(false);
			})
	}

	useEffect(() => {
		scroll.scrollToTop();
	}, []);

	useEffect(() => {
		setLoadingListNews(true);
		setDisabledSearch(true);

		const filter = {
			Title: searchTermTitleInput,
			NewStatus: searchTermStatusInput,
			Pagination: pageResults,
			Language: userLangContext.GetLangContext(),
		} as ParamsSearchNews;

		restApiClient.getNewsFiltered(filter)
			.then((data) => {
				if (data && data.news.length > 0) {
					data.news.map((e) => {
						const elementForList: ListItem = {
							avatar: e.image,
							title: "",
							description: (
								<BodyPanelListNews
									{...e}
									setChangeDataNews={setChangeDataNews}
									deleteNews={deleteNews}
								/>
							),
						};
						listParseComponent.push(elementForList);
					});
					setDataNews(listParseComponent);
					setTotalElements(data.totalRecord);
				} else {
					setDataNews([]);
					setMessageApi("not-result");
				}

				setDisabledSearch(false);
				setLoadingSkeleton(false);
				setLoadingListNews(false);
			}).finally(()=>{
				setLoadingSkeleton(false);
				setLoadingListNews(false);
			})
	}, [
		searchTermTitleInput,
		searchTermStatusInput,
		pageResults,
		changeDataNews,
	]);

	return (
		<>
			<Helmet>
				<title>
					{infoClient.incentiveSiteName} | {t("seo:title.contact")}
				</title>
				<meta
					name="description"
					content={t("seo:description.contact", {
						0: infoClient.incentiveSiteName,
					})}
				/>
				<meta name="robots" content="index,follow" />
				<link
					rel="canonical"
					href={URL_BASE + t("urls:public.contact")}
				/>
				<link
					rel="icon"
					type="image/ico"
					href={infoClient.favicon}
					sizes="32x32"
				/>
				<link
					href={URL_BASE + t("urls:public.contact")}
					hrefLang="es-ES"
					rel="alternate"
				/>
			</Helmet>
			{loadingSkeleton ? (
				<div className="container margin-bottom-8 d-flex justify-content-center align-items-center cont-skeleton">
					<Skeleton
						paragraph={{ rows: 5 }}
						active
					/>
				</div>
			) : (
					<div className="row special-mb">
						<div className="col-9">
							<TitleCustom
								level={1}
								class="title-section"
								text={t("news:title")}
							/>
						</div>
						<div className="col-3">
							<AlternateComponent
								desktopVersionLarge={desktopVersion()}
								tabletVersion={tabletVersion()}
								mobileVersionLarge={mobileVersion()}
							/>
						</div>
						<div className="col-12 col-lg-9">
							<ParagraphCustom
								class={"p-0"}
								text={t("news:subtitle", {
									0: infoClient.incentiveSiteName,
								})}
							/>
						</div>
						<div className="col-12 mt-3">
							<SearchNews
								searchTermTitleInput={searchTermTitleInput}
								searchTermStatusInput={searchTermStatusInput}
								setSearchTermTitleInput={setSearchTermTitleInput}
								setSearchTermStatusInput={setSearchTermStatusInput}
								setPageResults={setPageResults}
								setSearching={setSearching}
								disabledSearch={disabledSearch}
							/>
						</div>
						{
							loadingListNews ? (
								<Spin
									indicator={
										<LoadingOutlined
											style={{ fontSize: 24 }}
											spin
										/>
									}
								/>
							) : dataNews.length === 0 || messageApi !== "" ? (
								<NotResults
									section={"news"}
									dataSearch={searching}
									messageApi={messageApi}
								/>
							) : (
										<ListNews
											data={dataNews}
											count={totalElements}
											setPageResults={setPageResults}
											pageResults={pageResults}
										/>
									)
						}
					</div>
				)}
		</>
	);
};
