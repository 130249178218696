import * as React from "react";
import { useContext, useEffect } from "react";
import "./contact.sass";
import { Helmet } from "react-helmet-async";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../hooks/context/config";
import {FormContact} from "./formContact/formContact";
import scrollLib from "react-scroll";
import { URL_BASE } from "../../constants/constants";
import { TitleCustom } from "../../components/custom";

// Componente de información de contacto de la app
export const Contact = () => {
	const infoClient = useContext(ConfigContext);
	const { t } = useTranslation(["contact", "seo", "urls"]);
	const scroll = scrollLib.animateScroll;

	useEffect(() => {
		scroll.scrollToTop();
	}, []);

	return (
		<>
			<Helmet>
				<title>
					{infoClient.incentiveSiteName} | {t("seo:title.contact")}
				</title>
				<meta
					name="description"
					content={t("seo:description.contact", {
						0: infoClient.incentiveSiteName,
					})}
				/>
				<meta name="robots" content="index,follow" />
				<link
					rel="canonical"
					href={URL_BASE + t("urls:public.contact")}
				/>
				<link
					rel="icon"
					type="image/ico"
					href={infoClient.favicon}
					sizes="32x32"
				/>
				<link
					href={URL_BASE + t("urls:public.contact")}
					hrefLang="es-ES"
					rel="alternate"
				/>
			</Helmet>

			<div className="container special-mb">
				<TitleCustom
					level={1}
					class="title-align-fag"
					text={t("contact:title")}
				/>
				<FormContact />
			</div>
		</>
	);
};

