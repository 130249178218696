import React, { useContext, useEffect, useState } from "react";
import { Typography, Col, Row, Alert, Button } from 'antd'
import "./private.sass";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

import {
    faUsersCog,
    faCog,
    faNewspaper,
    faCoins,
    faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontIconsCustom } from "../../custom";
import { Link } from "react-router-dom";
import { RestApiClientContext } from "../../../hooks/context/pageContext";

const { Title } = Typography;

// Componente de la vista principal de la aplicación en su zona privada
export const HomePrivate = () => {
    const { t } = useTranslation(["urls", "linksNames", "dashboard"]);
    const restApiClient = useContext(RestApiClientContext);
    
    const icons = [
        faCog,
        faUsersCog,
        faCalendarAlt,
        faCoins,
        faNewspaper,
    ];

    const [pendingInvoices, setPendingInvoices] = useState<number>(0);
    useEffect(()=>{
        restApiClient.getPendingInvoices().then((res) => {
            setPendingInvoices(res.pendingInvoices || 0);
        });
    },[])

    const [showBillingAlert, setShowBillingAlerts] = useState<boolean>(true);

    const getBillingInfo = () =>{
        restApiClient.getHideBillingAletsAndDetailsConfiguration().then((response) => {
            setShowBillingAlerts(response);
        });
      }
    
      React.useEffect(() => {
        getBillingInfo();
      }, []);

	return (
        <Row gutter={[16,32]} className="shortcuts">
            {
                <Col xs={!showBillingAlert && pendingInvoices >= 2 ? 24 : 0}>
                {
                    pendingInvoices >= 2 &&
                    <Alert
                    className="billing-alert"
                    message={t("dashboard:shortcuts.alerts.pendingInvoices.title")}
                    showIcon
                    description={<div className="billing-alert">
                        {ReactHtmlParser(t("dashboard:shortcuts.alerts.pendingInvoices.description", [pendingInvoices]))}
                        <div className="actions">
                        <Button type="primary">
                            <Link to={t("urls:private.billing")}>
                                {t("dashboard:shortcuts.alerts.pendingInvoices.go-to")}
                            </Link>
                        </Button>
                        </div>
                    </div>}
                    type="error"
                    />
                }
            </Col>
            }
            <Col 
                xs={{ span: 24, offset: 0 }} 
                sm={{ span: 24, offset: 0 }} 
                md={{ span: 24, offset: 0 }} 
                lg={{ span: 10, offset: 2 }} 
                xl={{ span: 10, offset: 2 }} 
                xxl={{ span: 6, offset: 2 }} 
                className="shortcut"
            >
                <FontIconsCustom classIcon={ "shortcut-icon icons-color-menu icons-color-marging" } icon={icons[0]} />
                    <Title  className="shortcut-title" level={3} >
                        <Link to={t("urls:private.regularization" )} >
                        {t("dashboard:shortcuts.bookings.title")}
                        </Link>
                    </Title>
                <p>
                {t("dashboard:shortcuts.bookings.description")}
                </p>
            </Col>

            <Col 
                xs={{ span: 24, offset: 0 }} 
                sm={{ span: 24, offset: 0 }} 
                md={{ span: 24, offset: 0 }} 
                lg={{ span: 10, offset: 2 }} 
                xl={{ span: 10, offset: 2 }} 
                xxl={{ span: 6, offset: 2 }} 
                className="shortcut"
            >
                <FontIconsCustom classIcon={ "shortcut-icon icons-color-menu icons-color-marging" } icon={icons[1]} />
                    <Title  className="shortcut-title" level={3}>
                        <Link to={t("urls:private.agents-register" )} >
                        {t("dashboard:shortcuts.rewards-night.title")}
                        </Link>
                    </Title>
                <p>
                    {t("dashboard:shortcuts.agents.description")}
                </p>
            </Col>

            <Col 
                xs={{ span: 24, offset: 0 }} 
                sm={{ span: 24, offset: 0 }} 
                md={{ span: 24, offset: 0 }} 
                lg={{ span: 10, offset: 2 }} 
                xl={{ span: 10, offset: 2 }} 
                xxl={{ span: 6, offset: 2 }} 
                className="shortcut"
            >
                <FontIconsCustom classIcon={ "shortcut-icon icons-color-menu icons-color-marging" } icon={icons[2]} />
                <Title  className="shortcut-title" level={3}>
                    <Link to={t("urls:private.rewards-night" )} >
                        {t("dashboard:shortcuts.rewards-night.title")}
                    </Link>
                </Title>
                <p>
                {t("dashboard:shortcuts.rewards-night.description")}
                </p>
            </Col>

            <Col 
                xs={{ span: 24, offset: 0 }} 
                sm={{ span: 24, offset: 0 }} 
                md={{ span: 24, offset: 0 }} 
                lg={{ span: 10, offset: 2 }} 
                xl={{ span: 10, offset: 2 }} 
                xxl={{ span: 6, offset: 2 }} 
                className="shortcut"
            >
                <FontIconsCustom classIcon={ "shortcut-icon icons-color-menu icons-color-marging" } icon={icons[3]} />
                <Title  className="shortcut-title" level={3}>
                    
                    <Link to={t("urls:private.rewards" )} >
                    {t("dashboard:shortcuts.rewards.title")}
                    </Link>
                </Title>
                <p>
                {t("dashboard:shortcuts.rewards.description")}
                </p>
            </Col>


            <Col 
                xs={{ span: 24, offset: 0 }} 
                sm={{ span: 24, offset: 0 }} 
                md={{ span: 24, offset: 0 }} 
                lg={{ span: 10, offset: 2 }} 
                xl={{ span: 10, offset: 2 }} 
                xxl={{ span: 6, offset: 2 }} 
                className="shortcut"
            >
                <FontIconsCustom classIcon={ "shortcut-icon icons-color-menu icons-color-marging" } icon={icons[0]} />
                <Title  className="shortcut-title" level={3}>
                    <Link to={t("urls:private.config" )} >
                    {t("dashboard:shortcuts.config.title")}
                    </Link>
                    </Title>
                <p>
                {t("dashboard:shortcuts.config.description")}
                </p>
            </Col>

            <Col 
                xs={{ span: 24, offset: 0 }} 
                sm={{ span: 24, offset: 0 }} 
                md={{ span: 24, offset: 0 }} 
                lg={{ span: 10, offset: 2 }} 
                xl={{ span: 10, offset: 2 }} 
                xxl={{ span: 6, offset: 2 }} 
                className="shortcut"
            >
                <FontIconsCustom classIcon={ "shortcut-icon icons-color-menu icons-color-marging" } icon={icons[4]} />
                <Title  className="shortcut-title" level={3}>
                    <Link to={t("urls:private.news" )} >
                    {t("dashboard:shortcuts.news.title")}
                    </Link>
                    </Title>
                <p>
                {t("dashboard:shortcuts.news.description")}
                </p>
            </Col>
        </Row>
    );
};

