import { ButtonsCustom, FontIconsCustom, FormCustom, FormItemsContact, InputCustom, ParagraphCustom, SwitchCustomPro } from '../../components/custom';
import { RewardSupplierDto } from 'src/models/rewards/Reward';
import React, { useEffect, useState } from 'react';
import parser from 'react-html-parser'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { showNotification } from '../../utils/notificationsGeneric';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import "./rewardSupplier.sass";
import { useContext } from 'react';
import { RestApiClientContext } from '../../hooks/context/pageContext';
import { Divider } from 'antd';
import { numberFormat } from '../../utils/formatAmount';
import { LanguageManagement } from '../../models/language/languageUserManagement';
import { LangContext } from '../../hooks/context/lang';
import { Rewards } from './rewards';

interface Props {
    rewardSupplier: RewardSupplierDto;
}

export const RewardSupplier = (props: Props) => {

    const { t } = useTranslation(["seo", "urls", "rewards", "translation", "modalClosedSession"]);
    const userLangContext = useContext<LanguageManagement>(LangContext);

    const restApiClient = useContext(RestApiClientContext);
    const [form] = Form.useForm();
    const { rewardSupplier } = props;

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [reward, setReward] = useState<RewardSupplierDto>(rewardSupplier);

    const setValue = (propName: keyof RewardSupplierDto, value: any) => {
        const newReward = {
            ...reward,
            [propName]: value ? parseFloat(value) : null,
        } as RewardSupplierDto;

        setReward(newReward);
    }

    const onClickButton = () => {
        if (isEditing) {
            saveReward();
        }
        setIsEditing(!isEditing);
    }

    const onCancelButton = () => {
        form.setFieldsValue({
            increment: rewardSupplier.increment,
            minimum: rewardSupplier.minimumAmount,
            maximum: rewardSupplier.maximumAmount
        });
        setReward(rewardSupplier);
        setIsEditing(false);
        setHasErrors(false);
    }

    const onChangeStatus = () => {
        const newReward = {
            ...reward,
            status: !reward.status,
        } as RewardSupplierDto;

        setReward(newReward);
        saveChangeStatus();
    }

    const saveChangeStatus = () => {
        restApiClient.ChangeStatusReward(reward.id, reward.status )
            .then((data) => {
                
            })
    }

    const saveReward = () => {
        restApiClient.UpdateSupplierReward(reward.id, reward.minimumAmount, reward.maximumAmount, reward.increment )
            .then((data) => {
               
            })
    }

    const getLimitText = () : JSX.Element => {

        if (reward.maximumAmountLimit && reward.minimumAmountLimit){
            return <ParagraphCustom
                    class={"p-0 m-0 text-amounts-between"}
                    text={parser(`${t("rewards:suppliers.text-description1")} <strong class="strong-text">${reward.name}</strong> ${t("rewards:suppliers.text-description2")} <strong class="strong-text">${numberFormat(reward.minimumAmountLimit, userLangContext.lang)}</strong> ${t("rewards:suppliers.text-description3")} <strong class="strong-text">${numberFormat(reward.maximumAmountLimit, userLangContext.lang)}</strong> ${reward.currency.id}`)}
        />
        }else{
            return <ParagraphCustom
                    class={"p-0 m-0 text-amounts-between"}
                    text={parser(`${t("rewards:suppliers.no-limits-change")} <strong class="strong-text">${reward.name}</strong>.`)}
            />
        }

            
    }

    useEffect(() => {
        form.setFieldsValue({
            increment: reward.increment,
            minimum: reward.minimumAmount,
            maximum: reward.maximumAmount
        });
    }, []);

    return (
        <>
            <div className="col-12 p-0 display-elements-list rewards-suppliers-header">
                <div className="col-6 p-0">
                    <img src={reward.image} className="logo-provider" />
                </div>
                <div className="col-6 p-0 element-float-right">
                    <div className="element-float-right">
                        <SwitchCustomPro
                            checked={reward.status}
                            id={reward.id}
                            onChange={onChangeStatus}
                        />
                    </div>
                </div>
            </div>
            <FormCustom form={form} onFinish={() => null}>
                <div className="row">
                    <div className="col-12 col-xl-4">
                        <div className="display-elements-list">
                            <ParagraphCustom
                                text={
                                    t("rewards:suppliers.increment")
                                }
                                class="table-header-rewards"
                            />
                            <FontIconsCustom
                                tooltip={
                                    t("rewards:tooltip.increment")
                                }
                                classIcon="icons-color-menu icons-color-marging q-icon info-color"
                                icon={faInfoCircle}
                                placement={"bottom"}
                            />
                        </div>
                        <FormItemsContact
                            name="increment"
                        >
                            <InputCustom
                                id={`increment-${reward.id}`}
                                className="main-input"
                                classIcon={"search-agency-icon-input"}
                                prefix={false}
                                suffix={"%"}
                                allowClear={false}
                                onChange={e => setValue("increment", e.target.value)}
                                disabled={!isEditing}
                            />
                        </FormItemsContact>
                    </div>
                    <div className="col-12 col-md-8 col-xl-5">
                        <div className="col-6 pl-0 pl-xl-3 min-content">
                            <div className="display-elements-list">
                                <ParagraphCustom
                                    text={
                                        <strong>
                                            {
                                                t("rewards:suppliers.minimum")
                                            }
                                        </strong>
                                    }
                                    class="table-header-rewards"
                                />
                                <FontIconsCustom
                                    tooltip={
                                        t("rewards:tooltip.minimum")
                                    }
                                    classIcon="icons-color-menu icons-color-marging q-icon info-color"
                                    icon={faInfoCircle}
                                    placement={"bottom"}
                                />
                            </div>
                            <FormItemsContact
                                name="minimum"
                                rules={[
                                    () => ({
                                        validator(rule, value) {
                                            if (reward.minimumAmountLimit && value < reward.minimumAmountLimit) {
                                                setHasErrors(true);
                                                return Promise.reject(t("rewards:suppliers.error-min"));
                                            }
                                            setHasErrors(false);
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <InputCustom
                                    id={`minimum-amount-${reward.id}`}
                                    className="main-input"
                                    classIcon={"search-agency-icon-input"}
                                    prefix={false}
                                    suffix={reward.currency.symbol}
                                    allowClear={false}
                                    onChange={e => setValue("minimumAmount", e.target.value)}
                                    disabled={!isEditing}
                                />
                            </FormItemsContact>
                        </div>
                        <div className="col-6 pr-0 pr-md-3 max-content">
                            <div className="display-elements-list">
                                <ParagraphCustom
                                    text={
                                        <strong>
                                            {
                                                t("rewards:suppliers.maximum")
                                            }
                                        </strong>
                                    }
                                    class="table-header-rewards"
                                />
                                <FontIconsCustom
                                    tooltip={
                                        t("rewards:tooltip.maximum")
                                    }
                                    classIcon="icons-color-menu icons-color-marging q-icon info-color"
                                    icon={faInfoCircle}
                                    placement={"bottom"}
                                />
                            </div>
                            <FormItemsContact
                                name="maximum"
                                rules={[
                                    () => ({
                                        validator(rule, value) {
                                            if (value < reward.minimumAmount) {
                                                setHasErrors(true);
                                                return Promise.reject(t("rewards:suppliers.error-minmax"));
                                            }
                                            if (reward.maximumAmountLimit && value > reward.maximumAmountLimit) {
                                                setHasErrors(true);
                                                return Promise.reject(t("rewards:suppliers.error-max"));
                                            }
                                            setHasErrors(false);
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <InputCustom
                                    id={`maximum-amount-${reward.id}`}
                                    className="main-input"
                                    classIcon={"search-agency-icon-input"}
                                    prefix={false}
                                    suffix={reward.currency.symbol}
                                    allowClear={false}
                                    onChange={e => setValue("maximumAmount", e.target.value)}
                                    disabled={!isEditing}
                                />
                            </FormItemsContact>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-3 btn-alignment element-float-right">
                        <div className="d-none d-md-block col-md-6 p-0">
                            {
                                isEditing &&
                                <ButtonsCustom
                                    id={`cancel-button-${reward.id}`}
                                    class={"no-border-button-cancel col-12"}
                                    type=""
                                    htmlType={null}
                                    onClick={onCancelButton}
                                    disabled={false}
                                    size={null}
                                    content={
                                        <span>
                                            {
                                                t("rewards:buttons.cancel")
                                            }
                                        </span>
                                    }
                                />
                            }
                        </div>
                        <div className="col-12 col-md-6 p-0">
                            {
                                isEditing &&
                                <ButtonsCustom
                                    id={`dynamic-cancel-button-${reward.id}`}
                                    class={"no-border-button-cancel col-12 d-md-none dynamic-cancel"}
                                    type=""
                                    htmlType={null}
                                    onClick={onCancelButton}
                                    disabled={false}
                                    size={null}
                                    content={
                                        <span>
                                            {
                                                t("rewards:buttons.cancel")
                                            }
                                        </span>
                                    }
                                />
                            }
                            <ButtonsCustom
                                id={`edit-button-${reward.id}`}
                                class={
                                    "align-right " + (isEditing ? "primary-button" : "button-transparent-line")
                                }
                                type=""
                                htmlType={null}
                                onClick={onClickButton}
                                disabled={hasErrors}
                                size={null}
                                content={
                                    <span>
                                        {
                                            isEditing ? t("rewards:buttons.save") : t("rewards:buttons.edit")
                                        }
                                    </span>
                                }
                            />
                        </div>
                    </div>
                </div>
            </FormCustom>

            <Divider
                className="info-provider-separator"
            />

            {
                getLimitText()
            }
            
        </>
    );
}