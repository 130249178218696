import * as React from "react";
import { useContext, useEffect, useState } from "react";
import "../../i18n";
import "./app.sass";
import { BasePublic } from "../baseLayouts/public/index";
import { BasePrivate } from "../baseLayouts/private/index";
import { ConfigContext } from "../../hooks/context/config";
import { LoginContext, UserContext } from "../../hooks/context/userInfo";
import {
    LangContext,
    AllLanguages,
    LangUrlContext,
} from "../../hooks/context/lang";
import { BrowserRouter as Router } from "react-router-dom";
import { LocalizedRouter } from "../../routes/public/routes";
import { AppLanguage } from "../../routes/appLanguages";
import { appStrings } from "../../routes/localizations/index";
import { LocalizedRouterPrivate } from "../../routes/private/routes";
import { CookiesProvider } from "react-cookie";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import * as AjaxAPI from "../../api/ajaxApi";
import { DURATION_COOKIE } from "../../constants/constants";
import { RestApiClientContext } from "../../hooks/context/pageContext";
import { RestApiClient } from "../../api/restClient";

// Componente principal de nuestra aplicación y que renderizamos en el main.tsx
export const App = (config) => {
    // Libreria para el manejador de idioma de la app
    const { t, i18n } = useTranslation();

    // Contexto con el idioma de usuario y en la que se visualizara la app
    const language = useContext(LangContext);
    // Contexto con el idioma URL
    const languageUrl = useContext(LangUrlContext);
    let initLang: string = "";
    let initLangURL: string = "";

    if (language.GetLangContext() === "") {
        initLang = config.userLang;
    } else {
        initLang = language.GetLangContext();
    }
    initLangURL = config.allLanguages.filter((f) => f.id === initLang).shortId;

    language.UpdateLangContext(initLang);
    languageUrl.UpdateUrlLangContext(initLangURL);

    // Contexto con todos los idiomas disponibles en el sistema para app
    const allLang = useContext(AllLanguages);
    allLang.UpdateAllLangContext(config.allLanguages);

    // Contexto con la configuracion del cliente obtenida de BBDD y poder visualizar logo, color principal, etc
    const configuration = useContext(ConfigContext);
    configuration.UpdateConfigContext(config.config);

    // Cookie que determina si se aceptaron las cookies
    const [cookies] = useCookies(["cookies"]);
    // Cookie que alamacena el token del usuario logado
    const [userLogin, setUserToken] = useCookies(["userToken"]);
    // Contexto con los datos del usuario si esta logado
    const userContext = useContext(UserContext);
    // Control adicional si esta o no logado el usuario
    const loginUserDone = false;
    // Contexto usuario logado
    const loginContext = useContext(LoginContext);

    // Estado inicial del login
    const [login, setLogin] = useState(loginUserDone);

    // Carga inicial con el color destacado del cliente
    const style =
        configuration &&
        `
    :root {
        --primary-color: ${configuration.colour};
    }
  `;
    // Actualizar la libreria i18n para el idioma de la web
    useEffect(() => {
        i18n.changeLanguage(initLang);
    }, []);


  return (
        // En el fallback podemos añadir un spinner (<Spinner/>)
        <>
            <LoginContext.Provider
                value={{
                    login,
                    updateValue: (key) => {
                        setLogin(key);
                    },
                }}
            >
                <CookiesProvider>
                    {login || typeof userLogin.userToken !== "undefined" ? (                      
                        <RestApiClientContext.Provider value={new RestApiClient(userLogin.userToken, t)}>
                            <LocalizedRouterPrivate
                                RouterComponent={Router}
                                languages={AppLanguage}
                                appStrings={appStrings}
                                defaultLanguage={initLangURL}
                            >
                                <style>{style}</style>
                                <BasePrivate />
                            </LocalizedRouterPrivate>
                        </RestApiClientContext.Provider>
                    ) : (
                            <LocalizedRouter
                                RouterComponent={Router}
                                languages={AppLanguage}
                                appStrings={appStrings}
                                defaultLanguage={initLangURL}
                            >
                                <style>{style}</style>
                                <BasePublic />
                            </LocalizedRouter>
                        )}
                </CookiesProvider>
            </LoginContext.Provider>
        </>
    );
};
