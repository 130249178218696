import React, { useContext, useEffect, useState } from "react";
import "./rooms.sass";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import {
  ButtonsCustom,
  DrawerCustom,
  FontIconsCustom,
  ParagraphCustom,
  TableCustom,
  TitleCustom
} from "src/components/custom";
import { LoadingOutlined } from "@ant-design/icons";
import { Input, Skeleton, Spin } from "antd";
import { RestApiClientContext } from "src/hooks/context/pageContext";
import { LangContext } from "src/hooks/context/lang";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { EditRoom } from "./editRoom/editRoom";
import { NotResults } from "src/components/noResultGeneric/notResults";

interface RoomsItem {
  id: string;
  roomName: string;
  identityCode: string;
  comments: string;
  edit: any;
}

export const Rooms = () => {
  const { t } = useTranslation("config");
  const restApiClient = useContext(RestApiClientContext);
  const language = useContext(LangContext);
  const scrollLib = require("react-scroll");
  const scroll = scrollLib.animateScroll;




  const [filterNames, setFilterNames] = useState<any[]>([]);
  const columns = [
    {
      title: t("rooms.table.name"),
      dataIndex: "roomName",
      key: "roomName",
      className: "room-name",
      filters:filterNames,
      onFilter: (value: string, record) => record.roomName?.indexOf(value) === 0,
    },
    {
      title: t("rooms.table.code"),
      dataIndex: "identityCode",
      key: "identityCode"
    },
    {
      title: t("rooms.table.comment"),
      dataIndex: "comments",
      key: "comments"
    },
    {
      title: null,
      dataIndex: "edit",
      key: "edit",
      width: "20px"
    }
  ];
  const [loadingSkeleton, setLoadingSkeleton] = useState<boolean>(true);
  const [loadingRooms, setLoadingRooms] = useState<boolean>(true);
  const [disabledSearch, setDisabledSearch] = useState<boolean>(false);
  const [messageApi, setMessageApi] = useState<string>("");
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [canDelete, setCanDelete] = useState(false);
  const [dataRooms, setDataRooms] = useState<RoomsItem[]>(null);
  const [eventsData, setEventsData] = useState<RoomsItem[]>(dataRooms);
  const [drawerChoices, setDrawerChoices] = useState([]);
  const Search = Input.Search;

  const handleSearch = (searchText) => {
    setEventsData([...dataRooms.filter((room) => room?.roomName?.includes(searchText))]);
  };

  const handleAddChannel = () => {
    setSelectedRoomId(null);
    setVisibleDrawer(true);
  };

  const handleEdit = async (cell) => {
    try {
      await setSelectedRoomId(cell.id);
      await setVisibleDrawer(true);
    } catch (err) {
      alert(err.message);
    }
  };

  const handleRender = () => {
    setLoadingRooms(true);
    setDisabledSearch(true);
    setMessageApi("");
    restApiClient
      .getRoomTypes()
      .then((data) => {
        if (data && data.length > 0) {

          const theFilterNames = data.map((e) => {
            return {
              value: e.roomTypesTranslations?.find((rt) => rt.idLanguage === language?.lang)?.name,
              text: e.roomTypesTranslations?.find((rt) => rt.idLanguage === language?.lang)?.name
            };
          });

          setFilterNames(theFilterNames);


          setDataRooms(
            data.map((e) => {
              return {
                id: e.id,
                roomName: e.roomTypesTranslations?.find((rt) => rt.idLanguage === language?.lang)?.name,
                identityCode: e.identityCode,
                comments: e?.comments,
                edit: (
                  <span onClick={() => handleEdit(e)} className="action-edit">
                    <FontIconsCustom icon={faEdit} />
                  </span>
                )
              };
            })
          );
        } else {
          setDataRooms([]);
          setMessageApi("not-result");
        }
        setDisabledSearch(false);
        setLoadingSkeleton(false);
        setLoadingRooms(false);
      })
      .catch((reason: any) => {
        setLoadingRooms(false);
      })
      .finally(() => {
        setLoadingSkeleton(false);
        setLoadingRooms(false);
      });
  };

  useEffect(() => {
    handleRender();
    scroll.scrollToTop();
  }, []);

  //Useeffect to check if Room can be deleted
  const [selectedRoom, setSelectedRoom] = useState<any>();
  const [searchingRoomData, setSearchingRoomData] = useState<boolean>(false);
  useEffect(() => {
    if (selectedRoomId) {
      setSearchingRoomData(true);
      restApiClient.getRoomTypeById(selectedRoomId).then((data) => {
        //El nombre del parámetro isDeleted no es para saber si está borrado sino más bien para saber si se puede borrar.
        //quien le puso el nombre al atributo en vez de poner CanBeDeleted usó isDeleted. 
        setCanDelete(!data.isDeleted);
        setSelectedRoom(data);
        setSearchingRoomData(false);

      });
    }
  }, [selectedRoomId]);

  const handleDelete = () : void => {
        restApiClient.deleteRoomType(selectedRoomId).finally(()=>{
          setDataRooms(null);
          setDrawerChoices([]); 
          setSelectedRoom(undefined); 
          setVisibleDrawer(false);
          handleRender();
        });
  }

  return (
    <>
        <div id="room-types" className="special-mb config-tab">
          <div className="row col-12">
            <TitleCustom level={4} class="title-section" text={t("rooms.main-title")} />
          </div>
          <div className="row">
            <div className="col-12">
              <ParagraphCustom class={"p-0"} text={t(`rooms.main-description`)} />
            </div>
          </div>
          <div className="row my-1">
            <div className="col col-md-8 my-1">
              <Search
                disabled={disabledSearch}
                placeholder={t("rooms.search-channel.placeholder")}
                onSearch={(e) => handleSearch(e)}
                allowClear
              />
              
            </div>
            <div className="col align-self-end buttons my-1">
              <ButtonsCustom
                class={"primary-button"}
                type=""
                htmlType={null}
                onClick={handleAddChannel}
                size={null}
                content={
                  <span>
                    <FontAwesomeIcon icon={faPlus} />
                    <strong>{t("rooms.add-new")}</strong>
                  </span>
                }
              />
            </div>
          </div>
          <Skeleton active loading={loadingSkeleton || loadingRooms}>
            {eventsData?.length < 1 || messageApi !== "" ? (
              <div className="row">
                <NotResults section="rooms" messageApi={messageApi} />
              </div>
            ) : (
              <div className="row">
                <TableCustom
                  className="table-rooms col-12 my-4"
                  columns={columns}
                  dataSource={eventsData || dataRooms}
                  pagination={true}
                  scroll={{ x: 929 }}
                  bordered
                />
              </div>
            )}
          </Skeleton>
          
        </div>
        <DrawerCustom
          title={
            <header className="d-flex justify-content-between align-items-center">
              <span className="d-block">{t(`rooms.add-${selectedRoomId ? "edit" : "new"}`)}</span>
              {selectedRoomId && (
                <ButtonsCustom
                  onClick={handleDelete}
                  content={<span>{t("rooms.drawer-delete")}</span>}
                  danger={true}
                  disabled={canDelete}
                />
              )}
            </header>
          }
          visible={visibleDrawer}
          setVisible={setVisibleDrawer}
          width={520}
          info={""}
          placement="right"
          closable={true}
          onClose={() => {setDrawerChoices([]); setSelectedRoom(undefined); setVisibleDrawer(false); setSelectedRoomId(null);}}
          content={
            <EditRoom
              searchingRoomData={searchingRoomData}
              setDrawerChoices={setDrawerChoices}
              drawerChoices={drawerChoices}
              roomData={selectedRoom}
              setVisible={setVisibleDrawer}
              reload={handleRender}
            />
          }
        />
    </>
  );
};
