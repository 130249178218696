import * as React from "react";
import { Badge } from "antd";
import { FontIconsCustom } from "../index";
import "./badges.sass";

export const BadgeCustomComponent = (param) => {
    return (
        <Badge
            className={param.classBadge}
            count={param.count}
        >
            <FontIconsCustom
                classIcon={param.classIcon}
                icon={param.icon}
            />
        </Badge>
    );
};
