import React from "react";
import { Menu } from "antd";

// Componente custom de Menu
export const MenusCustomProComponent = (props) => {
    return (
        <Menu
            {...props}
        >
            {props.children}
        </Menu>
    );
};

// Componente custom de items de Menu
export const MenusItemsCustomProComponent = (props) => {
    return (
        <Menu.Item
            {...props}
        >
            {props.children}
        </Menu.Item>
    );
};
