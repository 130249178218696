

export const exportExcel= async (url:string) =>{
    const response = await fetch(
        url,{
            method: 'GET',
            headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
            'X-Authorization': sessionStorage.getItem('token'),
            'Access-Control-Allow-Origin': '*'
            },
        })
        .then(response => response.blob())
        return response;
}


export const exportRewardsExcel = async (url:string) =>{
    const response = await fetch(
        url,{
            method: 'GET',
            headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
            'X-Authorization': sessionStorage.getItem('token'),
            'Access-Control-Allow-Origin': '*'
            },
        })
        .then(response => response.blob())
        return response;
}