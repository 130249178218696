import * as React from "react";
import { Layout } from "antd";
import { FontIconsCustom, MenusCustomPro, MenusItemsCustomPro } from "../index";
import { LinkActiveContext } from "../../../hooks/context/linkActive";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ClientNumber } from "../../baseLayouts/private/layout/clientNumber/clientNumber";
import { Link } from "react-router-dom";
import { RestApiClientContext } from "src/hooks/context/pageContext";

const { Sider, Content } = Layout;

export const LayoutCustomComponent = (param) => {
  const linkActive = useContext(LinkActiveContext);
  const restApiClient = useContext(RestApiClientContext);

  // Libreria i18n para traducir
  const { t } = useTranslation(["urls", "linksNames"]);

  const [hideBillingMenu, setHideBillingMenu] = React.useState<boolean>(true);

  const getBillingInfo = () =>{
    restApiClient.getHideBillingAletsAndDetailsConfiguration().then((response) => {
      setHideBillingMenu(response || false);
    });
  }

  React.useEffect(() => {
    getBillingInfo();
  }, []);

  return (
    <Layout>
      <Sider
        id={param.id}
        trigger={param.trigger}
        collapsible={param.collapsible}
        collapsed={param.collapsed}
        collapsedWidth={param.collapsedWidth}
        width={param.width}
        className={param.class}
      >
        <MenusCustomPro
          mode="inline"
          className={"column-menu-items"}
          defaultSelectedKeys={param.defaultSelectedKeysMenu}
        >
          {param.listMenu.map((element, index) => {
            if (hideBillingMenu && element.indexOf("billing") !== -1){
              return "";
            }
            const showDest: string = t("urls:" + "private" + "." + element);
            return (
              <MenusItemsCustomPro
                key={showDest}
                className={
                  "padding-elements-link" +
                  (window.location.pathname + window.location.hash ===
                  t("urls:" + "private" + "." + element)
                    ? " disabled-li"
                    : "")
                }
              >
                <Link
                  id={"link-" + "private" + "-mobile-" + element + "-" + index}
                  key={index}
                  to={t("urls:" + "private" + "." + element)}
                  className={
                    window.location.pathname + window.location.hash ===
                    t("urls:" + "private" + "." + element)
                      ? "secondary-links active"
                      : "secondary-links"
                  }
                  onClick={() =>
                    linkActive.updateValueUrl(t("urls:private." + element))
                  }
                  disabled={
                    window.location.pathname + window.location.hash ===
                    t("urls:" + "private" + "." + element)
                  }
                >
                  <div className="box-link-menu">
                    <div className="padding-link-desktop width-menu-mobile">
                      <FontIconsCustom
                        classIcon={"icons-color-menu icons-color-marging"}
                        icon={param.iconsMenu[index]}
                      />
                      {t("linksNames:" + element)}
                    </div>
                  </div>
                </Link>
              </MenusItemsCustomPro>
            );
          })}
          {!param.collapsed && <ClientNumber />}
        </MenusCustomPro>
      </Sider>
      <Content>{param.content}</Content>
    </Layout>
  );
};
