import React from "react";
import {
    ListCustomPro,
    ListItemCustomPro,
    ListItemMetaCustomPro,
    TitleCustom
} from "../custom";
import { CustomPagination } from "../../components/utils/pagination/pagination";
import { TotalElementsTableList } from "../../components/utils/totalElementsTableList/totalElementsTableList";
import { Pagination } from "../../models/pagination/pagination";
import { RewardSupplierDto } from "src/models/rewards/Reward";
import { RewardSupplier } from "./rewardSupplier";
import { useTranslation } from "react-i18next";

interface Props {
    rewardsSuppliers: RewardSupplierDto[];
    totalElements: number;
    pageResults: Pagination;
    setPageResults: (page: Pagination) => void;
}

export const RewardsSupplierList = (props: Props) => {
    const { t } = useTranslation(["seo", "urls", "rewards", "translation", "modalClosedSession"]);

    const { rewardsSuppliers, totalElements, pageResults, setPageResults } = props;

    const onChangeCallApi = (pagination: Pagination) => {
		setPageResults(pagination);
    };

    return (
        <div className="content-list">
            <TitleCustom
                level={1}
                class="title-section"
                text={t("rewards:suppliers.section")}
            />
            <ListCustomPro
                itemLayout={"horizontal"}
                dataSource={rewardsSuppliers}
                renderItem={(item: RewardSupplierDto) => (
                    <ListItemCustomPro className={"p-0 not-line-down"}>
                        <ListItemMetaCustomPro
                            className={"box-list-providers"}
                            description={
                                <RewardSupplier
                                    key={item.id}
                                    rewardSupplier={item}
                                />
                            }
                        />
                    </ListItemCustomPro>
                )}
            ></ListCustomPro>
            <div className="col-12 p-0 pagination-container">
                <CustomPagination
                    setPageResults={setPageResults}
                    pageResults={pageResults}
                    countData={totalElements}
                    onChangeCallApi={onChangeCallApi}
                />
            </div>
            <div className="col-12 p-0">
                <TotalElementsTableList total={totalElements} />
            </div>
        </div>
    );
}