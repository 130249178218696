import React, { useContext, useEffect, useState } from "react";
import "./params.sass";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { LangContext } from "src/hooks/context/lang";
import { RestApiClientContext } from "src/hooks/context/pageContext";
import {
  ButtonsCustom,
  DatePickerCustom,
  DrawerCustom,
  FontIconsCustom,
  FormCustom,
  SwitchCustomPro,
  TableCustom,
  TitleCustom,
} from "src/components/custom";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Skeleton,
  Spin,
  Typography,
} from "antd";
import { EditParams } from "./editRegimen/editParams";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { KnowUsCfg } from "src/models/knowUs/KnowUsCfg";

import { LoadingOutlined } from "@ant-design/icons";

interface ParamsItems {
  positions: PositionsItems[];
}

interface PositionsItems {
  id: string;
  name: string;
  identityCode: number;
  positionsTranslations: { idLanguage: string; name: string }[];
}

interface OtherConfig {
  mandatoryCompanyName: boolean;
  allowDuplicateLocatorReservations: boolean;
  ranking: {
    showRankingInWebAgents: boolean;
    showMyPositionInWebAgents: boolean;
  };
  noIndexNoFollow: boolean;
  showBillingInfo: boolean;
}

const Title = Typography;

export const Params = () => {
  const { t } = useTranslation("config");
  const restApiClient = useContext(RestApiClientContext);
  const language = useContext(LangContext);
  const [loadingSkeleton, setLoadingSkeleton] = useState<boolean>(true);
  const [loadingParams, setLoadingParams] = useState<boolean>(true);
  const [selectedPositionId, setSelectedPositionId] = useState(null);
  const [drawerChoices, setDrawerChoices] = useState([]);
  const [messageApi, setMessageApi] = useState<string>("");
  const [dataParams, setDataParams] = useState<ParamsItems>(null);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [positionsData, setPositionsData] = useState([]);
  const scrollLib = require("react-scroll");
  const scroll = scrollLib.animateScroll;
  const [form] = Form.useForm();
  const [formKowUs] = Form.useForm();
  const [formRanking] = Form.useForm();

  const [noIndexNoFollow, setNoIndexNoFollow] = useState<boolean>(false);
  const getNoIndexNoFollowData = (): void => {
    restApiClient.getAllowNoIndexNoFollow().then((data) => {
      setNoIndexNoFollow(data);
    });
  };

  const [savingRanking, setSavingRanking] = useState<boolean>(false);
  const [savingBookingRequestFormConfig, setSavingBookingRequestFormConfig] =
    useState<boolean>(false);

  const [otherConfigurations, setOtherConfigurations] = useState<OtherConfig>({
    allowDuplicateLocatorReservations: false,
    mandatoryCompanyName: false,
    ranking: {
      showMyPositionInWebAgents: false,
      showRankingInWebAgents: false,
    },
    noIndexNoFollow: true,
    showBillingInfo: true,
  });

  const [gtmId, setGtmId] = useState<string>("");
  const GetGtmIdConfig = (): void => {
    restApiClient.getGtmId().then((data) => {
      setGtmId(data);
    });
  };

  const SaveGtmId = (): void => {
    restApiClient.setGtmId(gtmId);
  };

  const [minDateCheckIn, setMinDateCheckIn] = useState<any>(null);
  const getMinCheckIn = (): void => {
    setLoadingParams(true);
    restApiClient
      .getMinCheckIn()
      .then((data) => {
        setMinDateCheckIn(data);
      })
      .catch((e) => {
        throw new Error(e);
      })
      .finally(() => {
        setLoadingParams(false);
      });
  };

  const updateMinCheckIn = (newDate: any): void => {
    setLoadingParams(true);
    restApiClient
      .updateMinCheckIn(newDate)
      .then((data) => {
        setMinDateCheckIn(moment(newDate));
      })
      .catch((e) => {
        throw new Error(e);
      })
      .finally(() => {
        setLoadingParams(false);
      });
  };

  const getOtherConfig = (): void => {
    setLoadingParams(true);
    restApiClient
      .getOtherConfig()
      .then((data) => {
        setOtherConfigurations(data);

        formRanking.setFieldsValue({
          allowGeneralRanking: data?.ranking.showRankingInWebAgents,
          allowPersonalRanking: data?.ranking.showMyPositionInWebAgents,
        });

        form.setFieldsValue({
          allowReservations: data?.allowDuplicateLocatorReservations,
          isMandatory: data?.mandatoryCompanyName,
          noIndexNoFollow: data?.noIndexNoFollow,
        });
      })
      .catch((e) => {
        throw new Error(e);
      })
      .finally(() => {
        setLoadingParams(false);
      });
  };

  const columns = [
    {
      title: t("params.table.name"),
      dataIndex: "positionName",
      key: "positionName",
      className: "positionName",
    },
    {
      title: t("params.table.column"),
      dataIndex: "positionTrans",
      key: "positionTrans",
      className: "positionTrans",
    },
    {
      title: null,
      dataIndex: "edit",
      key: "edit",
      width: "20px",
    },
  ];

  const handleEdit = async (cell) => {
    try {
      await setSelectedPositionId(cell.id);
      await setVisibleDrawer(true);
    } catch (err) {
      alert(err.message);
    }
  };

  const handleAddRegimen = () => {
    setSelectedPositionId(null);
    setVisibleDrawer(true);
  };

  useEffect(() => {
    getOtherConfig();
    getMinCheckIn();
  }, []);

  const saveRankingCfg = (): void => {
    var formData = formRanking.getFieldsValue();
    setSavingRanking(true);
    restApiClient.updateRankingConfig(formData).finally(() => {
      setSavingRanking(false);
    });
  };

  const handleRender = () => {
    setMessageApi("");
    setLoadingSkeleton(true);
    restApiClient
      .getPositions()
      .then((data) => {
        if (data && data?.length) {
          setDataParams((prevData) => ({
            ...prevData,
            positions: data.map((position, index) => ({
              ...position,
              edit: (
                <span
                  key={index}
                  onClick={() => handleEdit(position)}
                  className="action-edit"
                >
                  <FontIconsCustom icon={faEdit} />
                </span>
              ),
              positionName: position.positionsTranslations?.[1]?.name,
              positionTrans: position.positionsTranslations?.[0]?.name,
            })),
          }));
        } else {
          setDataParams((prevData) => ({ ...prevData, positions: [] }));
          setMessageApi("not-result");
        }
      })
      .finally(() => {
        setLoadingSkeleton(false);
      });
  };

  const [knowUsCfg, setKnowUsCfg] = useState<KnowUsCfg>({
    emailToSendBookingRequests: "",
    isEnabled: false,
    releaseDays: 1,
    sendCopyToAgent: false,
  });

  const [knowUsCfgIsEnabled, setKnowUsCfgIsEnabled] = useState<boolean>(false);
  const [knowUsCfgSendCopyToAgent, setKnowUsCfgSendCopyToAgent] =
    useState<boolean>(false);
  const [knowUsCfgReleaseDays, setKnowUsCfgReleaseDays] = useState<number>(1);
  const [
    knowUsCfgEmailToSendBookingRequests,
    setKnowUsCfgEmailToSendBookingRequests,
  ] = useState<string>("");
  const getKnowUsConfiguration = () => {
    restApiClient.getKnowUsConfiguration("").then((r: KnowUsCfg) => {
      setKnowUsCfg(r);
      setKnowUsCfgIsEnabled(r.isEnabled);
      setKnowUsCfgSendCopyToAgent(r.sendCopyToAgent);
      setKnowUsCfgReleaseDays(r.releaseDays);
      setKnowUsCfgEmailToSendBookingRequests(r.emailToSendBookingRequests);
    });
  };

  const saveRequestBookingCfg = (): void => {
    const parameters: KnowUsCfg = {
      isEnabled: knowUsCfgIsEnabled,
      sendCopyToAgent: knowUsCfgSendCopyToAgent,
      emailToSendBookingRequests: knowUsCfgEmailToSendBookingRequests,
      releaseDays: knowUsCfgReleaseDays,
    };

    setSavingBookingRequestFormConfig(true);
    restApiClient.saveKnowUsCfg(parameters).finally(() => {
      setSavingBookingRequestFormConfig(false);
    });
  };

  useEffect(() => {
    getKnowUsConfiguration();
    GetGtmIdConfig();
  }, []);

  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  useEffect(() => {
    handleRender();
    scroll.scrollToTop();
  }, []);

  return (
    <>
      {visibleDrawer && (
        <DrawerCustom
          title={
            <header className="d-flex justify-content-between align-items-center">
              <span className="d-block">
                {t(`regimens.add-${selectedPositionId ? "edit" : "new"}`)}
              </span>
              {selectedPositionId && (
                <ButtonsCustom
                  onClick={() => {
                    try {
                      restApiClient.deletePosition(selectedPositionId);
                      setDataParams((prevData) => ({
                        ...prevData,
                        positions: prevData.positions.filter(
                          (r) => r.id !== selectedPositionId
                        ),
                      }));
                      setVisibleDrawer(false);
                    } catch (error) {}
                  }}
                  content={<span>{t("rooms.drawer-delete")}</span>}
                  danger={true}
                />
              )}
            </header>
          }
          visible={visibleDrawer}
          setVisible={setVisibleDrawer}
          width={520}
          info={""}
          placement="right"
          closable={true}
          onClose={() => setVisibleDrawer(false)}
          content={
            <EditParams
              setDrawerChoices={setDrawerChoices}
              drawerChoices={drawerChoices}
              id={selectedPositionId}
              setVisible={setVisibleDrawer}
              reload={handleRender}
            />
          }
        />
      )}
      <Skeleton active loading={loadingParams || loadingSkeleton}>
        <div id="params" className="special-mb config-tab">
          <div className="row col-12">
            <TitleCustom level={4} text={t("params.main-title")} />
          </div>
          <div className="row col-12">
            <div className="align-self-center">
              <TitleCustom level={5} text={t("params.sub-title")} />
            </div>
            <div className="col align-self-center buttons my-1">
              <ButtonsCustom
                class={"primary-button"}
                type=""
                htmlType={null}
                onClick={handleAddRegimen}
                size={"small"}
                content={
                  <span>
                    <FontAwesomeIcon icon={faPlus} />
                    <strong>{t("regimens.add-new")}</strong>
                  </span>
                }
              />
            </div>
          </div>
          <div className="row">
            <TableCustom
              className="table-regimens col-12 my-4"
              columns={columns}
              dataSource={dataParams?.positions}
              pagination={true}
              bordered
            />
          </div>

          <Divider />

          <Form layout="vertical" form={formRanking} onFinish={saveRankingCfg}>
            <div className="params-title">
              <Typography.Title level={5}>
                Ranking
                <Button
                  size="small"
                  type="primary"
                  htmlType="submit"
                  disabled={savingRanking}
                >
                  {savingRanking ? <LoadingOutlined /> : <></>}
                  {t("params.request-booking.save")}
                </Button>
              </Typography.Title>
            </div>
            <Form.Item
              name="allowGeneralRanking"
              className="flex-container d-flex flex-row align-items-center justify-content-between my-3"
              valuePropName="checked"
              label={
                <span className="d-block">
                  {t("params.request-booking.enabled")}
                </span>
              }
            >
              <SwitchCustomPro
                defaultChecked={
                  otherConfigurations.ranking.showRankingInWebAgents
                }
                checked={otherConfigurations.ranking.showRankingInWebAgents}
              />
            </Form.Item>

            <Form.Item
              name="allowPersonalRanking"
              className="flex-container d-flex flex-row align-items-center justify-content-between my-3"
              valuePropName="checked"
              initialValue={
                otherConfigurations.ranking.showMyPositionInWebAgents
              }
              label={
                <span className="d-block">
                  {t("params.allowRankingPersonalPosition")}
                </span>
              }
            >
              <SwitchCustomPro
                onChange={(newValue) => {
                  //restApiClient.updateAllowPersonalRanking(newValue);
                }}
              />
            </Form.Item>
          </Form>

          <Divider />

          <Form
            layout="vertical"
            form={formKowUs}
            onFinish={saveRequestBookingCfg}
          >
            <div className="params-title">
              <Typography.Title level={5}>
                {t("params.request-booking.title")}
                <Button
                  disabled={savingBookingRequestFormConfig}
                  size="small"
                  type="primary"
                  htmlType="submit"
                >
                  {savingBookingRequestFormConfig ? <LoadingOutlined /> : <></>}
                  {t("params.request-booking.save")}
                </Button>
              </Typography.Title>
            </div>

            <Form.Item
              name="requestBookingEnabled"
              className="flex-container d-flex flex-row align-items-center justify-content-between my-3"
              valuePropName="checked"
              label={
                <span className="d-block">
                  {t("params.request-booking.enabled")}
                </span>
              }
            >
              <SwitchCustomPro
                defaultChecked={knowUsCfg.isEnabled}
                checked={knowUsCfg.isEnabled}
                onChange={setKnowUsCfgIsEnabled}
              />
            </Form.Item>

            <Form.Item
              name="requestBookingSendCopyToAgent"
              className="flex-container d-flex flex-row align-items-center justify-content-between my-3"
              valuePropName="checked"
              label={
                <span className="d-block">
                  {t("params.request-booking.send-copy-to-agent")}
                </span>
              }
            >
              <SwitchCustomPro
                disabled={!knowUsCfgIsEnabled}
                defaultChecked={knowUsCfg.sendCopyToAgent}
                checked={knowUsCfg.sendCopyToAgent}
                onChange={setKnowUsCfgSendCopyToAgent}
              />
            </Form.Item>

            <Form.Item
              name="requestBookingDestinationEmail"
              initialValue={knowUsCfg.emailToSendBookingRequests}
              className="flex-container d-flex flex-row align-items-center justify-content-between my-3"
              label={
                <span className="d-block">
                  {t("params.request-booking.destination-email")}
                </span>
              }
              rules={[
                {
                  message: t("validations.name"),
                  required: knowUsCfgIsEnabled,
                },
              ]}
            >
              <Input
                disabled={!knowUsCfgIsEnabled}
                value={knowUsCfg.emailToSendBookingRequests}
                onChange={(v: any) => {
                  setKnowUsCfgEmailToSendBookingRequests(v.target.value);
                }}
              />
            </Form.Item>

            <Form.Item
              name="requestBookingRelease"
              initialValue={knowUsCfg.releaseDays ?? 7}
              className="flex-container d-flex flex-row align-items-center justify-content-between my-3"
              label={
                <span className="d-block">
                  {t("params.request-booking.release")}
                </span>
              }
              rules={[
                {
                  message: t("validations.name"),
                  required: knowUsCfgIsEnabled,
                },
              ]}
            >
              <Input
                disabled={!knowUsCfgIsEnabled}
                type="number"
                min={1}
                max={365}
                value={knowUsCfg.releaseDays}
                onChange={(v: any) => {
                  setKnowUsCfgReleaseDays(v.target.value);
                }}
              />
            </Form.Item>
          </Form>

          <Divider />

          <Typography.Title level={5}>
            {t("params.other-configurations")}
          </Typography.Title>

          <FormCustom
            onFinish={null}
            form={form}
            layout="vertical"
            id="edit-channel"
            onFieldsChange={null}
          >
            <Form.Item
              name="isMandatory"
              className="flex-container d-flex flex-row align-items-center justify-content-between my-3"
              valuePropName="checked"
              label={<span className="d-block">{t("params.isMandatory")}</span>}
            >
              <SwitchCustomPro
                defaultChecked={otherConfigurations.mandatoryCompanyName}
                checked={otherConfigurations.mandatoryCompanyName}
                onChange={(newValue) => {
                  restApiClient.updateMandatoryName(newValue);
                }}
              />
            </Form.Item>
            <Form.Item
              name="allowReservations"
              className="flex-container d-flex flex-row align-items-center justify-content-between my-3"
              valuePropName="checked"
              label={
                <span className="d-block">{t("params.allowReservations")}</span>
              }
            >
              <SwitchCustomPro
                defaultChecked={
                  otherConfigurations.allowDuplicateLocatorReservations
                }
                checked={otherConfigurations.allowDuplicateLocatorReservations}
                onChange={(newValue) => {
                  restApiClient.updateAllowReservations(newValue);
                }}
              />
            </Form.Item>

            <Form.Item
              name="changeCheckInDate"
              className="flex-container d-flex flex-row align-items-center justify-content-between my-3"
              valuePropName="checked"
              label={
                <span className="d-block">
                  {t("params.set-mmin-date-value")}
                </span>
              }
            >
              <DatePickerCustom
                placeholder={t("translation:lang.dateFormat")}
                className="col-11 col-md-12"
                onChange={(date, dateString) => {
                  updateMinCheckIn(dateString);
                }}
                value={
                  minDateCheckIn != null ? moment(minDateCheckIn) : moment()
                }
              />
            </Form.Item>

            <Form.Item
              name="noIndexNoFollow"
              className="flex-container d-flex flex-row align-items-center justify-content-between my-3"
              valuePropName="checked"
              label={
                <span className="d-block">
                  {t("params.set-allow-noindex-nofollow")}
                </span>
              }
            >
              <SwitchCustomPro
                defaultChecked={otherConfigurations.noIndexNoFollow}
                checked={otherConfigurations.noIndexNoFollow}
                onChange={(newValue) => {
                  restApiClient.updateAllowNoIndexNoFollow(newValue);
                }}
              />
            </Form.Item>
            </FormCustom>

            <Divider />

            <Form
            layout="vertical"
            form={formKowUs}
            onFinish={saveRequestBookingCfg}
          >
            <div className="params-title">
              <Typography.Title level={5}>
                {"Google Tags Manager (GTM)"}
                <Button
                  size="small"
                  type="primary"
                  onClick={SaveGtmId}
                >
                  {savingRanking ? <LoadingOutlined /> : <></>}
                  {t("params.request-booking.save")}
                </Button>
              </Typography.Title>
            </div>

            <Form.Item
              name="gtmid"
              initialValue={gtmId ?? ''}
              className="flex-container d-flex flex-row align-items-center justify-content-between my-3"
              label={
                <span className="d-block">GTM ID</span>
              }
            >
              <Input
                value={gtmId}
                onChange={(v: any) => {
                  setGtmId(v.target.value);
                }}
              />
            </Form.Item>
          </Form>

            
          
        </div>
      </Skeleton>
    </>
  );
};
