import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../hooks/context/config";
import { Skeleton, Spin } from "antd";
import { API_URL_TEST, URL_BASE, URL_IMAGE } from "../../constants/constants";
import { NotResults } from "../noResultGeneric/notResults";
import {
    PopoverCustom,
    ButtonsCustom,
    FontIconsCustom,
    ParagraphCustom,
    TitleCustom,
} from "../custom";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { ListItem } from "../custom/lists/interfaceLists";
import { AlternateComponent } from "../utils/alternateComponent/alternateComponent";
import { Pagination, DefaultPagination } from "../../models/pagination/pagination";
import { SearchReservationFilter } from "./searchReservation/searchReservation";
import { LangContext } from "../../hooks/context/lang";
import { StructureListReservation } from "./structureListReservation/structureListReservation";
import { ListReservationComponent } from "./listReservation/listReservation";
import { StateReservations } from "../../constants/enums";
import Filteres from "../../models/regularizations/filteres"; // TODO: INTEGRACION EXCEL
import FileDownload from 'js-file-download';  // TODO: INTEGRACION EXCEL
import { LoadingOutlined } from "@ant-design/icons";
import { ConfigManagement } from "../../models/config/configManagement";
import { LanguageManagement } from "../../models/language/languageUserManagement";
import { User } from "../../models/user/userModel";
import { Reservation } from "./interfaces/reservation";
import { RestApiClientContext } from "../../hooks/context/pageContext";
import { exportExcel } from "../../components/utils/exportExcel/exportExcel";


import "./regularization.sass";

export const Regularization = () => {
    const restApiClient = useContext(RestApiClientContext);

    const { t } = useTranslation([
        "contact",
        "seo",
        "urls",
        "agentsRegister",
        "regularization",
    ]);

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const scrollLib = require("react-scroll");
    const scroll = scrollLib.animateScroll;

    const infoClient = useContext<ConfigManagement>(ConfigContext);
    const language = useContext<LanguageManagement>(LangContext);

    const [messageApi, setMessageApi] = useState<string>("");

    const [, setDataExcel] = useState<ListItem[]>([]);

    const [loadingList, setLoadingList] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [dataStructureList, setDataStructureList] = useState<ListItem[]>([]);
    const listParseComponent: ListItem[] = [];
    const [searchTermLocatorInput, setSearchTermLocatorInput] = useState<string>("");
    const [searchTermAgentEmailInput, setSearchTermAgentEmailInput] = useState<string>("");
    const [searchTermOwnLocatorInput, setSearchTermOwnLocatorInput] = useState<string>("");
    const [searchTermStateInput, setSearchTermStateInput] = useState<string>("");
    const [searchTermCheckinDateInput, setSearchTermCheckinDateInput] = useState<string>("");
    const [searchTermCheckoutDateInput, setSearchTermCheckoutDateInput] = useState<string>("");
    const [searchTermAgencyInput, setSearchTermAgencyInput] = useState<string>("");
    const [searchTermNameInput, setSearchTermNameInput] = useState<string>("");
    const [searchTermEstablishmentInput, setSearchTermEstablishmentInput] = useState<string>("");

    const [searching, setSearching] = useState<boolean>(false);

    const [totalElements, setTotalElements] = useState<number>(0);

    const [pageResults, setPageResults] = useState<Pagination>(DefaultPagination);

    const [lng, setLng] = useState<string>(language.GetLangContext());

    const [elementFormSearch, setElementFormSearch] = useState<boolean>(false)

    // TODO: Tipo archivo Excel
    const fileExtension: string = ".xlsx";

    const [loadingExcel, setLoadingExcel] = useState<boolean>(false);

    const desktopVersion = () => {
        return (
            <ButtonsCustom
                id="btn-excel"
                class={"button-agent-register margin-button-excel-agent excel-button"}
                type=""
                htmlType={null}
                onClick={sendExcel}
                disabled={false}
                size={null}
                style={{backgroundColor: "red !important"}}
                content={
                    <div className="button-excel">
                        {loadingExcel ? <LoadingOutlined /> : <img src={URL_IMAGE + "excel-logo.svg"} />}
                        <ParagraphCustom
                            class={"mb-0 ml-1"}
                            text={t("regularization:button-excel")}
                        />
                    </div>
                }
            />
        );
    };

    const tabletVersion = () => {
        return desktopVersion();
    };

    const mobileVersion = () => {
        return (
            <PopoverCustom
                classBody={"position-float-right"}
                body={
                    <ButtonsCustom
                        id={"btn-excel"}
                        class={"button-list-agents"}
                        type=""
                        htmlType={null}
                        disabled={false}
                        size={null}
                        icon={<img src={URL_IMAGE + "excel-logo.svg"} />}
                        content={
                            <div className="button-excel">
                                {loadingExcel ? <LoadingOutlined /> : <img src={URL_IMAGE + "excel-logo.svg"} />}
                                <FontIconsCustom
                                    classIcon={"icons-list-agent-register"}
                                    icon={faEllipsisV}
                                />
                            </div>
                        }
                    />
                }
                content={
                    <ButtonsCustom
                        id="btn-excel"
                        class="button-agent-register-movil excel-button"
                        type=""
                        onClick={sendExcel}
                        htmlType={null}
                        disabled={false}
                        size={null}
                        icon={<img src={URL_IMAGE + "excel-logo.svg"} />}
                        content={
                            <div className="button-excel">
                                {loadingExcel ? <LoadingOutlined /> : <img src={URL_IMAGE + "excel-logo.svg"} />}
                                <ParagraphCustom
                                    class={"mb-0 ml-1"}
                                    text={t("regularization:button-excel")}
                                />
                            </div>
                        }
                    />
                }
                placement={"topLeft"}
                title=""
                trigger="focus"
            />
        );
    };

    useEffect(() => {
        scroll.scrollToTop();
    }, []);

    // Funcion que exporta la informacion a un excel
    const [downloadingBookings, setDownloadingBookings] = useState<boolean>(false);
    const sendExcel = async () => {
        setLoadingExcel(true);
        setDownloadingBookings(true);
        const response = await exportExcel(`${API_URL_TEST}/Bookings/exportexcel?language=${language.GetLangContext()}`).finally(()=>{setLoadingExcel(false)}).finally(()=>{setDownloadingBookings(false)});
        FileDownload(response, t("agentsRegister:excel-agents-booking-file") + fileExtension);
    };

    useEffect(() => {
        if (language.GetLangContext() !== lng) {
            setLng(language.GetLangContext());
        }
    });

    const getBookingsFiltered = () : void => {
        setLoadingList(true);
        setElementFormSearch(true)

        const filter = {
            bookingReference: searchTermLocatorInput,
            ownReference: searchTermOwnLocatorInput,
            bookingStatus: searchTermStateInput,
            checkIn: searchTermCheckinDateInput,
            checkOut: searchTermCheckoutDateInput,
            agency: searchTermAgencyInput,
            agentEmail: searchTermAgentEmailInput,
            nameSurnameClient: searchTermNameInput,
            idEstablishment: searchTermEstablishmentInput,
            pagination: pageResults,
            language: language.GetLangContext(),
        }

        restApiClient.getBookingsFiltered(filter)
            .then((data) => {
                if (data && data.bookings.length > 0) {
                    var finalList: ListItem[] = [];

                    data.bookings.map((element) => {
                        const elementForList: ListItem = {
                            avatar: null,
                            title: "",
                            description: (
                                <StructureListReservation
                                    onchange={getBookingsFiltered}
                                    data={element as Reservation}
                                    setPageResults={setPageResults}
                                />
                            ),
                        };
                        finalList.push(elementForList);
                    });
                    setDataStructureList(finalList);
                    setDataExcel(data.bookings);
                    setTotalElements(data.totalRecord);
                } else {
                    setDataStructureList([]);
                }

                setElementFormSearch(false);
                setLoading(false);
                setLoadingList(false);
            })
    }

    useEffect(() => {
        getBookingsFiltered();
    }, [
        searchTermLocatorInput,
        searchTermAgentEmailInput,
        searchTermOwnLocatorInput,
        searchTermStateInput,
        searchTermCheckinDateInput,
        searchTermCheckoutDateInput,
        searchTermAgencyInput,
        searchTermNameInput,
        searchTermEstablishmentInput,
        pageResults,
        lng,
    ]);

    return (
        <>
            <Helmet>
                <title>
                    {infoClient.incentiveSiteName} | {t("seo:title.contact")}
                </title>
                <meta
                    name="description"
                    content={t("seo:description.contact", {
                        0: infoClient.incentiveSiteName,
                    })}
                />
                <meta name="robots" content="index,follow" />
                <link
                    rel="canonical"
                    href={URL_BASE + t("urls:public.contact")}
                />
                <link
                    rel="icon"
                    type="image/ico"
                    href={infoClient.favicon}
                    sizes="32x32"
                />
                <link
                    href={URL_BASE + t("urls:public.contact")}
                    hrefLang="es-ES"
                    rel="alternate"
                />
            </Helmet>
            
                    <div className="row special-mb">
                        <div className="col-9">
                            <TitleCustom
                                level={1}
                                class="title-section"
                                text={t("regularization:title")}
                            />
                            <ParagraphCustom
                                class={"p-0"}
                                text={t("regularization:subtitle", {
                                    0: infoClient.incentiveSiteName,
                                })}
                            />
                        </div>
                        <div className="col-3">
                            <AlternateComponent
                                desktopVersionLarge={desktopVersion()}
                                tabletVersion={tabletVersion()}
                                mobileVersionLarge={mobileVersion()}
                            />
                        </div>
                        <SearchReservationFilter
                            searchTermLocatorInput={searchTermLocatorInput}
                            searchTermAgentEmailInput={searchTermAgentEmailInput}
                            searchTermOwnLocatorInput={searchTermOwnLocatorInput}
                            searchTermStateInput={searchTermStateInput}
                            searchTermCheckinDateInput={searchTermCheckinDateInput}
                            searchTermCheckoutDateInput={searchTermCheckoutDateInput}
                            searchTermAgencyInput={searchTermAgencyInput}
                            searchTermNameInput={searchTermNameInput}
                            searchTermEstablishmentInput={
                                searchTermEstablishmentInput
                            }
                            setSearchTermLocatorInput={setSearchTermLocatorInput}
                            setSearchTermOwnLocatorInput={
                                setSearchTermOwnLocatorInput
                            }
                            setSearchTermAgentEmailInput={setSearchTermAgentEmailInput}
                            setSearchTermStateInput={setSearchTermStateInput}
                            setSearchTermCheckinDateInput={setSearchTermCheckinDateInput}
                            setSearchTermCheckoutDateInput={setSearchTermCheckoutDateInput}
                            setSearchTermAgencyInput={setSearchTermAgencyInput}
                            setSearchTermNameInput={setSearchTermNameInput}
                            setSearchTermEstablishmentInput={
                                setSearchTermEstablishmentInput
                            }
                            setPageResults={setPageResults}
                            setSearching={setSearching}
                            elementFormSearch={elementFormSearch}
                        />
                        <Skeleton
                            loading={loading}
                            paragraph={{ rows: 5 }}
                            active
                        >
                            { loadingList ? (
                                <Spin indicator={antIcon} />
                            ) : dataStructureList.length === 0 || messageApi !== "" ? (
                                <NotResults
                                    section={"regularization"}
                                    dataSearch={searching}
                                    messageApi={messageApi}
                                />
                            ) : (
                                <ListReservationComponent
                                    dataStructureList={dataStructureList}
                                    count={totalElements}
                                    setPageResults={setPageResults}
                                    pageResults={pageResults}
                                />
                            )}
                        </Skeleton>
                    </div>
        </>
    );
};
