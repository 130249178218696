import EndPointConfig from "src/models/api/endPointConfig";

const controller = 'KnowUs';

/// Define all EndPoints for Client Data.
export const KnowUsEP : EndPointConfig[] = [
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetKnowUsConfiguration')
        .setActionName("GetKnowUsConfiguration"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setAlias('SaveKnowUsCfg')
        .setActionName("SaveKnowUsCfg")
];

