import React, { useState, useEffect, useContext } from "react";
import {
    ModalCustomPro,
    ParagraphCustom,
    RadioGroupButtonCustom,
    RadioGroupCustom,
} from "../../custom";
import { getLocaleDatePicker } from "../../../utils/datePicker";
import { PickerLocale } from "antd/es/date-picker/generatePicker";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "./modalRegularization.sass";
import { LangContext } from "../../../hooks/context/lang";
import { StateReservations } from "../../../constants/enums";
import { StructureChangeStatus } from "../structureChangeStatus/structureChangeStatus";
import { LoadingOutlined } from '@ant-design/icons'
import ReactHtmlParser from "react-html-parser";
import {
    DefaultPagination,
    Pagination,
} from "../../../models/pagination/pagination";
import { ReservationInfo } from "../interfaces/reservationInfo";
import { LanguageManagement } from "src/models/language/languageUserManagement";
import { Radio } from "../interfaces/radioInfo";
import { Email } from "../interfaces/emailsReservation";
import { RestApiClientContext } from "../../../hooks/context/pageContext";
import { Alert, Button, Checkbox, Skeleton, Tooltip, notification } from "antd";

import { InfoCircleOutlined } from "@ant-design/icons";

interface DataModal {
    onchange: any;
    idReservation: string;
    reservation: ReservationInfo;
    setvisibleModalChangeStatus: (value: boolean) => void;
    setPageResults: (pagination: Pagination) => void;
}

export const ModalRegularization = (props: DataModal) => {
    const restApiClient = useContext(RestApiClientContext);

    const {
        idReservation, reservation, setvisibleModalChangeStatus, setPageResults,
    } = props

    const { t } = useTranslation(["regularization"]);

    const local: PickerLocale = getLocaleDatePicker();

    const [listOptions, setListOptions] = useState<any>([]);

    const userLangContext = useContext<LanguageManagement>(LangContext);

    const [lng] = useState<string>(userLangContext.GetLangContext());

    const [statusRadioSelectCheck, setStatusRadioSelectCheck] = useState<
        Radio
    >();

    const [statusStructureVisible, setStatusStructureVisible] = useState<
        boolean
    >(false);

    const [constentBodyMail, setConstentBodyMail] = useState<string>("");

    const [ownReferenceState, setOwnReferenceState] = useState<string>("");

    const [emailVisualCheck, setEmailVisualCheck] = useState<Email[]>([]);

    const statusRadioSelect = (e) => {
        setStatusStructureVisible(true);
        setStatusRadioSelectCheck(JSON.parse(e.target.value));
    };
    const [changingStatus, setChangingStatus] = useState<boolean>(false);

    const openNotificationWithIcon = (type: any) => {
        notification[type]({
          message: t("regularization:similar-bookings.warning"),
          description: t("regularization:similar-bookings.warning-alert-confirm"),
        });
      };


    const handleOk = (e) => {
        if (!confirmReadSimilarBookings && similarBookings?.length > 0){
            openNotificationWithIcon("warning");
            return false;
        }

        if (changingStatus){
            return false;
        }
        setChangingStatus(true);
        restApiClient.changeStatusBooking(idReservation, statusRadioSelectCheck.id, ownReferenceState, constentBodyMail)
            .then((data) => {
                if (data) {
                    /*showNotification(
                        "success",
                        t(
                            "regularization:messages-notification.booking-" +
                            statusRadioSelectCheck.id +
                            "-title"
                        ),
                        t(
                            "regularization:messages-notification.booking-" +
                            statusRadioSelectCheck.id +
                            "-text"
                        ),
                        "topRight"
                    );*/
                    props.onchange();
                }
                setPageResults(DefaultPagination);
            })
            .finally(()=>{
                setChangingStatus(false);
            })
    };

    const handleCancel = (e) => {
        setvisibleModalChangeStatus(false);
    };

    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const getStatusBookingSelect = (languageUser) => {
        setLoadingStatus(true);
        restApiClient.getBookingsStatus(languageUser)
            .then((data) => {
                const optionsState = [];
                data.map((e) => {
                    optionsState.push({
                        id: e.id,
                        name: e.name,
                        description: e.description,
                    });
                });

                getStatusChangeOptionByStatusActuality(optionsState);
            }).finally(()=>{
                setLoadingStatus(false);
            })
    };

    const [similarBookings, setsimilarBookings] = useState<any[]>([])
    const getCoincidences = () => {
        restApiClient.getBookingCoincidences(idReservation)
            .then((data) => {
                setsimilarBookings(data ?? []);
                if (data.length == 0){
                    setConfirmReadSimilarBookings(true);
                }
            })
    }

    const [confirmReadSimilarBookings, setConfirmReadSimilarBookings] = useState<boolean>(false);
    const onChangeSimilarConfirm = (v) => {
        setConfirmReadSimilarBookings(v.target.checked);
    }

    useEffect(() => {
        getStatusBookingSelect(userLangContext.GetLangContext());
        getBookingStatusEmails();
    }, [lng]);

    const getStatusChangeOptionByStatusActuality = (options) => {
        const dateCo = new Date(reservation.checkOut);
        const today = new Date();
        let optionAux = [];
        let optionAditional = [];

        if (
            dateCo < today &&
            (reservation.status.id === StateReservations.Pending ||
                reservation.status.id === StateReservations.Incomplete)
        ) {
            optionAux = options.filter(
                (opt) =>
                    opt.id !== StateReservations.Validated &&
                    opt.id !== StateReservations.Cancelled
            );

            optionAditional = options.filter(
                (opt) =>
                    opt.id === StateReservations.Validated ||
                    opt.id === StateReservations.Cancelled
            );

            // Modificamos los nombres para que aunque  sea lo mismo que validada y cancelada el nombre a mostrar debe ser otro
            // (regularizacion-validada o regularizacion-cancelada )
            optionAditional[0].name = t(
                "regularization:modal.status-aditional.regularization-cancel"
            );
            optionAditional[1].name = t(
                "regularization:modal.status-aditional.regularization-active"
            );

            optionAditional.map((e) => {
                optionAux.push(e);
            });
        }

        switch (reservation.status.id) {
            case StateReservations.Pending:
                if (dateCo < today) {
                    setListOptions(
                        optionAux.filter(
                            (opt) =>
                                opt.id === StateReservations.Validated ||
                                opt.id === StateReservations.Cancelled ||
                                opt.id === StateReservations.Incomplete ||
                                opt.id === StateReservations.Duplicated ||
                                opt.id === StateReservations.Rejected
                        )
                    );
                } else {
                    setListOptions(
                        options.filter(
                            (opt) =>
                                opt.id === StateReservations.Regularized ||
                                opt.id === StateReservations.Incomplete ||
                                opt.id === StateReservations.Duplicated ||
                                opt.id === StateReservations.Rejected
                        )
                    );
                }
                break;
            case StateReservations.Incomplete:
                if (dateCo < today) {
                    setListOptions(
                        optionAux.filter(
                            (opt) =>
                                opt.id === StateReservations.Validated ||
                                opt.id === StateReservations.Cancelled ||
                                opt.id === StateReservations.Duplicated ||
                                opt.id === StateReservations.Rejected
                        )
                    );
                } else {
                    setListOptions(
                        options.filter(
                            (opt) =>
                                opt.id === StateReservations.Regularized ||
                                opt.id === StateReservations.Duplicated ||
                                opt.id === StateReservations.Rejected
                        )
                    );
                }
                break;
            case StateReservations.Regularized:
                setListOptions(
                    options.filter(
                        (opt) =>
                            opt.id === StateReservations.Validated ||
                            opt.id === StateReservations.Cancelled
                    )
                );
                break;
        }
    };

    const getBookingStatusEmails = () => {
        restApiClient.getBookingStatusEmails(idReservation)
            .then((data) => {
                setEmailVisualCheck(data);
            })
    };

    useEffect(()=>{
        getCoincidences();
    },[])

    return (
        <ModalCustomPro
            title={t("regularization:modal.title", {
                0: reservation.status.name,
            })}
            visible={true}
            closable={true}
            onOk={handleOk}
            okText={changingStatus ? <><LoadingOutlined /> {t("regularization:modal.buttons.accept")}</> : t("regularization:modal.buttons.accept")}
            onCancel={handleCancel}
            cancelText={t("regularization:modal.buttons.cancel")}
            bodyStyle={{ maxHeight: 700, overflow: "auto" }}
            className="modal-button-cancel-regularization"
        >
            <div className="col-12 box-main-info">
                <div className="col-12 p-0 info-box">
                    <div className="col-md-6 col-12">
                        <ParagraphCustom
                            class="mb-0 text-title-info-reservation"
                            text={t("regularization:info-reservation.locator")}
                        />
                        <ParagraphCustom
                            class="mb-3"
                            text={
                                <strong className="color-text-info">
                                    {reservation.reference}
                                </strong>
                            }
                        />
                        <ParagraphCustom
                            class="mb-0 text-title-info-reservation"
                            text={t("regularization:info-reservation.roomsNumber")}
                        />
                        
                        <ParagraphCustom
                            class="mb-3"
                            text={
                                <strong className="color-text-info">
                                    {reservation.roomsNumber}
                                </strong>
                            }
                        />
                        <ParagraphCustom
                            class="mb-0 text-title-info-reservation"
                            text={t(
                                "regularization:info-reservation.date-ch-in-out"
                            )}
                        />
                        <ParagraphCustom
                            class="mb-3"
                            text={
                                <strong className="color-text-info">
                                    {moment(reservation.checkIn).format(
                                        local.dateFormat
                                    ) +
                                        "  " +
                                        moment(
                                            reservation.checkOut
                                        ).format(local.dateFormat)}
                                </strong>
                            }
                        />
                        <ParagraphCustom
                            class="mb-0 text-title-info-reservation"
                            text={t("regularization:info-reservation.agent")}
                        />
                        <ParagraphCustom
                            class={"mb-3"}
                            text={
                                <strong className="color-text-info">
                                    {reservation.nameSurnameAgent}
                                </strong>
                            }
                        />
                    </div>
                    <div className="col-md-6 col-12 info-right-reservation">
                        <ParagraphCustom
                            class="mb-0 text-title-info-reservation"
                            text={t("regularization:info-reservation.client")}
                        />
                        <ParagraphCustom
                            class="mb-3"
                            text={
                                <strong className="color-text-info">
                                    {reservation.nameSurnameClient}
                                </strong>
                            }
                        />
                        <ParagraphCustom
                            class="mb-0 text-title-info-reservation"
                            text={t(
                                "regularization:info-reservation.establishment"
                            )}
                        />
                        <ParagraphCustom
                            class="mb-3"
                            text={
                                <strong className="color-text-info">
                                    {reservation.establishmentName}
                                </strong>
                            }
                        />
                        <ParagraphCustom
                            class="mb-0 text-title-info-reservation"
                            text={t("regularization:info-reservation.agency")}
                        />
                        <ParagraphCustom
                            class={"mb-0"}
                            text={
                                <strong className="color-text-info">
                                    {reservation.agencyName}
                                </strong>
                            }
                        />
                    </div>
                </div>
                <div className="col-12">
                    <ParagraphCustom
                        class={"mb-0 text-title-info-reservation"}
                        text={t("regularization:info-reservation.channel")}
                    />
                    <ParagraphCustom
                        class={"mb-3"}
                        text={
                            <strong className="color-text-info">
                                {reservation.bookingWay.name}
                            </strong>
                        }
                    />
                        
                </div>
                    {
                        reservation.bookingRoomsConfiguration && reservation.bookingRoomsConfiguration.length > 0 ?
                            <div className="col-12">
                                <ParagraphCustom
                                    class={"mb-0 text-title-info-reservation"}
                                    text={t("regularization:info-reservation.roomsExtraInfo")}
                                />
                                <ParagraphCustom
                                    class="mb-3"
                                    text={
                                        <strong className="color-text-info">
                                            <ul>
                                                {
                                                    reservation.bookingRoomsConfiguration.map((bookingRoomConfiguration: any, i: number)=>{
                                                        return <li>{bookingRoomConfiguration.roomType}  - {bookingRoomConfiguration.regimen}</li>
                                                    })
                                                }
                                            </ul>
                                        </strong>
                                    }
                                />
                            </div>
                        :
                        <></>
                    }
                
                {reservation.ownReference && (
                    <div className="col-12 mt-3">
                        <ParagraphCustom
                            class={"mb-0 text-title-info-reservation"}
                            text={t(
                                "regularization:info-reservation.own-locator"
                            )}
                        />
                        <ParagraphCustom
                            class={"mb-md-0 mb-3"}
                            text={
                                <strong className="color-text-info">
                                    {reservation.ownReference}
                                </strong>
                            }
                        />
                    </div>
                )}
            </div>
            <div className="col-12 p-0 mt-4 change-status-box">
                {
                    similarBookings.length > 0 ? 
                        <div>
                            <Alert
                                message={t("regularization:similar-bookings.warning")}
                                description={t("regularization:similar-bookings.warning-title")}
                                type="warning"
                                showIcon
                                closable
                            />
                            <p className="similar-text">{t("regularization:similar-bookings.warning-message")}</p>
                        </div>
                    :
                    <></>
                }
                <ul className="similar-list">
                    {
                        similarBookings?.map((bookingData: any) => {
                            return  <li>
                                        <Tooltip placement="top" title={ReactHtmlParser(t("regularization:similar-bookings.warning-info", [
                                                                                                                                                bookingData.reference,
                                                                                                                                                bookingData.nameSurnameAgent,
                                                                                                                                                moment(bookingData.creationDate).format(local.dateFormat),
                                                                                                                                                moment(bookingData.checkIn).format(local.dateFormat),
                                                                                                                                                moment(bookingData.checkOut).format(local.dateFormat), 
                                                                                                                                                bookingData.status.id]))}>
                                            <InfoCircleOutlined className="similar-icon"/> 
                                        </Tooltip>
                                        {bookingData.reference} ({bookingData.status.id}) 
                                    </li>
                        })
                    }
                </ul>

                {
                    similarBookings.length > 0
                    ?
                    <>
                        <Checkbox onChange={onChangeSimilarConfirm}>{t("regularization:similar-bookings.warning-confirm")}</Checkbox>
                    </>
                    : 
                    <></>
                }

                {
                    /*
                        <Button className="btn-search" onClick={getCoincidences}>
                            {t("regularization:similar-bookings.btn-repeat-search")}
                        </Button>
                    */
                }
            </div>
            <div className="col-12 p-0 mt-4 change-status-box">
                <ParagraphCustom
                    class="mb-0"
                    text={
                        <strong>
                            {t("regularization:modal.select-status")}
                        </strong>
                    }
                />
                <Skeleton active loading={loadingStatus}>
                    <RadioGroupCustom
                        onChange={statusRadioSelect}
                        buttonStyle="solid"
                    >
                        
                            {listOptions.map((e, i) => {
                                return (
                                    <RadioGroupButtonCustom
                                        key={i}
                                        value={JSON.stringify(e)}
                                        onClick={()=>{
                                            //Programar scroll
                                        }}
                                    >
                                        {e.name}
                                    </RadioGroupButtonCustom>
                                );
                            })}
                    </RadioGroupCustom>
                </Skeleton>

            </div>
            <div className="col-12 p-0 p-3">
                {statusStructureVisible && (
                    <StructureChangeStatus
                        id={idReservation}
                        ownReference={reservation.ownReference}
                        langAgent={reservation.idAgentLanguage}
                        agency={reservation.bookingWay.id}
                        statusRadioSelectCheck={statusRadioSelectCheck}
                        emailVisualCheck={emailVisualCheck}
                        setConstentBodyMail={setConstentBodyMail}
                        setOwnReferenceState={setOwnReferenceState}
                        futureRewards={`${reservation.futureReward} ${reservation.currencyId}`}
                    />
                )}
            </div>
            
        </ModalCustomPro>
    );
};
