// Modelo para el idioma de usuario
export class LanguageManagement {
    public lang: string;

    constructor() {
        this.lang = "";
    }

    UpdateLangContext = (newValue: string) => {
        this.lang = newValue;
    }

    GetLangContext = (): string => {
        return this.lang;      
    }

}
