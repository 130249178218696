import React, { useState, useEffect, useContext } from "react";
import "./formContact.sass";
import { Form, Row, Col, Skeleton, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { showNotification } from "../../../utils/notificationsGeneric";
import { LinkActiveContext } from "../../../hooks/context/linkActive";
import { LangContext } from "../../../hooks/context/lang";
import DataContact from './interfaceDataContact';
import {
	FormCustom,
	InputCustom,
	ButtonsCustom,
	FormItemsContact,
	SelectCustom,
	TextAreaCustomPro,
	CheckCustom,
} from "../../custom";
import { LoadingOutlined } from "@ant-design/icons";
import { RestApiClientContext } from "../../../hooks/context/pageContext";

// Componente del formulario de contacto de la web
export const FormContact = () => {
	const restApiClient = useContext(RestApiClientContext);

	const linkActive = useContext(LinkActiveContext);
	const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
	const zone = window.location.pathname.includes("app")
		? "private"
		: "public";

	// Idioma para los textos
	const { t } = useTranslation(["contact", "urls", "translation"]);
	const [validationShow, setValidationShow] = useState<boolean>(false);
	const [inputName, setInputName] = useState<string>("");
	const [inputEmail, setInputEmail] = useState<string>("");
	const [inputSubject, setInputSubject] = useState<string>("OT");
	const [inputSubjectElement, setInputSubjectElement] = useState<string>("");
	const [inputMessage, setInputMessage] = useState<string>("");
	const [inputCheck, setInputCheck] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);

	const [loadingButton, setLoadingButton] = useState(false);

	const [subjects, setSubjects] = useState([]);
	const [sendForm, setSendForm] = useState(false);

	const language = useContext(LangContext);
	const userLang = language.GetLangContext();

	const handleChangeName = (e) => {
		setInputName(e.target.value);
	};

	const handleChangeEmail = (e) => {
		setInputEmail(e.target.value);
	};

	const handleChangeSubject = (e) => {
		setInputSubject(JSON.parse(e).id);
		setInputSubjectElement(JSON.parse(e));
	};

	const handleChangeMessage = (e) => {
		setInputMessage(e.target.value);
	};

	const [form] = Form.useForm();

	// Submit form
	const onFinish = async () => {
		const dataContact = {
			name: inputName,
			email: inputEmail,
			idTypeEmail: inputSubject,
			message: inputMessage,
		} as DataContact

		restApiClient.sendBackOfficeForm(dataContact)
			.then((data) => {
				setLoadingButton(false);

				if (data) {
					setSendForm(data);

					showNotification(
						"success",
						t("contact:response.title"),
						t("contact:response.message"),
						"topRight"
					);
					form.resetFields();
					setInputSubjectElement("");
					setInputCheck(false);
				}
			})
	};

	const onFinishFailed = () => {
		setLoadingButton(false);
	};

	useEffect(() => {
		getElement();
	}, []);

	const getElement = () => {
		form.resetFields();

		restApiClient.getContactEmailTypes(userLang)
		.then((data) => {
			if (data) {
				const opts = [];

				data.map((e, i) => {
					opts.push({
						id: e.id,
						name: e.text,
					});
				});

				setSubjects(opts);
				setLoading(false);
			}
		})
	};

	return (
		<>
			{loading ? (
				<Skeleton active />
			) : (
					<>
						<FormCustom
							form={form}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
						>
							<div className="row">
								<div className="col-12 col-lg-6 contain-main-data">
									<FormItemsContact
										key={1}
										className={"row-form-item-contact"}
										label={t("contact:form.name")}
										name={"name"}
										rules={[
											{
												required: true,
												message: t(
													"contact:form.errors.message"
												),
											},
											{
												whitespace: true,
												message: t(
													"contact:form.errors.message"
												),
											},
										]}
									>
										<InputCustom
											id="name"
											className="main-input"
											onChange={handleChangeName}
										/>
									</FormItemsContact>
									<FormItemsContact
										key={2}
										className={"row-form-item-contact"}
										label={t("contact:form.email")}
										name={"mail"}
										rules={[
											{
												required: true,
												message: t(
													"contact:form.errors.message"
												),
											},
											{
												type: "email",
												message: t(
													"contact:form.errors.invalid-mail"
												),
											},
										]}
									>
										<InputCustom
											id="mail"
											className="main-input"
											onChange={handleChangeEmail}
										/>
									</FormItemsContact>
									<FormItemsContact
										key={3}
										className="row-form-item-contact"
										label={t("contact:form.subject")}
										name="subject"
										rules={[
											{
												required: true,
												message: t(
													"contact:form.errors.message"
												),
											},
										]}
									>
										<SelectCustom
											id="subject"
											className="main-input"
											options={subjects}
											onChange={handleChangeSubject}
											placeholder={
												t(
													"contact:form.placeHolden-subject"
												)
											}
										/>
									</FormItemsContact>
								</div>
								<div className="col-12 col-lg-6 contain-text-area">
									<FormItemsContact
										key={4}
										className="row-form-item-contact"
										label={t("contact:form.message")}
										name="message"
										rules={[
											{
												required: true,
												message: t(
													"contact:form.errors.message"
												),
											},
											{
												whitespace: true,
												message: t(
													"contact:form.errors.message"
												),
											},
										]}
									>
										<TextAreaCustomPro
											id="message"
											className="main-input"
											onChange={handleChangeMessage}
											maxlength={1000}
										/>
									</FormItemsContact>
								</div>
								<div className="col-12 contain-check-link-form">
									<FormItemsContact
										key={5}
										className="margin-bottom-check"
										name="remember"
										rules={[
											{
												validator: (_, value) =>
													value
														? Promise.resolve()
														: Promise.reject(
															t(
																"contact:form.errors.message"
															)
														),
											},
										]}
									>
										<CheckCustom
											id="remember"
											className="main-input box-check"
											valuePropName="checked"
											content={
												<div className="align-text-check">
													{t("form.check")}
													<Link
														id="link-privacy-policies"
														className="primary-links"
														to={t(
															"urls:" +
															zone +
															".privacy-policies"
														)}
														onClick={() =>
															linkActive.updateValueUrl(
																t(
																	"urls:" +
																	zone +
																	".privacy-policies"
																)
															)
														}
													>
														{t("contact:form.link")}
													</Link>
												</div>
											}
										/>
									</FormItemsContact>
									{validationShow && (
										<div className="message-error-check">
											{t("contact:form.errors.message")}
										</div>
									)}
								</div>
								<div className="col-12 contain-button-link-form">
									<Form.Item>
										<Row className={"align-text-buttons"}>
											<Col
												span={24}
												className="align-text-buttons"
											>
												<ButtonsCustom
													id="btn-send-form-contact"
													class="col-6 primary-button button-send-contact"
													type="primary"
													size="large"
													htmlType="submit"
													onClick={() =>
														setLoadingButton(true)
													}
													content={t(
														"contact:form.button"
													)}
												/>
											</Col>
										</Row>
									</Form.Item>
								</div>
							</div>
						</FormCustom>
						{loadingButton && (
							<Spin className="spin-contact" indicator={antIcon} />
						)}
					</>
				)}
		</>
	);
};

