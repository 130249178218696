
// Regular Expresion for password validation: Must have uppercase and lowercase characters
export const PASSWORD_LOWER_AND_UPPER_RE : RegExp  = /([a-z][A-Z])|([A-Z][a-z])/;

// Regular Expresion for password validation: Empty characters (no allowed)
export const PASSWORD_HAVE_EMPTY_CHARACTERS_RE : RegExp = /\s/;

// Regular Expresion for password validation: Text between 8 and 25 characters
export const PASSWORD_MIN_MAX_LENGTH_RE : RegExp = /([a-z]|[A-Z]|[0-9]){8,25}/;

// Validar un email
export const MAIL_VALIDATE : RegExp = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;

// Solo números
export const ONLY_NUMBERS: RegExp = /^[0-9]+\.*$/;

// Solo números que pueden ser decimales
export const ONLY_NUMBER_D: RegExp = /^[0-9][\.\d]*(,\d+)?$/;

// Evita que se pueda poder ejemplo : 5.5.5
export const NO_CHART_REPITED : RegExp = /^[0-9]{0,100}([,.][0-9]{0,100})?$/;

// Evita que se pueda poder ejemplo : 5.5.5 y limita a 2 decimales
export const NO_CHART_REPITED_AND_NUMBER_DECIMAL : RegExp = /^[0-9]{0,100}([,.][0-9]{0,2})?$/;
