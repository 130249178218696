import React, { useState, useEffect, useContext } from "react";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { Row, Col, Tooltip, Popconfirm } from "antd";
import "./structureListAgents.sass";
import {
    faUser,
    faGlobe,
    faEllipsisV,
    faQuestionCircle,
    faUserTie,
    faBuildingUser
} from "@fortawesome/free-solid-svg-icons";
import {
    FontIconsCustom,
    SwitchCustom,
    ButtonsCustom,
    TagInfoCustom,
    PopoverCustom,
    DrawerCustom,
    TooltipCustom,
    ModalCustom,
    TextAreaCustomPro,
    ParagraphCustom,
    SwitchCustomPro,
} from "../../custom";
import {
    ExclamationCircleOutlined,
    ClockCircleOutlined,
    MinusCircleOutlined,
    LoadingOutlined,
    MailOutlined
} from "@ant-design/icons";
import { StructureShowInfoAgent } from "../structureShowInfoAgent/structureShowInfoAgent";
import moment from "moment";
import { getLocaleDatePicker } from "../../../utils/datePicker";
import { PickerLocale } from "antd/es/date-picker/generatePicker";
import { StateReservations, StatesAgent, StateUser } from "../../../constants/enums";
import { showNotification } from "../../../utils/notificationsGeneric";
import { LangContext } from "../../../hooks/context/lang";
import { RestApiClientContext } from "../../../hooks/context/pageContext";
import { AgentFilter } from "../../../models/agents/agentFilter";
import { showConfirm, showConfirmOrCancel } from "src/utils/modalsGenerics";

export const StructureListAgentsRegister = (param) => {

    const restApiClient = useContext(RestApiClientContext);
    const userLangContext = useContext(LangContext);

    const { t } = useTranslation(["contact", "seo", "urls", "agentsRegister"]);

    const [visible, setVisible] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);

    const [commentState, setComment] = useState(param.data.comments);

    const [agentInfo, setAgentInfo] = useState("");

    const [loadingS, setLoadingS] = useState(false);

    const [switchP, setSwitchP] = useState<boolean>(param.data.idStatus === StatesAgent.Active);

    const [statusAgent, setStatusAgent] = useState(param.data.idStatus);

    const [iconTagInfo, setIconTagInfo] = useState<any>(null)
    const [typeTagInfo, setTypeTagInfo] = useState<string>("")
    const [textTag, setTextTag] = useState<string>("");
    const [titleTooltip, setTitleTooltip] = useState<string>("");

    const lng = useContext(LangContext);
    const lngState = lng.GetLangContext();

    const local: PickerLocale = getLocaleDatePicker();

    const DenyAgent = () => {
        setVisibleModal(true);
    };

    const ValueComment = (e) => {
        setComment(e.target.value);
    };

    const ChangeValueStatusAgent = (idAgent, value, comment = null) => {
        const commentByAgent = comment !== null ? comment : commentState;
        setVisibleModalConfirm(false);

        restApiClient.changeStatusAgent(idAgent, value, commentByAgent)
            .then((data) => {
                if (data) {
                    setStatusAgent(value);
                    param.setChangeDataAgent([idAgent]);
                    if (value === StatesAgent.Active && value === StatesAgent.Disable) {
                        setSwitchP(value === StatesAgent.Active);
                    }
                } 
            });
    };

    const [deletingAgent, setDeletingAgent] = useState<boolean>(false);
    const removeAgent = (idAgent) : void => {
        setDeletingAgent(true);
        restApiClient.removeAgent(idAgent)
            .then((data) => { 
                param.setChangeDataAgent([idAgent]);
            })
            .finally(()=>{
                setDeletingAgent(false);
            });
    }

    const showInfoAgent = (idAgent) => {

        const filter: AgentFilter = {
            AgentId: idAgent,
            NameSurname: '',
            Email: '',
            AgentStatus: '',
            LocationAgency: '',
            Agency: '',
            AgencyId: '',
            Pagination: {
                Page: 1,
                ResultPage: 1
            },
            Lang: userLangContext.GetLangContext(),
        };

        restApiClient.getAgentsFiltered(filter)
            .then((data) => {
                if (data !== null && data.agents !== null && data.agents.length > 0) {
                    setAgentInfo(data.agents[0]);
                    setVisible(true);
                } else {
                    showNotification(
                        "error",
                        t("translation:error.title"),
                        t("translation:error.description"),
                        "topRight"
                    );
                }
            })
    };

    const onCloseDrawer = () => {
        setVisible(false);
    };

    const onChange = (e) => {
        setLoadingS(true);

        if (!e) {
            setVisibleModalConfirm(true);
        } else {
            ChangeValueStatusAgent(
                param.data.id,
                statusAgent === StatesAgent.Active ?
                    StatesAgent.Disable :
                    StatesAgent.Active,
                commentState
            );
        }
    };

    const closeModal = () => {
        setVisibleModalConfirm(false);
        setLoadingS(false);
    }

    const [agencyName, setAgencyName] = useState<JSX.Element>(<></>);
    const [agentAddress, setAgentAddress] = useState<string>('');

    useEffect(() => {

        var agentData = param.data;
        if (agentData.idAgentType == 2){
            // Agente independiente
            try{
                if (agentData.companyName && agentData.companyName.length > 0){
                    setAgencyName(<>{agentData.companyName}</>);
                }else{
                    setAgencyName(<>{t("agentsRegister:list.independent")}</>);
                }
            }catch(ex){
                console.log("EX", ex.message)
                setAgencyName(<>N/D</>)
            }

            try{
                setAgentAddress(agentData.location.formattedAddress);
            }catch(ex){
                console.log("EX", ex.message)
                setAgentAddress('N/D')
            }
        }else{
            // Agente de agencia
            try{
                setAgencyName(<>{agentData.agency.commercialName} <small className='agency-brand-name'>{agentData.agency.brandName}</small></>)
            }catch(ex){
                console.log("EX", ex.message)
                setAgentAddress('N/D')
            }

            try{
                setAgentAddress(agentData.agency.address);
            }catch(ex){
                console.log("EX", ex.message)
                setAgentAddress('N/D')
            }
        }

        switch (param.data.idStatus) {
            case StatesAgent.AccessDenied:
                setTitleTooltip(t(
                    "agentsRegister:tooltips.error", { 0: param.data.agencyName }
                ))
                setIconTagInfo(<MinusCircleOutlined />);
                setTypeTagInfo("error");
                setTextTag(t(
                    "agentsRegister:text-states.no-validate"
                ))
                break
            case StatesAgent.PendingValidation:
                setTitleTooltip(t(
                    "agentsRegister:tooltips.pending-validate", { 0: param.data.agencyName }
                ))
                setIconTagInfo(<ClockCircleOutlined />);
                setTypeTagInfo("default");
                setTextTag(t(
                    "agentsRegister:text-states.pending-validate")
                )
                break
            case StatesAgent.PendingConfirmation:
                setTitleTooltip(t("agentsRegister:tooltips.pending-confirmation"))
                setIconTagInfo(<MailOutlined />);
                setTypeTagInfo("default");
                setTextTag(t("agentsRegister:text-states.pending-confirmation"))
                break;
        }
    }, []);


    const [sendingConfirmation, setSendingConfirmation] = useState<boolean>(false);
    const resendEmailConfirmation = () : void => {
        setSendingConfirmation(true);
        restApiClient.resendEmailAgentNotification(param.data.id, param.data.idLanguage).finally(()=>{
            setSendingConfirmation(false);
        });
    }

    return (
        <>
            <div className="col-12 p-0">
                <div className="col-12 p-0 display-elements-list">
                    <div className="col-6 p-0 display-elements-list">
                        <div>
                            <FontIconsCustom
                                classIcon={"icons-list-show"}
                                icon={faUser}
                            />
                        </div>
                        <div>
                            <ParagraphCustom
                                class={"mb-0"}
                                text={<strong>{param.data.nameSurname.toUpperCase()}</strong>}
                            />
                            <ParagraphCustom
                                class={"mb-0 mail-style"}
                                text={param.data.mail}
                            />
                            <ParagraphCustom
                                text={t("agentsRegister:list.date-register") +
                                    moment(param.data.creationDate).format(
                                        local.dateFormat
                                    )}
                            />
                        </div>
                    </div>
                    <div className="col-6 p-0 options-box">
                        <div className="display-elements-list-switch element-float-right">
                            <div>
                                {param.data.idStatus === StatesAgent.Active ||
                                    param.data.idStatus === StatesAgent.Disable ? (<FontIconsCustom
                                        tooltip={
                                            param.data.idStatus ===
                                                StatesAgent.Active
                                                ? t(
                                                    "agentsRegister:tooltips.active"
                                                )
                                                : t("agentsRegister:tooltips.no-active")
                                        }
                                        classIcon="icons-color-menu icons-color-marging q-icon"
                                        icon={faQuestionCircle}
                                        placement={"bottom"}
                                    />) : (<></>)
                                }
                            </div>
                            <div className="switch-box">
                                {param.data.idStatus === StatesAgent.Active ||
                                    param.data.idStatus === StatesAgent.Disable ? (
                                    <div className="position-box-info-tooltip">
                                        <SwitchCustomPro
                                            loading={loadingS}
                                            checked={switchP}
                                            id={param.data.id}
                                            onChange={onChange}>
                                        </SwitchCustomPro>
                                    </div>
                                ) : (
                                    <SwitchCustom
                                        active={false}
                                        id={param.data.id}
                                        state={true}
                                    />
                                )
                                }
                            </div>
                            <div>
                                <PopoverCustom
                                    classBody={"position-float-right"}
                                    body={
                                        <ButtonsCustom
                                            id={
                                                "btn-validate-agent-" +
                                                param.data.id
                                            }
                                            class={"button-list-agents"}
                                            htmlType={null}
                                            disabled={false}
                                            size={null}
                                            content={
                                                <FontIconsCustom
                                                    classIcon={
                                                        "icons-list-agent-register"
                                                    }
                                                    icon={faEllipsisV}
                                                />
                                            }
                                        />
                                    }
                                    content={
                                        <>
                                            {(param.data.idStatus ===
                                                StatesAgent.PendingValidation ||
                                                param.data.idStatus ===
                                                StatesAgent.AccessDenied) && (
                                                    <div
                                                        className="link-menu-popover"
                                                        onClick={
                                                            param.data.idStatus ===
                                                                StatesAgent.PendingValidation
                                                                ? () =>
                                                                    DenyAgent()
                                                                : () =>
                                                                    ChangeValueStatusAgent(
                                                                        param.data
                                                                            .id,
                                                                        StatesAgent.Active
                                                                    )
                                                        }
                                                    >
                                                        {param.data.idStatus ===
                                                            StatesAgent.PendingValidation
                                                            ? t(
                                                                "agentsRegister:options-menu.no-active-agent"
                                                            )
                                                            : t(
                                                                "agentsRegister:options-menu.active-agent"
                                                            )}
                                                    </div>
                                                )}
                                            <div
                                                className="link-menu-popover"
                                                onClick={() =>
                                                    showInfoAgent(param.data.id)
                                                }
                                            >
                                                {t("agentsRegister:options-menu.show-info-agent")}
                                            </div>
                                            
                                            {
                                                [StatesAgent.PendingConfirmation, StatesAgent.PendingValidation].includes(param.data.idStatus) ? 
                                                    <div
                                                        className="link-menu-popover"
                                                        onClick={() =>
                                                            showConfirmOrCancel(
                                                                t("agentsRegister:remove-agent.title"),
                                                                t("agentsRegister:remove-agent.message"),
                                                                t("agentsRegister:remove-agent.confirm-button"),
                                                                t("agentsRegister:remove-agent.cancel-button"), 
                                                                ()=>{},
                                                                ()=>{removeAgent(param.data.id)},)
                                                        }
                                                    >
                                                        {t(
                                                            "agentsRegister:list.button-remove-agent"
                                                        )}
                                                    </div>
                                                    
                                                :
                                                <></>
                                            }
                                            
                                        </>
                                    }
                                    placement={"bottom"}
                                    title=""
                                    trigger="focus"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 p-0">
                    <div className="col-12 p-0 display-elements-list">
                        <div>
                            <Tooltip placement="top" title={param?.data?.idAgentType == 1 ? t("agentsRegister:searchAgentsRegister.options.aa") : t("agentsRegister:searchAgentsRegister.options.ai")}>
                                <FontIconsCustom
                                    classIcon={"icons-list-show"}
                                    icon={param?.data?.idAgentType == 1 ? faBuildingUser : faGlobe }
                                />
                                <span>
                                    {
                                        //Este Span es solo para que se muestre el tooltip
                                    }
                                </span>
                            </Tooltip>
                        </div>
                        <div>
                                <ParagraphCustom
                                    class="mb-0"
                                    text={
                                            <strong>
                                                    {agencyName}
                                                
                                            </strong>}
                                />
                            <ParagraphCustom
                                class="mb-2"
                                text={agentAddress}
                            />
                            
                        </div>
                    </div>
                </div>
                {
                    param.data.idStatus == StatesAgent.PendingConfirmation ? 
                    <ButtonsCustom
                        id={
                            "btn-resend-email-agent-" +
                            param.data.id
                        }
                        class={
                            "primary-button button-validate-agent-register"
                        }
                        type=""
                        htmlType={null}
                        onClick={resendEmailConfirmation}
                        disabled={deletingAgent}
                        size={null}
                        content={<>{sendingConfirmation || deletingAgent ? <LoadingOutlined />:''}&nbsp;{t("agentsRegister:list.button-resend-email")}</>}
                    />
                    : ''
                }

                

                {param.data.idStatus !== StatesAgent.Active && param.data.idStatus !== StatesAgent.Disable && (
                    <div className="col-12 p-0 mt-2 mb-2 display-elements-list">
                        <div style={{ width: 22 }}></div>
                        <div className="col-12 display-elements-list-buttons">
                            <div className="col-md-4 col-12 p-0">
                                <TooltipCustom
                                    title={titleTooltip}
                                    info={
                                        <div className="position-box-info-tooltip">
                                            <TagInfoCustom
                                                class={
                                                    "padding-inf-tag text-ellipse"
                                                }
                                                icon={iconTagInfo}
                                                type={typeTagInfo}
                                                text={textTag}
                                            />
                                        </div>
                                    }
                                    placement={"bottom"}
                                />
                            </div>
                            <div className="col-lg-8 col-12 p-0 box-button-list-agent">
                                {param.data.idStatus ===
                                    StatesAgent.PendingValidation && (
                                        <ButtonsCustom
                                            id={
                                                "btn-validate-agent-" +
                                                param.data.id
                                            }
                                            class={
                                                "primary-button button-validate-agent-register"
                                            }
                                            type=""
                                            htmlType={null}
                                            onClick={() =>
                                                ChangeValueStatusAgent(
                                                    param.data.id,
                                                    StatesAgent.Active
                                                )
                                            }
                                            disabled={deletingAgent}
                                            size={null}
                                            content={<>{deletingAgent ? <LoadingOutlined />:''}&nbsp;{t("agentsRegister:list.button-validate-agent")}</>}
                                        />
                                    )}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {visible && (
                <DrawerCustom
                    title={t("agentsRegister:drawer.title")}
                    visible={visible}
                    setVisible={setVisible}
                    width={360}
                    info={""}
                    placement="right"
                    closable={true}
                    onClose={onCloseDrawer}
                    content={
                        <StructureShowInfoAgent
                            id={param.data.id}
                            data={agentInfo}
                            setCommentL={setComment}
                        />
                    }
                />
            )}

            {   // Modal en la que pulsamos el botón y el estado lo cambiamos a "Denegado"
                visibleModal && (
                    <ModalCustom
                        title={""}
                        visible={visibleModal}
                        setVisible={setVisibleModal}
                        closable={false}
                        content={
                            <div className="col-12 row">
                                <div className="col-1">
                                    <ExclamationCircleOutlined className="color-exclamation" />
                                </div>
                                <div className="col-11 mb-2">
                                    <div>
                                        <strong>
                                            {t(
                                                "agentsRegister:modals.modal-agente-denegado.title"
                                            )}
                                        </strong>
                                    </div>
                                    <div className="mb-4">
                                        {t(
                                            "agentsRegister:modals.modal-agente-denegado.text"
                                        )}
                                    </div>
                                    <TextAreaCustomPro
                                        onChange={ValueComment}
                                        id={param.data.id}
                                        rows={6}
                                        className={"col-12 main-input mb-4"}
                                        placeholder={""}
                                        defaultValue={commentState}
                                    />
                                    <Row>
                                        <Col
                                            span={24}
                                            className="align-text-buttons"
                                        >
                                            <ButtonsCustom
                                                id="btn-accept"
                                                class={
                                                    "col-6 col-sm-4 primary-button"
                                                }
                                                type=""
                                                htmlType={null}
                                                onClick={() =>
                                                    ChangeValueStatusAgent(
                                                        param.data.id,
                                                        StatesAgent.AccessDenied,
                                                        commentState
                                                    )
                                                }
                                                disabled={false}
                                                size={null}
                                                content={t(
                                                    "agentsRegister:modals.modal-agente-denegado.button"
                                                )}
                                            />
                                            <ButtonsCustom
                                                id="btn-cancel"
                                                class={
                                                    "col-6 col-sm-4 default-button margin-right-button"
                                                }
                                                type=""
                                                htmlType={null}
                                                onClick={() =>
                                                    setVisibleModal(false)
                                                }
                                                disabled={false}
                                                size={null}
                                                content={t(
                                                    "agentsRegister:form-buttons.cancel"
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        }
                    />
                )}

            {   // Modal en la que cambiado el estado de un agente de activo a inactivo
                visibleModalConfirm && (
                    <ModalCustom
                        title={""}
                        visible={visibleModalConfirm}
                        setVisible={setVisibleModalConfirm}
                        closable={false}
                        content={
                            <>
                                <div className="col-12 row p-0">
                                    <div className="col-2">
                                        <ExclamationCircleOutlined className="color-exclamation" />
                                    </div>
                                    <div className="col-10 mb-2">
                                        <div>
                                            <strong>
                                                {t(
                                                    "agentsRegister:modals.modal-agente-deshabilitado.title"
                                                )}
                                            </strong>
                                        </div>
                                        <div className="mb-4">
                                            {t(
                                                "agentsRegister:modals.modal-agente-deshabilitado.text"
                                            )}
                                        </div>
                                        <TextAreaCustomPro
                                            onChange={
                                                ValueComment
                                            }
                                            rows={6}
                                            className={
                                                "col-12 main-input mb-4"
                                            }
                                            defaultValue={commentState}
                                        />
                                    </div>
                                </div>
                                <Row>
                                    <Col span={24} className="align-text-buttons">
                                        <ButtonsCustom
                                            id="btn-accept"
                                            class={"col-4 primary-button"}
                                            type=""
                                            htmlType={null}
                                            onClick={() =>
                                                ChangeValueStatusAgent(
                                                    param.data.id,
                                                    param.data
                                                        .idStatus ===
                                                        StatesAgent.Active
                                                        ? StatesAgent.Disable
                                                        : StatesAgent.Active,
                                                    commentState
                                                )}
                                            disabled={false}
                                            size={null}
                                            content={t(
                                                "agentsRegister:modals.modal-agente-deshabilitado.button"
                                            )}
                                        />
                                        <ButtonsCustom
                                            id="btn-cancel"
                                            class={
                                                "col-4 default-button margin-right-button"
                                            }
                                            type=""
                                            htmlType={null}
                                            onClick={closeModal}
                                            disabled={false}
                                            size={null}
                                            content={t(
                                                "agentsRegister:form-buttons.cancel"
                                            )}
                                        />
                                    </Col>
                                </Row>
                            </>
                        }
                    />
                )}
        </>
    );
};

