import React from "react";
import "../../../i18n";
import { Tag } from "antd";
import "./tagsInfo.sass";
import CheckableTag from "antd/lib/tag/CheckableTag";

// Componente de tags a modo informativos
export const TagInfoCustomComponent = (param) => {
	return (
    <Tag
      className={param.class}
      icon={param.icon}
      color={param.type}
    >
			{param.text}
		</Tag>
	);
};

// Componente de tags a modo informativos
export const TagCheckableCustomComponent = (props) => {
	return (
    <CheckableTag
      {...props}
    >
      {props.children}
    </CheckableTag>
	);
};
