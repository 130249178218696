import React from "react";
import "../../../i18n";
import "./listReservation.sass";
import {
    ListCustomPro,
    ListItemCustomPro,
    ListItemMetaCustomPro,
} from "../../custom";
import { CustomPagination } from "../../utils/pagination/pagination";
import { TotalElementsTableList } from "../../utils/totalElementsTableList/totalElementsTableList";
import { Pagination } from "../../../models/pagination/pagination";
import { ListItem } from "../../custom/lists/interfaceLists";
import { ListReservation } from '../interfaces/listReservation'

// Componente de listado de agentes registrados
export const ListReservationComponent = (props: ListReservation) => {

    const {
        dataStructureList,
        count,
        setPageResults,
        pageResults,
    }=props;

    // Función que se dispara en el evento de cambiar el elemento de paginación de la lista o tabla
    const onChangeCallApi = (pagination: Pagination) => {
        // Usuario pulsa página
        setPageResults(pagination);
    };

    return (
        <div className="col-12 content-list">
            <ListCustomPro
                itemLayout={"horizontal"}
                dataSource={dataStructureList}
                renderItem={(item: ListItem) => (
                    <ListItemCustomPro className={"p-0 not-line-down"}>
                        <ListItemMetaCustomPro
                            className={"box-list-reservation-style"}
                            description={item.description}
                        />
                    </ListItemCustomPro>
                )}
            ></ListCustomPro>
            <div className="col-12 p-0 pagination-container">
                <CustomPagination
                    setPageResults={setPageResults}
                    pageResults={pageResults}
                    countData={count}
                    onChangeCallApi={onChangeCallApi}
                />
            </div>
            <div className="col-12 p-0">
                <TotalElementsTableList total={count} />
            </div>
        </div>
    );
};
