import React, { useState, useContext } from "react";
import "../../../i18n";
import "../news.sass";
import { Tabs, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
	ParagraphCustom,
	ButtonsCustom,
	PopoverCustom,
	FontIconsCustom,
	TagInfoCustom,
	ModalCustom,
} from "../../custom";
import {
	CheckCircleOutlined,
	ClockCircleOutlined,
	MinusCircleOutlined,
} from "@ant-design/icons";
import {
	faEllipsisV,
	faStar,
	faEdit,
	faTrash,
	faEye,
	faCheckCircle,
	faBan,
} from "@fortawesome/free-solid-svg-icons";
import { StatusNews } from "../../../constants/enums";
import moment from "moment";
import { getLocaleDatePicker } from "../../../utils/datePicker";
import { PickerLocale } from "antd/es/date-picker/generatePicker";
import { showNotification } from "../../../utils/notificationsGeneric";
import { Link } from "react-router-dom";
import { LinkActiveContext } from "../../../hooks/context/linkActive";
import { RestApiClientContext } from "../../../hooks/context/pageContext";
import { debug } from "console";

const { TabPane } = Tabs;

export const BodyPanelListNews = (param) => {

	const history = useHistory();

	const restApiClient = useContext(RestApiClientContext);

	const { t } = useTranslation([
		"seo",
		"urls",
		"news",
		"translation",
		"modalClosedSession",
	]);

	const local: PickerLocale = getLocaleDatePicker();

	const linkActive = useContext(LinkActiveContext);

	const [statusNews, setStatusNews] = useState<StatusNews>(param.idStatus);
	const [visibleModalDeleteNews, setVisibleModalDeleteNews] = useState<boolean>(false);
	const [visibleModalUnpublishedNews, setVisibleModalUnpublishedNews] = useState<boolean>(false);
	const [loadingList, setLoadingList] = useState<boolean>(false);
	const [publishedNews, setPublishedNews] = useState<boolean>(statusNews === StatusNews.Published);
	const [featuredNews, setFeaturedNews] = useState<boolean>(param.featured);

	// Manejador para visualizar o no la modal de "eliminar noticia", publicarla o despublicarla
	const [newsId, setNewsId] = useState<string>(param.id);

	// Manejador para visualizar o no la modal de "eliminar noticia"
	const handleModalDeleteNews = (isVisible) => {
		setVisibleModalDeleteNews(isVisible);
	}
	const handleModalUnpublishedNews = (isVisible) => {
		setVisibleModalUnpublishedNews(isVisible);
	}

	const changeFeatured = () => {
		restApiClient.changeFeaturedNews(param.id, !featuredNews)
			.then((data) => {
				setFeaturedNews(!featuredNews);
				param.setChangeDataNews([param.id]);
			});
	};

	const changePublished = (progToPubl: boolean = false) => {
		setVisibleModalUnpublishedNews(false);

		const status = progToPubl ? progToPubl : !publishedNews;
		restApiClient.changeStatusNews(param.id, status)
			.then((data) => {
				setPublishedNews(!publishedNews);
				param.setChangeDataNews([param.id]);
				setStatusNews(
					publishedNews
						? StatusNews.NotPublished
						: StatusNews.Published
				);
			})
	};

	const tagIconSwitch = (val) => {
		let result = <></>;

		switch (val) {
			case StatusNews.Published:
			case StatusNews.Scheduled:
				if (moment().isBefore(param.startDate)){
					result = <ClockCircleOutlined className="d-none d-sm-block margin-icon-status" />
				}else{
					result = <CheckCircleOutlined className="d-none d-sm-block margin-icon-status" />
				}
				break;
			case StatusNews.NotPublished:
				result = <MinusCircleOutlined className="d-none d-sm-block margin-icon-status" />;
				break;
			case StatusNews.Draft:
			default:
				result = <ClockCircleOutlined className="d-none d-sm-block margin-icon-status" />;
				break;
		}

		return result;
	};

	const tagTypeSwitch = (val) : string => {
		let result : string = 'default';

		switch (val) {
			case StatusNews.Published:
			case StatusNews.Scheduled:
				if (moment().isBefore(param.startDate)){
					result = 'processing'
				}else{
					result = 'success'
				}
				break;
			case StatusNews.NotPublished:
				return "error";
			case StatusNews.Draft:
			default:
				return "default";
		}

		return result;
	};

	const tagTextSwitch = (val) : string => {
		let result : string = '';
		switch (val) {
			case StatusNews.Published:
			case  StatusNews.Scheduled:
				if (moment().isBefore(param.startDate)){
					result = t("news:status.SCH")
				}else{
					result = t("news:status.PUB")
				}
				break;
			default:
				result = t("news:status." + val);
		}

		return result;
	};

	return (
		<>
			<Tabs tabPosition="top" className="tab-flag-news">
				{param.newsContents.map((f, i) => {
					return (
						<TabPane
							tab={f.idLanguage}
							key={i}
						>
							<div className="row m-0">
								<div className="col-12 p-0 title-news">
									{param.newsContents[i].title}
								</div>
								<div className="p-0 options-news">
									<ButtonsCustom
										id={"btn-highlight-news-" + i}
										class={"btn-highlight-news"}
										htmlType={null}
										disabled={false}
										size={null}
										content={
											<FontIconsCustom
												tooltip={
													param.featured
														? t("news:highlight.text-highlighted")
														: t("news:highlight.text-not-highlighted")
												}
												placementTooltip={"topRight"}
												colorTooltip={"black"}
												classIcon={
													"star-list-news" +
													(featuredNews
														? " active"
														: "")
												}
												icon={faStar}
											/>
										}
										onClick={changeFeatured}
									/>
									<PopoverCustom
										classBody={"position-float-right"}
										body={
											<ButtonsCustom
												id={"btn-option-title" + i}
												class={"btn-option"}
												htmlType={null}
												disabled={false}
												size={null}
												content={
													<FontIconsCustom
														classIcon={
															"icon-ellipsis-opts"
														}
														icon={faEllipsisV}
													/>
												}
											/>
										}
										content={
											<div className="content-menu-option-news">
												{(moment().diff(param.startDate, "seconds") < 1 && publishedNews) &&
													<div
														className="element-option-news pl-3 pr-3 p-2"
														onClick={(() => changePublished(true))}
													>
														<FontIconsCustom
															classIcon={
																"icons-list-options"
															}
															icon={faCheckCircle}
														/>{" "}
														{t("news:action.publish")}
													</div>
												}
												<div
													className="element-option-news pl-3 pr-3 p-2"
													onClick={!publishedNews ? (() => changePublished()) : (() => handleModalUnpublishedNews(true))}
												>
													<FontIconsCustom
														classIcon={
															"icons-list-options"
														}
														icon={
															!publishedNews
																? faCheckCircle
																: faBan
														}
													/>{" "}
													{!publishedNews
														? t("news:action.publish")
														: t("news:action.unpublish")
													}
												</div>
												<div className="element-option-news pl-3 pr-3 p-2" onClick={()=>{history.push(`${t('urls:private.edit-news')}/${newsId}`)}}>
													<FontIconsCustom
														classIcon={
															"icons-list-options"
														}
														icon={faEdit}
													/>{" "}
													{t("news:action.edit")}
												</div>
												<div
													className="element-option-news pl-3 pr-3 p-2"
												>
													<Link
														id="link-detail-news"
														className={"default-links"}
														to={t("urls:private.detail-news") + "/" + `${param.id}`}
														onClick={() =>
															linkActive.updateValueUrl(
																t("urls:private.detail-news")
															)
														}
													>
														<FontIconsCustom
															classIcon={
																"icons-list-options"
															}
															icon={faEye}
														/>{" "}
														{t("news:action.see")}
													</Link>
												</div>
												<div
													className="element-option-news pl-3 pr-3 p-2"
													onClick={() =>
														handleModalDeleteNews(true)
													}
												>
													<FontIconsCustom
														classIcon={
															"icons-list-options"
														}
														icon={faTrash}
													/>{" "}
													{t("news:action.delete")}
												</div>
											</div>
										}
										placement={"bottomLeft"}
										title=""
										trigger="focus"
									/>
								</div>
								<TagInfoCustom
									class={"tag-status-news"}
									icon={tagIconSwitch(statusNews)}
									type={tagTypeSwitch(statusNews)}
									text={tagTextSwitch(statusNews)}
								/>
							</div>
						</TabPane>
					);
				})}
			</Tabs>
			{publishedNews && (
				<div className="content-date-news">
					<ParagraphCustom
						class="m-0 label-date-news"
						text={
							param.startDate && moment().isBefore(param.startDate)
								? t("news:future-publish-date")
								: t("news:publish-date")
						}
					/>
					<ParagraphCustom
						class="m-0"
						text={moment(param.startDate).format(local.dateFormat)}
					/>
				</div>
			)}
			{visibleModalDeleteNews && (
				<ModalCustom
					class="modal-bo"
					title={t("news:delete.title")}
					setVisible={setVisibleModalDeleteNews}
					closable={true}
					content={
						<>
							<ParagraphCustom
								class="text-white"
								text={t("news:delete.description")}
							/>
							<Row>
								<Col span={24} className="align-text-buttons">
									<ButtonsCustom
										id="btn-logout"
										type="primary"
										onClick={() =>
											param.deleteNews(param.id)
										}
										loading={loadingList}
										class="primary-button"
										content={t("news:delete.button.accept")}
									/>
									<ButtonsCustom
										id="btn-cancel-logout"
										class="default-button-dark margin-right-button"
										onClick={() =>
											handleModalDeleteNews(false)
										}
										content={t("news:delete.button.cancel")}
									/>
								</Col>
							</Row>
						</>
					}
				/>
			)}
			{visibleModalUnpublishedNews && (
				<ModalCustom
					class="modal-bo"
					title={t("news:unpublished.title")}
					setVisible={setVisibleModalUnpublishedNews}
					closable={true}
					content={
						<>
							<ParagraphCustom
								class="text-white"
								text={t("news:unpublished.description")}
							/>
							<Row>
								<Col span={24} className="align-text-buttons">
									<ButtonsCustom
										id="btn-logout"
										type="primary"
										onClick={() => changePublished()}
										loading={loadingList}
										class="primary-button"
										content={t("news:unpublished.button.accept")}
									/>
									<ButtonsCustom
										id="btn-cancel-logout"
										class="default-button-dark margin-right-button"
										onClick={() =>
											handleModalUnpublishedNews(false)
										}
										content={t("news:unpublished.button.cancel")}
									/>
								</Col>
							</Row>
						</>
					}
				/>
			)}
		</>
	);
};
