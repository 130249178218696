import React, { useEffect, useState } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from "@ant-design/icons";
import { arrayMoveImmutable } from 'array-move';
import { TableCustom } from 'src/components/custom';

import "./sorteable-table.sass";

export const SortableTable = ({ columns, dataSource, setDataSource, ...restProps }) => {
  const DragHandle = SortableHandle(() => restProps.handle || <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
  const SortableItem = SortableElement(props => <tr {...props} />);
  const SortableBody = SortableContainer(props => <tbody {...props} />);

  const [finalColums, setFinalColums] = useState(columns)


  useEffect(() => {
    const draggableColumn = {
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    }
    const withDragg = [...finalColums, draggableColumn]
    setFinalColums(withDragg)
  }, [])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter(
        el => !!el,
      );
      setDataSource(newData);
    }
  };

  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
    const isDisabled = dataSource.find(x => x.isDraggable === false)?.index === restProps['data-row-key']
    return  !isDisabled ? <SortableItem index={index} {...restProps} /> : <tr className="no-draggable" {...restProps}></tr>;
  };

  return (
    <TableCustom
      dataSource={dataSource}
      columns={finalColums}
      rowKey="index"
      id={restProps?.id}
      className={restProps?.className || 'sorteable-table'}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
      {...restProps}
    />
  );
}
