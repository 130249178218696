import { AvatarListMetaCustom, FontIconsCustom, ListCustomPro, ListItemCustomPro, ListItemMetaCustomPro, ParagraphCustom, TextCustom } from '../../../../components/custom';
import { ListItem } from '../../../../components/custom/lists/interfaceLists';
import { ConfigData, Periods } from '../../../../components/rewardsPerNight/interfaces/configSelect';
import React, { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import './listInfo.sass';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FormattedNumber } from 'react-intl';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { EditSeasons } from '../../../../components/rewardsPerNight/editSeasons/editSeasons';
import { DataListTableConfig } from '../../../../components/rewardsPerNight/interfaces/seasons';

export const ListRewardsPerNight = (props: DataListTableConfig) => {

    const {
        dataConfiguration,
        updateViewAddOrEditSeason
    } = props;

    const { t } = useTranslation(["rewardsPerNight", "translation"]);

    const [periodSelected, setPeriodSelected] = useState<Periods>();
    const [showDrawerEditPeriod, setShowDrawerEditPeriod] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<ListItem[]>();

    const listParseComponent: ListItem[] = [];

    const openEditPeriod= (data: Periods) => {
        setPeriodSelected(data);
        setShowDrawerEditPeriod(true);
    }

    useEffect(() => {

        dataConfiguration.periods.map((period) => {

            const listItemsP = {
                avatar: "",
                title: "",
                description: (
                    <div className="col-12 p-0 box-info-list">
                        <div className="col-1 p-0"></div>
                        <div className="col-10 p-0">
                            <div>
                                <ParagraphCustom
                                    class={"mb-0 ml-1"}
                                    text={t("rewardsPerNight:titles.name")}
                                />
                                <strong>{period.name}</strong>
                            </div>
                            <div>
                                <ParagraphCustom
                                    class={"mb-0 ml-1"}
                                    text={t("rewardsPerNight:titles.dates")}
                                />
                                <strong>
                                    {
                                        moment(period.startDate).format('D') + " " + t("rewardsPerNight:graphic.month-list-large." + (moment(period.startDate).month() + 1))
                                        + " - " +
                                        moment(period.endDate).format('D') + " " + t("rewardsPerNight:graphic.month-list-large." + (moment(period.endDate).month() + 1))
                                    }
                                </strong>
                            </div>
                            <div>
                                <ParagraphCustom
                                    class={"mb-0 ml-1"}
                                    text={t("rewardsPerNight:titles.reward-per-night")}
                                />
                                <strong>
                                    {
                                        period.amounts.map((amount, index) => {
                                            return (
                                                <>
                                                    {index !== 0 && (" - ")}
                                                    <TextCustom
                                                        class="data-info-list"
                                                        text={
                                                            <FormattedNumber
                                                                value={amount.value}
                                                                style="currency"
                                                                currency={amount.currencyId}
                                                            />
                                                        }
                                                    />
                                                </>
                                            )
                                        })
                                    }
                                </strong>
                            </div>
                        </div>
                        <div className="col-1 p-0" >
                            <FontIconsCustom
                                className="icons-edit"
                                icon={faEdit}
                                onClick={() => openEditPeriod(period)}
                            />
                        </div>
                    </div>
                ),
            };
            listParseComponent.push(listItemsP);
        });

        setData(listParseComponent);
        setLoading(false);
    }, []);

    return (
        <>
            {loading ? (
                <Skeleton paragraph={{ rows: 5 }} active />
                ) : (
                    <ListCustomPro
                        itemLayout={"horizontal"}
                        dataSource={data}
                        renderItem={(item: ListItem) => (
                            <ListItemCustomPro
                                className={"box-list-config-style-padding not-line-down"}
                            >
                                <ListItemMetaCustomPro
                                    avatar={
                                        item.avatar !== "" && (
                                            <AvatarListMetaCustom
                                                className={"avatar-list-agents"}
                                                src={item.avatar}
                                            />
                                        )
                                    }
                                    title={item.title}
                                    description={item.description}
                                />
                            </ListItemCustomPro>
                        )}
                    ></ListCustomPro>
                )
            }
            {showDrawerEditPeriod && (
                <EditSeasons
                    configurationSelect={dataConfiguration.id}
                    periodSelected={periodSelected}
                    setShowDrawerSeasons={setShowDrawerEditPeriod}
                    updateViewAddOrEditSeason= {updateViewAddOrEditSeason}
                />
            )}
        </>
    );
}
