import * as React from "react";
import { Form } from "antd";

export const FormCustomComponent = (props) => {

    const onFinish = values => {
        props.onFinish(values);
    };

    const onFinishFailed = values => {
        if (props.onFinishFailed)
            props.onFinishFailed(values);
    };

    return (
        <Form
            {...props}
            form={ props.form }
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            {props.children}
        </Form>
    )
}

export const FormItemsContactCustomComponent = (props) => {
    return (
        <Form.Item
            {...props}
        >
            {props.children}
        </Form.Item>
    )
}
