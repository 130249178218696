import EndPointConfig from '../../models/api/endPointConfig';

const controller : string = 'Bookings';

/// Define all EndPoints for Reset Password contact.
export const RegularizationEP : EndPointConfig[] = [
    // Obtiene el listado de los estados de las reservas
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('statuses')
        .setAlias('GetBookingsStatus'),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('filtered')
        .setAlias('GetBookingsFiltered'),
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller)
        .setAlias('ChangeStatusBooking')
        .setPattern('{id}/status'),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetBooking')
        .setPattern('{id}'),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetBookingStatusEmails')
        .setPattern('{id}/statusesemails'),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('duplicates')
        .setAlias('CheckForDuplicates'),
        
    

];