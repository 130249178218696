import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  DrawerCustom,
  MenusCustomPro,
  FontIconsCustom,
  MenusItemsCustomPro,
} from "../../../../custom/index";
import { ConfigContext } from "../../../../../hooks/context/config";
import "../header.sass";
import "./mobileMenu.sass";
import { LINKS_MOBILE_PRIVATE } from "../../../../../constants/constants";
import {
  faHome,
  faUsersCog,
  faCog,
  faNewspaper,
  faCoins,
  faQuestionCircle,
  faBars,
  faCalendarAlt,
  faChartLine,
  faPercent,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuPop } from "../../menuPopProfile/menuPop";
import { LinkActiveContext } from "../../../../../hooks/context/linkActive";
import { cleanUp } from "../../../../../utils/cleanUp";
import { MenuLanguages } from "../../../../languageSelector/comboLanguage";
import { Notifications } from "./notifications/notifications";
import { ClientNumber } from "../../layout/clientNumber/clientNumber";

// Menú vislble para versiones tablet o móvil (de MD para abajo)
export const MobileMenu = (params) => {
  // Libreria i18n para traducir
  const { t } = useTranslation(["urls", "linksNames"]);

  // Estado para mostrar o ocultar modal cookie
  const [modalLogin, setmodalLogin] = useState(false);

  // Control para desplegar o ocultar menu lateral
  const [visible, setVisible] = useState(false);

  // Contexto con la configuracion
  const theme = useContext(ConfigContext);

  // Se muestra el menu lateral
  const showDrawer = () => {
    setVisible(true);
  };

  // Se oculta el menu lateral
  const onClose = (e) => {
    setVisible(false);
    linkActive.updateValueUrl(t("urls:public." + e));
  };

  // Estados para el manejador de la funcionalidad del menu cabecera que se ocultara al hacer scroll hacia la parte inferior, pero que en el momento de subir se vuelve a mostrar
  const [top, setTop] = useState("no-fixed");
  const [space, setSpace] = useState("div-aux-no-space");
  const [ascend, setascend] = useState(0);
  const linkActive = useContext(LinkActiveContext);
  let url = window.location.pathname + window.location.hash;
  const icons = [
    faHome,
    faUsersCog,
    faCog,
    faChartLine,
    faNewspaper,
    faCoins,
    faCalendarAlt,
    faPercent,
    faLayerGroup,
    faCog,
    faQuestionCircle,
  ];


  if (url.substring(url.length, url.length - 1) === "/") {
    url = url.substring(0, url.length - 1);
  }

  // Ponemos a la escucha el scroll
  useEffect(() => {
    cleanUp();
    window.addEventListener("scroll", scrollTop);
    return () => window.removeEventListener("resize", scrollTop);
  });

  // Comprobamos si asciende o desciende
  const scrollTop = () => {
    if (window.pageYOffset > ascend) {
      setTop("no-fixed");
      setSpace("div-aux-no-space");
      setascend(window.pageYOffset);
    } else {
      setTop("fixed");
      setSpace("div-aux-space");
      setascend(window.pageYOffset);
    }
  };

  return (
    <>
      <div className={top + " col-12 box-header-mobile"}>
        <div className="col-2 col-sm-4 col-md-8 menuCon position-icon padding-left-header width-items">
          <div className="vertical-center">
            <FontAwesomeIcon
              onClick={showDrawer}
              className="icons-color"
              icon={faBars}
            />
          </div>

          <DrawerCustom
            placement="left"
            closable={false}
            class="lateral-menu-drawer"
            visible={visible}
            setVisible={setVisible}
            info={""}
            onClose={onClose}
            content={
              <>
                <div className="d-md-none content-logo-menu">
                  <img
                    className="logo"
                    src={theme.logo}
                    title={theme.brandName}
                    alt={theme.brandName}
                  />
                </div>
                <MenusCustomPro
                  mode="inline"
                  defaultSelectedKeys={[url]}
                  onClick={onClose}
                >
                  {LINKS_MOBILE_PRIVATE.map((element, index) => {
                    const showDest: string = t(
                      "urls:" + "private" + "." + element
                    );
                    return (
                      <MenusItemsCustomPro
                        key={showDest}
                        className={
                          "padding-elements-link" +
                          (window.location.pathname + window.location.hash ===
                          t("urls:" + "private" + "." + element)
                            ? " disabled-li"
                            : "")
                        }
                      >
                        <Link
                          id={"link-" + "private" + "-mobile-" + element + "-" + index}
                          key={index}
                          to={t("urls:" + "private" + "." + element)}
                          className={
                            window.location.pathname + window.location.hash ===
                            t("urls:" + "private" + "." + element)
                              ? "secondary-links active"
                              : "secondary-links"
                          }
                          onClick={() => onClose(element)}
                          disabled={
                            window.location.pathname + window.location.hash ===
                            t("urls:" + "private" + "." + element)
                          }
                        >
                          <div className="box-link-menu">
                            <div className="padding-link-desktop width-menu-mobile">
                              <FontIconsCustom
                                classIcon={
                                  "icons-color-menu icons-color-marging"
                                }
                                icon={icons[index]}
                              />
                              {t("linksNames:" + element)}
                            </div>
                          </div>
                        </Link>
                      </MenusItemsCustomPro>
                    );
                  })}
                </MenusCustomPro>
                <ClientNumber class="content-client-number-small" />
              </>
            }
          />
          <div className="d-none d-md-block p-0 logo width-items">
            <div className="vertical-center-logo">
              <Link
                id="main-private-logo-mobile"
                className="logo-mobile"
                to={t("urls:private.start")}
                onClick={() =>
                  linkActive.updateValueUrl(t("urls:private.start"))
                }
                disabled={
                  window.location.pathname + window.location.hash ===
                  t("urls:private.start")
                }
              >
                <img
                  className="logo"
                  src={theme.logo}
                  title={theme.brandName}
                  alt={theme.brandName}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-10 col-sm-8 col-md-4 sesion-init position-login-button padding-right-header width-items">
          <div className="col-12 p-0 links-row">
            <div className="align-center-links float-link">
              <div className="content-notifications">
                <Notifications />
              </div>
              <div className="padding-link-desktop secondary-links-svg align-center-links user-header">
                <MenuPop type="mobile" />
              </div>
              <div className="padding-top-flag">
                <MenuLanguages key="1" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={space}></div>
    </>
  );
};
