import * as React from "react";
import { useContext } from "react";
import "./footer.sass";
import { ConfigContext } from "../../hooks/context/config";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LinkActiveContext } from "../../hooks/context/linkActive";
import { TextCustom } from "../../components/custom";

// Componente del pie de la aplicación
export const Footer = (param) => {
	const theme = useContext(ConfigContext);
	const { t } = useTranslation(["urls", "linksNames"]);
	const linkActive = useContext(LinkActiveContext);

	return (
		<footer className={"col-12 footer-container " + param.class}>
			<div className="col-12 footer-row">
				<div className="col-lg-1"></div>
				<div className="col-12 col-lg-3 first-half-footer">
					<TextCustom text={theme.incentiveSiteName} />
				</div>
				<div className="col-12 col-lg-7 second-half-footer">
					<Link
						id="link-footer-term-conditions"
						className={
							"default-links" +
							(window.location.pathname + window.location.hash ===
							t("urls:" + param.zone + ".term-conditions")
								? " active"
								: "")
						}
						to={t("urls:" + param.zone + ".term-conditions")}
						onClick={() =>
							linkActive.updateValueUrl(
								t("urls:" + param.zone + ".term-conditions")
							)
						}
						disabled={
							window.location.pathname + window.location.hash ===
							t("urls:" + param.zone + ".term-conditions")
						}
					>
						{t("linksNames:term-conditions")}
					</Link>
					<span> | </span>
					<Link
						id="link-footer-policy-policies"
						className={
							"default-links" +
							(window.location.pathname + window.location.hash ===
							t("urls:" + param.zone + ".privacy-policies")
								? " active"
								: "")
						}
						to={t("urls:" + param.zone + ".privacy-policies")}
						onClick={() =>
							linkActive.updateValueUrl(
								t("urls:" + param.zone + ".privacy-policies")
							)
						}
						disabled={
							window.location.pathname + window.location.hash ===
							t("urls:" + param.zone + ".privacy-policies")
						}
					>
						{t("linksNames:privacy-policies")}
					</Link>
					<span> | </span>
					<Link
						id="link-footer-legal-advice"
						className={
							"default-links" +
							(window.location.pathname + window.location.hash ===
							t("urls:" + param.zone + ".legal-advice")
								? " active"
								: "")
						}
						to={t("urls:" + param.zone + ".legal-advice")}
						onClick={() =>
							linkActive.updateValueUrl(
								t("urls:" + param.zone + ".legal-advice")
							)
						}
						disabled={
							window.location.pathname + window.location.hash ===
							t("urls:" + param.zone + ".legal-advice")
						}
					>
						{t("linksNames:legal-advice")}
					</Link>
					<span> | </span>
					<Link
						id="link-footer-cookies-policy"
						className={
							"default-links" +
							(window.location.pathname + window.location.hash ===
							t("urls:" + param.zone + ".cookies-policy")
								? " active"
								: "")
						}
						to={t("urls:" + param.zone + ".cookies-policy")}
						onClick={() =>
							linkActive.updateValueUrl(
								t("urls:" + param.zone + ".cookies-policy")
							)
						}
						disabled={
							window.location.pathname + window.location.hash ===
							t("urls:" + param.zone + ".cookies-policy")
						}
					>
						{t("linksNames:cookies-policy")}
					</Link>
				</div>
				<div className="col-lg-1"></div>
			</div>
		</footer>
	);
};

