import React, { useState, useContext, useEffect } from "react";
import * as AjaxAPI from "../../../api/ajaxApi";
import "./term-conditions.sass";
import { Helmet } from "react-helmet-async";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../hooks/context/config";
import ReactHtmlParser from "react-html-parser";
import scrollLib from "react-scroll";
import { URL_BASE } from "../../../constants/constants";
import { showNotification } from "../../../utils/notificationsGeneric";
import { ClientDataModel } from "../../../models/config/clientData";
import { TitleCustom } from "../../custom";
import { useLegalTexts } from "../legalTexts";

const scroll = scrollLib.animateScroll;

// Componente de información de Términos y Condiciones de la app
export const TermConditions = () => {
	const infoClient = useContext(ConfigContext); // TODO: Actualizar cuando obtengamos los datos de infoClient confirmados
	const { t } = useTranslation(["termConditions", "seo", "urls"]);

	const clientData = useLegalTexts(t)

	useEffect(() => {

		scroll.scrollToTop();

	}, []);


	return (
		<>
			<Helmet>
				<title>
					{infoClient.incentiveSiteName} |{" "}
					{t("seo:title.term-conditions")}
				</title>
				<meta
					name="description"
					content={t("seo:description.term-conditions", {
						0: infoClient.incentiveSiteName,
					})}
				/>
				<meta name="robots" content="noindex,nofollow" />
				<link
					rel="canonical"
					href={URL_BASE + t("urls:public.term-conditions")}
				/>
				<link
					rel="icon"
					type="image/ico"
					href={infoClient.favicon}
					sizes="32x32"
				/>
				<link
					href={URL_BASE + t("urls:public.term-conditions")}
					hrefLang="es-ES"
					rel="alternate"
				/>
			</Helmet>

			<div className="container mt-3 special-mb">
				<TitleCustom
					level={4}
					class="main-title-color"
					text={t("termConditions:main-title")}
				/>
				{ReactHtmlParser(t("termConditions:section.title.1"))}
				{ReactHtmlParser(
					t("termConditions:section.paragraph.1", {
						0: clientData.incentiveSiteName,
						1: clientData.socialName,
						2: clientData.socialAddress,
						3: clientData.identityCode,
					})
				)}
				{ReactHtmlParser(t("termConditions:section.paragraph.2"))}

				{ReactHtmlParser(t("termConditions:section.title.2"))}
				{ReactHtmlParser(
					t("termConditions:section.paragraph.3", {
						0: clientData.incentiveSiteName,
					})
				)}
				{ReactHtmlParser(
					t("termConditions:section.paragraph.4", {
						0: clientData.emailContact,
					})
				)}
				{ReactHtmlParser(
					t("termConditions:section.paragraph.5", {
						0: clientData.socialName,
					})
				)}

				{ReactHtmlParser(t("termConditions:section.title.3"))}
				{ReactHtmlParser(
					t("termConditions:section.paragraph.6", {
						0: clientData.socialName,
					})
				)}

				{ReactHtmlParser(t("termConditions:section.title.4"))}
				{ReactHtmlParser(
					t("termConditions:section.paragraph.7", {
						0: clientData.socialName,
					})
				)}
				{ReactHtmlParser(t("termConditions:section.paragraph.8"))}

				{ReactHtmlParser(t("termConditions:section.title.5"))}
				{ReactHtmlParser(t("termConditions:section.paragraph.9"))}
				{ReactHtmlParser(t("termConditions:section.paragraph.10"))}
				{ReactHtmlParser(
					t("termConditions:section.paragraph.11", {
						0: clientData.incentiveSiteName,
					})
				)}

				{ReactHtmlParser(t("termConditions:section.title.6"))}
				{ReactHtmlParser(
					t("termConditions:section.paragraph.12", {
						0: clientData.incentiveSiteName,
						1: clientData.socialName,
					})
				)}
				{ReactHtmlParser(
					t("termConditions:section.paragraph.13", {
						0: clientData.socialName,
					})
				)}
				{ReactHtmlParser(
					t("termConditions:section.paragraph.14", {
						0: clientData.socialName,
					})
				)}
				{ReactHtmlParser(
					t("termConditions:section.paragraph.15", {
						0: clientData.socialName,
					})
				)}
				{ReactHtmlParser(t("termConditions:section.paragraph.16"))}
				{ReactHtmlParser(
					t("termConditions:section.paragraph.17", {
						0: clientData.socialName,
						1: clientData.emailContact,
						2: infoClient.incentiveSiteName,
						3: clientData.socialAddress,
					})
				)}
				{ReactHtmlParser(
					t("termConditions:section.paragraph.18", {
						0: infoClient.incentiveSiteName,
						1: clientData.emailContact,
					})
				)}
				{ReactHtmlParser(t("termConditions:section.paragraph.19"))}
			</div>
		</>
	);
};
