import React, { useContext, useEffect, useState } from "react";
import "./establishments.sass";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import {
  ButtonsCustom,
  DrawerCustom,
  FontIconsCustom,
  ParagraphCustom,
  TableCustom,
  TitleCustom
} from "src/components/custom";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import { faPlus, faStar, faKey } from "@fortawesome/free-solid-svg-icons";
import { SearchEstablishments } from "./searchEstablishments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotResults } from "src/components/noResultGeneric/notResults";
import { RestApiClientContext } from "src/hooks/context/pageContext";
import { EditEstablishment } from "./editEstablishment/editEstablishment";
import { ActionsCell } from "./ActionsCell/ActionsCell";
import { LangContext } from "src/hooks/context/lang";
import { AddEstablishment } from "./addEstablishment/addEstablishment";
import Search from "antd/lib/input/Search";

interface EstablishmentsItem {
  id: string;
  identityCode: number;
  actions: JSX.Element;
  category: JSX.Element;
  categoryType: string;
  name: string;
  roomsNumber: string;
}

interface DataEstablishment {
  identityCode: string;
  category: number;
  idCategoryType: number;
  name: string;
  roomsNumber: number;
  isActive: boolean;
  id?: string;
  regimens: any[];
  roomTypes: any[];
}

const defaultData: DataEstablishment = {
  identityCode: "",
  name: "",
  category: 1,
  idCategoryType: 1,
  isActive: true,
  roomsNumber: 0,
  regimens: [],
  roomTypes: []
};

export const Establishments = () => {
  const { t } = useTranslation("config");

  const [filterNames, setFilterNames] = useState<any[]>([]);
  const columns = [
    {
      title: t("establishments.table.name"),
      dataIndex: "name",
      key: "name",
      filters:filterNames,
      onFilter: (value: string, record) => record.name?.indexOf(value) === 0,
    },
    {
      title: t("establishments.table.identityCode"),
      dataIndex: "identityCode",
      key: "identityCode"
    },
    {
      title: t("establishments.table.isActive"),
      dataIndex: "isActive",
      key: "isActive",
      width: 140
    },
    {
      title: t("establishments.table.category"),
      dataIndex: "category",
      key: "category",
      width: 80
    },
    {
      title: t("establishments.table.roomsNumber"),
      dataIndex: "roomsNumber",
      key: "roomsNumber",
      width: 80
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 80
    }
  ];

  const restApiClient = useContext(RestApiClientContext);
  const scrollLib = require("react-scroll");
  const scroll = scrollLib.animateScroll;
  const [loadingSkeleton, setLoadingSkeleton] = useState<boolean>(true);
  const [loadingHotels, setLoadingHotels] = useState<boolean>(true);
  const [disabledSearch, setDisabledSearch] = useState<boolean>(false);
  const [messageApi, setMessageApi] = useState<string>("");
  const [searching, setSearching] = useState<boolean>(false);
  const [dataHotels, setDataHotels] = useState<EstablishmentsItem[]>([]);
  const [searchTermNameInput, setSearchTermNameInput] = useState<string>("");
  const [visibleEditDrawer, setVisibleEditDrawer] = useState(false);
  const [visibleAddDrawer, setVisibleAddDrawer] = useState(false);
  const [selectedHotelId, setSelectedHotelId] = useState(null);
  const language = useContext(LangContext);

  const [selectedHotelData, setSelectedHotelData] = useState<any>();
  const [canBeDeleted, setCanBeDeleted] = useState<boolean>(false);

  const handleAddHotels = () => {
    setSelectedHotelId(null);
    setVisibleAddDrawer(true);
  };

  const handleRender = (filter = "") => {
    setDisabledSearch(true);
    setMessageApi("");
    try {
      setLoadingHotels(true);

      const params : any = {
        pagination:{
          page: 1,
          resultPage: 1000
        },
        filter: filter ?? ""
      }

      restApiClient
        .getEstablishmentsFiltered(params)
        .then((data) => {
          if (data && data.establishments && data.establishments.length > 0) {

            const theFilterNames = data.establishments.map((e) => {
              return {
                value: e.name,
                text:e.name
              };
            });
  
            setFilterNames(theFilterNames);

            setDataHotels(
              data.establishments.map((e) => {
                return {
                  key: e.id,
                  id: e.id,
                  name: e.name,
                  identityCode: e.identityCode,
                  isActive: e.isActive ? t("establishments.table.active") : t("establishments.table.inActive"),
                  roomsNumber: e.roomsNumber,
                  category: (
                    <div className="category">
                      <span>{e.category}</span>
                      {e.idCategoryType === 2 ? <FontIconsCustom icon={faKey} /> : <FontIconsCustom icon={faStar} />}
                    </div>
                  ),
                  actions: (
                    <ActionsCell
                      reload={() => handleRender(filter)}
                      setHotelId={setSelectedHotelId}
                      setVisibleDrawer={setVisibleEditDrawer}
                      cell={e}
                    />
                  )
                };
              })
            );
          } else {
            setDataHotels([]);
            setMessageApi("not-result");
          }
          setDisabledSearch(false);
          setLoadingSkeleton(false);
          setLoadingHotels(false);
        })
        .catch((reason: any) => {
          throw new Error(reason);
        })
        .finally(()=>{
          setLoadingHotels(false);
        });
    } finally {
      setLoadingSkeleton(false);
    }
  };

  const [loadingHotelData, setLoadingHotelData] = useState<boolean>(false);
  const getSelectedHotel = () : void => {
      setVisibleEditDrawer(true);

      setLoadingHotelData(true)
      restApiClient.getEstablishmentById(selectedHotelId).then((data) => {
        setSelectedHotelData(data);
        setCanBeDeleted(data.canBeDeleted);
      }).finally(()=>{
        setLoadingHotelData(false);
      });
  }


  const [deletingEstablishment, setDeletingEstablishment] = useState<boolean>(false);
  const handleDeleteEstablishment = () : void => {
    setDeletingEstablishment(true);
    restApiClient.deleteEstablishment(selectedHotelId).finally(()=>{
      setVisibleEditDrawer(false);
      handleRender();
    }).finally(()=>{
      setDeletingEstablishment(false);
    });
  }

  const [rooms, setRooms] = useState<any[]>([]);
  const getRoomsData = () : void => {
    restApiClient
        .getRoomTypes()
        .then((data: any) => {
          setRooms((prevData) => {
            return {
              ...prevData,
              roomTypes: [
                ...data.map((room) => ({
                  ...room,
                  name: room?.roomTypesTranslations?.find((rt) => rt.idLanguage === language?.lang)?.name
                }))
              ]
            };
          });
        })
        .catch((reason: any) => {
          throw new Error(reason);
        });
  }

  const [regimens, setRegimens] = useState<any[]>([]);
  const getRegimensData = () : void => {
    restApiClient
        .getRegimens()
        .then((data: any) => {
          setRegimens((prevData) => {
            return {
              ...prevData,
              regimens: [
                ...data.map((room) => ({
                  ...room,
                  name: room?.regimenTranslations?.find((rt) => rt.idLanguage === language?.lang)?.name
                }))
              ]
            };
          });
        })
        .catch((reason: any) => {
          throw new Error(reason);
        });
  }

  useEffect(()=>{
    if(selectedHotelId){
      getSelectedHotel();
    }
  },[selectedHotelId]);

  useEffect(() => {
    scroll.scrollToTop();
    getRoomsData();
    getRegimensData();
  }, []);

  useEffect(() => {
    const filter = searchTermNameInput || "";
    handleRender(filter);
  }, [searchTermNameInput]);

  return (
    <>
    <Skeleton active loading={loadingSkeleton}>
      <div id="establishments" className="special-mb config-tab">
          <div className="row col-12">
            <TitleCustom level={4} class="title-section" text={t("establishments.main-title")} />
          </div>
          <div className="row">
            <div className="col-12">
              <ParagraphCustom class={"p-0"} text={t("establishments.main-description-filter")} />
            </div>
          </div>
          <div className="row my-1">
            <div className="col col-md-8 my-1">
              <Search
                disabled={disabledSearch}
                placeholder={t("rooms.search-channel.placeholder")}
                onSearch={(e) => setSearchTermNameInput(e)}
                allowClear
              />
            </div>
            <div className="col align-self-end buttons my-1">
              <ButtonsCustom
                class={"primary-button"}
                htmlType={null}
                onClick={handleAddHotels}
                size={null}
                content={
                  <span>
                    <FontAwesomeIcon icon={faPlus} />
                    <strong>{t("establishments.add-establishment")}</strong>
                  </span>
                }
              />
            </div>
          </div>
          <Skeleton active loading={loadingHotels}>
              {
                dataHotels?.length < 1 || messageApi !== "" ? (
                  <div className="row">
                    <NotResults section="establishments" dataSearch={searching} messageApi={messageApi} />
                  </div>
                ) : (
                <div className="row">
                  <TableCustom
                    className="table-establishments col-12 my-4"
                    columns={columns}
                    dataSource={dataHotels}
                    bordered
                  />
                </div>
              )}
          </Skeleton>
          
      </div>
    </Skeleton>
      
    <DrawerCustom
          title={
            <header className="d-flex justify-content-between align-items-center">
              <span className="d-block">{t(`establishments.add-${selectedHotelId ? "edit" : "new"}`)}</span>
            </header>
          }
          visible={visibleAddDrawer}
          setVisible={setVisibleAddDrawer}
          width={520}
          info={""}
          placement="right"
          closable={true}
          onClose={() =>{
            setVisibleAddDrawer(false);
          }}
          content={
              <AddEstablishment
                data={selectedHotelData}
                reload={() => handleRender("")}
                id={selectedHotelId}
                setVisible={setVisibleAddDrawer}
                roomList={rooms}
                regimenList={regimens}
              />
          }
        />
    <DrawerCustom
          title={
            <header className="d-flex justify-content-between align-items-center">
              <span className="d-block">{t(`establishments.add-${selectedHotelId ? "edit" : "new"}`)}</span>
              {
                <ButtonsCustom
                  onClick={handleDeleteEstablishment}
                  content={<span>{deletingEstablishment ? <LoadingOutlined /> : ''} {t("rooms.drawer-delete")}</span>}
                  danger={true}
                  disabled={!canBeDeleted || deletingEstablishment}
                />
              }
            </header>
          }
          visible={visibleEditDrawer}
          setVisible={setVisibleEditDrawer}
          width={520}
          info={""}
          placement="right"
          closable={true}
          onClose={() =>{
            setSelectedHotelData(undefined);
            setVisibleEditDrawer(false);
          }}
          content={
              <EditEstablishment
                isLoadingData={loadingHotelData}
                data={selectedHotelData}
                reload={() => handleRender("")}
                id={selectedHotelId}
                setVisible={setVisibleEditDrawer}
              />
          }
        />
    </>
  );
};
