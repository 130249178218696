import React from "react";
import { Tooltip } from "antd";

export const TooltipCustomComponent = (param) => {
    return (
        <Tooltip
            placement={param.placement}
            title={param.title}
            color={param.bgcolor}
        >
            {param.info}
        </Tooltip>
    );
}

export const TooltipProCustomComponent = (props) => {
    return (
        <Tooltip
        {...props}
        >
            {props.children}
        </Tooltip>
    );
}