import React, { useState, useEffect, useContext } from "react";
import "./wallet.sass";
import { FormattedNumber } from "react-intl";
import { useTranslation } from "react-i18next";
import {
    faQuestionCircle,
    faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import {
    DrawerCustom,
    DropdownCustom,
    FontIconsCustom,
    ParagraphCustom,
} from "../custom/index";
import { LangContext } from "../../hooks/context/lang";
import { DetailBank } from "./detailBank";
import { RestApiClientContext } from "../../hooks/context/pageContext";

// Componente Base de la parte publica de nuestra aplicación
export const Wallet = () => {
    // Libreria i18n para traducir
    const { t } = useTranslation([
        "urls",
        "linksNames",
        "translation",
        "wallet",
    ]);

    const restApiClient = useContext(RestApiClientContext);

    const [listExchange, setListExchange] = useState([]);
    const [visible, setVisible] = useState(false);
    const [visibleChildren, setVisibleChildren] = useState(false);
    const [dataBank, setDataBank] = useState([]);

    // Contexto con los datos del usuario si esta logado
    const language = useContext(LangContext);
    const userLang = language.GetLangContext();

    useEffect(() => {
        restApiClient.getAvailableBalances(userLang)
            .then((data) => {

                if (data) {
                    setListExchange(data);
                }
            })
    }, []);

    // Muestra el panel Drawer con la lista de divisas
    const showDrawer = () => {
        setVisible(true);
    };

    const showChildrenDrawer = (idCurrency) => {
        restApiClient.getBankDetailsByCurrency(idCurrency)
            .then((data) => {
                if (data) {
                    setDataBank(data);
                }

                setVisibleChildren(true);
            })
    };

    const onClose = () => {
        setVisible(false);
    };

    const onCloseChildrenDrawer = () => {
        setVisibleChildren(false);
    };

    // Elemento dentro del Dropdown
    const exchange = (
        <>
            <div className="dropdown-exchange" onClick={showDrawer}>
                {t("translation:view-more-exchanges")}
            </div>
            <DrawerCustom
                title={t("wallet:available-amounts")}
                class="list-wallet"
                visible={visible}
                closable={false}
                setVisible={setVisible}
                info={""}
                width={320}
                onClose={onClose}
                content={listExchange.map((e, i) => {
                    return (
                        <div
                            key={i}
                            className="exchange-pair mb-3"
                            onClick={() => {
                                showChildrenDrawer(e.idCurrency);
                            }}
                        >
                            <ParagraphCustom
                                class="exchange-name"
                                text={e.nameCurrency}
                            />
                            <ParagraphCustom
                                class={
                                    "exchange-amount" +
                                    (e.minimumAllowed ? " red-color" : "")
                                }
                                text={
                                    <FormattedNumber
                                        value={e.amount}
                                        style="currency"
                                        currency={e.idCurrency}
                                    />
                                }
                            />
                        </div>
                    );
                })}
            />
            <DrawerCustom
                visible={visibleChildren}
                title={t("wallet:reload-amount")}
                placement="right"
                closable={false}
                width={350}
                onClose={onCloseChildrenDrawer}
                content={<DetailBank data={dataBank} />}
            />
        </>
    );

    return (
        <div className="row content-wallet">
            <div className="col-12 col-lg-11">
                {t("translation:available-amount")}
                <FontIconsCustom
                    tooltip={t("wallet:tooltip-info")}
                    classIcon="icons-color-menu icons-color-marging q-icon"
                    icon={faQuestionCircle}
                />
            </div>
            <div className="d-none d-lg-block col-lg-1 p-0"></div>
            <DropdownCustom overlay={exchange} className="row wallet-content">
                <div className="col-12 values-wallet">
                    <div className="content-currency">
                        {listExchange.slice(0, 3).map((e, i) => {
                            return (
                                <span key={e.id}>
                                    <span
                                        className={
                                            e.minimumAllowed
                                                ? "text-min-allowed"
                                                : "text-no-min-allowed"
                                        }
                                    >
                                        <FormattedNumber
                                            value={e.amount}
                                            style="currency"
                                            currency={e.idCurrency}
                                        />
                                    </span>
                                    {i < 2 ? " | " : " "}
                                </span>
                            );
                        })}

                        <FontIconsCustom
                            classIcon="icons-color-menu margin-icon-wallet chevron-language secondary-links-svg"
                            icon={faChevronDown}
                        />
                    </div>
                </div>
            </DropdownCustom>
        </div>
    );
};
