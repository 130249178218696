import EndPointConfig from "../../models/api/endPointConfig";

const controller = "Positions";

/// Define all EndPoints for Position section.
export const PositionsEP: EndPointConfig[] = [
  // Envio de formulario a la API
  new EndPointConfig().setMethod("GET").setController(controller).setAlias("GetPositions"),

  new EndPointConfig().setMethod("GET").setController(controller).setPattern("{id}").setAlias("GetPositionById"),

  new EndPointConfig().setMethod("POST").setController(controller).setAlias("CreatePositions"),

  new EndPointConfig().setMethod("PUT").setController(controller).setAlias("UpdatePositions"),

  new EndPointConfig().setMethod("DELETE").setController(controller).setPattern("{id}").setAlias("DeletePosition")
];
