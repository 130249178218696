import EndPointConfig from '../../models/api/endPointConfig';

const controller = 'TTOO';

/// Define all EndPoints for channels section.
export const ChannelsEP: EndPointConfig[] = [
  // Envio de formulario a la API
  new EndPointConfig()
    .setMethod('GET')
    .setController(controller)
    .setAlias('GetChannels'),

  new EndPointConfig()
    .setMethod('GET')
    .setController(controller)
    .setAlias('GetChannelsFiltered'),

  new EndPointConfig()
    .setMethod('PUT')
    .setController(controller)
    .setActionName('favorite')
    .setAlias('UpdateFavoriteChannel'),

  new EndPointConfig()
    .setMethod('PUT')
    .setActionName('active')
    .setController(controller)
    .setAlias('UpdateActiveChannel'),

  new EndPointConfig()
    .setMethod('PUT')
    .setActionName('orders')
    .setController(controller)
    .setAlias('UpdateOrderChannels'),

  new EndPointConfig()
    .setMethod("GET")
    .setController(controller)
    .setPattern("{id}")
    .setAlias("GetChannelById"),

  new EndPointConfig()
    .setMethod("POST")
    .setController(controller)
    .setAlias("CreateChannel"),

  new EndPointConfig()
    .setMethod("PUT")
    .setController(controller)
    .setAlias("UpdateChannel"),

  new EndPointConfig()
    .setMethod("DELETE")
    .setController(controller)
    .setPattern("{id}")
    .setAlias("DeleteChannel")
];
