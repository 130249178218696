import { PickerLocale } from "antd/es/date-picker/generatePicker";
import { useTranslation } from "react-i18next";

// Funcion que usamos para darle el formato a las fechas y los textos en el idioma que tiene el usuario seleccionado
// Esto sobreescribe lo aplicado por AntD y es necesario
export const getLocaleDatePicker = () => {
    const { t } = useTranslation();

    const local: PickerLocale = {
        lang: {
            locale: t("translation:lang.locale"),
            placeholder: t("translation:lang.placeholder"),
            rangePlaceholder: t("translation:lang.rangePlaceholder"),
            today: t("translation:lang.today"),
            now: t("translation:lang.now"),
            backToToday: t("translation:lang.backToToday"),
            ok: t("translation:lang.ok"),
            clear: t("translation:lang.clear"),
            month: t("translation:lang.month"),
            year: t("translation:lang.year"),
            timeSelect: t("translation:lang.timeSelect"),
            dateSelect: t("translation:lang.dateSelect"),
            monthSelect: t("translation:lang.monthSelect"),
            yearSelect: t("translation:lang.yearSelect"),
            decadeSelect: t("translation:lang.decadeSelect"),
            yearFormat: t("translation:lang.yearFormat"),
            dateFormat: t("translation:lang.dateFormat"),
            dayFormat: t("translation:lang.dayFormat"),
            dateTimeFormat: t("translation:lang.dateTimeFormat"),
            monthFormat: t("translation:lang.monthFormat"),
            monthBeforeYear: true,
            previousMonth: t("translation:lang.previousMonth"),
            nextMonth: t("translation:lang.nextMonth"),
            previousYear: t("translation:lang.previousYear"),
            nextYear: t("translation:lang.nextYear"),
            previousDecade: t("translation:lang.previousDecade"),
            nextDecade: t("translation:lang.nextDecade"),
            previousCentury: t("translation:lang.previousCentury"), 
            nextCentury: t("translation:lang.nextCentury"),
        },
        timePickerLocale: {
            placeholder: t("translation:timePickerLocale.placeholder"),
        },
        dateFormat: t("translation:dateFormat"),
        dateTimeFormat: t("translation:dateTimeFormat"),
        weekFormat: t("translation:weekFormat"),
        monthFormat: t("translation:monthFormat"),
    };

    return local;
};

// Funcion para el tratamiento de fechas que pasen a JSON.stringify para enviar a la API
export const treatmentDates= (date)=> {
    const dateConvert= new Date(date)
    const timezoneOffsetInHours = -(dateConvert.getTimezoneOffset() / 60);
    const correctedDate = new Date(dateConvert.getFullYear(), dateConvert.getMonth(),
    dateConvert.getDate(), dateConvert.getHours(), dateConvert.getMinutes(), dateConvert.getSeconds(),
    dateConvert.getMilliseconds());
    correctedDate.setHours(dateConvert.getHours() + timezoneOffsetInHours);
    const iso = correctedDate.toISOString();

    return iso
}
