import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { MenuLanguages } from "../../../../languageSelector/comboLanguage";
import { Link } from "react-router-dom";
import { ConfigContext } from "../../../../../hooks/context/config";
import "../header.sass";
import "./mobileMenu.sass";
import { LINKS_MOBILE_PUBLIC } from "../../../../../constants/constants";
import {
    faUser,
    faBars,
    faStar,
    faHome,
    faQuestionCircle,
    faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinkActiveContext } from "../../../../../hooks/context/linkActive";
import { cleanUp } from "../../../../../utils/cleanUp";
import {
    FontIconsCustom,
    DrawerCustom,
    MenusCustomPro,
    MenusItemsCustomPro,
} from "../../../../custom";

// Menú vislble para versiones tablet o móvil (de MD para abajo)
export const MobileMenu = (params) => {
    // Libreria i18n para traducir
    const { t } = useTranslation(["urls", "linksNames"]);
    const linkActive = useContext(LinkActiveContext);

    // Estado para mostrar o ocultar modal cookie
    const [modalLogin, setmodalLogin] = useState(false);

    // Control para desplegar o ocultar menu lateral
    const [visible, setVisible] = useState(false);

    // Contexto con la configuracion
    const theme = useContext(ConfigContext);

    // Se muestra el menu lateral
    const showDrawer = () => {
        setVisible(true);
    };

    // Se oculta el menu lateral
    const onClose = (e) => {
        setVisible(false);
        linkActive.updateValueUrl(t("urls:public." + e));
    };

    // Estados para el manejador de la funcionalidad del menu cabecera que se ocultara al hacer scroll hacia la parte inferior, pero que en el momento de subir se vuelve a mostar
    const [top, setTop] = useState("no-fixed");
    const [space, setSpace] = useState("div-aux-no-space");
    const [ascend, setascend] = useState(0);
    let url = window.location.pathname + window.location.hash;

    if (url.substring(url.length, url.length - 1) === "/") {
        url = url.substring(0, url.length - 1);
    }

    // Ponemos a la escucha el scroll
    useEffect(() => {
        cleanUp();
        window.addEventListener("scroll", scrollTop);
        return () => window.removeEventListener("scroll", scrollTop);
    });

    // Comprobamos si asciende o desciende
    const scrollTop = () => {
        if (window.pageYOffset > ascend) {
            setTop("no-fixed");
            setSpace("div-aux-no-space");
            setascend(window.pageYOffset);
        } else {
            setTop("fixed");
            setSpace("div-aux-space");
            setascend(window.pageYOffset);
        }
    };

    const icons = [faHome, faQuestionCircle, faPhoneAlt];

    return (
        <>
            <div className={top + " col-12 box-header-mobile"}>
                <div className="col-1 menuCon position-icon padding-left-header width-items">
                    <div className="vertical-center">
                        <FontAwesomeIcon
                            onClick={showDrawer}
                            className="icons-color"
                            icon={faBars}
                        />
                    </div>
                    <DrawerCustom
                        placement="left"
                        closable={false}
                        class={"drawer-menu-public lateral-menu-drawer"}
                        onClose={onClose}
                        visible={visible}
                        content={
                            <MenusCustomPro
                                mode="inline"
                                defaultSelectedKeys={[url]}
                                onClick={onClose}
                            >
                                {LINKS_MOBILE_PUBLIC.map((element, index) => {
                                    const showDest: string = t(
                                        "urls:" + "public" + "." + element
                                    );
                                    return (
                                        <MenusItemsCustomPro
                                            key={showDest}
                                            className={
                                                "padding-elements-link" +
                                                (window.location.pathname +
                                                    window.location.hash ===
                                                t(
                                                    "urls:" +
                                                        "public" +
                                                        "." +
                                                        element
                                                )
                                                    ? " disabled-li"
                                                    : "")
                                            }
                                        >
                                            <Link
                                                id={
                                                    "link-" +
                                                    "public" +
                                                    "-mobile-" +
                                                    element +
                                                    "-" +
                                                    index
                                                }
                                                key={index}
                                                to={t(
                                                    "urls:" +
                                                        "public" +
                                                        "." +
                                                        element
                                                )}
                                                className={
                                                    window.location.pathname +
                                                        window.location.hash ===
                                                    t(
                                                        "urls:" +
                                                            "public" +
                                                            "." +
                                                            element
                                                    )
                                                        ? "secondary-links active"
                                                        : "secondary-links"
                                                }
                                                onClick={() => onClose(element)}
                                                disabled={
                                                    window.location.pathname +
                                                        window.location.hash ===
                                                    t(
                                                        "urls:" +
                                                            "public" +
                                                            "." +
                                                            element
                                                    )
                                                }
                                            >
                                                <div className="box-link-menu">
                                                    <div className="padding-link-desktop width-menu-mobile">
                                                        <FontIconsCustom
                                                            classIcon={
                                                                "icons-color-menu icons-color-marging"
                                                            }
                                                            icon={icons[index]}
                                                        />
                                                        {t(
                                                            "linksNames:" +
                                                                element
                                                        )}
                                                    </div>
                                                </div>
                                            </Link>
                                        </MenusItemsCustomPro>
                                    );
                                })}
                            </MenusCustomPro>
                        }
                    />
                </div>
                <div className="col-7 d-md-block p-0 logo-public width-items">
                    <div className="vertical-center-logo">
                        <Link
                            id="link-public-main-logo"
                            className="logo-mobile"
                            to={t("urls:public.home")}
                            onClick={() =>
                                linkActive.updateValueUrl(t("urls:public.home"))
                            }
                            disabled={
                                window.location.pathname +
                                    window.location.hash ===
                                t("urls:public.home")
                            }
                        >
                            <img
                                className="logo-public"
                                src={
                                    theme.logo
                                }
                                title={theme.brandName}
                                alt={theme.brandName}
                            />
                        </Link>
                    </div>
                </div>
                <div className="col-4 sesion-init menuCon position-login-button padding-right-header padding-left-header-public width-items">
                    <div className="col-12 p-0 links-row">
                        <div className="align-center-links float-link">
                            <div className="padding-link-desktop secondary-links-svg align-center-links">
                                <Link
                                    id="link-public-main-logo"
                                    className={"secondary-links"}
                                    to={t("urls:public.home")}
                                    onClick={() =>
                                        linkActive.updateValueUrl(
                                            t("urls:public.home")
                                        )
                                    }
                                    disabled={
                                        window.location.pathname +
                                            window.location.hash ===
                                        t("urls:public.home")
                                    }
                                >
                                    <FontIconsCustom
                                        classIcon="icons-color-menu"
                                        icon={faUser}
                                    />
                                </Link>
                            </div>
                            <div className="padding-top-flag">
                                <MenuLanguages
                                    key="2"
                                    section="public-mobile"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={space}></div>
        </>
    );
};
