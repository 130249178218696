import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./notifications.sass";
import {
    BadgeCustom,
    ParagraphCustom,
    MenusCustomPro,
    MenusItemsCustomPro,
    AvatarCustom,
    DropdownCustom,
} from "../../../../../custom";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { NotificationsType } from "../../../../../../constants/enums";
import { LangContext } from "../../../../../../hooks/context/lang";
import { showNotification } from "../../../../../../utils/notificationsGeneric";
import { LinkActiveContext } from "../../../../../../hooks/context/linkActive";
import { NotResults } from "../../../../../noResultGeneric/notResults";

import { RestApiClientContext } from "../../../../../../hooks/context/pageContext";

// Componente Notificaciones para la cabecera de la página
export const Notifications = () => {
    const restApiClient = useContext(RestApiClientContext);

    const linkActive = useContext(LinkActiveContext);

    // Libreria i18n para traducir
    const { t } = useTranslation(["notifications", "translation"]);

    // Contexto con el idioma del usuario
    const language = useContext(LangContext);
    const userLang = language.GetLangContext();

    const [notifications, setNotifications] = useState([]);
    const [messageApi, setMessageApi] = useState<string>("");

    const listNotifications = [];

    useEffect(() => {
        restApiClient.getNotifications(userLang)
            .then((data) => {
                if (data) {
                    data.map((e) => {
                        const notification = {
                            notificationData: {
                                avatar:
                                    e.notificationType ===
                                        NotificationsType.Agent
                                        ? e.image
                                        : "",
                                title: "",
                                description: (
                                    <>
                                        <div>
                                            <ParagraphCustom
                                                class="size-notif"
                                                text={e.message}
                                            />
                                            <ParagraphCustom
                                                class="m-0 size-notif text-time"
                                                text={t(
                                                    e.timeElapsed
                                                        .time === 1
                                                        ? "time." +
                                                        e
                                                            .timeElapsed
                                                            .type +
                                                        "-ago"
                                                        : "time." +
                                                        e
                                                            .timeElapsed
                                                            .type +
                                                        "-s-ago",
                                                    {
                                                        0: e.timeElapsed
                                                            .time,
                                                    }
                                                )} // Construye el string con el tiempo transcurrido de la notificación
                                            />
                                        </div>
                                    </>
                                ),
                            },
                            notificationType: e.notificationType,
                        };

                        listNotifications.push(notification);
                    });

                    setNotifications(listNotifications);
                } else {
                    setNotifications([]);
                    setMessageApi("not-result");
                }
            })
    }, []);

    const menu = (
        <MenusCustomPro className="content-list-items-notifications">
            {notifications.length > 0 ? (
                notifications.map((e, i) => {
                    return (
                        <MenusItemsCustomPro
                            className="content-line-break"
                            key={i}
                        >
                            <Link
                                className="link-list height-box-notification"
                                to={
                                    e.notificationType ===
                                        NotificationsType.Agent
                                        ? t("urls:private.agents-register")
                                        : t("urls:private.regularization")
                                }
                                onClick={() =>
                                    linkActive.updateValueUrl(
                                        e.notificationType ===
                                            NotificationsType.Agent
                                            ? t("urls:private.agents-register")
                                            : t("urls:private.regularization")
                                    )
                                }
                            >
                                <div className="content-avatar-list">
                                    <AvatarCustom
                                        classAvatar={"avatar-list-agents"}
                                        // avatar={e.notificationData.avatar} // TODO: Mientras no tengamos imágenes buenas, se queda así
                                        avatar={
                                            e.notificationType ===
                                                NotificationsType.Agent
                                                ? "https://cdn.pro.api.beds2b.com/images/defaults/agent.png"
                                                : "https://cdn.pro.api.beds2b.com/images/defaults/booking.png"
                                        }
                                    />
                                </div>
                                {e.notificationData.description}
                            </Link>
                        </MenusItemsCustomPro>
                    );
                })
            ) : (
                    <NotResults section={"notification"} messageApi={messageApi} />
                )}
        </MenusCustomPro>
    );

    return (
        <DropdownCustom overlay={menu}>
            <div>
                <BadgeCustom
                    count={notifications.length}
                    icon={faBell}
                    classIcon="icons-color-menu icons-color-marging secondary-links-svg color-bell"
                />
            </div>
        </DropdownCustom>
    );
};
