import * as React from "react";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { URL_IMAGE } from "../../constants/constants";
import {
	faTimesCircle,
	faInbox,
	faSearch,
	faNewspaper
} from "@fortawesome/free-solid-svg-icons";
import "./noResults.sass";
import {
    FontIconsCustom,
    TitleCustom,
    ParagraphCustom,
} from "../../components/custom";

export const NotResults = (param) => {
    const { t } = useTranslation([
        "agentsRegister",
        "notifications",
        "translation"
    ]);

    switch (param.section) {
        case "regularization":
            return (
                <div className="agent-register-not-results panel-content">
                    <FontIconsCustom
                        classIcon={
                            "icons-color-agent-register-" + param.messageApi
                        }
                        icon={
                            param.messageApi === "error"
                                ? faTimesCircle
                                : param.dataSearch
                                    ? faSearch
                                    : faInbox
                        }
                    />
                    {
                        // Primera condicion: error
                        param.messageApi === "error" ? (
                            <>
                                <TitleCustom
                                    level={4}
                                    text={t(
                                        "agentsRegister:no-result-page.error.title"
                                    )}
                                />
                                <ParagraphCustom
                                    class="text-no-found"
                                    text={
                                        <strong>
                                            {t(
                                                "agentsRegister:no-result-page.error.description"
                                            )}{" "}
                                        </strong>
                                    }
                                />
                            </>
                        ) : (
                                // Sin resultado en la busqueda || No hay agentes registrados
                                <>
                                    <ParagraphCustom
                                        class="text-no-found"
                                        text={
                                            <strong>
                                                {param.dataSearch
                                                    ? t(
                                                        "regularization:no-result-page.search-no-result.description"
                                                    )
                                                    : t(
                                                        "agentsRegister:no-result-page.not-result.description"
                                                    )}
                                            </strong>
                                        }
                                    />
                                </>
                            )
                    }
                </div>
            );
        case "agentsRegister":
            return (
                <div className="bg-not-results panel-content">
                    <FontIconsCustom
                        classIcon={"icons-color-" + param.messageApi}
                        icon={
                            param.messageApi === "error"
                                ? faTimesCircle
                                : param.dataSearch
                                    ? faSearch
                                    : faInbox
                        }
                    />
                    {
                        // Primera condicion: error
                        param.messageApi === "error" ? (
                            <>
                                <TitleCustom
                                    level={4}
                                    text={t(
                                        "agentsRegister:no-result-page.error.title"
                                    )}
                                />
                                <ParagraphCustom
                                    class="text-no-found"
                                    text={
                                        <strong>
                                            {t(
                                                "agentsRegister:no-result-page.error.description"
                                            )}{" "}
                                        </strong>
                                    }
                                />
                            </>
                        ) : (
                                // Sin resultado en la busqueda || No hay agentes registrados
                                <>
                                    <ParagraphCustom
                                        class="text-no-found"
                                        text={
                                            <strong>
                                                {param.dataSearch
                                                    ? t(
                                                        "agentsRegister:no-result-page.search-no-result.description"
                                                    )
                                                    : t(
                                                        "agentsRegister:no-result-page.not-result.description"
                                                    )}
                                            </strong>
                                        }
                                    />
                                </>
                            )
                    }
                </div>
            );
        case "news":
            return (
                <div className="bg-not-results panel-content">
                    <FontIconsCustom
                        classIcon={
                            "icons-color-" + param.messageApi
                        }
                        icon={
                            param.messageApi === "error"
                                ? faTimesCircle
                                : faNewspaper
                        }
                    />
                    {
                        param.messageApi === "error" ? (
                            <>
                                <TitleCustom
                                    level={4}
                                    text={t(
                                        "news:no-result-page.error.title"
                                    )}
                                />
                                <ParagraphCustom
                                    class="text-no-found"
                                    text={
                                        <strong>
                                            {t(
                                                "news:no-result-page.error.description"
                                            )}{" "}
                                        </strong>
                                    }
                                />
                            </>
                        ) :
                            param.dataSearch ? (
                                <>
                                    <ParagraphCustom
                                        class="text-no-found"
                                        text={
                                            <strong>
                                                {t(
                                                    "news:no-result-page.search-no-result.description"
                                                )}
                                            </strong>
                                        }
                                    />
                                </>
                            ) : (
                                    <ParagraphCustom
                                        class="text-no-found"
                                        text={t(
                                            "news:no-result-page.not-result.description"
                                        )}
                                    />
                                )
                    }
                </div>
            );
        case "notification":
            return (
                <div className="notification-register-not-results panel-content">
                    <FontIconsCustom
                        classIcon={
                            "icons-color-notification-" + param.messageApi
                        }
                        icon={
                            param.messageApi === "error"
                                ? faTimesCircle
                                : param.dataSearch
                                    ? faSearch
                                    : faInbox
                        }
                    />
                    {
                        param.messageApi === "error" ? (
                            <>
                                <TitleCustom
                                    level={4}
                                    text={t(
                                        "agentsRegister:no-result-page.error.title"
                                    )}
                                />
                                <ParagraphCustom
                                    class="text-no-found"
                                    text={
                                        <strong>
                                            {t(
                                                "agentsRegister:no-result-page.error.description"
                                            )}{" "}
                                        </strong>
                                    }
                                />
                            </>
                        ) : (
                                <ParagraphCustom
                                    class="text-no-found"
                                    text={t("notifications:not-result")}
                                />
                            )
                    }
                </div>
            );
        case "rewardsPerNigthtEstablishments":
            return (
                <div className="panel-content color-box-no-result">
                    <ParagraphCustom
                        text={
                            <strong>
                                Aún no tienes establecimientos asociados
                            </strong>
                        }
                    />
                </div>
            );

        default:
            return (
                <div className="bg-not-results panel-content">
                    <FontIconsCustom
                        classIcon={"icons-color-" + param.messageApi}
                        icon={faSearch}
                    />
                    <ParagraphCustom
                        class="text-no-found"
                        text={
                            <strong>
                                {t(
                                    "translation:not-results.text-not-found"
                                )}
                            </strong>
                        }
                    />
                </div>
            );
    }
};
