import * as React from "react";
import "../../../../i18n";
import "./header.sass";
import { MobileMenu } from "./responsiveHeader/mobileMenu";
import { DesktopMenu } from "./responsiveHeader/desktopMenu";
import { AlternateComponent } from "../../../utils/alternateComponent/alternateComponent";

// Componente main de la cabecera
export const HeaderPrivate = (params) => {
    return (
        <AlternateComponent
            desktopVersionLarge={
                <DesktopMenu
                    collapsedState={params.collapsedState}
                    toggle={params.toggle}
                    active={params.active}
                    setActive={params.setActive}
                    key="1"
                />
            }
            tabletVersion={
                <MobileMenu
                    key="2"
                    active={params.active}
                    setActive={params.setActive}
                />
            }
            mobileVersionLarge={
                <MobileMenu
                    key="2"
                    active={params.active}
                    setActive={params.setActive}
                />
            }
        />
    );
};
