import { InputCustomComponent, SelectCustomComponent, CheckCustomComponent, InputPasswordCustomComponent, DatePickerCustomComponent, TextAreaCustomProComponent, SelectCustomProComponent, OptionsCustomComponent, InputNumberCustomComponent, RangePickerCustomComponent } from './inputs/inputs';
import { FontIconsCustomComponent } from './fontIcons/fontIcons';
import { ButtonsCustomComponent } from './buttons/buttons';
import { SwitchCustomComponent, SwitchCustomProComponent } from './switches/switches';
import { TagInfoCustomComponent,TagCheckableCustomComponent } from './tagsInfo/tagsInfo';
import { DrawerCustomComponent } from './drawers/drawers';
import { DropdownCustomComponent } from './dropdowns/dropdowns';
import { BadgeCustomComponent } from './badges/badges';
import { CollapseCustomComponent, PanelsCustomComponent } from './collapses/collapses';
import { AvatarListMetaCustomComponent, ListCustomProComponent, ListItemCustomProComponent, ListItemMetaCustomProComponent } from './lists/lists';
import { TitleCustomComponent, TextCustomComponent, ParagraphCustomComponent } from './typographies/typographies';
import { FormCustomComponent, FormItemsContactCustomComponent } from './forms/forms';
import { PopoverCustomComponent } from './popovers/popovers';
import { TabsCustomComponent } from './tabs/tabs';
import { TooltipCustomComponent, TooltipProCustomComponent } from './tooltips/tooltips';
import { ModalCustomComponent, ModalCustomButtonsComponent, ModalCustomPruComponent, ModalCustomProComponent } from './modals/modals';
import { MenusCustomProComponent, MenusItemsCustomProComponent } from './menus/menus';
import { AvatarCustomComponent } from './avatar/avatar';
import { LayoutCustomComponent } from './layout/layout';
import { TableCustomComponent } from './tables/tables';
import { RadioCustomComponent, RadioGroupButtonCustomComponent, RadioGroupCustomComponent } from './radio/radio';
import { UploadCustomComponent } from './upload/upload';
import { CheckBoxCustomComponent } from './checkbox/checkbox'

// En este archivo tenemos todos los export referentes a nuestros custom

// Iconos antd
export const FontIconsCustom = FontIconsCustomComponent;

// Buttons antd
export const ButtonsCustom = ButtonsCustomComponent;

// Switch antd
export const SwitchCustom = SwitchCustomComponent;
export const SwitchCustomPro = SwitchCustomProComponent;

// Tag antd
export const TagInfoCustom = TagInfoCustomComponent;
export const TagCheckableCustom = TagCheckableCustomComponent;

// Popover antd
export const PopoverCustom = PopoverCustomComponent;

// Drawer ants
export const DrawerCustom = DrawerCustomComponent;

// Dropdown antd
export const DropdownCustom = DropdownCustomComponent;

// Badge antd
export const BadgeCustom = BadgeCustomComponent;

// Texts antd
export const TitleCustom = TitleCustomComponent;
export const TextCustom = TextCustomComponent;
export const ParagraphCustom = ParagraphCustomComponent;

// Tab antd
export const TabsCustom = TabsCustomComponent;

// Tooltip antd
export const TooltipCustom = TooltipCustomComponent;
export const TooltipProCustom = TooltipProCustomComponent;

// Modal antd
export const ModalCustom = ModalCustomComponent;
export const ModalCustomButtons = ModalCustomButtonsComponent
export const ModalCustomPro = ModalCustomProComponent;

// Avatar antd
export const AvatarCustom = AvatarCustomComponent;

// Layout antd
export const LayoutCustom = LayoutCustomComponent;

// Form antd
export const FormCustom = FormCustomComponent;
export const FormItemsContact = FormItemsContactCustomComponent

// Table antd
export const TableCustom = TableCustomComponent;

// Modal antd
export const ModalCustomPru = ModalCustomPruComponent;

// Collapse antd
export const CollapseCustom = CollapseCustomComponent;
export const PanelsCustom = PanelsCustomComponent;

// Menu antd
export const MenusCustomPro = MenusCustomProComponent;
export const MenusItemsCustomPro = MenusItemsCustomProComponent;

// List antd
export const ListCustomPro = ListCustomProComponent
export const ListItemCustomPro = ListItemCustomProComponent;
export const ListItemMetaCustomPro = ListItemMetaCustomProComponent;
export const AvatarListMetaCustom = AvatarListMetaCustomComponent;

// Inputs antd
export const DatePickerCustom = DatePickerCustomComponent;
export const RangePickerCustom = RangePickerCustomComponent;
export const InputPasswordCustom = InputPasswordCustomComponent;
export const CheckCustom = CheckCustomComponent;
export const SelectCustom = SelectCustomComponent;
export const InputCustom = InputCustomComponent;
export const TextAreaCustomPro = TextAreaCustomProComponent;
export const SelectCustomPro = SelectCustomProComponent;
export const OptionsCustom = OptionsCustomComponent;
export const InputNumberCustom = InputNumberCustomComponent;

// Radio
export const RadioGroupCustom = RadioGroupCustomComponent;
export const RadioGroupButtonCustom = RadioGroupButtonCustomComponent;
export const RadioCustom = RadioCustomComponent;

// Upload Antd
export const UploadCustom = UploadCustomComponent;

// Checkbox Antd
export const CheckBoxCustom = CheckBoxCustomComponent;
