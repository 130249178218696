import { RestApiClientContext } from '../../../hooks/context/pageContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Skeleton } from 'antd';
import './addConfiguration.sass'
import { DrawerCustom, FormCustom, FormItemsContact, ButtonsCustom, InputCustom, TagCheckableCustom, ParagraphCustom } from '../../../components/custom';
import { Form } from "antd";
import { Establishments } from '../../../components/regularization/interfaces/establishments';
import { LanguageManagement } from '../../../models/language/languageUserManagement';
import { LangContext } from '../../../hooks/context/lang';
import { AddConfData } from '../interfaces/configSelect'

export const AddConfiguration = (props: AddConfData) => {

    const {
        addNewConfig,
        setAddNewConfig,
        setShowDrawerAddConfiguration
    } = props

    const { t } = useTranslation(["rewardsPerNight"]);
    const [form] = Form.useForm();
    const userLangContext = useContext<LanguageManagement>(LangContext);
    const restApiClient = useContext(RestApiClientContext);

    const [listOptionsEstablishments, setListOptionsEstablishments] = useState<Establishments[]>([]);
    const [lng, setLng] = useState<string>(userLangContext.GetLangContext());
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedTags, setSelectedTags] = useState<Establishments[]>([]);
    const [inputName, setInputName] = useState<string>("");

    const [selectedTargetId, setSelectedTargetId] = useState<string>('');

    useEffect(() => {
        if (userLangContext.GetLangContext() !== lng) {
            setLng(userLangContext.GetLangContext());
        }
    });

    useEffect(()=>{
        setSelectedTargetId(props.targetId ?? '');
    },[props.targetId])

    useEffect(() => {
        GetEstablishmentsSelect();
    }, [lng]);

    const setNewConfiguration = () => {
        const listId: string[] = [];

        selectedTags.map((e) => {
            listId.push(e.id);
        });

        restApiClient.AddConfigurations(inputName, listId, selectedTargetId)
            .then((data) => {
                if (data) {
                    setShowDrawerAddConfiguration(false);
                    setAddNewConfig(!addNewConfig);
                }
            });
    }

    const closeDrawerDirectSales = () => {
        setShowDrawerAddConfiguration(false);
    }

    const GetEstablishmentsSelect = () => {
        setLoading(true);

        restApiClient.getEstablishments()
            .then((data) => {
                setListOptionsEstablishments(data);
                setLoading(false);

            });
    };

    const onAddAllEstablishments = () : void => {
        var selectedTags = [];
        listOptionsEstablishments.forEach((e: Establishments) => {
            selectedTags = [...selectedTags, e] ;
        });
        setSelectedTags(selectedTags);

    }

    const onRemoveAllEstablishments = () : void => {
        setSelectedTags([]);
    }

    const handleChange = (tag: Establishments, checked: any) => {
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
        setSelectedTags(nextSelectedTags);
    }

    const handleChangeName = (e: any) => {
        setInputName(e.target.value);
    }

    return (
        <DrawerCustom
            title={t("rewardsPerNight:add-configuration")}
            visible={true}
            info={""}
            width={520}
            placement="right"
            closable={true}
            onClose={closeDrawerDirectSales}
            content={
                        <Skeleton active loading={loading}>
                            <FormCustom
                                form={form}
                                onFinish={setNewConfiguration}
                                layot="vertical"
                            >
                                <FormItemsContact
                                    name="name-config"
                                    label={t("rewardsPerNight:name-configuration-label")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("rewardsPerNight:edit-add-configuration.error-form.required")
                                        },
                                        {
                                            whitespace: true,
                                            message: t("rewardsPerNight:edit-add-configuration.error-form.white-spaces")
                                        },
                                    ]}
                                >
                                    <InputCustom
                                        className="input-add-configuration main-input"
                                        id="name-config"
                                        onChange={handleChangeName}
                                    />
                                </FormItemsContact>
                                <FormItemsContact className="tags-display">
                                    <ParagraphCustom
                                        class="texto-number-info"
                                        text={
                                            <><strong> {t("rewardsPerNight:to-assing-establishment")} </strong> {`${" " + selectedTags.length + "/" + listOptionsEstablishments.length}`}</>
                                        }
                                    />
                                    <Row gutter={16} style={{margin: "5px 0 10px 0"}}>
                                        <Col xs={24} sm={24} md={12}>
                                            <Button style={{width: "100%"}} type="primary" className='btn-primary' onClick={onAddAllEstablishments}>{t('btn-add-all-establishments')}</Button>
                                        </Col>
                                        <Col xs={24} sm={24} md={12}>
                                            <Button style={{width: "100%"}} type="primary" className='btn-primary' onClick={onRemoveAllEstablishments}>{t('btn-remove-all-establishments')}</Button>
                                        </Col>
                                    </Row>
                                    {listOptionsEstablishments.map((establishmentS) => {
                                        return (
                                            <TagCheckableCustom
                                                key={establishmentS}
                                                checked={selectedTags.indexOf(establishmentS) > -1}
                                                onChange={checked => handleChange(establishmentS, checked)}
                                            >
                                                {establishmentS.name}
                                            </TagCheckableCustom>
                                        );
                                    })}
                                </FormItemsContact>
                                <FormItemsContact>
                                    <div className="direct-sale-btn-align">
                                        <ButtonsCustom
                                            htmlType="submit"
                                            class="primary-button submit-btn-direct-sale"
                                            content={t("rewardsPerNight:create")}
                                        />
                                        <ButtonsCustom
                                            onClick={closeDrawerDirectSales}
                                            class="default-button cancel-btn-direct-sale"
                                            content={t("rewardsPerNight:cancel")}
                                        />
                                    </div>
                                </FormItemsContact>
                            </FormCustom>
                        </Skeleton>
            }
        />
    );
}
