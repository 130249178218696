import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { YEAR_DEFAULT_TIMELESS } from "../../../constants/constants";
import moment from "moment";
import React from "react";
import { useTranslation } from 'react-i18next';

interface Data {
  value: any;
  onChange: (value: any[]) => void;
}

export const CalendarHeader = (props: Data) => {

  const { value, onChange } = props;
  const { t } = useTranslation(["rewardsPerNight", "translation"]);

  const currMonthName = (month) => {
    return t("rewardsPerNight:graphic.month-list-large." + (moment(month).month() + 1));
  }

  const prevMonth = () => {

    let nextDate = [];

    if (value.length > 1) {
      nextDate = [value[0].clone().subtract(1, "month"), value[value.length-1].clone().subtract(1, "month")];

    } else {
      nextDate = [value[0].clone().subtract(1, "month"), value[0].clone()];
    }

    nextDate = [
      moment(nextDate[0]._d).year() < YEAR_DEFAULT_TIMELESS ? nextDate[0].clone().add(1,"year") : nextDate[0],
      moment(nextDate[value.length-1]._d).year() < YEAR_DEFAULT_TIMELESS ? nextDate[value.length-1].clone().add(1,"year") : nextDate[value.length-1]
    ];

    return nextDate;
  }

  const nextMonth = () => {

    let nextDate = [];

    if (value.length > 1) {
      nextDate = [value[0].clone().add(1, "month"), value[value.length-1].clone().add(1, "month")];
    } else {
      nextDate = [value[0].clone().add(1, "month"), value[0].clone().add(2, "month")];
    }

    nextDate = [
      moment(nextDate[0]._d).year() > YEAR_DEFAULT_TIMELESS ? nextDate[0].clone().subtract(1,"year") : nextDate[0],
      moment(nextDate[value.length-1]._d).year() > YEAR_DEFAULT_TIMELESS ? nextDate[value.length-1].clone().subtract(1,"year") : nextDate[1]
    ];

    return nextDate;
  }

  const thisMonth = () => {
    return moment(value[0]).isSame(new Date(), "month");
  }

  return (
    <div className="col-12 p-0 header">
      <div
        className="col-1 p-0 previous"
        onClick={() => !thisMonth() && onChange(prevMonth())}
      >
        {<LeftOutlined />}
      </div>
      <div className="col-10 p-0 current">
        {value.map((month, mi) => (
          <div className={"col-md-6 col-12 month-" + mi}>
            {currMonthName(month)}
          </div>
        ))}
      </div>
      <div className="col-1 p-0 next" onClick={() => onChange(nextMonth())}>
        {<RightOutlined />}
      </div>
    </div>
  );
}
