import { RewardsConfigurationOwn } from 'src/models/rewards/Reward';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import { numberFormat } from '../../utils/formatAmount';
import { LanguageManagement } from '../../models/language/languageUserManagement';
import { LangContext } from '../../hooks/context/lang';
import { ButtonsCustom, ParagraphCustom } from "../custom";
import "./rewardsOwn.sass";

interface Props {
    rewardsOwn: RewardsConfigurationOwn;
    editRewards: () => void;
}

export const RewardsOwn = (props: Props) => {

    const { rewardsOwn, editRewards } = props;
    const { t } = useTranslation(["seo", "urls", "rewards", "translation", "modalClosedSession"]);

    const userLangContext = useContext<LanguageManagement>(LangContext);

    return (
        <>
            {
                rewardsOwn &&
                <div className="row multi-border">
                    <div className="avatar-rewards-own col-12 col-md-4 col-xl-5">
                        <img src={rewardsOwn.image} />
                    </div>
                    <div className="table-rewards-content col-12 col-md-8 col-xl-7">
                        <div className="row table-header-rewards">
                            <div className="col-2 pr-0 empty-header"></div>
                            <div className="row col-10 pr-0 text-header">
                                <div className="col-sm-6 col-12">
                                    <ParagraphCustom
                                        text={t("rewards:owners.increment")}
                                        class="table-header-rewards"
                                    />
                                </div>
                                <div className="col-sm-3 col-6">
                                    <ParagraphCustom
                                        text={t("rewards:owners.minimum")}
                                        class="table-header-rewards"
                                    />
                                </div>
                                <div className="col-sm-3 col-6 pl-2 pr-0">
                                    <ParagraphCustom
                                        text={t("rewards:owners.maximum")}
                                        class="table-header-rewards"
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            rewardsOwn.ownRewards.map((reward, index) =>
                                <div key={index}>
                                    <div className="row">
                                        <div className="col-3 col-sm-2 pr-0">
                                            <ParagraphCustom
                                                text={
                                                    <>
                                                        <span className="table-col-currency">{reward.currency.id} </span>
                                                        <span className="table-col-symbol">({reward.currency.symbol})</span>
                                                    </>
                                                }
                                            />
                                        </div>
                                        <div className="row col-9 col-sm-10 pr-0">
                                            <div className="col-sm-6 col-12 pl-xs-0">
                                                <div className="d-block d-sm-none">
                                                    <ParagraphCustom
                                                        text={<strong>{t("rewards:owners.increment")}</strong>}
                                                        class="table-header-rewards"
                                                    />
                                                </div>
                                                <ParagraphCustom
                                                    text={
                                                        <>
                                                            {reward.increment} <span className="text-increment">({t("rewards:owners.percentage")})</span>
                                                        </>
                                                    }
                                                />
                                            </div>
                                            <div className="col-sm-3 col-6">
                                                <div className="d-block d-sm-none">
                                                    <ParagraphCustom
                                                        text={<strong>{t("rewards:owners.minimum")}</strong>}
                                                        class="table-header-rewards"
                                                    />
                                                </div>
                                                <ParagraphCustom
                                                    text={
                                                        <>
                                                            {reward.minimumAmount !== 0 ? numberFormat(reward.minimumAmount, userLangContext.lang):"" } <span className="text-currency-symbol">{reward.currency.symbol}</span>
                                                        </>
                                                    }
                                                    class="align-amount"
                                                />
                                            </div>
                                            <div className="col-sm-3 col-6 pl-2 pr-0">
                                                <div className="d-block d-sm-none">
                                                    <ParagraphCustom
                                                        text={<strong>{t("rewards:owners.maximum")}</strong>}
                                                        class="table-header-rewards"
                                                    />
                                                </div>
                                                <ParagraphCustom
                                                    text={
                                                        <>
                                                            {reward.maximumAmount !== 0 ? numberFormat(reward.maximumAmount, userLangContext.lang):"" } <span className="text-currency-symbol">{reward.currency.symbol}</span>
                                                        </>
                                                    }
                                                    class="align-amount"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        rewardsOwn.ownRewards.length-1 > index &&
                                            <Divider
                                                className="table-rewards-separator"
                                            />
                                    }
                                </div>
                            )
                        }
                        <div className="col-12 description-rewards">
                            <ParagraphCustom
                                class={"p-0"}
                                text={`${t("rewards:owners.validate-1")} ${rewardsOwn.monthsValidity} ${t("rewards:owners.validate-2")}`}
                            />
                        </div>
                        <ButtonsCustom
                            id={"edit-button"}
                            class={"button-transparent-line col-12 button-edit-rewards"}
                            type=""
                            htmlType={null}
                            onClick={editRewards}
                            disabled={false}
                            size={null}
                            content={ t("rewards:buttons.edit") }
                        />
                    </div>
                </div>
            }
        </>
    );
}
