import React, { Suspense } from "react";
import { App } from "src/components/app/app";
import { useConfig, useLanguages } from 'src/api/config';
import { useUserLang } from 'src/utils/languages';

const AppWrapper = () => {
    // Se obtiene la configuracion del cliente
    const config = useConfig()

    // Se obtiene el idioma inicial del cliente a través del navegador
    const userLang = useUserLang()

    // Se obtienen todos los idiomas de la plataforma
    const allApiLang = useLanguages()



    // Renderizamos el componenete principal
    return (
        <App
            config={config}
            userLang={userLang}
            allLanguages={allApiLang}
        />
    )
}

const AppWrapperWrapper = () =>
    <Suspense fallback={null}>
        <AppWrapper />
    </Suspense>

export default AppWrapperWrapper
