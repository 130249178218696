import * as AjaxApi from "./ajaxApi";
import { AgentFilter } from "../models/agents/agentFilter";
import { ParamsSearchNews } from "../components/news/interfaceNews";
import { StateReservations, StatesAgent, StatusNews } from "../constants/enums";
import { ParamsApi } from "../models/api/paramsApi";
import DataContact from "../components/contact/formContact/interfaceDataContact";
import UserModelSendDataLogin from "../models/user/userModelSendDataLogin";
import {
	CurrencyConversion,
	DirectSales,
	LimitedAmount
} from "../components/rewardsPerNight/interfaces/directSalesConfigInterface";
import { Pagination } from "../models/pagination/pagination";
import { News } from "../models/news/news";
import { Amount } from "../components/rewardsPerNight/interfaces/configSelect";
import { TargetDto } from "src/models/targets/TargetModels";
import { KnowUsCfg } from "src/models/knowUs/KnowUsCfg";
import { LegalText } from "src/models/legalTexts/LegalText";

export class RestApiClient {
	constructor(private accessToken?: string, private traslation?: any) { }

	public setAccessToken(token?: string) {
		this.accessToken = token;
	}

	public setLanguage(traslation?: any) {
		this.traslation = traslation;
	}

	public getBillingAccounts(): Promise<any> {
		return AjaxApi.doAction("GetBillingAccountsData", this.traslation).then((response) => response.data);
	}

	// #region EndPoints Customizations
	

	public getCustomizations(): Promise<any> {
		return AjaxApi.doAction("GetCustomizations", this.traslation).then((response) => response.data);
	}

	public getContractNumber(): Promise<any> {
		return AjaxApi.doAction("GetContractNumber", this.traslation).then((response) => response.data);
	}
	// #endregion

	// #region EndPoints Contacts
	public sendBackOfficeForm(data: DataContact): Promise<any> {
		const params: ParamsApi = {
			body: {
				name: data.name,
				email: data.email,
				idTypeEmail: data.idTypeEmail,
				message: data.message
			}
		};

		return AjaxApi.doAction("SendBackOfficeForm", this.traslation, params).then((response) => response.data);
	}

	public getContactEmailTypes(languageParam: any): Promise<any> {
		const params: ParamsApi = {
			query: {
				language: languageParam
			}
		};

		return AjaxApi.doAction("GetContactEmailTypes", this.traslation, params).then((response) => response.data);
	}
	// #endregion

	// #region EndPoints Notifications
	public getNotifications(languageParam: any): Promise<any> {
		const params: ParamsApi = {
			query: {
				language: languageParam
			}
		};

		return AjaxApi.doAction("GetNotifications", this.traslation, params).then((response) => response.data);
	}
	// #endregion

	// #region EndPoints AgentsManagement
	public getAgentsFiltered(filter: AgentFilter): Promise<any> {
		const params: ParamsApi = {
			query: {
				AgentId: filter.AgentId ? filter.AgentId : "00000000-0000-0000-0000-000000000000",
				NameSurname: filter.NameSurname,
				Email: filter.Email,
				AgentStatus: filter.AgentStatus === StatesAgent.All ? "" : filter.AgentStatus,
				LocationAgency: filter.LocationAgency,
				Agency: "",
				AgencyId: filter.AgencyId,
				Pagination: filter.Pagination,
				Language: filter.Lang
			}
		};

		return AjaxApi.doAction("GetAgentsFiltered", this.traslation, params).then((response) => response.data);
	}
	// #region EndPoints AgentsManagement
	public getLocations(language): Promise<any> {
		const params: ParamsApi = {
			query: {
				language: language
			}
		};

		return AjaxApi.doAction("getLocations", this.traslation, params).then((response) => response.data);
	}

	

	public getCountries(language): Promise<any> {
		const params: ParamsApi = {
			query: {
				language: language
			}
		};

		return AjaxApi.doAction("GetCountries", this.traslation, params).then((response) => response.data);
	}

	public changeTargetStatus(targetParams:{id: number, isActive: boolean}): Promise<any> {
		const params: ParamsApi = {
			path: targetParams
		};

		return AjaxApi.doAction("ChangeTargetStatus", this.traslation, params).then((response) => response.data);
	}

	public deleteTarget(targetParams:{id: number}): Promise<any> {
		const params: ParamsApi = {
			path: targetParams
		};

		return AjaxApi.doAction("DeleteTarget", this.traslation, params).then((response) => response.data);
	}

	public changeStatusAgent(idParam: string, status: any, commentsParam: any): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: idParam
			},
			body: {
				newStatus: status,
				comments: commentsParam
			}
		};

		return AjaxApi.doAction("ChangeStatusAgent", this.traslation, params).then((response) => response.data);
	}

	public removeAgent(idAgent: string): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: idAgent
			}
		};

		return AjaxApi.doAction("RemoveAgentData", this.traslation, params).then((response) => response.data);
	}

	public getAgent(idParam: string, languageParam: any): Promise<any> {
		const params: ParamsApi = {
			query: {
				language: languageParam
			},
			path: {
				id: idParam
			}
		};

		return AjaxApi.doAction("GetAgent", this.traslation, params).then((response) => response.data);
	}

	public getAgentsFilteredStatus(languageParam: any): Promise<any> {
		const params: ParamsApi = {
			query: {
				language: languageParam
			}
		};

		return AjaxApi.doAction("GetAgentsFilteredStatus", this.traslation, params).then((response) => response.data);
	}

	public resendEmailAgentNotification(id: any, language: string): Promise<any> {
		const params: ParamsApi = {
			query: {
				agentId: id,
				agentLanguage: language
			}
		};

		return AjaxApi.doAction("ResendEmailConfirmation", this.traslation, params).then((response) => response.data);
	}

	public updateAgentComments(idParam: string, comments: any): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: idParam
			},
			body: comments
		};
		return AjaxApi.doAction("UpdateAgentComments", this.traslation, params).then((response) => response.data);
	}
	// #endregion

	// #region EndPoints Bookings
	public getBooking(idParam: string, languageParam: any): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: idParam
			},
			query: {
				language: languageParam
			}
		};
		return AjaxApi.doAction("GetBooking", this.traslation, params).then((response) => response.data);
	}

	public getBookingsFiltered(filter: any): Promise<any> {
		const params: ParamsApi = {
			query: {
				bookingReference: filter.bookingReference,
				ownReference: filter.ownReference,
				bookingStatus: filter.bookingStatus === StateReservations.All ? "" : filter.bookingStatus,
				checkIn: filter.checkIn,
				checkOut: filter.checkOut,
				agentEmail: filter.agentEmail,
				agency: filter.agency,
				nameSurnameClient: filter.nameSurnameClient,
				idEstablishment: filter.idEstablishment === StateReservations.All ? "" : filter.idEstablishment,
				Pagination: filter.pagination,
				language: filter.language
			}
		};

		return AjaxApi.doAction("GetBookingsFiltered", this.traslation, params).then((response) => response.data);
	}

	public getBookingsStatus(languageParam: any): Promise<any> {
		const params: ParamsApi = {
			query: {
				language: languageParam
			}
		};

		return AjaxApi.doAction("GetBookingsStatus", this.traslation, params).then((response) => response.data);
	}

	public getBookingCoincidences(bookingId: string) : Promise<any> {
		const params: ParamsApi =  {
			query: {
				bookingId: bookingId
			}
		}

		return AjaxApi.doAction("CheckForDuplicates", this.traslation, params).then((response) => response.data);
	}

	public getBookingStatusEmails(idParam: string): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: idParam
			}
		};

		return AjaxApi.doAction("getBookingStatusEmails", this.traslation, params).then((response) => response.data);
	}

	public getMinCheckIn(): Promise<any> {
		const params: ParamsApi = {}

		return AjaxApi.doAction("GetMinDateCheckin", this.traslation, params).then((response) => response.data);
	}

	public getGtmId(): Promise<any> {
		const params: ParamsApi = {}

		return AjaxApi.doAction("GetGtmIdConfig", this.traslation, params).then((response) => response.data);
	}

	public setGtmId(newId: string): Promise<any> {
		const params: ParamsApi = {
			body:newId
		}

		return AjaxApi.doAction("SetGtmIdConfig", this.traslation, params).then((response) => response.data);
	}

	public updateMinCheckIn(newDate: any): Promise<any> {
		const params: ParamsApi = {
			body: newDate
		}

		return AjaxApi.doAction("UpdateMinDateCheckin", this.traslation, params).then((response) => response.data);
	}

	public updateAllowNoIndexNoFollow(newValue: any): Promise<any> {
		const params: ParamsApi = {
			body: newValue
		}

		return AjaxApi.doAction("SetNoIndexNoFollowConfiguration", this.traslation, params).then((response) => response.data);
	}

	public getAllowNoIndexNoFollow(): Promise<any> {
		const params: ParamsApi = {}

		return AjaxApi.doAction("GetNoIndexNoFollowConfiguration", this.traslation, params).then((response) => response.data);
	}

	public getHideBillingAletsAndDetailsConfiguration(): Promise<any> {
		const params: ParamsApi = {}

		return AjaxApi.doAction("GetHideBillingAletsAndDetailsConfiguration", this.traslation, params).then((response) => response.data);
	}


	public changeStatusBooking(
		idParam: string,
		status: any,
		ownReferenceParam: any,
		emailContentParam: any
	): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: idParam
			},
			body: {
				newStatus: status,
				ownReference: ownReferenceParam,
				emailContent: emailContentParam
			}
		};

		return AjaxApi.doAction("ChangeStatusBooking", this.traslation, params).then((response) => response.data);
	}
	//#endregion

	// #region EndPoints Establishments
	public getEstablishments(): Promise<any> {
		return AjaxApi.doAction("GetEstablishments", this.traslation).then((response) => response.data);
	}

	public getEstablishmentsFiltered(filter: any): Promise<any> {
		const params: ParamsApi = {
			body: filter
		};

		return AjaxApi.doAction("GetEstablishmentsFiltered", this.traslation, params).then((response) => response.data);
	}
	public getEstablishmentById(id: string): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: id
			}
		};
		return AjaxApi.doAction("GetEstablishmentById", this.traslation, params).then((response) => response.data);
	}
	public updateActiveEstablishment(id, isActive): Promise<any> {
		const params: ParamsApi = {
			query: { id },
			body: isActive
		};

		return AjaxApi.doAction("UpdateActiveEstablishment", this.traslation, params).then((response) => response.data);
	}
	public updateEstablishment(id, data): Promise<any> {
		const params: ParamsApi = {
			query: { id },
			body: data
		};
		return AjaxApi.doAction("UpdateEstablishment", this.traslation, params).then((response) => response.data);
	}
	public createEstablishment(data): Promise<any> {
		const params: ParamsApi = {
			body: data
		};
		return AjaxApi.doAction("CreateEstablishment", this.traslation, params).then((response) => response.data);
	}
	public deleteEstablishment(id: string): Promise<any> {
		const params: ParamsApi = {
			path: {id}
		};
		return AjaxApi.doAction("DeleteEstablishment", this.traslation, params).then((response) => response.data);
	}
	// #endregion

	// #region EndPoints News

	public getNews(idNews: string): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: idNews
			}
		};

		return AjaxApi.doAction("GetNews", this.traslation, params).then((response) => response.data);
	}

	public addNews(newsData: News): Promise<any> {
		const paramsAPI: ParamsApi = {
			body: newsData
		};

		return AjaxApi.doAction("AddNews", this.traslation, paramsAPI).then((response) => response.data);
	}

	public updateNews(newsData: News): Promise<any> {
		const paramsAPI: ParamsApi = {
			body: newsData,
			path: {
				id: newsData.id
			}
		};

		return AjaxApi.doAction("UpdateNews", this.traslation, paramsAPI).then((response) => response.data);
	}

	public getNewsFiltered(filter: ParamsSearchNews): Promise<any> {
		const params: ParamsApi = {
			query: {
				Title: filter.Title,
				NewStatus: filter.NewStatus === StatusNews.All ? "" : filter.NewStatus,
				Pagination: filter.Pagination,
				Language: filter.Language
			}
		};

		return AjaxApi.doAction("GetNewsFiltered", this.traslation, params).then((response) => response.data);
	}

	public getNewsStatus(): Promise<any> {
		return AjaxApi.doAction("GetNewsStatus", this.traslation).then((response) => response.data);
	}

	public getAgentLanguages(): Promise<any> {
		return AjaxApi.doAction("GetAgentLanguages", this.traslation).then((response) => response.data);
	}

	public getLegalTextByType(type: string): Promise<any> {
		const params : ParamsApi = {
			query: {
				type: type
			}
		}

		return AjaxApi.doAction("GetLegalTextByType", this.traslation, params).then((response) => response.data);
	}

	public saveLegalTexts(newData: LegalText[]): Promise<any> {
		const params : ParamsApi = {
			body: {
				legalTexts: newData
			}
		}

		return AjaxApi.doAction("SaveLegalTexts", this.traslation, params).then((response) => response.data);
	}

	

	public changeFeaturedNews(idNews: string, featuredNews: boolean): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: idNews
			},
			body: {
				featured: featuredNews
			}
		};

		return AjaxApi.doAction("ChangeFeaturedNews", this.traslation, params).then((response) => response.data);
	}

	public changeStatusNews(idNews: string, status: boolean): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: idNews
			},
			body: {
				active: status
			}
		};

		return AjaxApi.doAction("ChangeStatusNews", this.traslation, params).then((response) => response.data);
	}

	public deleteNews(idNews: string): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: idNews
			}
		};

		return AjaxApi.doAction("DeleteNews", this.traslation, params).then((response) => response);
	}

	// #endregion

	// #region EndPoints Balances
	public getAvailableBalances(languageParam: any): Promise<any> {
		const params: ParamsApi = {
			query: {
				language: languageParam
			}
		};

		return AjaxApi.doAction("GetAvailableBalances", this.traslation, params).then((response) => response.data);
	}

	public getBankDetailsByCurrency(id: string): Promise<any> {
		const params: ParamsApi = {
			path: {
				currency: id
			}
		};

		return AjaxApi.doAction("GetBankDetailsByCurrency", this.traslation, params).then((response) => response.data);
	}
	// #endregion

	// #region - EndPoints Users
	public getUserName(): Promise<string> {
		return AjaxApi.doAction("GetUserName", this.traslation).then<string>((response) => response.data);
	}
	public getUserLanguage(): Promise<string> {
		return AjaxApi.doActionWithoutNotification("GetUserLanguage", this.traslation).then<string>((response) => response.data);
	}

	public getPendingInvoices(): Promise<{pendingInvoices: number}> {
		return AjaxApi.doAction("CheckForPendingInvoices", this.traslation).then<{pendingInvoices: number}>((response) => response.data);
	}

	public logoutUser(): Promise<boolean> {
		return AjaxApi.doAction("UserLogout", this.traslation).then<boolean>((response) => response.data);
	}

	public loginUser(userData: UserModelSendDataLogin): Promise<string> {
		const params: ParamsApi = {
			body: {
				UserData: userData.UserData,
				Password: userData.Password
			}
		};
		return AjaxApi.doAction("UserLogin", this.traslation, params).then<string>((response) => response.data);
	}
	// #endregion

	//#region rewards per night
	public getConfigurations(filter: string): Promise<any> {

		const params : ParamsApi = {
			query: {
				filter: filter
			}
		}

		return AjaxApi.doAction("GetConfigurations", this.traslation, params).then((response) => response.data);
	}

	public GetConfiguration(idConfig: string): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: idConfig
			}
		};
		return AjaxApi.doAction("GetConfiguration", this.traslation, params).then((response) => response.data);
	}

	public getDirectSales(): Promise<any> {
		return AjaxApi.doAction("GetDirectSales", this.traslation).then((response) => response.data);
	}

	public getLimitAmounts(): Promise<any> {
		return AjaxApi.doAction("GetLimitAmountRewards", this.traslation).then((response) => response.data);
	}

	public UpdateLimitAmountRewards(limits: LimitedAmount[]): Promise<any> {
		const params: ParamsApi = {
			body: limits
		};
		return AjaxApi.doAction("UpdateLimitAmountRewards", this.traslation, params).then((response) => response.data);
	}

	public putDirectSales(directSales: DirectSales): Promise<any> {
		const params: ParamsApi = {
			body: {
				activeType: directSales.activeType,
				percentage: directSales.percentage,
				amounts: directSales.amounts
			}
		};

		return AjaxApi.doAction("PutDirectSales", this.traslation, params).then((response) => response.data);
	}

	public AddConfigurations(nameParam: string, establishmentsParam: string[], targetId: string): Promise<any> {
		const params: ParamsApi = {
			body: {
				name: nameParam,
				establishmentsIds: establishmentsParam,
				targetId: targetId
			}
		};

		return AjaxApi.doAction("AddConfigurations", this.traslation, params).then((response) => response.data);
	}

	public SetEditConfiguration(idConfig: string, nameParam: string, selectedTags: string[]): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: idConfig
			},
			body: {
				name: nameParam,
				establishmentsIds: selectedTags
			}
		};
		return AjaxApi.doAction("SetEditConfiguration", this.traslation, params).then((response) => response.data);
	}

	public DeleteConfiguration(idConfig: string): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: idConfig
			}
		};
		return AjaxApi.doAction("DeleteConfiguration", this.traslation, params).then((response) => response.data);
	}

	public EditSeason(
		idParam: string,
		seasonIdParam: string,
		dataAmount: Amount[],
		nameParam: string,
		DateParam: any[],
		minNightsAllowed: number
	): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: idParam,
				seasonId: seasonIdParam
			},
			body: {
				amounts: dataAmount,
				name: nameParam,
				startDate: DateParam[0],
				endDate: DateParam[1],
				minNightsAllowed: minNightsAllowed
			}
		};
		return AjaxApi.doAction("EditSeason", this.traslation, params).then((response) => response.data);
	}

	public AddSeason(
		idConfig: string,
		dataAmount: Amount[],
		nameParam: string,
		DateParam: any[],
		minNightsAllowed: number
	): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: idConfig
			},
			body: {
				amounts: dataAmount,
				name: nameParam,
				startDate: DateParam[0],
				endDate: DateParam[1],
				minNightsAllowed: minNightsAllowed
			}
		};
		return AjaxApi.doAction("AddSeasons", this.traslation, params).then((response) => response.data);
	}
	// #endregion

	// #region EndPoints Currencies
	public getCurrencyConversion(currencyConversion: CurrencyConversion): Promise<any> {
		const params: ParamsApi = {
			query: {
				currencyFrom: currencyConversion.currencyFrom,
				amount: currencyConversion.amount,
				currencyTo: currencyConversion.currencyTo
			}
		};

		return AjaxApi.doAction("GetCurrencyConversion", this.traslation, params).then((response) => response.data);
	}

	public getListCurrency(): Promise<string[]> {
		return AjaxApi.doAction("GetListCurrency", this.traslation).then((response) => response.data);
	}
	// #endregion

	// #region EndPoints Rewards
	public GetRewardsOwn(): Promise<any> {
		return AjaxApi.doAction("GetRewardsOwn", this.traslation).then((response) => response.data);
	}

	public GetRewardsSuppliers(resultPageParam: number, pageParam: number): Promise<any> {
		const params: ParamsApi = {
			query: {
				resultPage: resultPageParam,
				page: pageParam
			} as Pagination
		};
		return AjaxApi.doAction("GetRewardsSuppliers", this.traslation, params).then((response) => response.data);
	}

	public UpdateOwnReward(monthsValidityParam: number, rewardsConfigurationUpdateParam: any[]): Promise<any> {
		const params: ParamsApi = {
			body: {
				months: monthsValidityParam,
				updateOwnRewardConfigurations: rewardsConfigurationUpdateParam
			}
		};
		return AjaxApi.doAction("UpdateOwnReward", this.traslation, params).then((response) => response.data);
	}

	public ChangeStatusReward(idParam: string, statusParam: boolean): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: idParam
			},
			body: !statusParam
		};
		return AjaxApi.doAction("ChangeStatusReward", this.traslation, params).then((response) => response.data);
	}

	public UpdateSupplierReward(
		idParam: string,
		minimumAmountParam: number,
		maximumAmountParam: number,
		incrementParam: number
	): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: idParam
			},
			body: {
				minimum: minimumAmountParam,
				maximum: maximumAmountParam,
				increment: incrementParam
			}
		};
		return AjaxApi.doAction("UpdateSupplierReward", this.traslation, params).then((response) => response.data);
	}
	// #endregion

	// #region EndPoints TTOO
	public getChannels(): Promise<any> {
		return AjaxApi.doAction("GetChannels", this.traslation).then((response) => response.data);
	}

	public getChannelsFiltered(filter: string): Promise<any> {
		const params: ParamsApi = {
			query: { filtered: filter }
		};

		return AjaxApi.doAction("GetChannelsFiltered", this.traslation, params).then((response) => response.data);
	}
	public updateActiveChannel(id, isActive): Promise<any> {
		const params: ParamsApi = {
			query: { id },
			body: isActive
		};

		return AjaxApi.doAction("UpdateActiveChannel", this.traslation, params).then((response) => response.data);
	}
	public updateFavoriteChannel(id, favorite): Promise<any> {
		const params: ParamsApi = {
			query: { id },
			body: favorite
		};

		return AjaxApi.doAction("UpdateFavoriteChannel", this.traslation, params).then((response) => response.data);
	}

	public updateOrderChannels(ids: string[]): Promise<any> {
		const params: ParamsApi = {
			body: ids
		};

		return AjaxApi.doAction("UpdateOrderChannels", this.traslation, params).then((response) => response.data);
	}

	public getChannelById(id: string): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: id
			}
		};
		return AjaxApi.doAction("GetChannelById", this.traslation, params).then((response) => response.data);
	}
	public updateChannel(id, data): Promise<any> {
		const params: ParamsApi = {
			query: { id },
			body: data
		};

		return AjaxApi.doAction("UpdateChannel", this.traslation, params).then((response) => response.data);
	}
	public createChannel(data): Promise<any> {
		const params: ParamsApi = {
			body: data
		};
		return AjaxApi.doAction("CreateChannel", this.traslation, params).then((response) => response.data);
	}

	public deleteChannel(id: string): Promise<any> {
		const params: ParamsApi = {
			path: id
		};

		return AjaxApi.doAction("DeleteChannel", this.traslation, params).then((response) => response.data);
	}
	// #endregion

	// #region EndPoints RoomTypes
	public getRoomTypes(): Promise<any[]> {
		return AjaxApi.doAction("GetRoomTypes", this.traslation).then((response) => response.data);
	}
	public getRoomTypeById(id: string): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: id
			}
		};
		return AjaxApi.doAction("GetRoomTypeById", this.traslation, params).then((response) => response.data);
	}
	public updateRoomType(id, data): Promise<any> {
		const params: ParamsApi = {
			query: { id },
			body: data
		};

		return AjaxApi.doAction("UpdateRoomType", this.traslation, params).then((response) => response.data);
	}
	public createRoomType(data): Promise<any> {
		const params: ParamsApi = {
			body: data
		};
		return AjaxApi.doAction("CreateRoomType", this.traslation, params).then((response) => response.data);
	}
	public deleteRoomType(id: string): Promise<any> {
		const params: ParamsApi = {
			path: {
			id: id
		}
    };
		return AjaxApi.doAction("DeleteRoomType", this.traslation, params).then((response) => response.data);
	}
	// #endregion

	// #region EndPoints Regimens
	public getRegimens(): Promise<any[]> {
		return AjaxApi.doAction("GetRegimens", this.traslation).then((response) => response.data);
	}
	public getRegimenById(id: string): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: id
			}
		};
		return AjaxApi.doAction("GetRegimenById", this.traslation, params).then((response) => response.data);
	}
	public updateRegimen(id, data): Promise<any> {
		const params: ParamsApi = {
			query: { id },
			body: data
		};
		return AjaxApi.doAction("UpdateRegimen", this.traslation, params).then((response) => response.data);
	}
	public createRegimen(data): Promise<any> {
		const params: ParamsApi = {
			body: data
		};
		return AjaxApi.doAction("CreateRegimen", this.traslation, params).then((response) => response.data);
	}
	public deleteRegimen(id: string): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: id
		}
    };
		return AjaxApi.doAction("DeleteRegimen", this.traslation, params).then((response) => response.data);
	}
	// #endregion

  // #region EndPoints Regimens

	public getOtherConfig(): Promise<any>{
		return AjaxApi.doAction("GetOtherConfigurations", this.traslation, {}).then((response)=>response.data);
	}

	public updateMandatoryName(data): Promise<any> {
		const params: ParamsApi = {
			body: data
		};
		return AjaxApi.doAction("UpdateMandatoryName", this.traslation, params).then((response) => response.data);
	}
	
	public updateAllowReservations(data): Promise<any> {
    	const params: ParamsApi = {
			body: data
		};
		return AjaxApi.doAction("UpdateAllowReservations", this.traslation, params).then((response) => response.data);
	}

	public updateAllowGeneralRanking(data): Promise<any> {
		const params: ParamsApi = {
				body: data
			};
			return AjaxApi.doAction("UpdateAllowGeneralRanking", this.traslation, params).then((response) => response.data);
		}

		public updateRankingConfig(data): Promise<any> {
			const params: ParamsApi = {
				body: data
			};
			return AjaxApi.doAction("UpdateRankingConfig", this.traslation, params).then((response) => response.data);
		}

		public updateAllowPersonalRanking(data): Promise<any> {
			const params: ParamsApi = {
				body: data
			};
			return AjaxApi.doAction("UpdateAllowPersonalRanking", this.traslation, params).then((response) => response.data);
		}
	

	public getPowerBIUrl(): Promise<any> {
		return AjaxApi.doAction("GetPowerBIURL", this.traslation).then((response) => response.data);
	}

	
  // #endregion

  // #region EndPoints Regimens
	public getPositions(): Promise<any> {
		return AjaxApi.doAction("GetPositions", this.traslation).then((response) => response.data);
	}
	public getPositionById(id: string): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: id
			}
		};
		return AjaxApi.doAction("GetPositionById", this.traslation, params).then((response) => response.data);
	}
	public updatePositions(id: string, data): Promise<any> {
		const params: ParamsApi = {
			query: { id },
			body: data
		};
		return AjaxApi.doAction("UpdatePositions", this.traslation, params).then((response) => response.data);
	}
	public createPositions(data): Promise<any> {
		const params: ParamsApi = {
			body: data
		};
		return AjaxApi.doAction("CreatePositions", this.traslation, params).then((response) => response.data);
	}
	public deletePosition(id: string): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: id
		}
    };
		return AjaxApi.doAction("DeletePosition", this.traslation, params).then((response) => response.data);
	}
	// #endregion

	// #region EndPoints Regimens
	public getAdvancedConfiguration(languageParam: any): Promise<any> {
		const params: ParamsApi = {
			query: {
				language: languageParam
			}
		};
		return AjaxApi.doAction("GetAdvancedConfiguration", this.traslation, params).then((response) => response.data);
	}


	public checkCoincidencesConfigurations(id: string, data): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: id
			},
			body: data
		};

		return AjaxApi.doAction("CheckAdvancedConfigurationsCoincidences", this.traslation, params).then((response) => response.data);
	}

	public changeAdvancedConfigurationStatus(id: string, newStatus: boolean): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: id,
				status: newStatus
			}
		};

		return AjaxApi.doAction("ChangeAdvancedConfigurationStatus", this.traslation, params).then((response) => response.data);
	}

	public updateAdvancedConfiguration(id: string, data): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: id
			},
			body: data
		};
		return AjaxApi.doAction("UpdateAdvancedConfiguration", this.traslation, params).then((response) => response.data);
	}

	public createAdvancedConfiguration(data): Promise<any> {
		const params: ParamsApi = {
			body: data
		};
		return AjaxApi.doAction("CreateAdvancedConfiguration", this.traslation, params).then((response) => response.data);
	}
	public deleteAdvancedConfiguration(id: string): Promise<any> {
		const params: ParamsApi = {
			path: {
				id: id
		}
    };
		return AjaxApi.doAction("DeleteAdvancedConfiguration", this.traslation, params).then((response) => response.data);
	}
	// #endregion
	// #region EndPoints AgentsManagement
	//Fix api call
	public getAgencies(filter: {name? : string, page: number, resultPage : number, language: string, address?:string}): Promise<any> {
		const params: ParamsApi = {
			query: filter?.name ? {
				['Pagination.Page']: filter.page,
				['Pagination.ResultPage']: filter.resultPage,
				language: filter?.language,
				name:  filter?.name,
				address: filter?.address
			} :  {
				['Pagination.Page']: filter.page,
				['Pagination.ResultPage']: filter.resultPage,
				language: filter?.language,
				address: filter?.address,
			}
		};

		return AjaxApi.doAction("GetAgenciesFiltered", this.traslation, params).then((response) => response.data);
	}
	// #region EndPoints AgentsManagement

	public getTargets(filter: {description?: string, page: number, resultPage: number, isActive: number, entityIds: string[]}) : Promise<any>{
		const params: ParamsApi = {
			body: filter
		};

		return AjaxApi.doAction("GetTargetsFiltered", this.traslation, params).then((response) => response.data);
	}

	public cloneConfiguration(parameters:{configurationId: string, targetToId: string}) : Promise<any>{
		const params: ParamsApi = {
			body:parameters
		}

		return AjaxApi.doAction("CloneConfigurationToTarget", this.traslation, params).then((response)=> response.data);
	}

	public createTarget(data: TargetDto) : Promise<any>{
		const params: ParamsApi = {
			body: data
		};

		return AjaxApi.doAction("PostTarget", this.traslation, params).then((response) => response.data);
	}

	public updateTarget(data: TargetDto) : Promise<any>{
		const params: ParamsApi = {
			body: data
		};

		return AjaxApi.doAction("UpdateTarget", this.traslation, params).then((response) => response.data);
	}

	
	public getKnowUsConfiguration(language: string){
		const params: ParamsApi = {
			query: {
				language: language
			}
		};
		return AjaxApi.doAction("GetKnowUsConfiguration", this.traslation, params).then<any>(
			(response) => response.data
		);	
	}

	public saveKnowUsCfg(parameters: KnowUsCfg){
		const params: ParamsApi = {
			body: parameters
		};
		return AjaxApi.doAction("SaveKnowUsCfg", this.traslation, params).then<any>(
			(response) => response.data
		);	
	}
	
}
