import EndPointConfig from '../../models/api/endPointConfig';

const controller = 'News';

/// Define all EndPoints for Manage News.
export const NewsEP: EndPointConfig[] = [

    // Obtiene el listado de estados posibles que puede tener una noticia
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('statuses')
        .setAlias('GetNewsStatus'),

    // Obtiene el listado de noticias
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('filtered')
        .setAlias('GetNewsFiltered'),

    // Cambia el "destacado" de una noticia
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller)
        .setPattern('{id}/highlight')
        .setAlias('ChangeFeaturedNews'),

    // Cambia el estado "Publicado" a "Despublicado" y viceversa de una noticia
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller)
        .setPattern('{id}/status')
        .setAlias('ChangeStatusNews'),

    // Elimina una noticia
    new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller)
        .setPattern('{id}')
        .setAlias('DeleteNews'),

    // Obtiene una noticia
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setPattern('{id}')
        .setAlias('GetNews'),

    // Create news
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setAlias('AddNews'),

    // Update news
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)
        .setPattern('{id}')
        .setAlias('UpdateNews')

];
