import React from "react";
import "../../../i18n";
import "./listAgentsRegister.sass";
import {
    ListCustomPro,
    ListItemCustomPro,
    ListItemMetaCustomPro,
    AvatarListMetaCustom,
} from "../../custom";
import { CustomPagination } from "../../../components/utils/pagination/pagination";
import { TotalElementsTableList } from "../../../components/utils/totalElementsTableList/totalElementsTableList";
import { Pagination } from "../../../models/pagination/pagination";
import { ListItem } from "../../../components/custom/lists/interfaceLists";

interface ListAgentsParams {
    data: ListItem[],
    count: number,
    setChangeDataAgent : any,
    setPageResults: any,
    pageResults: number
}
export const ListAgentsRegister = (param: ListAgentsParams) => {

    const onChangeCallApi = (pagination: Pagination) => {
        param.setPageResults(pagination);
    };

    return (
        <div className="col-12 content-list">
            <ListCustomPro
                itemLayout={"horizontal"}
                dataSource={param.data}
                renderItem={(item: ListItem) => (
                    <ListItemCustomPro className={"p-0 not-line-down"}>
                        <ListItemMetaCustomPro
                            className={"box-list-agents-style-padding"}
                            avatar={
                                item.avatar !== "" && (
                                    <AvatarListMetaCustom
                                        className={"avatar-list-agents"}
                                        src={item.avatar}
                                    />
                                )
                            }
                            title={item.title}
                            description={item.description}
                        />
                    </ListItemCustomPro>
                )}
            >
                
            </ListCustomPro>
            <div className="col-12 p-0 pagination-container">
                <CustomPagination
                    setPageResults={param.setPageResults}
                    pageResults={param.pageResults}
                    countData={param.count}
                    onChangeCallApi={onChangeCallApi}
                />
            </div>
            <div className="col-12 p-0">
                <TotalElementsTableList total={param.count} />
            </div>
        </div>
    );
};
