import React, { useContext } from "react";
import "./home.sass";
import {Login} from "./login/login";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { ConfigContext } from "../../hooks/context/config";
import { URL_IMAGE, URL_BASE } from "../../constants/constants";
import { Divider } from "antd";
import { TitleCustom } from "../../components/custom";

// Componente de la vista principal de la aplicación
export const Home = () => {
	// Contexto con la configuracion de la app
	const infoClient = useContext(ConfigContext);

	// Lenguaje para los textos
	const { t } = useTranslation(["seo", "translation"]);

	return (
		<>
			<Helmet>
				<title>
					{infoClient.incentiveSiteName} | {t("seo:title.home")}
				</title>
				<meta
					name="description"
					content={t("seo:description.home", {
						0: infoClient.incentiveSiteName,
					})}
				/>
				<meta name="robots" content="index,follow" />
				<link rel="canonical" href={URL_BASE} />
				<link
					rel="icon"
					type="image/ico"
					href={infoClient.favicon}
					sizes="32x32"
				/>
				<link href={URL_BASE} hrefLang="es-ES" rel="alternate" />
			</Helmet>
			<div className="container-home-color row">
				<div className="d-none d-md-block col-md-6 pr-0">
					<div className="welcome-text d-flex justify-content-center align-items-center">
						{t("translation:welcome-home")}
					</div>
					<img
						className="img-bg"
						src={URL_IMAGE + "backoffice-image-default.jpg"}
					/>
				</div>
				<div className="col-12 col-md-6 pl-md-0 d-flex justify-content-center align-items-center content-login-form">
					<div className="form-login col-12">
						<TitleCustom
							class="title-align"
							level={4}
							text={`${t("translation:access-to")} ${infoClient.incentiveSiteName}`}
						/>
						<Divider className="separator-login" />
						<Login />
					</div>
				</div>
			</div>
		</>
	);
};

