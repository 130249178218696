import React, { useContext, useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import "./newGroup.sass";
import { useTranslation } from "react-i18next";
import { RestApiClientContext } from "src/hooks/context/pageContext";
import { Alert, Form, Input, InputNumber, notification, Radio, Skeleton } from "antd";
import { ButtonsCustom, ParagraphCustom, RadioCustom, SwitchCustomPro, TitleCustom } from "src/components/custom";
import { NewGroupSelect } from "./newGroupSelect";
import { SortItem } from "src/components/custom/SortableTable/interfaceSort";
import { NO_CHART_REPITED, NO_CHART_REPITED_AND_NUMBER_DECIMAL, ONLY_NUMBER_D } from "src/constants/regex";
import {
  LoadingOutlined
} from '@ant-design/icons';

interface RetributionData {
  idAdvancedGroups?: string;
  name: string;
  idSalesType?: string;
  isActive?: boolean;
  order?: number;
  advancedGroupsConfigurations?: any[],
  advancedGroupsRegimens?: any[];
  advancedGroupsRoomTypes?: any[];
  advancedGroupsTtoo?: any[];
  advancedGroupsAmount?: any[];
}

interface RoomsItem {
  id: string;
  roomName: string;
  identityCode: string;
  comment: string;
}

interface RegimensItem {
  id: string;
  regimenName: string;
  identityCode: number;
  comment: string;
  isDeleted: boolean;
}

interface SortChannelItem extends SortItem {
  id: string;
  name: string;
  identityCode: number;
  isActive: string;
  actions: JSX.Element;
  isDraggable: boolean;
}

export const NewGroup = (props) => {
  const { t } = useTranslation(["advancedretributiontranslations"]);
  const { setAddNewGroup, data, reload } = props;
  const restApiClient = useContext(RestApiClientContext);
  const [dataRooms, setDataRooms] = useState<RoomsItem[]>(data?.advancedGroupsRoomTypes.filter((opt) => opt?.selected));
  const [dataConfigurations, setDataConfigurations] = useState<RoomsItem[]>(data?.advancedGroupsConfigurations.filter((opt) => opt?.selected));
  const [loading, setLoading] = useState(false)
  const [dataChannels, setDataChannels] = useState<SortChannelItem[]>(
    data?.advancedGroupsTtoo.filter((opt) => opt?.selected)
  );
  const [dataRegimens, setDataRegimens] = useState<RegimensItem[]>(
    data?.advancedGroupsRegimens.filter((opt) => opt?.selected)
  );
  const [fieldsData, setFieldsData] = useState(
    data?.advancedGroupsAmount?.reduce((a, v) => ({ ...a, [v?.idCurrency]: v?.amount }), {})
  );
  const [percentValue, setPercentValue] = useState(
    data?.advancedGroupsAmount?.[0]?.amountType === "percent" ? data?.advancedGroupsAmount?.[0]?.amount : null
  );

  const [radioValue, setRadioValue] = useState(data?.advancedGroupsAmount?.[0]?.amountType === "percent" ? 1 : 2);
  const [currencyList, setCurrencyList] = useState(null);
  const [isActive, setIsActive] = useState(data?.isActive);
  const [form] = Form.useForm();

  const [showCoincidencesAlert, setShowCoincidencesAlert] = useState<boolean>(false);
  const [groupCoincidenceName, setGroupCoincidenceName] = useState<string>('');

  const [savingData, setSavingData] = useState<boolean>(false);
  const handleSave = async () => {
      
      const body: RetributionData = {
        idAdvancedGroups: data?.idAdvancedGroups,
        name: data?.name,
        idSalesType: data?.idSalesType,
        isActive: isActive,
        order: -1,
        advancedGroupsRegimens: dataRegimens,
        advancedGroupsRoomTypes: dataRooms,
        advancedGroupsConfigurations: dataConfigurations,
        advancedGroupsTtoo: dataChannels,
        advancedGroupsAmount:
          radioValue === 2
            ? Object.keys(fieldsData)
                ?.filter((curr) => fieldsData?.[curr])
                .map((curr) => ({
                  amount: fieldsData[curr],
                  idAdvancedGroups: data?.idAdvancedGroups,
                  idCurrency: curr,
                  amountType: "fixed"
                }))
            : [
                {
                  amount: percentValue,
                  idAdvancedGroups: data?.idAdvancedGroups,
                  idCurrency: null,
                  amountType: "percent"
                }
              ]
      };

      
      if (!body?.advancedGroupsAmount 
          || body?.advancedGroupsAmount.length == 0 
          || body?.advancedGroupsAmount.some(value => value.amount === undefined || value.amount === null || value.amount === "")
        ){
        notification.error({
            message: t("empty.no-amounts"),
            description: '',
        })
      }else if (body?.advancedGroupsRegimens.length == 0 && body?.advancedGroupsRoomTypes.length == 0 && body?.advancedGroupsTtoo.length == 0){
        notification.error({
          message: t("empty.no-parameters"),
          description: '',
      })
      }else{
        setSavingData(true);
        restApiClient.updateAdvancedConfiguration(data?.idAdvancedGroups, body).finally(()=>{
          setSavingData(false);
        })
      }
      
     
  };

  const handleCheckCoincidences = async () => {
      const body: RetributionData = {
        idAdvancedGroups: data?.idAdvancedGroups,
        name: data?.name,
        idSalesType: data?.idSalesType,
        isActive: isActive,
        order: -1,
        advancedGroupsConfigurations: dataConfigurations,
        advancedGroupsRegimens: dataRegimens,
        advancedGroupsRoomTypes: dataRooms,
        advancedGroupsTtoo: dataChannels,
        advancedGroupsAmount:
          radioValue === 2
            ? Object.keys(fieldsData)
                ?.filter((curr) => fieldsData?.[curr])
                .map((curr) => ({
                  amount: fieldsData[curr] || 0,
                  idAdvancedGroups: data?.idAdvancedGroups,
                  idCurrency: curr,
                  amountType: "fixed"
                }))
            : [
                {
                  amount: percentValue || 0,
                  idAdvancedGroups: data?.idAdvancedGroups,
                  idCurrency: null,
                  amountType: "percent"
                }
              ]
      };
      
      restApiClient.checkCoincidencesConfigurations(data?.idAdvancedGroups, body).then((data: any)=>{

        if (data && data.length>0){
          setGroupCoincidenceName(data);
          setShowCoincidencesAlert(true);
        }else{
          setGroupCoincidenceName('');
          setShowCoincidencesAlert(false);
        }
      });
  };

  const handleOnChange = (e) => {
    setFieldsData({ ...fieldsData, [e[0].name]: e[0].value });
    form.setFieldsValue({ ...fieldsData, [e[0].name]: e[0].value });
  };

  const [removing, setRemoving] = useState<boolean>(false);
  const handleOnDelete = () => {
    setAddNewGroup(false);
    setRemoving(true);
    restApiClient.deleteAdvancedConfiguration(data?.idAdvancedGroups).then((r: any)=> {
      reload();
    }).finally(()=>{
      setRemoving(false);
    });
  }

  const convert = async () => {
    try {
      currencyList.forEach(async (curr, i) => {
        if (i) {
          const conversion = {
            currencyFrom: currencyList[0],
            amount: fieldsData[currencyList[0]],
            currencyTo: curr
          };
          const currencyConverted = await restApiClient.getCurrencyConversion(conversion);
          setFieldsData((prevData) => ({ ...prevData, [curr]: currencyConverted }));
          form.setFieldsValue({ ...fieldsData, [curr]: currencyConverted });
        }
      });
    } catch (e) {
    }
  };

  useEffect(() => {
    restApiClient.getListCurrency().then((data) => setCurrencyList(data));
  }, []);

  const handleChangeActiveStatus = (isActiveNewStatus: boolean) => {
    setSavingData(true);
    restApiClient.changeAdvancedConfigurationStatus(data?.idAdvancedGroups, isActiveNewStatus).finally(()=>{setSavingData(false)});
  }


  return (
    <Skeleton paragraph={{ rows: 12 }} active loading={loading}>
      <div id="new-group" className="row">
        <div className="button-container box w-100">
          <div className="title-buttons d-flex justify-content-between">
            <TitleCustom level={2} class="title-group" text={data?.name} />
            <div className="d-flex buttons justify-content-end align-items-center">
              <SwitchCustomPro checked={isActive} onChange={(checked: any) => { handleChangeActiveStatus(checked); setIsActive((prev) => !prev);}} />
              <ButtonsCustom
                htmlType="button"
                className="btn-primary"
                type="primary"
                disabled={savingData}
                onClick={handleSave}
                content={<span>{ savingData ? <LoadingOutlined /> : ''} {t("group.save")}</span>}
              />
              <ButtonsCustom
                className="btn-secondary"
                htmlType="submit"
                onClick={handleOnDelete}
                disabled={removing}
                content={<span>{ removing ? <LoadingOutlined /> : ''} {t("group.delete")}</span>}
              />
            </div>
          </div>
          {
            showCoincidencesAlert ?
            <div className="d-flex">
              <div className="col selects-col">
                <Alert
                  className="warn-advanced-group-coincidences"
                  message={ReactHtmlParser(t("group.coincidences", { 0: groupCoincidenceName }))}

                  type="warning"
                />
              </div>
            </div>
            :''
          }
          <div className="d-flex">
            <div className="col selects-col">
              <NewGroupSelect
                title={t("group.configurations")}
                options={data?.advancedGroupsConfigurations}
                placeholder={t("group.room-placeholder")}
                setData={setDataConfigurations}
                data={dataConfigurations}
                onChange={handleCheckCoincidences}
              />
              <NewGroupSelect
                title={t("group.room")}
                options={data?.advancedGroupsRoomTypes}
                placeholder={t("group.room-placeholder")}
                setData={setDataRooms}
                data={dataRooms}
                onChange={handleCheckCoincidences}
              />
            </div>
            <div className="col selects-col">
              <NewGroupSelect
                title={t("group.channel")}
                options={data?.advancedGroupsTtoo}
                placeholder={t("group.channel-placeholder")}
                setData={setDataChannels}
                data={dataChannels}
                onChange={handleCheckCoincidences}
                
              />
              <NewGroupSelect
                title={t("group.regimen")}
                options={data?.advancedGroupsRegimens}
                placeholder={t("group.regimen-placeholder")}
                setData={setDataRegimens}
                data={dataRegimens}
                onChange={handleCheckCoincidences}
              />
              
              
            </div>
          </div>
          <Form
            className="d-flex flex-column"
            onFinish={handleSave}
            form={form}
            layout="vertical"
            id="edit-channel"
            onFieldsChange={handleOnChange}
            initialValues={fieldsData}
          >
            <TitleCustom level={3} class="title-group-section" text={t("group.increments")} />
            <ParagraphCustom text={t("group.explained")} />
            <Radio.Group onChange={(e) => setRadioValue(e.target.value)} value={radioValue} className="radios d-flex">
              <div className="d-flex flex-column">
                <div className="radio-title d-flex flex-row">
                  <RadioCustom value={1} />
                  <ParagraphCustom text={t("group.percent")} />
                </div>
                <Form.Item name="percent">
                  <Input
                    suffix="%"
                    onChange={(e) => setPercentValue(e.target.value)}
                    value={percentValue}
                    defaultValue={percentValue}
                    disabled={radioValue !== 1 ? true : false}
                  />
                </Form.Item>
              </div>
              <div className="d-flex flex-column">
                <div className="radio-title d-flex flex-row ">
                  <RadioCustom value={2} />
                  <ParagraphCustom text={t("group.fixed")} />
                </div>
                <div className="inputs d-flex">
                  {
                    <Form.Item
                      name={currencyList?.[0]}
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? ONLY_NUMBER_D.test(value)
                                ? NO_CHART_REPITED.test(value)
                                  ? NO_CHART_REPITED_AND_NUMBER_DECIMAL.test(value)
                                    ? Promise.resolve()
                                    : Promise.reject(t("reward:modal.validations.num-decimals"))
                                  : Promise.reject(t("reward:modal.validations.formated"))
                                : Promise.reject(t("reward:modal.validations.number"))
                              : Promise.resolve()
                        }
                      ]}
                    >
                      <InputNumber
                        prefix={currencyList?.[0]}
                        disabled={radioValue !== 2 ? true : false}
                        decimalSeparator={","}
                      />
                    </Form.Item>
                  }
                  <ButtonsCustom
                    content={"Convertir"}
                    className="btn-primary"
                    type="primary"
                    onClick={() => convert()}
                    disabled={radioValue !== 2 ? true : false}
                  />
                  {currencyList &&
                    currencyList.map((val, i) =>
                      i ? (
                        <Form.Item name={val} key={val}>
                          <InputNumber
                            prefix={val}
                            key={i}
                            disabled={radioValue !== 2 ? true : false}
                            decimalSeparator={","}
                          />
                        </Form.Item>
                      ) : (
                        ""
                      )
                    )}
                </div>
              </div>
            </Radio.Group>
          </Form>
        </div>
      </div>
    </Skeleton>
    
  );
};
