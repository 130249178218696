import { RestApiClientContext } from '../../../hooks/context/pageContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputNumber, Skeleton } from 'antd';
import { DrawerCustom, FormCustom, FormItemsContact, ButtonsCustom, InputCustom, ParagraphCustom, TextCustom, InputNumberCustom} from '../../../components/custom';
import { Form } from "antd";
import { LanguageManagement } from '../../../models/language/languageUserManagement';
import { LangContext } from '../../../hooks/context/lang';
import { Amount } from '../interfaces/configSelect';
import { numberFormat } from '../../../utils/formatAmount';
import { CurrencyConversion } from '../interfaces/directSalesConfigInterface';
import { treatmentDates } from '../../../utils/datePicker';
import { NO_CHART_REPITED, NO_CHART_REPITED_AND_NUMBER_DECIMAL, ONLY_NUMBER_D } from '../../../constants/regex';

import {LoadingOutlined} from '@ant-design/icons'


import moment from 'moment';
import { convertStringToNumber } from '../../../utils/convertStringToNumber';
import { DatepickerDistribution } from '../../utils/datepickerOwn/indexDatepicker';
import { EditNewSeasons } from '../interfaces/seasons';

export const EditSeasons = (props: EditNewSeasons) => {

    const {
        configurationSelect,
        periodSelected,
        setShowDrawerSeasons,
        updateViewAddOrEditSeason
    } = props;

    const userLangContext = useContext<LanguageManagement>(LangContext);
    const restApiClient = useContext(RestApiClientContext);

    const { t } = useTranslation(["rewardsPerNight"]);
    const [form] = Form.useForm();

    const [loading, setLoading] = useState<boolean>(true);
    const [inputName, setInputName] = useState<string>("");
    const [inputMinNightsRewards, setInputMinNightsRewards] = useState<number>(1);
    const [dateRange, setDateRange] = useState<any>([moment(null), moment(null)]);
    const [dataAmount, setDataAmount] = useState<Amount[]>(periodSelected.amounts);

    const convertCurrency = async (listAmounts: Amount[]) => {

        const arrayex: Amount[] = listAmounts;

        arrayex.map(async (e, i) => {
            if (i > 0) {
                const conversion: CurrencyConversion = {
                    currencyFrom: arrayex[0].currencyId,
                    amount: arrayex[0].value,
                    currencyTo: e.currencyId
                }

                await restApiClient.getCurrencyConversion(conversion)
                    .then((data) => {
                        e.value = data;
                        valores[i] = data;
                    })
                    .finally(() => {
                        setDataAmount([]);
                        setDataAmount(arrayex);
                    })
            }
        })
    }

    const handleChangeName = (e: any) => {
        setInputName(e.target.value);
    }

    const handleChangeMinNightsRewards = (v: any) => {
        try{
            if (Number.isNaN(v) || Number.parseInt(v) <= 0){
              setInputMinNightsRewards(1);
            }else{
              setInputMinNightsRewards(Number.parseInt(v));
            }
          }catch(exception){
            setInputMinNightsRewards(1);
      
          }
    }

    const handleChangeDate = (day: any[]) => {
        const arrayDate: any[] = dateRange;

        if (moment(day[0]).isValid() || moment(day[day.length-1]).isValid()) {
            day.map((d, i) => {
                const value= moment(d).isValid() ? d : dateRange[i];
                arrayDate[i]= value;
            })
            setDateRange(arrayDate);
        } else {
            setDateRange([]);
        }
    };

    const [savingData, setSavingData] = useState<boolean>(false);
    const setEditNewSeasons = () => {
        const datesConvertToString: any[] = [];

        dateRange.map((date) => (
            datesConvertToString.push(treatmentDates(date))
        ));

        setSavingData(true);
        restApiClient.EditSeason(configurationSelect, periodSelected.id, dataAmount, inputName, datesConvertToString, inputMinNightsRewards)
            .then((data) => {
                if (data) {
                    setShowDrawerSeasons(false);
                    updateViewAddOrEditSeason();
                }
            }).finally(()=>{
                setSavingData(false);
            })
    }

    const closeDrawerAddSeasons = () => {
        setShowDrawerSeasons(false);
    }

    const handleChangeFixedValue = (e: any, idCurrency: string, index: number) => {

        dataAmount.filter(cur => cur.currencyId === idCurrency)[0].value = convertStringToNumber(e.target.value);
        valores[index] = e.target.value;
    };

    useEffect(() => {
        setLoading(false);
        setDateRange([periodSelected.startDate,periodSelected.endDate]);
        setInputName(periodSelected.name);
        setInputMinNightsRewards(periodSelected.minNightsAllowed);
    },[]);

    const valores: any[] = [];

    return (
        <DrawerCustom
            title={t("rewardsPerNight:edit-period")}
            visible={true}
            info={""}
            width={360}
            placement="right"
            closable={true}
            onClose={closeDrawerAddSeasons}
            content={
                <>
                    {loading ? (
                        <Skeleton />
                    ) : (
                            <>
                                <FormCustom
                                    form={form}
                                    onFinish={setEditNewSeasons}
                                    layout="vertical"
                                >
                                    <FormItemsContact
                                        name="name-seasons"
                                        label={t("rewardsPerNight:name-season-label")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("rewardsPerNight:edit-add-configuration.error-form.required")
                                            },
                                            {
                                                whitespace: true,
                                                message: t("rewardsPerNight:edit-add-configuration.error-form.white-spaces")
                                            },
                                        ]}
                                        initialValue={inputName}
                                    >
                                        <InputCustom
                                            className="input-add-configuration main-input"
                                            id="name-seasons"
                                            onChange={handleChangeName}
                                            defaultValue={inputName}
                                        />
                                    </FormItemsContact>
                                    <FormItemsContact
                                        name="min-nights-allowed"
                                        label={t("rewardsPerNight:min-nights-rewards")}
                                        initialValue={inputMinNightsRewards}
                                    >
                                        <InputNumber 
                                        className="input-add-configuration main-input"
                                        id="min-nights-allowed"
                                        onChange={handleChangeMinNightsRewards}
                                        defaultValue={inputMinNightsRewards}
                                        min={1}
                                        />
                                    </FormItemsContact>
                                    <FormItemsContact
                                        name="date"
                                        label={t("rewardsPerNight:date-season-label")}
                                        rules={[
                                            {
                                                validator: (_, value) => (
                                                    (moment(dateRange[0]).isValid() && moment(dateRange[1]).isValid() )
                                                    ?
                                                        Promise.resolve()
                                                    :
                                                        Promise.reject(t("rewardsPerNight:edit-add-configuration.error-form.required"))
                                                    )
                                            }
                                        ]
                                        }
                                        initialValue={[moment(dateRange[0]), moment(dateRange[dateRange.length-1])]}

                                    >
                                        <DatepickerDistribution
                                            datesInitDefault={[moment(dateRange[0]),moment(dateRange[dateRange.length-1])]}
                                            handleChangeDate={handleChangeDate}
                                        />
                                    </FormItemsContact>
                                    <FormItemsContact
                                        name="currency"
                                        className="row-form-item"
                                    >
                                        <TextCustom
                                            text={t('rewardsPerNight:matter-fixed')}
                                        />
                                        {
                                            // Draw the amount imputs
                                            dataAmount.map((e, i) => {
                                                return (
                                                    <FormItemsContact
                                                        className="margin-b-inputs"
                                                        name={e.currencyId}
                                                        rules={
                                                            [
                                                                {
                                                                    validator: (_, value) => (value
                                                                        ?
                                                                        ONLY_NUMBER_D.test(value)
                                                                            ?
                                                                            NO_CHART_REPITED.test(value)
                                                                                ?
                                                                                NO_CHART_REPITED_AND_NUMBER_DECIMAL.test(value)
                                                                                    ?
                                                                                    Promise.resolve()
                                                                                    :
                                                                                    Promise.reject(t('reward:modal.validations.num-decimals'))
                                                                                :
                                                                                Promise.reject(t('reward:modal.validations.formated'))
                                                                            :
                                                                            Promise.reject(t('reward:modal.validations.number'))
                                                                        :
                                                                        Promise.resolve())
                                                                }
                                                            ]
                                                        }
                                                    >
                                                        <div key={i}>
                                                            <InputCustom
                                                                id={e.currencyId}
                                                                addonBefore={`${e.currencyId}`}
                                                                className="direct-sale-input main-input-currency"
                                                                placeholder="0.00"
                                                                defaultValue={numberFormat(e.value,userLangContext.lang)}
                                                                onChange={(event: Event) => { handleChangeFixedValue(event, e.currencyId, i); }}
                                                            />
                                                            {
                                                                i === 0 &&
                                                                <div onClick={() => convertCurrency(dataAmount)}>
                                                                    <ParagraphCustom
                                                                        class="convert-currency clicable"
                                                                        text={t('rewardsPerNight:convert-currency')}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    </FormItemsContact>
                                                );
                                            })
                                        }
                                        <ParagraphCustom
                                            class="direct-sale-description convert-currency fixed-amount"
                                            text={t('rewardsPerNight:direct-sale-description')}
                                        />
                                    </FormItemsContact>
                                    <FormItemsContact>
                                        <div className="direct-sale-btn-align">
                                            <ButtonsCustom
                                                disabled={savingData}
                                                htmlType="submit"
                                                class="primary-button submit-btn-direct-sale"
                                                content={<>{savingData ? <LoadingOutlined /> : <></>} {t('rewardsPerNight:saved')}</>}
                                            />
                                            <ButtonsCustom
                                                onClick={closeDrawerAddSeasons}
                                                class="default-button cancel-btn-direct-sale"
                                                content={t('rewardsPerNight:cancel')}
                                            />
                                        </div>
                                    </FormItemsContact>
                                </FormCustom>
                            </>
                        )}
                </>
            }
        />
    );
}