// Modelo para configuracion de la app como el logo, color destacado, etc..
export class ConfigManagement {
    // tslint:disable-next-line:no-empty
    constructor () {

    }

    public id: number;
    public brandName: string;
    public incentiveSiteName: string;
    public favicon: string;
    public colour: string;
    public logo: string;

    UpdateConfigContext = (value: ConfigManagement) => {

        this.id = value.id;
        this.brandName = value.brandName;
        this.incentiveSiteName = value.incentiveSiteName;
        this.favicon = value.favicon;
        this.colour = value.colour;
        this.logo = value.logo;
    }
}
