// Import all "x" end points
// Import all contact end points
import { ContactEP } from "./contactEP";
// Import all login end points
import { LoginEP } from "./loginEP";
// Import all reset pass end points
import { ResetPassEP } from "./resetPassEP";
// Import agentsRegister pass end points
import { AgentsRegisterEP } from "./agentsRegisterEP";
// Import all exchange end points
import { ExchangeEP } from "./exchangeEP";
// Import all notifications end points
import { NotificationsEP } from "./notificationsEP";
// Import all client data end points
import { ClientDataEP } from "./clientDataEP";
// Import all reservation data end points
import { RegularizationEP } from "./regularizationEP";
// Import all manage news data end points
import { NewsEP } from "./newsEP";
// Import all establishments data end points
import { EstablishmentEP } from "./establishmentEP";
// Import all rewards per night data end points
import { RewardsPerNightEP } from "./rewardsPerNightEP";
/// Import all Currencies data endpoint
import { CurrenciesEP } from "./currenciesEP";
/// Import all Rewards data endpoint
import { RewardsEP } from "./rewardsEP";
// Import all Languates data end points
import { LanguagesEP } from "./languagesEP";
// Import all Languates data end points
import { LocationsEP } from "./locationsEP";
// Import all Channels/TTOO data endpoint
import { ChannelsEP } from "./channelsEP";
// Import all RoomTypes  data endpoint
import { RoomsEP } from "./roomsEP";
// Import all Regimens  data endpoint
import { RegimensEP } from "./regimensEP";
// Import all postions  data endpoint
import { PositionsEP } from "./positionsEP";
// Import all customizations  data endpoint
import { CustomizationsEP } from "./customizationsEP";
// Import all AdvancedConfiguration  data endpoint
import { AdvancedConfigurationEP } from "./advancedConfiguration";
import { AdvancedConfigurationEP2 } from "./advancedConfigurationEP";
// Import all Regimens  data endpoint
import { AgenciesEP } from "./agenciesEP";
// Import all Targets data endpoint
import { TargetsEP } from './targetsEP'
// Import all KnowUs data endpoint
import { KnowUsEP } from './KnowUsEP'
// Import all LegalTexts data endpoint
import { LegalTextsEP } from './legalTextsEP'
// Import all Billing data endpoint
import { BillingEP } from './billingEP'


/// TODO Carga perezosa de los EndPoints??

export const RegisterEndPoints = []
  .concat(ContactEP)
  .concat(LoginEP)
  .concat(ResetPassEP)
  .concat(AgentsRegisterEP)
  .concat(NotificationsEP)
  .concat(ClientDataEP)
  .concat(ExchangeEP)
  .concat(RegularizationEP)
  .concat(NewsEP)
  .concat(EstablishmentEP)
  .concat(RewardsPerNightEP)
  .concat(CurrenciesEP)
  .concat(RewardsEP)
  .concat(LanguagesEP)
  .concat(ChannelsEP)
  .concat(LocationsEP)
  .concat(RoomsEP)
  .concat(RegimensEP)
  .concat(PositionsEP)
  .concat(CustomizationsEP)
  .concat(AdvancedConfigurationEP)
  .concat(AdvancedConfigurationEP2)
  .concat(AgenciesEP)
  .concat(TargetsEP)
  .concat(KnowUsEP)
  .concat(LegalTextsEP)
  .concat(BillingEP);

