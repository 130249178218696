import EndPointConfig  from '../../models/api/endPointConfig';

const controller = 'LegalTexts';

/// Define all EndPoints for login agents methods.
export const LegalTextsEP : EndPointConfig[] = [
    // Get datos para el token de usuario
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('GetLegalTextByType')
        .setAlias('GetLegalTextByType'),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setActionName('SaveLegalTexts')
        .setAlias('SaveLegalTexts'),
];
