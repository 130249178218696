// Modelo del usuario logado
export class User {
    public userName: string;
    public urlLanguage: string;
    public language: string;

    // tslint:disable-next-line:no-empty
    constructor() {}

    UpdateUserContext = (name: string, langUrl: string, lang: string) => {
        this.userName = name;
        this.urlLanguage = langUrl;
        this.language = lang;
    };

    GetNameUser = (): string => {
        return this.userName;
    };

    GetLanguage = () : string => {
        return this.language;
    }

    GetLanguageURL = (): string => {
        return this.urlLanguage;
    };
}
