import React, { useEffect } from "react";
import "./layout.sass";
import { LINKS_LAYOUT_PRIVATE } from "../../../../constants/constants";
import {
    faHome,
    faUsersCog,
    faCog,
    faNewspaper,
    faCoins,
    faQuestionCircle,
    faCalendarAlt,
    faPercent,
    faChartLine,
    faLayerGroup,
    faFileInvoiceDollar
} from "@fortawesome/free-solid-svg-icons";
import RoutesContent from "../../../../routes/private/routesContent";
import { cleanUp } from "../../../../utils/cleanUp";
import { Wallet } from "../../../wallet/wallet";
import { LayoutCustom } from "../../../custom";
import { AlternateComponent } from "../../../utils/alternateComponent/alternateComponent";

// Layout menu lateral izquierdo zona privada
export const LayoutBasic = (param) => {
    // Libreria i18n para traducir
    const icons = [
        faHome,
        faUsersCog,
        faCog,
        faChartLine,
        faLayerGroup,
        faCalendarAlt,
        faPercent,
        faCoins,
        faNewspaper,
        faFileInvoiceDollar,
        faCog,
        faQuestionCircle,
    ];
    let url = window.location.pathname + window.location.hash;

    if (url.substring(url.length, url.length - 1) === "/") {
        url = url.substring(0, url.length - 1);
    }

    useEffect(() => {
        cleanUp();
    });

    // La versión tablet de Wallet es la misma que para Mobile
    const tabletVersion = () => {
        return mobileVersion();
    };

    // Alojamos el componente wallet para la versión móvil
    const mobileVersion = () => {
        return <Wallet />;
    };

    return (
        <LayoutCustom
            id="sider"
            trigger={null}
            collapsible={true}
            collapsed={param.collapsedState}
            collapsedWidth={60}
            width={250}
            class="site-layout"
            modeMenu="inline"
            defaultSelectedKeysMenu={[url]}
            classMenu="column-menu-items"
            listMenu={LINKS_LAYOUT_PRIVATE}
            iconsMenu={icons}
            content={
                <div className="container margin-bottom-8">
                    <div className="row content-wallet-layout d-lg-none">
                        <AlternateComponent
                            tabletVersion={tabletVersion()}
                            mobileVersionLarge={mobileVersion()}
                        />
                    </div>
                    <RoutesContent />
                </div>
            }
        />
    );
};
