import * as React from "react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuLanguages } from "../../../../languageSelector/comboLanguage";
import { Link } from "react-router-dom";
import { ConfigContext } from "../../../../../hooks/context/config";
import "../header.sass";
import "./desktopMenu.sass";
import {
    faQuestionCircle,
    faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { LinkActiveContext } from "../../../../../hooks/context/linkActive";
import { FontIconsCustom } from "../../../../custom";

// Menú visible para versiones escritorio (de MD para arriba)
export const DesktopMenu = (params) => {
    // Libreria i18n para traducir
    const { t } = useTranslation(["urls", "linksNames"]);
    const linkActive = useContext(LinkActiveContext);

    // Hook Context de la configuracion
    const theme = useContext(ConfigContext);

    // Estado para mostrar o ocultar modal cookie
    const [modalLogin, setmodalLogin] = useState(false);

    return (
        <>
            <div className="col-12 box-header">
                <div className="col-6 left-part-header padding-left-header">
                    <div className="col-12 p-0 links-row">
                        <div className="align-center-links">
                            <Link
                                id="link-public-main-logo"
                                to={t("urls:public.home")}
                                onClick={() =>
                                    linkActive.updateValueUrl(
                                        t("urls:public.home")
                                    )
                                }
                                disabled={
                                    window.location.pathname +
                                        window.location.hash ===
                                    t("urls:public.home")
                                }
                            >
                                <img
                                    className="logo"
                                    src={
                                        theme.logo
                                    }
                                    title={theme.brandName}
                                    alt={theme.brandName}
                                />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-6 right-part-header padding-right-header">
                    <div className="col-12 p-0 links-row margin-top-icons">
                        <div className="align-center-links float-link">
                            <div className="padding-link-desktop-public link-phone">
                                <Link
                                    id="link-public-contact"
                                    className={
                                        window.location.pathname ===
                                        t("urls:public.contact")
                                            ? "secondary-links active"
                                            : "secondary-links"
                                    }
                                    to={t("urls:public.contact")}
                                    onClick={() =>
                                        linkActive.updateValueUrl(
                                            t("urls:public.contact")
                                        )
                                    }
                                    disabled={
                                        window.location.pathname +
                                            window.location.hash ===
                                        t("urls:public.contact")
                                    }
                                >
                                    <FontIconsCustom
                                        classIcon="icons-color-menu icons-color-marging"
                                        icon={faPhoneAlt}
                                    />
                                </Link>
                            </div>
                            <div className="padding-link-desktop-public">
                                <Link
                                    id="link-faqs"
                                    className={
                                        window.location.pathname ===
                                        t("urls:public.faqs")
                                            ? "secondary-links active"
                                            : "secondary-links"
                                    }
                                    to={t("urls:public.faqs")}
                                    onClick={() =>
                                        linkActive.updateValueUrl(
                                            t("urls:public.faqs")
                                        )
                                    }
                                    disabled={
                                        window.location.pathname +
                                            window.location.hash ===
                                        t("urls:public.faqs")
                                    }
                                >
                                    <FontIconsCustom
                                        classIcon="icons-color-menu icons-color-marging"
                                        icon={faQuestionCircle}
                                    />
                                </Link>
                            </div>
                            <div className="padding-top-flag">
                                <MenuLanguages key="1" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="aux-div-header"></div>
        </>
    );
};
