import React, { useState, useContext } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import './resetPass.sass';
import * as AjaxAPI from '../../../../api/ajaxApi';
import { showConfirm, showError, showWarning } from '../../../../utils/modalsGenerics';
import { LinkActiveContext } from '../../../../hooks/context/linkActive';
import { UserContext } from '../../../../hooks/context/userInfo';
import { FormCustom, FormItemsContact, InputCustom, ButtonsCustom } from '../../../custom';
import { ParamsApi } from "../../../../models/api/paramsApi";
import { LangContext } from '../../../../hooks/context/lang';

// Componente de formulario de reiniciar contraseña
export const ResetPass = (params) => {

    // Contexto del usuario
    const userContext = useContext(UserContext);

    // Contexto del enlace activo
    const linkActive = useContext(LinkActiveContext);

    // Contexto con el idioma del usuario
    const language = useContext(LangContext);
    const userLang = language.GetLangContext();

    // Idioma para los textos
    const { t } = useTranslation(['resetPass', 'contact', 'urls']);

    // Estado del envío de los datos del formulario
    const [sendForm, setSendForm] = useState(false);

    // Hook del historial
    const history = useHistory();

    // Envío de petición de reiniciar contraseña
    const sendFormResetPass = (values) => {

        const param: ParamsApi = {
            body: {
                language: userLang,
                val: values
            }
        }

        AjaxAPI.doAction('sendFormResetPass', param)
            .then(v => {
                if (v.data.default.formResetPass.send) {
                    setSendForm(v.data.default.formResetPass.send);
                    showConfirm(v.data.default.formResetPass.title, v.data.default.formResetPass.message, t('resetPass:form.buttons.accept'));

                    linkActive.updateValueUrl(t("urls:public.home"));
                    history.push(t("urls:public.home"));
                } else {
                    setSendForm(v.data.default.formResetPass.send);

                    switch(v.data.default.formResetPass.state) {
                        case 1:
                        case 4:
                            showWarning(v.data.default.formResetPass.title, v.data.default.formResetPass.message, t('resetPass:form.buttons.accept'));
                            break;
                        case 3:
                            showError(v.data.default.formResetPass.title, v.data.default.formResetPass.message, t('resetPass:form.buttons.accept'));
                            break;
                    }
                }

                params.setVisibleFormPassword(false);
            })
            // tslint:disable-next-line:no-empty
            .catch(e => {});
    }

    // Submit boton
    const onFinish = async(values) => {
        // TODO : Quitar el true para que los datos no vengan mockeados.
        AjaxAPI.init(t);
        sendFormResetPass(values);
    };

    // Boton cancelar
    const HandleCancel = () => {
        params.setVisibleFormPassword(false);
    }

    return (
        <FormCustom
            name="basic"
            initialValues={ { remember: true } }
            onFinish={onFinish}
        >
                <FormItemsContact
                    label={ t('form.email') }
                    className="row-form-item"
                    name="mail"
                    rules={
                        [
                            {
                                required: true,
                                message: t('form.errors.email')
                            },
                            {
                                type : "email",
                                message: t('form.errors.invalid-mail')
                            },
                            {
                                max: 50,
                                message: t('form.errors.max-number')
                            }
                        ]
                    }
                >
                    <InputCustom
                        className="main-input"
                        id="mail-reset"
                    />
                </FormItemsContact>
                <FormItemsContact>
                    <Row>
                        <Col
                            span={ 24 }
                            className="align-text-buttons"
                        >
                            <ButtonsCustom
                                id="btn-send-reset-password"
                                type="primary"
                                htmlType="submit"
                                className="col-4 col-sm-3 primary-button"
                                content={ t('form.buttons.send') }
                            />
                            <ButtonsCustom
                                id="btn-cancel-reset-password"
                                onClick={ HandleCancel }
                                className="col-4 col-sm-3 default-button-transparent margin-right-button"
                                content={ t('form.buttons.cancel') }
                            />
                        </Col>
                    </Row>
                </FormItemsContact>
        </FormCustom>
    );
}

