import EndPointConfig from '../../models/api/endPointConfig';

const controller: string = 'Establishments';

/// Define all EndPoints for Reset Password contact.
export const EstablishmentEP: EndPointConfig[] = [
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetEstablishments'),

    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setActionName('filtered')
        .setAlias('GetEstablishmentsFiltered'),

    new EndPointConfig()
        .setMethod('PUT')
        .setActionName('active')
        .setController(controller)
        .setAlias('UpdateActiveEstablishment'),

    new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setPattern("{id}")
        .setAlias("GetEstablishmentById"),

    new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setAlias("CreateEstablishment"),

    new EndPointConfig()
        .setMethod("PUT")
        .setController(controller)
        .setAlias("UpdateEstablishment"),

    new EndPointConfig()
        .setMethod("DELETE")
        .setController(controller)
        .setPattern("{id}")
        .setAlias("DeleteEstablishment")
];