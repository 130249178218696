import { CURRENT_PAGE, PAGE_SIZE } from '../../constants/constants';

// Interfaz de paginación
export interface Pagination {
    page: number,
    resultPage: number
}

// Asociación de valores por defecto de los atributos de la paginación
export const DefaultPagination: Pagination = {
    page: CURRENT_PAGE,
    resultPage: PAGE_SIZE
}
