import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Skeleton, Tabs } from "antd";
import {
  FormCustom,
  ButtonsCustom,
  InputCustom,
  SwitchCustomPro,
  InputNumberCustom,
  CheckBoxCustom
} from "src/components/custom";

import {
  LoadingOutlined
} from '@ant-design/icons';


import { RestApiClientContext } from "src/hooks/context/pageContext";

import "./editEstablishment.sass";
import FormItem from "antd/es/form/FormItem";
import { Helmet } from "react-helmet-async";

interface EditEstablishmentProps {
  id: string;
  setVisible: (state: boolean) => void;
  reload: () => void;
  data?: any;
  isLoadingData: boolean;
}

interface DataEstablishment {
  identityCode: string;
  category: number;
  idCategoryType: number;
  name: string;
  roomsNumber: number;
  isActive: boolean;
  id?: string;
  
  regimens: any[];
  roomTypes: any[];
}

const { TabPane } = Tabs;

export const EditEstablishment = (props: EditEstablishmentProps) => {
  const { t } = useTranslation(["config"]);
  const { id, setVisible, reload } = props;
  const [filter, setFilter] = useState(0);
  const restApiClient = useContext(RestApiClientContext);
  const [form] = Form.useForm();

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [savingData, setSavingData] = useState<boolean>(false);

  const [fieldsData, setFieldsData] = useState<DataEstablishment>();

  useEffect(()=>{
    setLoadingData(props.isLoadingData);
  },[props.isLoadingData])

  useEffect(() => {
    if (props.data) {
      setFieldsData(props.data);   
    }else{
      form.resetFields();
    }
  }, [props.data]);

  useEffect(() => {
    if (fieldsData) {
      form.setFieldsValue({
        name: fieldsData?.name,
        identityCode: fieldsData?.identityCode,
        category: fieldsData?.category,
        idCategoryType: fieldsData?.idCategoryType,
        roomsNumber: fieldsData?.roomsNumber,
        isActive: fieldsData?.isActive
      } as DataEstablishment);
    }
  }, [fieldsData]);

  const handleOnChange = (e) => {
    setFieldsData({ ...fieldsData, [e[0].name]: e[0].value });
  };
  const handleRegimens = (e) => {
    const newRegimens = fieldsData?.regimens.map((r) => {
      if (r.idRegimens === e.target.id) {
        return { ...r, selected: !r.selected };
      }
      return r;
    });
    setFieldsData({ ...fieldsData, regimens: newRegimens });
  };

  const handleRooms = (e) => {
    const newRooms = fieldsData?.roomTypes.map((r) => {
      if (r.idRoomTypes === e.target.id) {
        return { ...r, selected: !r.selected };
      }
      return r;
    });
    setFieldsData({ ...fieldsData, roomTypes: newRooms });
  };

  const handleSave = async () => {
      const body: DataEstablishment = {
        name: form.getFieldValue("name"),
        identityCode: form.getFieldValue("identityCode"),
        category: +form.getFieldValue("category"),
        idCategoryType: +form.getFieldValue("idCategoryType") ?? 1,
        roomsNumber: +form.getFieldValue("roomsNumber"),
        isActive: form.getFieldValue("isActive"),
        regimens: fieldsData?.regimens,
        roomTypes: fieldsData?.roomTypes
      };
      if (id) {
        setSavingData(true);
        restApiClient.updateEstablishment(id, { id: id, ...body }).then((r: any)=> { reload(); }).finally(()=>{setVisible(false);setSavingData(false);});
      }
  };

  const listOptions = [1, 2, 3, 4, 5];
  const listImages = [
    { value: 1, icon: "\uf005" },
    { value: 2, icon: "\uf084" }
  ];
  return (
    <Skeleton active loading={props.isLoadingData}>
          <Helmet>
            <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.6.1/css/font-awesome.min.css" rel="stylesheet" />
          </Helmet>
          <FormCustom
            onFinish={handleSave}
            form={form}
            layout="vertical"
            id="edit-establishment"
            onFieldsChange={handleOnChange}
          >
            <Form.Item
              className="col form-item"
              label={t("establishments.drawer-name")}
              name="name"
              rules={[
                {
                  whitespace: true,
                  message: t("validations.name"),
                  required: true
                }
              ]}
            >
              <InputCustom placeholder={t("establishments.drawer-placeholder")} />
            </Form.Item>
            <Form.Item
              className="col form-item"
              label={t("establishments.drawer-code")}
              name="identityCode"
              rules={[
                {
                  whitespace: true,
                  message: t("validations.code")
                }
              ]}
            >
              <InputCustom placeholder={t("establishments.drawer-placeholder")} />
            </Form.Item>
            <Form.Item className="col-md-6 form-item" label={t("establishments.drawer-rooms")} name="roomsNumber">
              <InputNumberCustom placeholder={t("establishments.drawer-placeholder")} />
            </Form.Item>
            <Form.Item name="isActive" valuePropName="checked" label={t("channels.drawer-isActive")}>
              <SwitchCustomPro
                checked
                checkedChildren={t("channels.drawer-yes")}
                unCheckedChildren={t("channels.drawer-no")}
                onChange={() => setFieldsData({ ...fieldsData, isActive: !fieldsData?.isActive })}
              />
            </Form.Item>
            <span>{t("establishments.drawer-category")}</span>
            <div className="category-group d-flex">
              <FormItem
                className="col"
                name="category"
                rules={[
                  {
                    required: true,
                    message: t("validations.category")
                  }
                ]}
              >
                <div className="select_box">
                  <select className="category" defaultValue={fieldsData?.category}>
                    {listOptions.map((opt, i) => (
                      <option value={opt} key={i}>
                        {opt}
                      </option>
                    ))}
                  </select>
                </div>
              </FormItem>
              <Form.Item
                className="col"
                name="idCategoryType"
                rules={[
                  {
                    required: true,
                    message: t("validations.category")
                  }
                ]}
              >
                <div className="select_box">
                  <select className="category-type category" defaultValue={fieldsData?.idCategoryType}>
                    {listImages.map((opt, i) => (
                      <option value={opt.value} key={i}>
                        {opt.icon}
                      </option>
                    ))}
                  </select>
                </div>
              </Form.Item>
            </div>
            <Tabs
              className="form-item"
              defaultActiveKey="0"
              onChange={(newFilter) => {
                setFilter(parseInt(newFilter));
              }}
            >
              <TabPane key={0} tab={<div className="tab-header">{t("establishments.tabs.roomTypes")}</div>}>
                {fieldsData?.roomTypes?.map((r, i) => (
                  <label className="row" key={i}>
                    <CheckBoxCustom
                      id={r.idRoomTypes}
                      checked={r.selected || false}
                      content={r.name}
                      onChange={handleRooms}
                    />
                  </label>
                ))}
              </TabPane>
              <TabPane key={1} tab={<div className="tab-header">{t("establishments.tabs.regimens")}</div>}>
                {fieldsData?.regimens?.map((r, i) => {
                  return (
                    <label className="row" key={i}>
                      <CheckBoxCustom
                        id={r.idRegimens}
                        checked={r.selected || false}
                        content={r.name}
                        onChange={handleRegimens}
                      />
                    </label>
                  );
                })}
              </TabPane>
            </Tabs>
            <Form.Item className="form-item">
              <div className="d-flex buttons justify-content-end">
                <ButtonsCustom
                  className="btn-secondary"
                  htmlType="button"
                  onClick={() => setVisible(false)}
                  content={<span>{t("establishments.drawer-cancel")}</span>}
                />
                <ButtonsCustom
                  disabled={savingData}
                  className="btn-primary"
                  type="primary"
                  htmlType="submit"
                  content={<span>{savingData ? <LoadingOutlined /> : ''} {t("establishments.drawer-save")}</span>}
                />
              </div>
            </Form.Item>
          </FormCustom>
        </Skeleton>
  );
};
