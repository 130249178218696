import React from 'react';
import { Dropdown } from "antd";
import './dropdowns.sass';

export const DropdownCustomComponent = (props) => {
    return (
        <Dropdown
            {...props}
        >
            {props.children}
        </Dropdown>
    );
}