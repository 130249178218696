import EndPointConfig from "../../models/api/endPointConfig";

const controller = "Targets";

/// Define all EndPoints for Reset Password contact.
export const TargetsEP: EndPointConfig[] = [
    new EndPointConfig()
      .setMethod('POST')
      .setController(controller)
      .setActionName('filtered')
      .setAlias('GetTargetsFiltered'),
    new EndPointConfig()
      .setMethod('POST')
      .setController(controller)
      .setAlias('PostTarget'),
    new EndPointConfig()
      .setMethod('PATCH')
      .setController(controller)
      .setPattern("{id}/status/{isActive}")
      .setAlias('ChangeTargetStatus'),
    new EndPointConfig()
      .setMethod('DELETE')
      .setController(controller)
      .setPattern("{id}")
      .setAlias('DeleteTarget'),
    new EndPointConfig()
      .setMethod('PATCH')
      .setController(controller)
      .setAlias('UpdateTarget')
];
