import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Skeleton } from "antd";
import { FormCustom, ButtonsCustom, InputCustom, SwitchCustomPro } from "src/components/custom";
import { RestApiClientContext } from "src/hooks/context/pageContext";

import "./editRegimen.sass";
import { AddItemsByLanguage } from "../../addItemsByLanguage/addItemsByLanguage";

interface EditRegimenProps {
  id: string;
  data: any;
  isLoading: boolean;
  drawerChoices: any;
  setDrawerChoices: (state) => void;
  setVisible: (state: boolean) => void;
  reload: () => void;
}
interface DataRegimen {
  identityCode?: string;
  isDeleted?: boolean;
  regimenTranslations?: { idLanguage: string; name: string }[];
  id?: string;
  comment?: string;
}

const defaultData: DataRegimen = {
  identityCode: "",
  comment: "",
  regimenTranslations: []
};

export const EditRegimen = (props: EditRegimenProps) => {
  const { t } = useTranslation(["config"]);
  const { id, setVisible, reload, drawerChoices, setDrawerChoices } = props;
  const [fieldsData, setFieldsData] = useState(defaultData ?? {});
  const [isDisabled, setIsDisabled] = useState(false);
  const restApiClient = useContext(RestApiClientContext);
  const [form] = Form.useForm();

  const [loadingData, setLoadingData] = useState<boolean>(false);

  useEffect(()=>{
    setLoadingData(props.isLoading ?? false);
  },[props.isLoading])

  const resetForm = () : void => {
    form.resetFields();
    setFieldsData(defaultData);
}

  useEffect(()=>{
    if (props && props.data){
      setFieldsData(props.data)
    }else{
      resetForm();
    }
  },[props.data])


  useEffect(() => {
    if (fieldsData) {
      form.setFieldsValue({
        identityCode: fieldsData?.identityCode,
        regimenTranslations: fieldsData?.regimenTranslations,
        comment: fieldsData?.comment
      } as DataRegimen);
    }else{
      resetForm();
    }
  }, [fieldsData]);

  const handleOnChange = (e) => {
    setFieldsData({ ...fieldsData, [e[0].name]: e[0].value });
  };

  const handleSave = async () => {
    try {
      if (id) {
        const body: DataRegimen = {
          id: id,
          identityCode: form.getFieldValue("identityCode"),
          comment: form.getFieldValue("comment"),
          regimenTranslations: Object.keys(drawerChoices).map((lang) => ({
            idLanguage: lang,
            name: drawerChoices[lang]?.value
          }))
        };
        await restApiClient.updateRegimen(id, body);
      } else {
        const body: DataRegimen = {
          identityCode: form.getFieldValue("identityCode"),
          comment: form.getFieldValue("comment"),
          regimenTranslations: Object.keys(drawerChoices).map((lang) => ({
            idLanguage: lang,
            name: drawerChoices[lang]?.value
          }))
        };
        body;
        await restApiClient.createRegimen(body);
      }
    } finally {
      setVisible(false);
      reload();
    }
  };

  const handleCancel = () : void => {
    resetForm();
    setVisible(false);
  }

  return (
    <Skeleton active loading={loadingData}>
      <FormCustom onFinish={handleSave} form={form} layout="vertical" id="edit-channel" onFieldsChange={handleOnChange}>
          <AddItemsByLanguage
            title={t("regimens.drawer-title")}
            setData={setDrawerChoices}
            data={drawerChoices}
            defaultData={fieldsData?.regimenTranslations}
          />
          <Form.Item
            className="col form-item"
            label={t("regimens.drawer-code")}
            name="identityCode"
            rules={[
              {
                whitespace: true,
                message: t("validations.code")
              }
            ]}
          >
            <InputCustom placeholder={t("regimens.drawer-placeholder")} />
          </Form.Item>
          <Form.Item
            className="col form-item"
            label={t("regimens.drawer-comment")}
            name="comment"
            rules={[
              {
                whitespace: true,
                message: t("validations.code")
              }
            ]}
          >
            <InputCustom placeholder={t("regimens.drawer-placeholder")} />
          </Form.Item>
          <Form.Item className="form-item">
            <div className="d-flex buttons justify-content-end">
              <ButtonsCustom
                className="btn-secondary"
                htmlType="button"
                onClick={handleCancel}
                content={<span>{t("regimens.drawer-cancel")}</span>}
              />
              <ButtonsCustom
                disabled={isDisabled}
                className="btn-primary"
                type="primary"
                htmlType="submit"
                content={<span>{t("regimens.drawer-save")}</span>}
              />
            </div>
          </Form.Item>
        </FormCustom>
    </Skeleton>
    
  );
};
