import { FontIconsCustom, TableCustom, TextCustom } from '../../../../components/custom';
import React, { useState } from 'react';
import { ConfigData, Periods } from '../../../../components/rewardsPerNight/interfaces/configSelect';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { FormattedNumber } from 'react-intl';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import './tableInfo.sass'
import { EditSeasons } from '../../../../components/rewardsPerNight/editSeasons/editSeasons';
import { DataListTableConfig } from '../../../../components/rewardsPerNight/interfaces/seasons';

export const TableRewardsPerNight = (props: DataListTableConfig) => {

    const {
        dataConfiguration,
        updateViewAddOrEditSeason
    } = props;

    const { t } = useTranslation(["rewardsPerNight", "translation"]);

    const [periodSelected, setPeriodSelected] = useState<Periods>();
    const [showDrawerEditPeriod, setShowDrawerEditPeriod] = useState<boolean>(false);

    const openEditPeriod= (data: Periods) => {
        setPeriodSelected(data);
        setShowDrawerEditPeriod(true);
    }

    const columns = [
		{
			title: t("rewardsPerNight:titles.name"),
			dataIndex: "name",
            key: "name",
        },
        {
			title: t("rewardsPerNight:titles.dates"),
			dataIndex: "startDate",
            key: "startDate",
            render: (text, row: Periods, index) =>
                (

                    <TextCustom
                        class="reservation-text-date"
                        text={
                            moment(row.startDate).format('D') + " " + t("rewardsPerNight:graphic.month-list-large." + (moment(row.startDate).month() + 1))
                            + " - " +
                            moment(row.endDate).format('D') + " " + t("rewardsPerNight:graphic.month-list-large." + (moment(row.endDate).month() + 1))
                            }
                    />

                ),
        },
        {
			title: t("rewardsPerNight:titles.min-nights"),
			dataIndex: "minNightsAllowed",
            key: "minNightsAllowed",
            render: (text, row: Periods, index) =>
                (

                    <TextCustom
                        class="reservation-text-date"
                        text={t("rewardsPerNight:min-nights-resume-days", {0:row.minNightsAllowed})}
                    />

                ),
        },
        {
			title: t("rewardsPerNight:titles.reward-per-night"),
			dataIndex: "idCurrency",
            key: "idCurrency",
            render: (text, row: Periods, index) =>
                (
                    <>
                        {
                            row.amounts.map((amount, index) => {
                                return(
                                    <>
                                        {index !== 0 && (" - ")}
                                        <TextCustom
                                            class="reservation-text-date"
                                            text={
                                                <FormattedNumber
                                                    value={ amount.value }
                                                    style="currency"
                                                    currency={ amount.currencyId }
                                                />
                                            }
                                        />
                                    </>
                                )
                            })
                        }
                        <FontIconsCustom
                            classIcon="align-menu icons-edit"
                            icon={faEdit}
                            onClick={() => openEditPeriod(row)}
                        />
                    </>
                ),
        },
    ]
    return (
        <>
            <TableCustom
                className="table-bo"
                columns={columns}
                dataSource={dataConfiguration.periods}
                pagination={false}
                bordered
            />
            {showDrawerEditPeriod && (
                <EditSeasons
                    configurationSelect={dataConfiguration.id}
                    periodSelected={periodSelected}
                    setShowDrawerSeasons={setShowDrawerEditPeriod}
                    updateViewAddOrEditSeason={updateViewAddOrEditSeason}
                />
            )}
        </>
    );
}
