import EndPointConfig from '../../models/api/endPointConfig';

const controller = 'Agencies';

/// Define all EndPoints for agencies section.
export const AgenciesEP : EndPointConfig[] = [
    // Envio de formulario a la API

        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('filtered')
        .setAlias('GetAgenciesFiltered')
];