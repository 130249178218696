import React, { useContext, useEffect, useState, useTransition } from "react";

// https://ckeditor.com/docs/ckeditor5/latest/api/index.html
import { Editor } from '@tinymce/tinymce-react'

import './ManageLegalTexts.sass'
import { Button, Divider, Select, Skeleton, Typography  } from "antd";
import Language from "src/models/language/languagesManagement";
import { RestApiClientContext } from "src/hooks/context/pageContext";
import { LegalText } from "src/models/legalTexts/LegalText";

import { LoadingOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next";


const { Title } = Typography;


interface ManageLegalTextsProps {}


export const ManageLegalTexts = (props : ManageLegalTextsProps) : JSX.Element  => {
    const { t } = useTranslation("manageLegalTexts");
    
    const restApiClient = useContext(RestApiClientContext);

    const [languages, setLanguages] = useState<Language[]>([]);

    const getLanguages = () : void => {
        restApiClient.getAgentLanguages()
            .then((r)=>{
                if (r){
                    setLanguages(r);
                    showLegalText("PC");
                }
            });
    }


    const [actualLegalText, setActualLegalText] = useState<any>();
    const showLegalText = (v) : void => {
        setActualLegalText(v);
    }

    useEffect(()=>{
        getLanguages();
    },[])

    return(
        <div className="manage-legal-texts">
            <Title level={5}>
                {t("select-legal-text-type")}
            </Title>
            <Select style={{width: "100%"}} onChange={(v => {showLegalText(v)})} defaultValue={"PC"}>
                <Select.Option key="PC">{t("legal-text-types.particular-conditions")}</Select.Option>
                <Select.Option key="BF">{t("legal-text-types.booking-form")}</Select.Option>
            </Select>

            {
                actualLegalText ? <LegalTextForm legalTextType={actualLegalText} availableLanguages={languages}/> : <></>
            }


        </div>
    )
}

interface LegalTextFormProps{
    legalTextType: string,
    availableLanguages: Language[]
}
export const LegalTextForm = (props: LegalTextFormProps) : JSX.Element => {
    const { t } = useTranslation("manageLegalTexts");

    const restApiClient = useContext(RestApiClientContext);

    const { availableLanguages, legalTextType } = props;

    const [selectedLanguage, setSelectedLanguage] = useState<string>();
    const [selectedLegalText, setSelectedLegalText] = useState<LegalText>();

    const [legalTexts, setLegalTexts] = useState<LegalText[]>()

    const [loadingLegalText, setLoadingLegalText] = useState<boolean>(false);
    const getLegalText = () => {

        setLoadingLegalText(true);
        restApiClient.getLegalTextByType(legalTextType)
            .then((r)=>{
                if (r){
                    setLegalTexts(r);
                }
            })
            .finally(()=>{
                setLoadingLegalText(false);
            });
    }

    const [savingLegalText, setSavingLegalText] = useState<boolean>(false)
    const saveLegalText = () : void => {

        setSavingLegalText(true);
        restApiClient.saveLegalTexts(legalTexts)
            .finally(()=>{
                setSavingLegalText(false);
            });
    }

    const onChangeLanguage = (v: any) => {
        if (v.length > 0){
            setSelectedLanguage(v);

            var legalText = legalTexts.filter(lt => lt.idLanguage == v)[0];

            if (legalText){
                setSelectedLegalText(legalTexts.filter(lt => lt.idLanguage == v)[0]);
            }else{
                var newLegalText : LegalText = {
                    content: t('write-here', [availableLanguages.find(al => al.id == v)?.name]),
                    id: '',
                    idLanguage: v,
                    idLegalTextType:legalTextType
                };

                if(legalTexts.findIndex(lt => lt.idLanguage == v) == -1){
                    var actualLegalText = legalTexts;
                    actualLegalText.push(newLegalText);
                    setLegalTexts(actualLegalText);
                }

                setSelectedLegalText(newLegalText);
            }

        }
    }

    useEffect(()=>{
        if (legalTexts && legalTexts.length > 0){
            setSelectedLanguage(legalTexts[0].idLanguage);
            setSelectedLegalText(legalTexts[0]);
        }else{
            setSelectedLanguage(availableLanguages[0].id);
            setSelectedLegalText(undefined);
        }
    },[legalTexts])

    useEffect(()=>{
        getLegalText();
    },[legalTextType])

    return (
        <div className="legal-text-form">
            <Skeleton loading={loadingLegalText} active>
                <Divider />
                <Title level={5} className="select-language-title">
                    {t('select-language')}
                </Title>

                <Select className="language-selector" style={{width: "100%"}} defaultValue={selectedLanguage} onChange={onChangeLanguage}>
                    {
                        availableLanguages?.map((language: Language, index: number)=>{
                            return <Select.Option key={language.id}>{language.name}</Select.Option>
                        })
                    }
                </Select>

                {
                    selectedLanguage
                    ?
                        <Editor
                            apiKey="l85gt2wufrhxg3vn2nhvz3nwru0at7o39z36y0ub3ylbz37q"
                            initialValue={selectedLegalText?.content}
                            init={
                                {
                                    language: selectedLanguage,
                                    height: 500,
                                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                }
                            }
                            onChange={ (value) => {  
                                if (value && value.target){
                                    if (selectedLegalText){
                                        var data = selectedLegalText;
                                        data.content = value?.target?.getContent();
                                        setSelectedLegalText(data);
                                    }
                                    
                                }
                            }}
                        />
                    :
                    <>
                        {t("first-select-language")}
                    </>
                }
            </Skeleton>

            <div className="btn-actions">
                <Button onClick={getLegalText} danger disabled={savingLegalText}>
                    {t("buttons.reload")}
                </Button>
                <Button type="primary" onClick={saveLegalText} disabled={savingLegalText}> 
                    { savingLegalText ? <LoadingOutlined /> : <></>} 
                    {t("buttons.save")}
                </Button>
            </div>
            
        </div>
    )
}
