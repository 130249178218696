import React from 'react';
import './modalConfirm.sass';
import { ModalCustomPro } from '../../../components/custom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DataDelete } from '../interfaces/configSelect';

export const ModalConfirmationsActions = (props: DataDelete) => {

    const {
        buttonOk,
        buttonCancel,
        title,
        description,
        functionModal,
        setShowModalConfirmAction
    } = props

    return (
        <ModalCustomPro
            visible={true}
            onOk={functionModal}
            onCancel={setShowModalConfirmAction}
            closable={false}
            cancelText={buttonCancel}
            okText={buttonOk}
        >
            <div className="col-12 display-box-modal">
                <div className="col-1">
                    <ExclamationCircleOutlined
                        className="color-exclamation"
                    />
                </div>
                <div className="col-11">
                    <div>
                        <strong>{title}</strong>
                    </div>
                    <div>{description}</div>
                </div>
            </div>
        </ModalCustomPro>
    );
}
