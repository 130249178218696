import * as React from "react";
import { Avatar } from "antd";

export const AvatarCustomComponent = (param) => {
    return (
        <Avatar
            className={param.classAvatar}
            src={param.avatar}
        />
    );
};
