import React from 'react';
import './lists.sass';
import { List, Avatar } from "antd";

// Componente custom de List
export const ListCustomProComponent = (props) => {
	return (
		<List
			{...props}
		>
			{props.children}
		</List>
	);
};

// Componente custom de List.Item
export const ListItemCustomProComponent = (props) => {
	return (
		<List.Item
			{...props}
		>
			{props.children}
		</List.Item>
	);
};

// Componente custom de List.Item.Meta
export const ListItemMetaCustomProComponent = (props) => {
	return (
		<List.Item.Meta
			{...props}
		/>
	);
};

export const AvatarListMetaCustomComponent = (props) => {
	return(
		<Avatar
			{...props}
		/>
	)
}
