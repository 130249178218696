import React, { useContext, useEffect, useState } from 'react';
import './clientNumber.sass';
import { useTranslation } from "react-i18next";
import { ParagraphCustom } from '../../../../custom';
import { Divider } from 'antd';
import { User } from '../../../../../models/user/userModel';
import { RestApiClientContext } from "../../../../../hooks/context/pageContext";

// Componente Base de la parte publica de nuestra aplicación
export const ClientNumber = (param) => {
    const restApiClient = useContext(RestApiClientContext);

    const { t } = useTranslation();

    const [contractNumber, setcContractNumber] = useState<string>("");

    useEffect(() => {
        restApiClient.getContractNumber()
            .then((data) => {
                setcContractNumber(data)
            })
    }, [])

    return (
        <div className={"content-info-client"}>
            <Divider className="separator-client" />
            <ParagraphCustom
                class="label-client"
                text={t("translation:client-number")}
            />
            <ParagraphCustom
                class="label-number-client"
                text={contractNumber}
            />
        </div>
    );
}
