import i18n from 'i18next';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LANGUAGE, LANGUAGE_EN_US } from './constants/constants';

i18n
  .use(backend)
  .use(initReactI18next)
  .init({
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,
    whitelist: [DEFAULT_LANGUAGE, LANGUAGE_EN_US],
    backend: {
      loadPath: `${window.location.origin}/public/locales/{{lng}}/{{ns}}.json`
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
