import React, { useContext, useEffect, useState } from "react";
import "./editRoom.sass";
import { useTranslation } from "react-i18next";
import { AddItemsByLanguage } from "../../addItemsByLanguage/addItemsByLanguage";
import { ButtonsCustom, FormCustom, InputCustom } from "src/components/custom";
import { RestApiClientContext } from "src/hooks/context/pageContext";
import { Form, Skeleton } from "antd";

interface EditRoomProps {
  roomData: any;
  drawerChoices: any;
  setDrawerChoices: (state) => void;
  reload: () => void;
  setVisible?: (param: boolean) => void;
  searchingRoomData: boolean;
}
interface DataRooms {
  identityCode: string;
  isDeleted?: boolean;
  roomTypesTranslations: { idLanguage: string; name: string }[];
  id?: string;
  comments: string;
}

const defaultData: DataRooms = {
  identityCode: "",
  comments: "",
  roomTypesTranslations: []
};

export const EditRoom = (props: EditRoomProps) => {
  const { t } = useTranslation(["config"]);
  const { setDrawerChoices, drawerChoices, roomData, setVisible, reload } = props;
  const [fieldsData, setFieldsData] = useState(defaultData);
  const [isDisabled, setIsDisabled] = useState(false);
  const restApiClient = useContext(RestApiClientContext);
  const [form] = Form.useForm();


    
  const resetForm = () : void => {
      form.resetFields();
      setFieldsData(defaultData);
      setDrawerChoices([]);
  }

  useEffect(() => {
    if (roomData) {
      setFieldsData(roomData);
    }else{
      resetForm();
    }
  }, [roomData]);

  useEffect(() => {
    if (fieldsData) {
      fieldsData?.roomTypesTranslations.forEach((trans, i) => {});
      form.setFieldsValue({
        identityCode: fieldsData?.identityCode,
        roomTypesTranslations: fieldsData?.roomTypesTranslations,
        comments: fieldsData?.comments
      } as DataRooms);
    }
  }, [fieldsData]);

  const handleOnChange = (e) => {
    //setFieldsData({ ...fieldsData, [e[0].name]: e[0].value });
  };

  const handleSave = async () => {
    try {
      if (roomData) {
        const body: DataRooms = {
          id: roomData.id,
          identityCode: form.getFieldValue("identityCode"),
          comments: form.getFieldValue("comments"),
          roomTypesTranslations: Object.keys(drawerChoices).map((lang) => ({
            idLanguage: lang,
            name: drawerChoices[lang]?.value
          }))
        };
        await restApiClient.updateRoomType(roomData.id, body).then((r:any)=>{
          resetForm();
        });
      } else {
        const body: any = {
          identityCode: form.getFieldValue("identityCode"),
          comments: form.getFieldValue("comments"),
          roomTypesTranslations: Object.keys(drawerChoices).map((lang) => ({
            idLanguage: lang,
            name: drawerChoices[lang]?.value
          }))
        };

        await restApiClient.createRoomType(body).then((r:any)=>{
          resetForm();
        });
      }
    } finally {
      setVisible(false);
      reload();
    }
  };

  const handleCancel = () : void => {
    resetForm();
    setVisible(false);
  }

  return (
    <Skeleton active loading={props.searchingRoomData}>
      <FormCustom onFinish={handleSave} form={form} layout="vertical" id="edit-channel" onFieldsChange={handleOnChange}>
      <AddItemsByLanguage
        title={t("rooms.drawer-title")}
        setData={setDrawerChoices}
        data={drawerChoices}
        defaultData={fieldsData?.roomTypesTranslations}
      />
      <Form.Item
        className="col form-item"
        label={t("rooms.drawer-code")}
        name="identityCode"
        rules={[
          {
            whitespace: true,
            message: t("validations.code")
          }
        ]}
      >
        <InputCustom placeholder={t("rooms.drawer-placeholder")} />
      </Form.Item>
      <Form.Item
        className="col form-item"
        label={t("rooms.drawer-comment")}
        name="comments"
        rules={[
          {
            whitespace: true,
            message: t("validations.code")
          }
        ]}
      >
        <InputCustom placeholder={t("rooms.drawer-placeholder")} />
      </Form.Item>

      <Form.Item className="form-item">
        <div className="d-flex buttons justify-content-end">
          <ButtonsCustom
            className="btn-secondary"
            htmlType="button"
            onClick={handleCancel}
            content={<span>{t("rooms.drawer-cancel")}</span>}
            type={"primary"}
          />
          <ButtonsCustom
            disabled={isDisabled}
            className="btn-primary"
            type="primary"
            htmlType="submit"
            content={<span>{t("rooms.drawer-save")}</span>}
          />
        </div>
      </Form.Item>
    </FormCustom>
    </Skeleton>
  );
};
