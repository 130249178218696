import React, { useContext, useEffect, useState } from "react";
import "./addRetribution.sass";
import { useTranslation } from "react-i18next";
import { RestApiClientContext } from "src/hooks/context/pageContext";
import { ConfigContext } from "antd/lib/config-provider";
import { Form } from "antd";
import { ButtonsCustom, FormCustom, InputCustom } from "src/components/custom";
import { LoadingOutlined } from "@ant-design/icons";

interface AddRetributionProps {
  setVisible: (state: boolean) => void;
  setNewGroup: (state: boolean) => void;
  reload: () => void;
}

interface RetributionData {
  idAdvancedGroups?: string;
  name: string;
  idSalesType?: string;
  isActive?: boolean;
  order?: number;
  advancedGroupsRegimens?: any[];
  advancedGroupsRoomTypes?: any[];
  advancedGroupsTtoo?: any[];
  advancedGroupsAmount?: any[];
}

export const AddRetribution = (props: AddRetributionProps) => {
  const { t } = useTranslation(["advancedretributiontranslations"]);
  const {  setVisible, setNewGroup, reload } = props;
  const restApiClient = useContext(RestApiClientContext);
  const [groupName, setGroupName] = useState<string>('');
  const [form] = Form.useForm();


  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  }

  const [savingData, setSavingData] = useState<boolean>(false); 
  const handleSave = async () => {
    setSavingData(true);
    restApiClient.createAdvancedConfiguration(groupName).then((r)=>{
      if (r && r !== false){
       // setNewGroup(true); 
        form.resetFields();
        setGroupName('');
        setVisible(false);
        reload();
      }
    }).finally(()=>{
      setSavingData(false);
    })
  };

  useEffect(()=>{
    form.resetFields();
  },[props])

  return (
    <FormCustom
      form={form}
      layout="vertical"
      id="add-retribution"
      onFinish={handleSave}
    >
      <Form.Item name="groupName" className="form-item" rules={[
          {
            required: true,
          },
        ]}>
        <InputCustom onChange={(e) => setGroupName(e.target.value)} required/>
      </Form.Item>
      <Form.Item className="form-item">
        <div className="d-flex buttons justify-content-end">
          <ButtonsCustom
            disabled={savingData}
            className="btn-secondary"
            onClick={handleCancel}
            content={<span>{t("drawer.cancel")}</span>}
          />
          <ButtonsCustom
            className="btn-primary"
            type="primary"
            disabled={savingData}
            htmlType="submit"
            content={<span>{savingData ? <LoadingOutlined /> : ''} {t("drawer.save")}</span>}
          />
        </div>
      </Form.Item>
    </FormCustom>
  );
};
