import { FontIconsCustom, ParagraphCustom } from '../../../components/custom';
import React from 'react';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { TableRewardsPerNight } from './tableInfo/tableInfo';
import { ListRewardsPerNight } from './listInfo/listInfo';
import { AlternateComponent } from '../../../components/utils/alternateComponent/alternateComponent';
import { DataListTableConfig } from '../interfaces/seasons';
import { useTranslation } from 'react-i18next';

export const TableAndListShowInfo = (props: DataListTableConfig) => {

    const {
        dataConfiguration,
        updateViewAddOrEditSeason
    } = props;

    const { t } = useTranslation(["rewardsPerNight"]);

    const desktopVersion = () => {
        return (
            <TableRewardsPerNight
                updateViewAddOrEditSeason={updateViewAddOrEditSeason}
                dataConfiguration={dataConfiguration}
            />
        )
    }

    const tabletVersion = () => {
        return desktopVersion();
    }

    const mobileVersion = () => {
        return (
            <ListRewardsPerNight
                updateViewAddOrEditSeason={updateViewAddOrEditSeason}
                dataConfiguration={dataConfiguration}
            />
        );
    }

    return (
        <>
            <div className="col-12 p-0 icons-section">
                <div className="width-icons-configuration-reward">
                    <FontIconsCustom
                        className=""
                        icon={faList}
                    />
                </div>
                <div className="ml-2">
                    <ParagraphCustom
                        class="margin-b"
                        text={t("rewardsPerNight:list-table-section.title")}
                    />
                </div>
            </div>
            <ParagraphCustom
                text={t("rewardsPerNight:list-table-section.description")}
            />
            <div className="col-12 p-0 mt-5">
                <AlternateComponent
                    desktopVersionLarge={desktopVersion()}
                    tabletVersion={tabletVersion()}
                    mobileVersionLarge={mobileVersion()}
                />
            </div>
        </>
    );
}