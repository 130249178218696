import EndPointConfig, { MockData } from '../../models/api/endPointConfig';

const controller = 'Users';


/// Define all EndPoints for login agents methods.
export const LoginEP : EndPointConfig[] = [
    // Get datos para el token de usuario
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setActionName('login')
        .setAlias('UserLogin'),

    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('name')
        .setAlias('GetUserName'),

    new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller)
        .setActionName('login')
        .setAlias('UserLogout'),

        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('language')
        .setAlias('GetUserLanguage'),

        
];
