import { Divider, Timeline } from "antd";
import React from 'react'

const GetInfo = (roomsNumbers, howItWasCalculated, t, currency) : JSX.Element => {

    return(
        <>
            <ul style={{listStyle:"none", padding: 0}}>
                {
                    howItWasCalculated?.Limit > 0 ? <li>{t('howItWasCalculated:limited')}: <strong>{howItWasCalculated.Limit} {currency} x {roomsNumbers.length} { roomsNumbers.length > 1 ? t('howItWasCalculated:rooms') : t('howItWasCalculated:room')} = {howItWasCalculated.Limit * roomsNumbers.length} {currency}</strong></li>	: ''
                }
                {
                    howItWasCalculated?.Targets?.length > 0 ? howItWasCalculated.Targets?.map(target => <li>Target: {target.Value}</li>)	: ''
                }
            </ul>
                    
            {
                roomsNumbers.map(roomNumber => {
                    return <>
                                <Divider />
                                <ul style={{listStyle:"none", padding: 0}}>
                                    <li>{t('howItWasCalculated:room-number')}: {roomNumber}</li>	
                                    <li>{t('howItWasCalculated:room-type')}: {howItWasCalculated?.ResumeByDay?.filter(rby => rby.RoomNumber == roomNumber)[0].RoomName}</li>	
                                    <li>{t('howItWasCalculated:regime-name')}: {howItWasCalculated?.ResumeByDay?.filter(rby => rby.RoomNumber == roomNumber)[0].RegimeName}</li>	
                                </ul>
                                <Timeline>
                                    {
                                        howItWasCalculated?.ResumeByDay?.filter(rby => rby.RoomNumber == roomNumber).map(resumeByDay=>{
                                            return <Timeline.Item>
                                                        {resumeByDay.Day}
                                                        <ul>
                                                            <li>{t('howItWasCalculated:group-name')}: {resumeByDay.GroupName} </li>	
                                                            <li>{t('howItWasCalculated:period-name')}: {resumeByDay.PeriodName}</li>	
                                                            <li>{t('howItWasCalculated:amount')}: <strong>{resumeByDay.Amount} {currency}</strong></li>	
                                                            <li>{t('howItWasCalculated:advanced-group-name')}: {resumeByDay.AdvancedGroup} </li>	
                                                            <li>{t('howItWasCalculated:increment')}: <strong>{resumeByDay.Increment} {currency}</strong></li>	
                                                        </ul> 
                                                    </Timeline.Item>
                                        })
                                    }
                                </Timeline>
                        </>
                })
            }
        </>
    )
}

export default GetInfo;