import EndPointConfig from '../../models/api/endPointConfig';

const controller = 'agentsmanagement'

/// Define all EndPoints for Reset Password contact.
export const AgentsRegisterEP: EndPointConfig[] = [
    // Obtiene el listado de agentes con los parametros de busquda que se apliquen
    new EndPointConfig()
        .setMethod("DELETE")
        .setController(controller)
        .setPattern("{id}")
        .setActionName('RemoveAgentData')
        .setAlias("RemoveAgentData"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('filtered')
        .setAlias('GetAgentsFiltered'),
     new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('ResendEmailConfirmation')
        .setAlias('ResendEmailConfirmation'),

    // // Obtiene los estados disponibles en la app
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('filteredstatuses')
        .setAlias('GetAgentsFilteredStatus'),

    // // Cambia el estado del agente
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller)
        .setPattern('{id}/status')
        .setAlias('ChangeStatusAgent'),

    // // Guarda el comentario del hotelero hacia el agente
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller)
        .setPattern('{id}/comments')
        .setAlias('UpdateAgentComments'),

    // // Recoge los datos del detalle del agente
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setPattern('{id}')
        .setAlias('GetAgent'),

    // // Recoge los datos del detalle del agente
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('exportexcel')
        .setAlias('GetAllAgentsForExcel'),

    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('exportexcelrewards')
        .setAlias('GetAllAgentsRewards'),
        
];
