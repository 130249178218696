import * as React from "react";
import { Typography } from "antd";

const { Text, Paragraph, Title } = Typography;

export const TitleCustomComponent = (param) => {
	return (
		<Title
            className={param.class}
            copyable={param.copyable}
            disabled={param.disabled}
            ellipsis={param.ellipsis}
            level={param.level}
            type={param.type}
        >
            { param.text }
        </Title>
    );
};

export const TextCustomComponent = (param) => {
    return (
        <Text
            className={param.class}
            copyable={param.copyable}
            disabled={param.disabled}
            ellipsis={param.ellipsis}
            strong={param.strong}
            type={param.type}
        >
            {param.text}
        </Text>
    );
};

export const ParagraphCustomComponent = (param) => {
	return (
		<Paragraph
            className={param.class}
            copyable={param.copyable}
            disabled={param.disabled}
            ellipsis={param.ellipsis}
            strong={param.strong}
            type={param.type}
        >
            {param.text}
        </Paragraph>
    );
};
