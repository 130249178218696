import React, { useEffect } from 'react';
import { Pagination } from 'antd';
import './pagination.sass';
import '../../../i18n';
import { useTranslation } from 'react-i18next';
import { PAGE_SIZE } from '../../../constants/constants';

// Componente de paginación adaptado a las necesidades de la aplicación
export const CustomPagination = (param) => {
    const { t } = useTranslation(['translation']);

    // Función que almacena los valores relacionados con la paginación (pagína actual y nº elementos por página)
    const onChange = (current, pageSize) => {
        const pa = {
            page: current ?? 1,
            resultPage: pageSize ?? 10
        }

        param.onChangeCallApi(pa);
    }

    // Control del literal "página" en el cambiador de elementos por página
    const literalPage = {
        items_per_page: t('translation:page')
    };

    return (
        <Pagination
            current={ param?.pageResults?.page ?? 1}
            total={ param.countData }
            onChange={ onChange }
            onShowSizeChange={ onChange }
            defaultPageSize={ param.pageResults.resultPage }
            pageSizeOptions={ [PAGE_SIZE.toString(), '20', '50', '100'] }
            showSizeChanger={ param.countData > PAGE_SIZE }
            locale={ literalPage }
        />
    );
}
