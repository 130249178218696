import React, { useContext, useState, useEffect } from 'react';
import { Link, useHistory} from 'react-router-dom';
import { Skeleton } from 'antd';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import './detail-news.sass';
import ReactHtmlParser from 'react-html-parser';
import { AllLanguages, LangContext } from "../../../hooks/context/lang";
import { getLocaleDatePicker } from '../../../utils/datePicker';
import {
    ParagraphCustom,
    TitleCustom,
    SelectCustom,
    AvatarListMetaCustom,
    FontIconsCustom
} from "../../custom";
import { DetailDataNews, NewsContents } from '../searchNews/interfaceSearchNews';
import { SelectLanguage } from '../interfaceNews';
import { LanguageManagement } from "../../../models/language/languageUserManagement";
import { LinkActiveContext } from "../../../hooks/context/linkActive";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { RestApiClientContext } from "../../../hooks/context/pageContext";

export const DetailNews = (param) => {

    const { t } = useTranslation(['urls', 'news', 'linksNames']);

    const restApiClient = useContext(RestApiClientContext);
    const language = useContext<LanguageManagement>(LangContext);
    const linkActive = useContext(LinkActiveContext);
    const allLang = useContext(AllLanguages);

    const local: PickerLocale = getLocaleDatePicker();

    const [detailDataNews, setDetailDataNews] = useState<DetailDataNews>();
    const [textDataNews, setTextDataNews] = useState<NewsContents>();
    const [listLanguages, setListLanguages] = useState([]);
    const [selectLanguageValue, setSelectLanguageValue] = useState(null);
    const [lng, setLng] = useState<string>(language.GetLangContext());
    const [loadingSkeleton, setLoadingSkeleton] = useState<boolean>(true);

    const optionsState = [];

    const history = useHistory();

    let optionsInitial: SelectLanguage;

    const handleChangeLanguage = (e) => {
        setTextDataNews(detailDataNews.contents.filter(a => a.idLanguage === JSON.parse(e).id).pop());
        setSelectLanguageValue(JSON.parse(e));
    }

    useEffect(() => {

        if (language.GetLangContext() !== lng) {
            setLng(language.GetLangContext());
        }

        allLang.GetLanguage().map((e, i) => {
            optionsState.push({
                id: e.id,
                name: e.name,
                imageName: e.imageName
            });
        });

        restApiClient.getNews(param.match.params.id)
            .then((data) => {

                setDetailDataNews(data);

                const usedLanguages = optionsState.filter((el) => {
                    return data.contents.some((f) => {
                        return f.idLanguage === el.id;
                    });
                });

                setListLanguages(usedLanguages);

                if (usedLanguages.length > 1) {
                    optionsInitial = {
                        id: optionsState.filter(a => a.id === lng).map(a => a.id).pop(),
                        name: optionsState.filter(a => a.id === lng).map(a => a.name).pop(),
                        imageName: optionsState.filter(a => a.id === lng).map(a => a.imageName).pop(),
                    };

                    setSelectLanguageValue(optionsInitial);

                    setTextDataNews(data.contents.filter(a => a.idLanguage === optionsInitial.id).pop());
                } else {
                    optionsInitial = {
                        id: usedLanguages.map(a => a.id).pop(),
                        name: usedLanguages.map(a => a.name).pop(),
                        imageName: usedLanguages.map(a => a.imageName).pop(),
                    };

                    setSelectLanguageValue(optionsInitial);

                    setTextDataNews(data.contents.pop());
                }

                setLoadingSkeleton(false);
            })
            .catch((reason : any) => {
                history.push(t("urls:private.news"))
            });
    }, []);

    return (
        <>
            {
                loadingSkeleton ? (
                    <div className="container margin-bottom-8 d-flex justify-content-center align-items-center cont-skeleton">
                        <Skeleton
                            paragraph={{ rows: 5 }}
                            active
                        />
                    </div>
                ) : (
                    <div className="mt-5 news-container special-mb">
                        <div className="pl-0 col-12 link-back">
                            <Link
                                id={2}
                                className="link-back-list"
                                to={t("urls:private.news")}
                                onClick={() =>
                                    linkActive.updateValueUrl(
                                        t("urls:private.news")
                                    )
                                }
                            >
                                <FontIconsCustom
                                    classIcon="marked-angle-icon"
                                    icon={faAngleLeft}
                                />
                                <span className="text-back-list">{t("news:back-to-list")}</span>
                            </Link>
                        </div>
                        <div className="mt-3 row">
                            <div className="col-12 container-select-language">
                                <ParagraphCustom
                                    class="label-language"
                                    text={t("news:language")}
                                />
                                <SelectCustom
                                    id="language-selector"
                                    className="col-12 main-input"
                                    options={listLanguages}
                                    values={selectLanguageValue}
                                    onChange={handleChangeLanguage}
                                    disabled={listLanguages.length === 1}
                                />
                            </div>
                            <div className="col-12 box-news-details">
                                <TitleCustom
                                    level={2}
                                    class="title-detail-news"
                                    text={textDataNews.title}
                                />
                            </div>
                            {detailDataNews.urlImage &&
                                <div className="col-12 box-img-news-details">
                                    <AvatarListMetaCustom
                                        shape="square"
                                        className={"avatar-detail-news"}
                                        src={detailDataNews.urlImage ?? ""}
                                        title={"img-" + textDataNews.title}
                                    />
                                </div>
                            }
                            <div className="col-12 box-news-details">
                                <ParagraphCustom
                                    class="date-detail-news"
                                    text={
                                        detailDataNews.startDate && moment(detailDataNews.startDate).format(local.dateFormat)
                                    }
                                />
                                <ParagraphCustom
                                    class="text-detail-news"
                                    text={ReactHtmlParser(textDataNews.description)}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
}
