import * as React from "react"
import { Input, Select, Checkbox, DatePicker, InputNumber, Divider } from "antd"
import { FontIconsCustom } from "../index"
import "./inputs.sass"

const { Option } = Select
const { RangePicker } = DatePicker

export const InputCustomComponent = (param) => {
  return (
    <Input
      key={param.key}
      id={param.id}
      value={param.value}
      placeholder={param.placeholder}
      className={param.className}
      prefix={param.prefix && <FontIconsCustom className={param.classIcon} icon={param.icon} />}
      suffix={param.suffix}
      allowClear={param.allowClear}
      autoFocus={param.autoFocus}
      onChange={param.onChange}
      disabled={param.disabled}
      addonBefore={param.addonBefore}
      defaultValue={param.defaultValue}
    />
  )
}

export const InputPasswordCustomComponent = (param) => {
  return <Input.Password key={param.key} id={param.id} className={param.className} onChange={param.onChange} />
}

export const SelectCustomComponent = (param) => {
  return (
    <Select
      key={param.key}
      defaultValue={JSON.stringify(param.values)}
      value={param.values !== "" ? JSON.stringify(param.values) : ""}
      onChange={param.onChange}
      className={param.className}
      placeholder={param.placeholder}
      disabled={param.disabled}
    >
      {param.options.map((element, index) => {
        return (
          <Option key={index} value={JSON.stringify(element)}>
            {element.imageName ? <img className="selector-language-flag" src={element.imageName} /> : ""} {element.name}
          </Option>
        )
      })}
    </Select>
  )
}

export const SelectCustomProComponent = (props) => {
  return <Select {...props}>{props.children}</Select>
}

export const OptionsCustomComponent = (props) => {
  return <Option {...props}>{props.children}</Option>
}

export const TextAreaCustomProComponent = (props) => {
  return <Input.TextArea {...props} />
}

export const CheckCustomComponent = (param) => {
  return (
    <Checkbox id={param.id} key={param.key} className={param.className} onChange={param.onChange}>
      {param.content}
    </Checkbox>
  )
}

export const DatePickerCustomComponent = (props) => {
  return <DatePicker {...props} />
}

export const RangePickerCustomComponent = (props) => {
  return <RangePicker {...props} />
}

export const InputNumberCustomComponent = (props) => {
  return <InputNumber {...props} />
}
