import React, { useState, useContext, useEffect } from "react";
import * as AjaxAPI from "../../../api/ajaxApi";
import "./cookies-policy.sass";
import { Helmet } from "react-helmet-async";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../hooks/context/config";
import ReactHtmlParser from "react-html-parser";
import {
	Columns,
	Data1,
	Data2,
	Data3,
	Data4,
	Data5,
	Data6,
} from "./cookiesPolicyCellsValues";
import scrollLib from "react-scroll";
import { URL_BASE } from "../../../constants/constants";
import { showNotification } from "../../../utils/notificationsGeneric";
import { ClientDataModel } from "../../../models/config/clientData";
import {
	TitleCustom,
	ParagraphCustom,
	TableCustom,
} from "../../custom";
import { useLegalTexts } from "../legalTexts";

const scroll = scrollLib.animateScroll;

// Componente de información de Política de Cookies de la app
export const CookiesPolicy = () => {
	const infoClient = useContext(ConfigContext);
	const { t } = useTranslation([
		"cookiesPolicy",
		"seo",
		"urls",
		"translation",
	]);

	const clientData = useLegalTexts(t)

	// Hook para saber si hemos pulsado en uno de los enlaces con ancla
	useEffect(() => {

		scroll.scrollToTop();

	}, []);


	return (
		<>
			<Helmet>
				<title>
					{infoClient.incentiveSiteName} |{" "}
					{t("seo:title.cookies-policy")}
				</title>
				<meta
					name="description"
					content={t("seo:description.cookies-policy", {
						0: infoClient.incentiveSiteName,
					})}
				/>
				<meta name="robots" content="noindex,nofollow" />
				<link
					rel="canonical"
					href={URL_BASE + t("urls:public.cookies-policy")}
				/>
				<link
					rel="icon"
					type="image/ico"
					href={infoClient.favicon}
					sizes="32x32"
				/>
				<link
					href={URL_BASE + t("urls:public.cookies-policy")}
					hrefLang="es-ES"
					rel="alternate"
				/>
			</Helmet>

			<div className="container mt-3 special-mb">
				<TitleCustom
					level={4}
					class="main-title-color"
					text={t("cookiesPolicy:main-title")}
				/>

				{ReactHtmlParser(t("cookiesPolicy:section.1.title"))}
				{ReactHtmlParser(t("cookiesPolicy:section.1.paragraph"))}

				{ReactHtmlParser(t("cookiesPolicy:section.2.title"))}
				{ReactHtmlParser(t("cookiesPolicy:section.2.paragraph.1"))}
				{ReactHtmlParser(t("cookiesPolicy:section.2.paragraph.2"))}
				{ReactHtmlParser(t("cookiesPolicy:section.2.paragraph.3"))}

				{ReactHtmlParser(t("cookiesPolicy:section.3.title"))}
				{ReactHtmlParser(t("cookiesPolicy:section.3.paragraph"))}

				{ReactHtmlParser(t("cookiesPolicy:section.4.title"))}
				{ReactHtmlParser(t("cookiesPolicy:section.4.paragraph"))}

				{ReactHtmlParser(t("cookiesPolicy:section.5.title"))}
				{ReactHtmlParser(t("cookiesPolicy:section.5.paragraph"))}

				<ParagraphCustom
					class="title-color"
					text={
						<>
							{ReactHtmlParser(
								t("cookiesPolicy:section.6.title.1")
							)}
							<TableCustom
								className="table-bo"
								columns={Columns()}
								dataSource={Data1()}
								pagination={false}
								scroll={{ x: 929 }}
								bordered
							/>
						</>
					}
				/>
				<ParagraphCustom
					class="title-color"
					text={
						<>
							{ReactHtmlParser(
								t("cookiesPolicy:section.6.title.2")
							)}
							<TableCustom
								className="table-bo"
								columns={Columns()}
								dataSource={Data2()}
								pagination={false}
								scroll={{ x: 929 }}
								bordered
							/>
						</>
					}
				/>
				<ParagraphCustom
					class="title-color"
					text={
						<>
							{ReactHtmlParser(
								t("cookiesPolicy:section.6.title.3")
							)}
							<TableCustom
								className="table-bo"
								columns={Columns()}
								dataSource={Data3()}
								pagination={false}
								scroll={{ x: 929 }}
								bordered
							/>
						</>
					}
				/>
				{ReactHtmlParser(t("cookiesPolicy:section.6.paragraph.1"))}
				{ReactHtmlParser(t("cookiesPolicy:section.6.paragraph.2"))}
				<ParagraphCustom
					class="title-color"
					text={
						<>
							{ReactHtmlParser(
								t("cookiesPolicy:section.6.title.4")
							)}
							<TableCustom
								className="table-bo"
								columns={Columns()}
								dataSource={Data4()}
								pagination={false}
								scroll={{ x: 929 }}
								bordered
							/>
						</>
					}
				/>
				<ParagraphCustom
					class="title-color"
					text={
						<>
							{ReactHtmlParser(
								t("cookiesPolicy:section.6.title.5")
							)}
							<TableCustom
								className="table-bo"
								columns={Columns()}
								dataSource={Data5()}
								pagination={false}
								scroll={{ x: 929 }}
								bordered
							/>
						</>
					}
				/>

				<ParagraphCustom
					class="title-color"
					text={
						<>
							{ReactHtmlParser(
								t("cookiesPolicy:section.6.title.6")
							)}
							<TableCustom
								className="table-bo"
								columns={Columns()}
								dataSource={Data6()}
								pagination={false}
								scroll={{ x: 929 }}
								bordered
							/>
						</>
					}
				/>
				{ReactHtmlParser(t("cookiesPolicy:section.6.paragraph.3"))}

				{ReactHtmlParser(t("cookiesPolicy:section.7.title"))}
				{ReactHtmlParser(
					t("cookiesPolicy:section.7.paragraph", {
						0: clientData.socialName,
						1: clientData.incentiveSiteName,
						2: clientData.emailContact,
					})
				)}

				<ParagraphCustom
					class="mb-5 title-color"
					text={
						<>
							{ReactHtmlParser(
								t("cookiesPolicy:section.8.title")
							)}
							{ReactHtmlParser(
								t("cookiesPolicy:section.8.paragraph", {
									0: clientData.ownURLPrivacyPolicy,
								})
							)}
						</>
					}
				/>
			</div>
		</>
	);
};

