import { Button, Form, Select, Skeleton, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { RestApiClientContext } from 'src/hooks/context/pageContext';
import { TargetDto } from 'src/models/targets/TargetModels';

import * as Icons from '@ant-design/icons'

import './CloneConfigurationComponent.sass'
import { LoginContext } from 'src/hooks/context/userInfo';

const { Option } = Select;

interface CloneConfigurationComponentProps{
    configurationData: any,
    onCancel: () => void,
    onFinish: (targetId: number) => void
}

const CloneConfigurationComponent = (props: CloneConfigurationComponentProps) : JSX.Element => {
    const { t } = useTranslation(["cloneConfiguration"]);
    const loginContext = useContext(LoginContext);
  
    const restApiClient = useContext(RestApiClientContext);
    const [form] = Form.useForm();

    const {configurationData} = props;

    const [targets, setTargets] = useState<TargetDto[]>([]);
    const [loadingTargets, setLoadingTargets] = useState<boolean>(false);
    const getTargets = () => {
  
      var params = {
        description: '', 
        page: 0, 
        resultPage: 99999, 
        isActive: 1, 
        entityIds: []
      };
  
      setLoadingTargets(true);
      restApiClient.getTargets(params).then((result: any)=>{
        setTargets(result);
      }).finally(()=>{
        setLoadingTargets(false);
      })
    }

    const [cloningData, setCloningData] = useState<boolean>(false);
    const onFinish = () : void => {
        var params = {
            configurationId: configurationData.id,
            targetToId: form.getFieldValue("targetId")
        };
      

        setCloningData(true);
        restApiClient.cloneConfiguration(params).then(r =>{
            props.onFinish(form.getFieldValue("targetId"));
        }).finally(()=>{
            setCloningData(false);
        })
    }

    const onCancel = () : void => {
        props.onCancel();
    }

    useEffect(()=>{
        getTargets();
    },[])

    return(
        <div className='clone-configuration-component'>
            <Typography.Title level={5}>
                {
                    t('clone-title', [configurationData.name])
                }
            </Typography.Title>
            <Skeleton active loading={loadingTargets}>
                <Form onFinish={onFinish} form={form} layout="vertical">
                    <Form.Item name="targetId" className="form-item" label={t('target-destination')} rules={[{
                            required: true,
                            message:t('field-required')
                        },
                        ]}>
                        <Select style={{width: "100%"}}>
                            {
                                targets.map((t: TargetDto, i: number) => <Option key={i} value={t.id}>{t.description}</Option>)
                            }
                        </Select>
                    </Form.Item>
                    
                    <div className="action-buttons">
                        <Button danger onClick={onCancel}>
                            {t('cancel')}
                        </Button>

                        <Button htmlType='submit' type='primary' className='btn-primary'>
                            {
                                cloningData ? <Icons.LoadingOutlined style={{marginRight: "5px"}} /> : ''
                            }
                            {t('clone')}
                        </Button>
                    </div>
                </Form>
            </Skeleton>
        </div>
    )
}

export default CloneConfigurationComponent;