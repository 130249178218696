import React from "react";
import { Drawer } from "antd";
import "./drawers.sass";

// Componente Drawer para el menú lateral derecho de la lista de divisas
export const DrawerCustomComponent = (param) => {

	return (
		<Drawer
            className={param.class}
			title={param.title}
			placement={param.placement}
			width={param.width}
			closable={param.closable}
			onClose={param.onClose}
			visible={param.visible}
			footer={param.footer}
		>
            {param.content}
		</Drawer>
	);
};
