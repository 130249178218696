import React, { useContext, useEffect, useState } from "react";
import { faStar, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontIconsCustom, SwitchCustomPro } from "src/components/custom";
import { RestApiClientContext } from "src/hooks/context/pageContext";

import './ActionsCell.sass'

export const ActionsCell = ({ cell, setVisibleDrawer, setChannelId, reload}) => {
  const restApiClient = useContext(RestApiClientContext);
  const [status, setStatus] = useState('')

  const handleActive = async () => {
    if (status === 'loading') return
    setStatus('loading')
    try {
      const res = await restApiClient.updateActiveChannel(cell.id, !cell.isActive)
      res && setStatus('success')
      reload()
    } catch(err) {
      console.error(err.message)
      setStatus('error')
    }
  }
  const handleEdit = async () => {
    try {
      await setChannelId(cell.id)
      await setVisibleDrawer(true)
    } catch(err) {
      console.error(err.message)
    }
  }
  const handleFavorite = async () => {
    if (status === 'loading') return
    setStatus('loading')
    try {
      const res = await restApiClient.updateFavoriteChannel(cell.id, !cell.isFavorite)
      res && setStatus('success')
      reload()
    } catch(err) {
      console.error(err.message)
      setStatus('error')
    }
  }
  return (
    <div className={`actions-cell ${status === 'loading' ? 'disabled' : ''}`}>
      <SwitchCustomPro
        checked={cell.isActive}
        onChange={handleActive}
      />
      <span onClick={handleEdit} className="action-edit">
        <FontIconsCustom icon={faEdit} />
      </span>
      <FontIconsCustom
        classIcon={`star-list ${cell.favorite ? 'active' : ''}`}
        icon={faStar}
        onClick={handleFavorite}
      />
    </div>
  );
};
