import React, { useEffect, useState } from "react";
import "../../../i18n";
import { Switch } from "antd";

// Componente de switchBasic
export const SwitchCustomComponent = (param) => {

    const [checkSwitch, setCheckSwitch] = useState(null);
    const [loading, setLoading] = useState(true);

    const onChange = (e) => {
        setCheckSwitch(!e.target.value);
        param.activeFunc(param.id, !e.target.value);
    };

    useEffect(() => {
        setLoading(false);
        setCheckSwitch(param.active);
    });

    return (
        <>
            {loading ? (
                <></>
            ) : (
                <div className={param.class}>
                    <Switch
                        className={param.classSwitch}
                        disabled={param.state}
                        checked={param.active}
                        onChange={onChange}
                    />
                </div>
            )}
        </>
    );
};

export const SwitchCustomProComponent = (props) => {
    return (
        <>
            <Switch
                {...props}
            />
        </>
    );
};
