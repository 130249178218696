import React, { useContext, useEffect, useState } from "react";
import "./newGroupSelect.sass";
import {  Select } from "antd";
import {  TitleCustom } from "src/components/custom";
import { TagRender } from "./tagRender";
import { FullscreenOutlined, FullscreenExitOutlined, CheckSquareOutlined } from "@ant-design/icons";

interface NewGroupProps {
  title: string;
  options: any[];
  placeholder: string;
  setData: (val: any) => void;
  data: any
  onChange?: () => void;
}

export const NewGroupSelect = (props : NewGroupProps) => {
  const { title, options, placeholder, setData, data } = props;
  const [maxTag, setMaxTag] = useState(4);
  const canExpanded = data.length > 4;
  const [changed, setChanged] = useState<boolean>(false);

  const handleChange = (value) => {
      setData(value.map(v => ({...options.find(opt => opt?.id === v), selected: true})));
      setChanged(true);
  };

  const selectAllItems = () : void => {
    setData(options.map(opt => ({...options.find(o => o?.id == opt.id), selected:true})));
    setChanged(true);
  }

  useEffect(()=>{
    if (changed && props.onChange){
      props.onChange();
      setChanged(false);
    }
  },[changed])


  return (
    <div id="new-group-select" className="my-3">
      <div className="title-img d-flex justify-content-between">
        <TitleCustom level={3} class="title-group-select" text={title} />
        <div>
          <CheckSquareOutlined onClick={selectAllItems} className={`align-self-center bg-opacity-50 icon`} style={{marginRight: "5px"}}/>
          {maxTag
            ? <FullscreenOutlined
              className={`align-self-center bg-opacity-50 icon ${!canExpanded ? 'disabled' : ''}`}
              onClick={() =>  setMaxTag(prevVal => prevVal ? null : 4)}
            />
            : <FullscreenExitOutlined
              className={`align-self-center bg-opacity-50 icon ${!canExpanded ? 'disabled' : ''}`}
              onClick={() =>  setMaxTag(prevVal => prevVal ? null : 4)}
            />
          }
        </div>
      </div>
      <Select
        tagRender={TagRender}
        mode="multiple"
        allowClear
        showSearch
        style={{
          width: "100%"
        }}
        placeholder={placeholder}
        onChange={(e) => handleChange(e)}
        filterOption={(value: any, option)=>{
          const match : boolean = (option!.children as unknown as string).toLowerCase().includes(value.toLowerCase())

          return match;
        }}
        value={data?.map((opt) => opt?.id)}
        maxTagCount={maxTag}
      >
        {options?.map((opt) => (
          <Select.Option key={opt?.id} value={opt?.id}>
            {opt?.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
