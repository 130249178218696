import React, { useContext, useEffect, useState } from "react";
import "./addItemsByLanguage.sass";
import { AllLanguages } from "src/hooks/context/lang";
import { useTranslation } from "react-i18next";
import { InputCustom } from "src/components/custom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { usewebAgentsLanguages } from "src/api/config";

interface AddItemsByLanguageProps {
  title: String;
  setData: (any) => void;
  data: any[];
  defaultData: { idLanguage: string; name: string }[];
}
export const AddItemsByLanguage = (props: AddItemsByLanguageProps) => {
  
  const webAgentsLang = usewebAgentsLanguages();
  
  const { t } = useTranslation(["config"]);
  const { title, setData, data, defaultData } = props;
  const [languageNumber, setLanguageNumber] = useState<number>(100);
  const allLang = useContext(AllLanguages);
  const [selectedLanguage, setSelectedLanguage] = useState(webAgentsLang?.map((l) => l?.id));

  
  useEffect(() => {
    if (defaultData?.length) {
      let defaultLangData = {};
      // defaultData contiene las traducciones disponibles

      webAgentsLang?.forEach((data)=>{
        defaultLangData = {
          ...defaultLangData,
          [data.id]: {
            value: defaultData.find(dfd => dfd.idLanguage === data.id)?.name ?? "",
            index: data?.id
          }
        };
      })

      setData(defaultLangData);
    }
  }, [defaultData]);

  const handleDelete = (id) => {
    setData((prevData) => {
      delete prevData[id];
      return prevData;
    });
    setLanguageNumber((num) => num--);
  };

  
  return (
    <div id="add-items" className="my-3">
      <fieldset className="d-flex flex-column">
        <label>{title}</label>
        {webAgentsLang?.map((lang, i) =>{
          if (
            (defaultData && webAgentsLang && Object.keys(webAgentsLang).length === Object.keys(defaultData)?.length) || i < languageNumber){
            return <div className="input-custom d-flex">
              <InputCustom
                suffix={<>{selectedLanguage[i].toUpperCase()?.slice(0, 2)}</>
                }
                defaultValue={defaultData?.find((d) => d?.idLanguage.indexOf(lang?.id) !== -1)?.name}
                onChange={(e) => {
                  setData((prevData) => ({
                    ...prevData,
                    [selectedLanguage
                      ? webAgentsLang?.find((singeLang) => singeLang?.id === selectedLanguage[i])?.id
                      : lang?.id]: { value: e.target.value, index: i }
                  }));
                }}
                value={data?.[selectedLanguage?.[i]]?.value ?? data[lang?.id]?.value}
                key={i}
              />
              
            </div>
          }else{
            return <>No languages sets...</>
          }

        })}
      </fieldset>
    </div>
  );
};
