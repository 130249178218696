import React, { useState, useEffect, useContext } from "react";
import "./search-news.sass";
import {
	InputCustom,
	SelectCustom,
	ButtonsCustom,
	ParagraphCustom,
} from "../../custom";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { DataSearchNews } from "./interfaceSearchNews";
import { showNotification } from "../../../utils/notificationsGeneric";
import { LangContext } from "../../../hooks/context/lang";
import { DefaultPagination } from "../../../models/pagination/pagination";
import { StatusNews } from "../../../constants/enums";
import { RestApiClientContext } from "../../../hooks/context/pageContext";

export const SearchNews = (param: DataSearchNews) => {
	const restApiClient = useContext(RestApiClientContext);

	const { t } = useTranslation(["news", "translation"]);

	const userLangContext = useContext(LangContext);

	const [selectedLanguage, setSelectedLanguage] = useState<string>(userLangContext.GetLangContext());

	const [listOptionsStates, setListOptionsStates] = useState([]);
	const [selectOptionValue, setSelectOptionValue] = useState(null);

	const [loaderSkeleton, setLoaderSkeleton] = useState<boolean>(true);

	const [searchTermTitle, setSearchTermTitle] = useState<string>(param.searchTermTitleInput);
	const [searchTermStatus, setSearchTermStatus] = useState<string>(param.searchTermStatusInput);

	// Manejadores de cambios en el input
	const handleChangeTitle = (e) => {
		setSearchTermTitle(e.target.value);
	};
	const handleChangeStatus = (e) => {
		setSearchTermStatus(e);
		setSelectOptionValue(JSON.parse(e));
	};

	// Devuelve al componente padre los valores de búsqueda
	const valuesElementsForm = () => {
		param.setSearching(true);
		param.setSearchTermTitleInput(searchTermTitle);
		param.setSearchTermStatusInput(
			searchTermStatus !== StatusNews.All
				? JSON.parse(searchTermStatus).id
				: ""
		);
		param.setPageResults(DefaultPagination);
	};

	useEffect(() => {
		if (userLangContext.GetLangContext() !== selectedLanguage) {
			setSelectedLanguage(userLangContext.GetLangContext());
		}
	});

	useEffect(() => {
		restApiClient.getNewsStatus()
			.then((data) => {
				const optionsState = [
					{
						id: "ALL",
						name: t("news:status.ALL"),
					},
				];

				data.map((e, i) => {
					optionsState.push({
						id: e,
						name: t("news:status." + e),
					});
				});

				setListOptionsStates(optionsState);

				const selected = param.searchTermStatusInput
					? optionsState.find(
						(e) => e.id === param.searchTermStatusInput
					)
					: {
						// Recogemos los valores del primer elemento de la lista en el caso de que no coincidiese el que almacena el state
						id: optionsState[0].id,
						name: optionsState[0].name,
					};

				setSelectOptionValue({
					id: selected.id,
					name: selected.name,
				});

				setLoaderSkeleton(false);
			})
	}, [selectedLanguage]);

	return (
		<div className="row">
			<div className="col-12 col-lg-5 mb-3 mb-lg-0">
				<ParagraphCustom
					text={<strong>{t("news:label.title-news")}</strong>}
				/>
				<InputCustom
					id="input-title-news"
					value={searchTermTitle}
					placeholder=""
					className="main-input"
					disabled={param.disabledSearch}
					prefix={false}
					allowClear={true}
					icon={faSearch}
					autoFocus={true}
					onChange={handleChangeTitle}
				/>
			</div>
			<div className="col-12 col-lg-4 mb-5 mb-lg-0">
				<ParagraphCustom
					text={<strong>{t("news:label.status")}</strong>}
				/>
				<SelectCustom
					id="select-status"
					className="col-12 main-input"
					options={listOptionsStates}
					values={selectOptionValue}
					onChange={handleChangeStatus}
					disabled={param.disabledSearch}
				/>
			</div>
			<div className="col-12 col-lg-3 align-self-end">
				<ButtonsCustom
					id="btn-search-news"
					class={"primary-button col-12"}
					type=""
					htmlType={null}
					onClick={valuesElementsForm}
					disabled={param.disabledSearch}
					size={null}
					content={
						<span>
							<strong>{t("news:button.search-news")}</strong>
						</span>
					}
				/>
			</div>
		</div>
	);
};
