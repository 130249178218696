import React from 'react';
import './totalElementsTableList.sass';
import '../../../i18n';
import { useTranslation } from 'react-i18next';

// Componente que muestra el total de elementos que hay en una lista o tabla
export const TotalElementsTableList = (param) => {
    const { t } = useTranslation(['genericsTexts']);

    return (
        <div className="text-color-result">
            { param.total + t("genericsTexts:result-table") }
        </div>
    );
}

