import React, { useContext, useEffect, useState } from "react";
import "./menuPop.sass";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Row, Col } from "antd";
import { LoginContext, UserContext } from "../../../../hooks/context/userInfo";
import {
    faUser,
    faSignOutAlt,
    faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import {
    DropdownCustom,
    ModalCustom,
    ParagraphCustom,
    ButtonsCustom,
    FontIconsCustom,
    MenusCustomPro,
} from "../../../custom";
import { showNotification } from "../../../../utils/notificationsGeneric";
import { LangContext, LangUrlContext } from "../../../../hooks/context/lang";
import { DURATION_COOKIE } from "../../../../constants/constants";
import { User } from "src/models/user/userModel";
import { RestApiClientContext } from "../../../../hooks/context/pageContext";

export const MenuPop = (param) => {
    const restApiClient = useContext(RestApiClientContext);

    const { t } = useTranslation(["urls", "linksNames", "modalClosedSession"]);
    const [, , removeCookie] = useCookies(["userToken"]);
    const loginContext = useContext(LoginContext);
    const userData = useContext<User>(UserContext);
    const [visible, setVisible] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const languageUrl = useContext(LangUrlContext);

    const [userLogin, setUserToken] = useCookies(["userToken"]);

    const userContext = useContext(UserContext);

    const language = useContext(LangContext);

    const history = useHistory();

    const handleClosedSesion = () => {
        setVisible(true);
    };

    useEffect(() => {
        restApiClient.getUserName()
            .then((name) => {
                const date = Date.now() + DURATION_COOKIE;
                setName(name);
                setUserToken("userToken", userLogin.userToken, {
                    path: "/",
                    expires: new Date(date),
                });
                sessionStorage.setItem("token", userLogin.userToken);

                userContext.UpdateUserContext(
                    name,
                    languageUrl.GetURLLangContext(),
                    language.GetLangContext()
                );
            })
    }, []);

    const closedSession = () => {
        restApiClient.logoutUser()
            .then((isLogout) => {
                if (isLogout) {
                    removeCookie("userToken", { path: "/" });
                    loginContext.updateValue(false);
                    history.push("/" + userData.GetLanguageURL() + "/");
                    window.location.reload();
                } else {
                    showNotification(
                        "error",
                        t("translation:error.title"),
                        t("translation:error.description"),
                        "topRight"
                    );
                }
            })
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const menu = (
        <MenusCustomPro className={"menu-profile"}>
            <div
                id="div-logout"
                className="box-link-menu content-profile"
                onClick={handleClosedSesion}
            >
                <div className=" secondary-links-svg vertical-center-draw">
                    <FontIconsCustom
                        classIcon="logout-icon icons-color-menu icons-color-marging"
                        icon={faSignOutAlt}
                    />
                    {t("linksNames:logout")}
                </div>
            </div>
        </MenusCustomPro>
    );

    return (
        <>
            <DropdownCustom overlay={menu}>
                <div>
                    <ButtonsCustom
                        id="btn-user-header"
                        class={
                            "p-0 secondary-links-svg" +
                            (window.location.pathname ===
                                t("urls:private.profile")
                                ? " active"
                                : "")
                        }
                        content={
                            <>
                                <FontIconsCustom
                                    classIcon={
                                        "icons-color-menu" +
                                        (param.type === "desktop"
                                            ? " icons-color-marging"
                                            : "")
                                    }
                                    icon={faUser}
                                />
                                {param.type === "desktop" ? (
                                    <span className="content-user-name">
                                        {name}
                                    </span>
                                ) : (
                                        ""
                                    )}
                                <FontIconsCustom
                                    classIcon={
                                        "icons-color-menu icons-color-marging chevron-language marging-menu-user"
                                    }
                                    icon={faChevronDown}
                                />
                            </>
                        }
                    />
                </div>
            </DropdownCustom>
            {visible && (
                <ModalCustom
                    class="modal-bo"
                    title={t("modalClosedSession:title")}
                    setVisible={setVisible}
                    closable={true}
                    content={
                        <>
                            <ParagraphCustom
                                class="text-white"
                                text={t("modalClosedSession:question")}
                            />
                            <Row>
                                <Col span={24} className="align-text-buttons">
                                    <ButtonsCustom
                                        id="btn-logout"
                                        type="primary"
                                        onClick={closedSession}
                                        class="primary-button"
                                        content={t(
                                            "modalClosedSession:button-closed-session"
                                        )}
                                    />
                                    <ButtonsCustom
                                        id="btn-cancel-logout"
                                        class="default-button-dark margin-right-button"
                                        onClick={handleCancel}
                                        content={t(
                                            "modalClosedSession:button-cancel"
                                        )}
                                    />
                                </Col>
                            </Row>
                        </>
                    }
                />
            )}
        </>
    );
};
