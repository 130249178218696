import EndPointConfig from "../../models/api/endPointConfig";

const controller = "Regimens";

/// Define all EndPoints for channels section.
export const RegimensEP: EndPointConfig[] = [
  // Envio de formulario a la API
  new EndPointConfig().setMethod("GET").setController(controller).setAlias("GetRegimens"),

  new EndPointConfig().setMethod("GET").setController(controller).setPattern("{id}").setAlias("GetRegimenById"),

  new EndPointConfig().setMethod("POST").setController(controller).setAlias("CreateRegimen"),

  new EndPointConfig().setMethod("PUT").setController(controller).setAlias("UpdateRegimen"),

  new EndPointConfig().setMethod("DELETE").setController(controller).setPattern("{id}").setAlias("DeleteRegimen")
];
