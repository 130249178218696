import React, { useState } from "react";
import "./index.sass";
import "../../../i18n";
import { Footer } from "../../footer/footer";
import { HeaderPrivate } from "./header/header";
import { LayoutBasic } from "../private/layout/layout";
import { useTranslation } from "react-i18next";
import { LinkActiveContext } from "../../../hooks/context/linkActive";

// Componente Base de la parte publica de nuestra aplicación
export const BasePrivate = () => {
    // Libreria para el manejador de idioma de la app
    const { i18n } = useTranslation();

    const [collapsedState, setCollapsedState] = useState(false);
    const [active, setActive] = useState(window.location.pathname);

    const toggle = () => {
        setCollapsedState(!collapsedState);
    };

    return (
        <LinkActiveContext.Provider
            value={{
                active,
                updateValueUrl: (url) => {
                    setActive(url);
                },
            }}
        >
            <div className="main-content-private">
                <HeaderPrivate
                    toggle={toggle}
                    collapsedState={collapsedState}
                    active={active}
                    setActive={setActive}
                />
                <LayoutBasic
                    collapsedState={collapsedState}
                    active={active}
                    setActive={setActive}
                />
            </div>
            <Footer
                zone="private"
                active={active}
                setActive={setActive}
                class="footer-private"
            />
        </LinkActiveContext.Provider>
    );
};
