import EndPointConfig, { MockData } from '../../models/api/endPointConfig';

const controller = 'Billing';


/// Define all EndPoints for login agents methods.
export const BillingEP : EndPointConfig[] = [
    // Get datos para el token de usuario

        new EndPointConfig()
            .setMethod('GET')
            .setController(controller)
            .setActionName('CheckForPendingInvoices')
            .setAlias('CheckForPendingInvoices'),

            new EndPointConfig()
            .setMethod('GET')
            .setController(controller)
            .setActionName('GetBillingAccountsData')
            .setAlias('GetBillingAccountsData'),
        
];
