import * as React from "react";
import { Checkbox } from "antd";

export const CheckBoxCustomComponent = (param) => {
	return (
		<Checkbox
			id={param.id}
			onChange={param.onChange}
			checked={param.checked}
		>
			{param.content}
		</Checkbox>
	);
};
