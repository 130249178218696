import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { FormCustom, ButtonsCustom, InputCustom, SwitchCustomPro } from "src/components/custom";
import { RestApiClientContext } from "src/hooks/context/pageContext";

import "./editParams.sass";
import { AddItemsByLanguage } from "../../addItemsByLanguage/addItemsByLanguage";

interface EditPositionsProps {
  id: string;
  drawerChoices: any;
  setDrawerChoices: (state) => void;
  setVisible: (state: boolean) => void;
  reload: () => void;
}
interface DataPositions {
  isDeleted?: boolean;
  positionsTranslations?: { idLanguage: string; name: string }[];
  name?: string
  id?: string;
}

const defaultData: DataPositions = {
  positionsTranslations: []
};

export const EditParams = (props: EditPositionsProps) => {
  const { t } = useTranslation(["config"]);
  const { id, setVisible, reload, drawerChoices, setDrawerChoices } = props;
  const [fieldsData, setFieldsData] = useState(defaultData ?? {});
  const [isDisabled, setIsDisabled] = useState(false);
  const restApiClient = useContext(RestApiClientContext);
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      restApiClient.getPositionById(id).then((data) => setFieldsData(data));
    }else setFieldsData({})
  }, []);

  useEffect(() => {
    if (fieldsData) {
      form.setFieldsValue({
        regimenTranslations: fieldsData?.positionsTranslations,
      } as DataPositions);
    }
  }, [fieldsData]);

  const handleOnChange = (e) => {
    setFieldsData({ ...fieldsData, [e[0].name]: e[0].value });
  };

  const handleSave = async () => {
    try {
      if (id) {
        const body: DataPositions = {
          id: id,
          name: 'test',
          positionsTranslations: Object.keys(drawerChoices).map((lang) => ({
            idLanguage: lang,
            name: drawerChoices[lang]?.value
          }))
        };
        await restApiClient.updatePositions(id, body);
      } else {
        const body: DataPositions = {
          name: 'test',
          positionsTranslations: Object.keys(drawerChoices).map((lang) => ({
            idLanguage: lang,
            name: drawerChoices[lang]?.value
          }))
        };
        await restApiClient.createPositions(body);
      }
    } finally {
      setVisible(false);
      reload();
    }
  };

  return (
    <FormCustom onFinish={handleSave} form={form} layout="vertical" id="edit-params" onFieldsChange={handleOnChange}>
      <AddItemsByLanguage
        title={t("params.drawer-title")}
        setData={setDrawerChoices}
        data={drawerChoices}
        defaultData={fieldsData?.positionsTranslations}
      />
      <Form.Item className="form-item">
        <div className="d-flex buttons justify-content-end">
          <ButtonsCustom
            className="btn-secondary"
            htmlType="button"
            onClick={() => setVisible(false)}
            content={<span>{t("params.drawer-cancel")}</span>}
          />
          <ButtonsCustom
            disabled={isDisabled}
            className="btn-primary"
            type="primary"
            htmlType="submit"
            content={<span>{t("params.drawer-save")}</span>}
          />
        </div>
      </Form.Item>
    </FormCustom>
  );
};
