import React, { useEffect, useState, useContext } from "react";
import { AutoComplete, Divider, Select, Skeleton, Tooltip } from "antd";
import "../../../i18n";
import "./searchAgent.sass";
import { useTranslation } from "react-i18next";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { InputCustom, SelectCustom, ButtonsCustom, ParagraphCustom } from "../../custom";
import { LangContext } from "../../../hooks/context/lang";
import { DataSearch } from "./interfaceDataSearch";
import { RestApiClientContext } from "../../../hooks/context/pageContext";

export const SearchAgent = (param: DataSearch) => {
  const restApiClient = useContext(RestApiClientContext);
  const { t } = useTranslation(["agentsRegister", "translation"]);

  const userLangContext = useContext(LangContext);

  const [loader, setLoader] = useState(true);

  const [listOptions, setListOptions] = useState([]);
  const [selectValue, setSelectValue] = useState(null);
  const [agenciesList, setAgenciesList] = useState(null);

  const [agencyNameSearchCriteria, setAgencyNameSearchCriteria] = useState<string>('')

  const [searchTermName, setSearchTermName] = useState(param.searchTermNameInput);
  const [searchTermEmail, setSearchTermEmail] = useState(param.searchTermEmailInput);
  const [searchTermState, setSearchTermState] = useState(param.searchTermStateInput);
  const [searchTermLocator, setSearchTermLocator] = useState(param.searchTermLocatorInput);
  const [searchTermAgency, setSearchTermAgency] = useState(param.searchTermAgencyInput);

  const [lng, setLng] = useState(userLangContext.GetLangContext());

  // Devuelve el valor al componente padre de los elementos del formulario
  const searchCriteria = () => {
    param.setSearching(true);
    param.setSearchTermNameInput(searchTermName?.trim());
    param.setSearchTermEmailInput(searchTermEmail?.trim());
    param.setSearchTermStateInput(searchTermState !== "" ? JSON.parse(searchTermState).id : "");
    param.setSearchTermLocatorInput(searchTermLocator?.trim());
    param.setSearchTermAgencyInput(searchTermAgency);
    param.setPageResults({ Page: 1, ResultPage: 6 });
  };

  // Manejadores de cambio en el input
  const handleChangeName = (e) => {
    setSearchTermName(e.target.value);
  };
  const handleChangeEmail = (e) => {
    setSearchTermEmail(e.target.value);
  };
  const handleChangeState = (e) => {
    setSearchTermState(e);
    setSelectValue(JSON.parse(e));
  };
  const handleChangeLocator = (e) => {
    setSearchTermLocator(e.target.value);

    if (agencyNameSearchCriteria && agencyNameSearchCriteria.length > 0){
      getAgenciesList(agencyNameSearchCriteria);
    }

  };
  const handleChangeAgency = (e) => {
    setSearchTermAgency(e);
  };

  useEffect(() => {
    if (userLangContext.GetLangContext() !== lng) {
      setLng(userLangContext.GetLangContext());
    }
  });

  useEffect(() => {
    restApiClient.getAgentsFilteredStatus(lng).then((data) => {
      const optionsState = [
        {
          id: "ALL",
          name: t("agentsRegister:options-state.ALL")
        }
      ];

      data.map((e, i) => {
        optionsState.push({
          id: e,
          name: t("agentsRegister:options-state." + e)
        });
      });

      setListOptions(optionsState);
      const selected = param.searchTermStateInput
        ? optionsState.find((e) => e.id === param.searchTermStateInput)
        : {
            id: optionsState[0].id,
            name: optionsState[0].name
          };
      setSelectValue({
        id: selected.id,
        name: selected.name
      });
      setLoader(false);
    });
  }, [lng]);

  const getAgenciesList = (name?, results = 20) => {
    const filter = !name
      ? { page: 1, resultPage: results, language: lng, address: searchTermLocator }
      : { page: 1, resultPage: results, name: name, language: lng, address: searchTermLocator };
    restApiClient.getAgencies(filter).then((data) => {
      setAgenciesList(data);
      setLoader(false);
    });
  };

  const onSearch = async (searchText, results = 20) => {
    await getAgenciesList(searchText, results);
  };

  return (
      <div className="col-12 mt-3">
          <div className="row pr-3 pl-3 form-search-agency">
            <div className="col-xs-12 col-lg-5 label-left-search-lg">
              <ParagraphCustom text={<strong>{t("agentsRegister:searchAgentsRegister.labels.label-1")}</strong>} />
              <InputCustom
                id={"name"}
                value={searchTermName}
                placeholder=""
                className="main-input"
                prefix={false}
                allowClear={true}
                classIcon={"search-agency-icon-input"}
                icon={faSearch}
                autoFocus={true}
                onChange={handleChangeName}
                disabled={param.elementFormSearch}
              />
            </div>
            <div className="col-xs-12 col-lg-4 col-md-6 label-left-search label-right-search">
              <ParagraphCustom text={<strong>{t("agentsRegister:searchAgentsRegister.labels.label-2")}</strong>} />
              <InputCustom
                id={"email"}
                value={searchTermEmail}
                placeholder=""
                className="main-input"
                classIcon={"search-agency-icon-input"}
                prefix={false}
                allowClear={true}
                icon={faSearch}
                onChange={handleChangeEmail}
                disabled={param.elementFormSearch}
              />
            </div>
            <div className="col-xs-12 col-lg-3 col-md-6 label-right-search">
              <ParagraphCustom text={<strong>{t("agentsRegister:searchAgentsRegister.labels.label-3")}</strong>} />
              <SelectCustom
                id="state"
                className="col-12 main-input"
                options={listOptions}
                values={selectValue}
                onChange={handleChangeState}
                disabled={param.elementFormSearch}
              />
            </div>
            <div className="col-xs-12 col-lg-5 label-left-search-lg">
              <ParagraphCustom text={<strong>{t("agentsRegister:searchAgentsRegister.labels.label-4")}</strong>} />
              <InputCustom
                value={searchTermLocator}
                id={"localization"}
                placeholder=""
                className="main-input"
                classIcon={"search-agency-icon-input"}
                prefix={false}
                allowClear={true}
                icon={faSearch}
                onChange={handleChangeLocator}
                disabled={param.elementFormSearch}
              />
            </div>
            <div className="col-xs-12 col-lg-4 label-left-search label-right-search">
              <ParagraphCustom text={<strong>{t("agentsRegister:searchAgentsRegister.labels.label-5")}</strong>} />
              <AutoComplete
                disabled={param.elementFormSearch}
                showSearch
                allowClear
                optionFilterProp="children"
                className="col-12 main-select"
                onSearch={(e) => onSearch(e, 20)}
                onSelect={(e, opt) => {
                  handleChangeAgency(opt?.key);
                }}
                onChange={(v)=>{
                  if (!v || v.length == 0){
                    handleChangeAgency('');
                  }else{
                    setAgencyNameSearchCriteria(v);
                  }
                }}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <span style={{ margin: "6px", display: "block" }}>{`${t(
                      "regularization:searchReservationRegister.tooltip",
                      {
                        0: agenciesList?.totalRecord - 20 < 0 ? 0 : agenciesList?.totalRecord
                      }
                    )}`}</span>
                  </div>
                )}
              >
                {agenciesList?.agencies.map((opt) => (
                  <Select.Option value={opt?.name} key={opt?.id}>
                    {opt?.name}
                    <Tooltip title={opt?.address}>
                      <small style={{display:"block"}}>{opt?.address}</small>
                    </Tooltip>
                  </Select.Option>
                ))}
              </AutoComplete>
              <small style={{display:"block", color: "#cfcfcf"}}>
                {t("agentsRegister:searchAgentsRegister.labels.label-commercial-name")}
              </small>
            </div>
            <div className="col-xs-12 col-lg-3 label-right-search button-search">
              <ButtonsCustom
                id="search-agency"
                class={"primary-button col-12"}
                type=""
                htmlType={null}
                onClick={searchCriteria}
                disabled={param.elementFormSearch}
                size={null}
                content={
                  <span>
                    <strong>{t("agentsRegister:searchAgentsRegister.button")}</strong>
                  </span>
                }
              />
            </div>
          </div>
        </div>
  );
};
