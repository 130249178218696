import React, { useEffect, useState } from "react";
import "./directSalesConfig.sass";
import { ParagraphCustom, TitleCustom } from "../../custom";
import { useTranslation } from "react-i18next";
import { DirectSales, Amount, DataDirectSales } from "../interfaces/directSalesConfigInterface";
import { DrawerDirectSales } from "./drawerDirectSales";
import { LimitAmountConfig } from "../limitAmountConfig/limitAmountConfig";

export const DirectSalesConfig = (props: DataDirectSales) => {
  const { data, limitAmounts, changeValuesLimits } = props;

  const { t } = useTranslation(["rewardsPerNight"]);

  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [dataSales, setDataSales] = useState<DirectSales>();
  const [dataSalesAmount, setDataSalesAmount] = useState<Amount[]>([]);

  useEffect(() => {
    if (data && data.amounts) {
      setDataSales({ ...data });
      setDataSalesAmount([...data.amounts]);
    }
  }, [data]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <TitleCustom level={4} class="title-section" text={t("rewardsPerNight:main-title")} />
        </div>
        <div className="col-12 col-md-7 col-lg-8 description-section-rewards-per-night">
          <ParagraphCustom class={"p-0"} text={t("rewardsPerNight:main-description-1")} />
          <ParagraphCustom class={"p-0"} text={t("rewardsPerNight:main-description-2")} />
        </div>
        <div className="col-12 col-md-5 col-lg-4">
          <LimitAmountConfig limitAmounts={limitAmounts} onchange={changeValuesLimits} />
        </div>
      </div>
      {visibleDrawer && (
        <DrawerDirectSales
          amounts={JSON.parse(JSON.stringify(dataSalesAmount))}
          dataDirectSales={dataSales}
          setVisibleDrawer={setVisibleDrawer}
        />
      )}
    </>
  );
};
