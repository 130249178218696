import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../hooks/context/config";
import { Skeleton, Spin, Result, Button, Typography } from "antd";

import { SearchAgent } from "./searchAgent/searchAgent";
import "./agentsRegister.sass";
import {
    ButtonsCustom,
    PopoverCustom,
    FontIconsCustom,
    ParagraphCustom,
    TitleCustom,
} from "../custom";
import { NotResults } from "../../components/noResultGeneric/notResults";
import { ListAgentsRegister } from "./listAgentsRegister/listAgentsRegister";
import { StructureListAgentsRegister } from "./structureListAgents/structureListAgents";
import { URL_IMAGE, URL_BASE, API_URL_TEST } from "../../constants/constants";
import { Pagination, DefaultPagination } from "../../models/pagination/pagination";
import { AlternateComponent } from "../../components/utils/alternateComponent/alternateComponent";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { ListItem } from "../custom/lists/interfaceLists";
import { LangContext } from "../../hooks/context/lang";
import FileDownload from 'js-file-download';
import { LoadingOutlined } from "@ant-design/icons";
import { exportExcel } from "../../components/utils/exportExcel/exportExcel";
import { AgentFilter } from "../../models/agents/agentFilter";
import { RestApiClientContext } from "../../hooks/context/pageContext";

export const AgentsRegister = () => {
    const restApiClient = useContext(RestApiClientContext);

    const { t } = useTranslation(["contact", "seo", "urls", "agentsRegister", "regularization"]);

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const infoClient = useContext(ConfigContext);

    const userLangContext = useContext(LangContext);

    const [data, setData] = useState<ListItem[]>([]);

    const [totalElements, setTotalElements] = useState<number>(0);

    const [searchTermNameInput, setSearchTermNameInput] = useState<string>("");
    const [searchTermEmailInput, setSearchTermEmailInput] = useState<string>("");
    const [searchTermStateInput, setSearchTermStateInput] = useState<string>("");
    const [searchTermLocatorInput, setSearchTermLocatorInput] = useState<string>("");
    const [searchTermAgencyInput, setSearchTermAgencyInput] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(true);

    const [loadingList, setLoadingList] = useState<boolean>(true);

    const [searching, setSearching] = useState<boolean>(false);

    const [changeDataAgent, setChangeDataAgent] = useState([]);

    const [pageResults, setPageResults] = useState<any>({Page: 1, ResultPage: 10});

    const [elementFormSearch, setElementFormSearch] = useState<boolean>(false)

    const [messageErrorApi, setMessageErrorApi] = useState<string>("");

    const [loadingError, setLoadingError] = useState<boolean>(false);

    const scrollLib = require("react-scroll");
    const scroll = scrollLib.animateScroll;


    const fileExtension = ".xlsx";

    const desktopVersion = () => {
        return (
            <div className="action-buttons">
                <ButtonsCustom
                    id="btn-excel"
                    class={"button-agent-register margin-button-excel-agent"}
                    type=""
                    htmlType={null}
                    onClick={downloadRewardsExcel}
                    disabled={false}
                    size={null}
                    content={
                        <div className="button-excel">
                            {downloadingRewards ? <LoadingOutlined /> : <img src={URL_IMAGE + "excel-logo.svg"} />}
                            <ParagraphCustom
                                class={"mb-0 ml-1"}
                                text={<> {t("regularization:list.rewards")}</>}
                            />
                        </div>
                    }
                />

                <ButtonsCustom
                    id="btn-excel"
                    class={"button-agent-register margin-button-excel-agent"}
                    type=""
                    htmlType={null}
                    onClick={sendExcel}
                    disabled={false}
                    size={null}
                    content={
                        <div className="button-excel">
                            {downloadingAgents ? <LoadingOutlined /> : <img src={URL_IMAGE + "excel-logo.svg"} />} 
                            <ParagraphCustom
                                class={"mb-0 ml-1"}
                                text={<>{t("agentsRegister:button-excel")}</>}

                            />
                        </div>
                    }
                />
            </div>
            
        );
    };

    const tabletVersion = () => {
        return desktopVersion();
    };

    const mobileVersion = () => {
        return (
            <PopoverCustom
                classBody={"position-float-right"}
                body={
                    <ButtonsCustom
                        id={"btn-excel"}
                        class={"button-list-agents"}
                        type=""
                        htmlType={null}
                        // onClick={() => validate(param.data.id)}
                        disabled={false}
                        size={null}
                        content={
                            <FontIconsCustom
                                classIcon={"icons-list-agent-register"}
                                icon={faEllipsisV}
                            />
                        }
                    />
                }
                content={
                    <div>
                        <ButtonsCustom
                        id="btn-excel"
                        class="button-agent-register-movil"
                        type=""
                        onClick={downloadRewardsExcel}
                        htmlType={null}
                        disabled={false}
                        size={null}
                        content={
                            <div className="button-excel">
                                {downloadingRewards ? <LoadingOutlined /> : <img src={URL_IMAGE + "excel-logo.svg"} />} 
                                <ParagraphCustom
                                    class={"mb-0 ml-1"}
                                    text={<>{t("regularization:list.rewards")}</>}
                                />
                            </div>
                        }
                        />
                        <ButtonsCustom
                            id="btn-excel"
                            class="button-agent-register-movil"
                            type=""
                            onClick={sendExcel}
                            htmlType={null}
                            disabled={false}
                            size={null}
                            content={
                                <div className="button-excel">
                                    {downloadingAgents ? <LoadingOutlined /> : <img src={URL_IMAGE + "excel-logo.svg"} />} 
                                    <ParagraphCustom
                                        class={"mb-0 ml-1"}
                                        text={<>{t("agentsRegister:button-excel")}</>}

                                    />
                                </div>
                            }
                        />
                    </div>
                }
                placement={"bottomLeft"}
                title=""
                trigger="focus"
            />
        );
    };

    useEffect(() => {
        scroll.scrollToTop();
    }, []);

    const getImageName = (agentData : any) : string => {
        return agentData.imageName === undefined || agentData.imageName === null ? "" : agentData.imageName;

    }

    const getAgentsFiltered = (filter: AgentFilter) : void => {
        setElementFormSearch(true)
        setLoading(true);
        setLoadingList(true);
        restApiClient.getAgentsFiltered(filter)
                     .then((data)=>{
                        const listParseComponent: ListItem[] = [];
                        data.agents.map((agentData) => {

                            const elementForList: ListItem = {
                                avatar: getImageName(agentData),
                                title: "",
                                description: (
                                    <StructureListAgentsRegister
                                        data={agentData}
                                        setChangeDataAgent={
                                            setChangeDataAgent
                                        }
                                    />
                                ),
                            };
                            listParseComponent.push(elementForList);
                        });

                        setData(listParseComponent);
                        setTotalElements(data.totalRecord);
                     })
                     .finally(()=>{
                        setElementFormSearch(false)
                        setLoading(false);
                        setLoadingList(false);
                     });
    }


    useEffect(() => {
        const filter = {
            NameSurname: searchTermNameInput,
            Email: searchTermEmailInput,
            AgentStatus: searchTermStateInput,
            LocationAgency: searchTermLocatorInput,
            Agency: "",
            AgencyId: searchTermAgencyInput,
            Pagination: pageResults,
            Lang: userLangContext.GetLangContext(),
        } as AgentFilter;
        getAgentsFiltered(filter);
    }, [
        searchTermNameInput,
        searchTermEmailInput,
        searchTermStateInput,
        searchTermLocatorInput,
        searchTermAgencyInput,
        pageResults,
        changeDataAgent,
    ]);

    const [downloadingRewards, setDownloadingRewards] = useState<boolean>(false);
    const downloadRewardsExcel = async () => {
        setDownloadingRewards(true);
        const response = await exportExcel(`${API_URL_TEST}/AgentsManagement/exportexcelrewards?language=${userLangContext.GetLangContext()}`);
        FileDownload(response, t("agentsRegister:excel-rewards-name-file") + fileExtension);
        setDownloadingRewards(false);
    };

    const [downloadingAgents, setDownloadingAgents] = useState<boolean>(false);
    const sendExcel = async () => {
        setDownloadingAgents(true);
        const response = await exportExcel(`${API_URL_TEST}/AgentsManagement/exportexcel?language=${userLangContext.GetLangContext()}`);
        setDownloadingAgents(false);
        FileDownload(response, t("agentsRegister:excel-name-file") + fileExtension);

    };

    return (
        <>
            <Helmet>
                <title>
                    {infoClient.incentiveSiteName} | {t("seo:title.contact")}
                </title>
                <meta
                    name="description"
                    content={t("seo:description.contact", {
                        0: infoClient.incentiveSiteName,
                    })}
                />
                <meta name="robots" content="index,follow" />
                <link
                    rel="canonical"
                    href={URL_BASE + t("urls:public.contact")}
                />
                <link
                    rel="icon"
                    type="image/ico"
                    href={infoClient.favicon}
                    sizes="32x32"
                />
                <link
                    href={URL_BASE + t("urls:public.contact")}
                    hrefLang="es-ES"
                    rel="alternate"
                />
            </Helmet>
            
            <div className="row special-mb">
                <div className="col-12">
                    <TitleCustom
                        level={1}
                        class="title-section"
                        text={t("agentsRegister:title")}
                    />
                    <ParagraphCustom
                        class={"p-0"}
                        text={t("agentsRegister:subtitle", {
                            0: infoClient.incentiveSiteName,
                        })}
                    />
                </div>
                <div className="col-12">
                    <AlternateComponent
                        desktopVersionLarge={desktopVersion()}
                        tabletVersion={tabletVersion()}
                        mobileVersionLarge={mobileVersion()}
                    />
                </div>

                <SearchAgent
                    searchTermNameInput={searchTermNameInput}
                    searchTermEmailInput={searchTermEmailInput}
                    searchTermStateInput={searchTermStateInput}
                    searchTermLocatorInput={searchTermLocatorInput}
                    searchTermAgencyInput={searchTermAgencyInput}
                    setSearchTermNameInput={setSearchTermNameInput}
                    setSearchTermEmailInput={setSearchTermEmailInput}
                    setSearchTermStateInput={setSearchTermStateInput}
                    setSearchTermLocatorInput={setSearchTermLocatorInput}
                    setSearchTermAgencyInput={setSearchTermAgencyInput}
                    setPageResults={setPageResults}
                    setSearching={setSearching}
                    elementFormSearch={elementFormSearch}
                />
                    <Skeleton loading={loading} active paragraph={{ rows: 5 }}>
                    {
                        loadingError ?
                            <Result
                                style={{ width: "100%" }}
                                status="error"
                                title={t("agentsRegister:agent-list:error-title")}
                                subTitle={t("agentsRegister:agent-list:error-description")}
                                extra={[
                                    <Button key="retry" onClick={() => {
                                        const filter = {
                                            NameSurname: searchTermNameInput,
                                            Email: searchTermEmailInput,
                                            AgentStatus: searchTermStateInput,
                                            LocationAgency: searchTermLocatorInput,
                                            Agency: searchTermAgencyInput,
                                            Pagination: pageResults,
                                            Lang: userLangContext.GetLangContext(),
                                        } as AgentFilter;
                                        getAgentsFiltered(filter)
                                    } }>
                                        {t("agentsRegister:agent-list:error-try-again")}
                                    </Button>,
                                ]}
                            >
                            </Result>
                            :
                            <>
                                {
                                    totalElements === 0 ? 
                                        <NotResults
                                            section={"agentsRegister"}
                                            dataSearch={searching}
                                            messageApi={messageErrorApi}
                                        />
                                    : 
                                        <ListAgentsRegister
                                            data={data}
                                            count={totalElements}
                                            setChangeDataAgent={setChangeDataAgent}
                                            setPageResults={setPageResults}
                                            pageResults={pageResults}
                                        />
                                }
                            </>
                    }
                    </Skeleton>


                </div>
        </>
    );
};
