
import { Button, Table, Tag, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { RestApiClientContext } from 'src/hooks/context/pageContext';
import BillingAccountModel from 'src/models/billing/BillingAccountModel';

import './billing.sass';
import { useTranslation } from 'react-i18next';
import { ParagraphCustom, TitleCustom } from '../custom';

interface BillingInfoComponentProps{}

export const BillingInfoComponent = (props: BillingInfoComponentProps) : JSX.Element => {
    const { t } = useTranslation(["billing"]);
    
    const restApiClient = useContext(RestApiClientContext);

    const [billingAccountList, setBillingAccountList] = useState<BillingAccountModel[]>([]);  
    const [loading, setLoading] = useState<boolean>(false);

    const getBillingAccountList = () => {
        setLoading(true);
        restApiClient.getBillingAccounts().then((res) => {
            setBillingAccountList(res);
        }).finally(()=>{
            setLoading(false);
        });

    }

    const columns = [
        {
          title: <>{t('billing:table.company-name')}</>,
          dataIndex: 'companyName',
          key: 'companyName',
        },
        {
          title: <>{t('billing:table.pending-invoices')}</>,
          dataIndex: 'pendigInvoiceData',
          key: 'pendigInvoiceData',
          render: (invoices: any) => <div>
                {
                    invoices.map((invoice: any, index: number) => {
                        var invoiceAmount = Number.parseFloat(invoice.invoiceAmount);
                        //Round to 2 decimals ceil or floor
                        invoiceAmount = Math.round(invoiceAmount * 100) / 100;

                        return <Tag className="invoice-info" color="red"  key={index}>{invoice.invoiceNumber} | {invoiceAmount} {invoice.currency.toUpperCase()} | {invoice.invoiceDate}</Tag>
                    })
                }
          </div>
        },
        {
          title: 'Dashboard',
          dataIndex: 'portalURL',
          key: 'portalURL',
          render: (text: string) => <a href={`${text}`} target='_blank'>Ir a portal facturación</a>
        },
      ];

    useEffect(()=>{
        getBillingAccountList();
    },[])

    return (
        <div className="billing-info">

            <TitleCustom
                level={1}
                class="title-section"
                text={t("billing:title")}
            />

            <ParagraphCustom
                class={"p-0"}
                text={t("billing:subtitle")}
            />

            <Table className="billing-list" dataSource={billingAccountList} columns={columns} loading={loading} pagination={{pageSize: 5}} />
        </div>
    )
}
