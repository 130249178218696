import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';

// Componente de iconos de Font Awesome
export const FontIconsCustomComponent = (param) => {
    return (
        <Tooltip
            title={ param.tooltip }
            placement={ param.placementTooltip }
            color={ param.colorTooltip }
        >
            <FontAwesomeIcon
                className={ param.classIcon }
                icon={ param.icon }
                onClick={param.onClick}
            />
        </Tooltip>
    );
}
