import { FontIconsCustom, ParagraphCustom, TagInfoCustom } from '../../custom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './infoShowEstablishment.sass'
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { NotResults } from '../../noResultGeneric/notResults';
import { DataShow } from '../interfaces/seasons';

export const EstablishmentShow = (props: DataShow) => {

    const { dataConfiguration } = props;
    const { t } = useTranslation(["rewardsPerNight", "translation"]);

    return (
        <>
            <div className="col-12 p-0">
                <div className="col-12 p-0 icons-section">
                    <div className="width-icons-configuration-reward">
                        <FontIconsCustom
                            className=""
                            icon={faBuilding}
                        />
                    </div>
                    <div className="ml-2">
                        <ParagraphCustom
                            class="margin-b"
                            text={t("rewardsPerNight:establishment-section.title")}
                        />
                    </div>
                </div>
                <ParagraphCustom
                    text={t("rewardsPerNight:establishment-section.description")}
                />
            </div>
            {
                dataConfiguration.establishments.length > 0 ?
                    (
                        <div className="col-12 p-0 display-tag-config panel-content-tags">
                            {
                                dataConfiguration.establishments.map((e, i) => {
                                    return (
                                        <TagInfoCustom
                                            key={i}
                                            class="tags-margin"
                                            text={e.name}
                                        />
                                    )
                                })
                            }
                        </div>
                    ) :
                    (
                        <NotResults
                            section={"rewardsPerNigthtEstablishments"}
                        />
                    )
            }
        </>
    );
}
