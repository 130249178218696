import { Modal } from 'antd';

const confirm = Modal.confirm;
const info = Modal.info;
const error = Modal.error;
const success = Modal.success;
const warning = Modal.warning;

export function showConfirmOrCancel(mainTitle, message, okTextButton, koTextButton, onCancelFunction = null, onOkFunction = null) {
  confirm({
    title: mainTitle,
    content: message,
    okText: okTextButton,
    cancelText: koTextButton,
    onOk: onOkFunction,
    okType: 'danger',
    onCancel:onCancelFunction
  });
}

export function showConfirm(mainTitle, message, button, func = null) {
  confirm({
    title: mainTitle,
    content: message,
    okText : button,
    className:"modal-button-cancel modal-bo",
    onOk() {
      if(func != null){
        func.setmodalLogin(true)
      }
    }
  });
}

export function showInfo(mainTitle, message, button) {
  info({
    title: mainTitle,
    content: message,
    className:"modal-button-cancel modal-bo",
    okText : button
  });
  }

export function showError(mainTitle, message, button) {
  error({
      title: mainTitle,
      content: message,
      className:"modal-button-cancel modal-bo",
      okText :button
  });
}

export function showSuccess(mainTitle, message, button) {
  success({
    title: mainTitle,
    content: message,
    className:"modal-button-cancel modal-bo",
    okText : button
  });
}

export function showWarning(mainTitle, message, buttonAcept,buttonCancel="", func = null) {
  warning({
    title: mainTitle,
    content: message,
    okText : buttonAcept,
    cancelText:buttonCancel,
    onOk() {
      if(func != null){
        func.confirmDelete()
      }
    },
    onCancel(): boolean {
      return false
    },
  });
}
