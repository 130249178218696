import React, { useContext, useState, useEffect } from 'react';
import './faqs.sass';
import { Skeleton } from 'antd';
import { ConfigContext } from '../../hooks/context/config';
import '../../i18n';
import { useTranslation } from 'react-i18next';
import { TitleCustom, ParagraphCustom, PanelsCustom, CollapseCustom } from '../custom/index';
import { FaqsModel } from '../../models/custom/faqs';

export const Faqs = () => {
    const { t } = useTranslation(['urls', 'faqs']);
    const infoClient = useContext(ConfigContext);

    // Estado que controla el "loader" skeleton
    const [loader, setLoader] = useState(true);
    const listFaqs: FaqsModel[] = [
        {
            header: t("faqs:questions.1"),
            description: t("faqs:answers.1", { 0: "[description]" }),
        },
        {
            header: t("faqs:questions.2"),
            description: t("faqs:answers.2", { 0: "[description]" }),
        },
        { header: t("faqs:questions.3"), description: t("faqs:answers.3") },
        {
            header: t("faqs:questions.4", { 0: infoClient.incentiveSiteName }),
            description: t("faqs:answers.4"),
        },
        {
            header: t("faqs:questions.5", { 0: infoClient.incentiveSiteName }),
            description: t("faqs:answers.5"),
        },
        { header: t("faqs:questions.6"), description: t("faqs:answers.6") },
    ];

    useEffect(() => {
        setLoader(false);
    }, []);

    return (
        <div id={t("urls:public.faqs")} className="container special-mb">
            <TitleCustom
                level={1}
                class="title-align-fag"
                text= {t('faqs:main-title')}
            />
            {
                loader ? (
                    <Skeleton active />
                ) : (
                    <CollapseCustom
                        className="black-style"
                        bordered={false}
                    >
                        {listFaqs.map((e, i) => {
                            return (
                                <PanelsCustom
                                    header={e.header}
                                    key={i}
                                >
                                    <ParagraphCustom text={e.description} />
                                </PanelsCustom>
                            );
                        })}
                    </CollapseCustom>
                )
            }
        </div>
    )
}

