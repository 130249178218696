import React, { useContext, useEffect, useState } from "react";
import "./regimens.sass";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import {
  ButtonsCustom,
  DrawerCustom,
  FontIconsCustom,
  ParagraphCustom,
  TableCustom,
  TitleCustom
} from "src/components/custom";
import { LoadingOutlined } from "@ant-design/icons";
import { Input, Skeleton, Spin } from "antd";
import { RestApiClientContext } from "src/hooks/context/pageContext";
import { LangContext } from "src/hooks/context/lang";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import { NotResults } from "src/components/noResultGeneric/notResults";
import { EditRegimen } from "./editRegimen/editRegimen";

interface RegimensItem {
  id: string;
  regimenName: string;
  identityCode: number;
  comment: string;
  canBeDeleted: boolean;
  edit: JSX.Element;
}

export const Regimens = () => {
  const { t } = useTranslation("config");
  const restApiClient = useContext(RestApiClientContext);
  const language = useContext(LangContext);
  const scrollLib = require("react-scroll");
  const scroll = scrollLib.animateScroll;


  const [filterNames, setFilterNames] = useState<any[]>([]);
  const columns = [
    {
      title: t("regimens.table.name"),
      dataIndex: "regimenName",
      key: "regimenName",
      className: "regimenName",
      filters:filterNames,
      onFilter: (value: string, record) => record.regimenName?.indexOf(value) === 0,
    },
    {
      title: t("regimens.table.code"),
      dataIndex: "identityCode",
      key: "identityCode"
    },
    {
      title: t("regimens.table.comment"),
      dataIndex: "comment",
      key: "comment"
    },
    {
      title: null,
      dataIndex: "edit",
      key: "edit",
      width: "20px"
    }
  ];
  const [loadingSkeleton, setLoadingSkeleton] = useState<boolean>(true);
  const [loadingRegimens, setLoadingRegimens] = useState<boolean>(true);
  const [disabledSearch, setDisabledSearch] = useState<boolean>(false);
  const [messageApi, setMessageApi] = useState<string>("");
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [selectedRegimenId, setSelectedRegimenId] = useState(null);
  const [dataRegimens, setDataRegimens] = useState<RegimensItem[]>(null);
  const [canDelete, setCanDelete] = useState(false);
  const [eventsData, setEventsData] = useState<RegimensItem[]>(dataRegimens);
  const [drawerChoices, setDrawerChoices] = useState([]);
  const Search = Input.Search;

  const handleSearch = (searchText) => {
    setEventsData([...dataRegimens.filter((regimen) => regimen?.regimenName?.includes(searchText))]);
  };

  const handleAddRegimen = () => {
    setSelectedRegimenId(null);
    setVisibleDrawer(true);
  };

  const handleEdit = async (cell) => {
    try {
      await setSelectedRegimenId(cell.id);
      await setVisibleDrawer(true);
    } catch (err) {
      alert(err.message);
    }
  };
  const handleRender = () => {
    setLoadingRegimens(true);
    setDisabledSearch(true);
    setMessageApi("");
    restApiClient
      .getRegimens()
      .then((data) => {
        if (data && data.length > 0) {

          const theFilterNames = data.map((e) => {
            return {
              value: e.regimenTranslations?.find((r) => r.idLanguage === language?.lang)?.name,
              text:e.regimenTranslations?.find((r) => r.idLanguage === language?.lang)?.name
            };
          });

          setFilterNames(theFilterNames);


          setDataRegimens(
            data.map((e) => {
              return {
                id: e.id,
                regimenName: e.regimenTranslations?.find((r) => r.idLanguage === language?.lang)?.name,
                identityCode: e.identityCode,
                comment: e?.comment,
                canBeDeleted: e?.canBeDeleted,
                key: e.id,
                edit: (
                  <span onClick={() => handleEdit(e)} className="action-edit">
                    <FontIconsCustom icon={faEdit} />
                  </span>
                )
              };
            })
          );
        } else {
          setDataRegimens([]);
          setMessageApi("not-result");
        }
        setDisabledSearch(false);
        setLoadingSkeleton(false);
        setLoadingRegimens(false);
      })
      .catch((reason: any) => {
        setLoadingRegimens(false);
      })
      .finally(() => {
        setLoadingSkeleton(false);
        setLoadingRegimens(false);
      });
  };

  useEffect(() => {
    handleRender();
    scroll.scrollToTop();
  }, []);

  //Useeffect to check if Room can be deleted
  const [selectedData, setSelectedData] = useState<any>();
  const [loadingSelectedData, setLoadingSelectedData] = useState<boolean>(false);
  useEffect(() => {
    if (selectedRegimenId) {
      setLoadingSelectedData(true);
      restApiClient.getRegimenById(selectedRegimenId).then((data) => {
        setCanDelete(data && data.canBeDeleted);
        setSelectedData(data);
    }).finally(()=>{
      setLoadingSelectedData(false);
    });
    }
  }, [selectedRegimenId]);

  const handleDelete = () : void => {
    restApiClient.deleteRegimen(selectedRegimenId).then((r)=>{
      handleRender();
    }).finally(()=>{
      setVisibleDrawer(false); 
      setSelectedData(undefined); 
      setDrawerChoices([]);
      setVisibleDrawer(false);
      
    });
  }

  return (
    <>
      <div id="regimens" className="special-mb config-tab">
        <div className="row col-12">
          <TitleCustom level={4} class="title-section" text={t("regimens.main-title")} />
        </div>
        <div className="row">
          <div className="col-12">
            <ParagraphCustom class={"p-0"} text={t(`regimens.main-description`)} />
          </div>
        </div>
        <div className="row my-1">
          <div className="col col-md-8 my-1">
            <Search
              disabled={disabledSearch}
              placeholder={t("regimens.search-channel.placeholder")}
              onSearch={(e) => handleSearch(e)}
              allowClear
            />
          </div>
          <div className="col align-self-end buttons my-1">
            <ButtonsCustom
              class={"primary-button"}
              type=""
              htmlType={null}
              onClick={handleAddRegimen}
              size={null}
              content={
                <span>
                  <FontAwesomeIcon icon={faPlus} />
                  <strong>{t("regimens.add-new")}</strong>
                </span>
              }
            />
          </div>
        </div>
        <Skeleton active loading={loadingSkeleton || loadingRegimens}>
          {eventsData?.length < 1 || messageApi !== "" ? (
            <div className="row">
              <NotResults section="regimens" messageApi={messageApi} />
            </div>
          ) : (
            <div className="row">
              <TableCustom
                className="table-regimens col-12 my-4"
                columns={columns}
                dataSource={eventsData || dataRegimens}
                pagination={true}
                bordered
              />
            </div>
          )}
        </Skeleton>
        
      </div>
      <DrawerCustom
          title={
            <header className="d-flex justify-content-between align-items-center">
              <span className="d-block">{t(`regimens.add-${selectedRegimenId ? "edit" : "new"}`)}</span>
              {selectedRegimenId && (
                <ButtonsCustom
                  onClick={handleDelete}
                  content={<span>{t("rooms.drawer-delete")}</span>}
                  danger={true}
                  disabled={!canDelete}
                />
              )}
            </header>
          }
          visible={visibleDrawer}
          setVisible={setVisibleDrawer}
          width={520}
          info={""}
          placement="right"
          closable={true}
          onClose={() => {setVisibleDrawer(false); setSelectedData(undefined); setDrawerChoices([]); setSelectedRegimenId(null);}}
          content={
            <EditRegimen
              isLoading={loadingSelectedData}
              data={selectedData}
              setDrawerChoices={setDrawerChoices}
              drawerChoices={drawerChoices}
              id={selectedRegimenId}
              setVisible={setVisibleDrawer}
              reload={handleRender}
            />
          }
        />
    </>
  );
};
