import React, { useContext, useEffect, useState } from "react";
import "./advancedRetribution.sass";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { RestApiClientContext } from "src/hooks/context/pageContext";
import { ConfigContext } from "../../hooks/context/config";
import { ButtonsCustom, DrawerCustom, TitleCustom } from "../custom";
import { AddRetribution } from "./addRetribution/addRetribution";
import { LangContext } from "src/hooks/context/lang";
import { NewGroup } from "./newGroup.tsx/newGroup";
import { ConfigManagement } from "src/models/config/configManagement";
import Search from "antd/lib/input/Search";
import { Result, Skeleton } from "antd";

export const AdvancedRetribution = () => {
  const { t } = useTranslation(["advancedretributiontranslations", "advancedRetribution, seo, urls"]);
  const scrollLib = require("react-scroll");
  const restApiClient = useContext(RestApiClientContext);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [addNewGroup, setAddNewGroup] = useState(false);
  const [loadingSkeleton, setLoadingSkeleton] = useState<boolean>(true);
  const [loadingChannels, setLoadingChannels] = useState<boolean>(true);
  const [disabledSearch, setDisabledSearch] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const scroll = scrollLib.animateScroll;
  const [messageApi, setMessageApi] = useState<string>("");
  const infoClient = useContext<ConfigManagement>(ConfigContext);
  const language = useContext(LangContext);
  const [eventsData, setEventsData] = useState(data ?? []);
  const userLang = language.GetLangContext();

  const handleRender = () => {
    setData([]);
    setLoadingChannels(true);
    setDisabledSearch(true);
    setMessageApi("");
    setLoadingSkeleton(true);
    restApiClient
      .getAdvancedConfiguration(userLang)
      .then((data) => {
        if (data && data.length > 0) {
          setData(data);
          setEventsData(data);
        } else {
          setData([]);
          setEventsData([]);
          setMessageApi("not-result");
        }
      })
      .catch((reason: any) => {
        console.error(reason);
        setLoadingChannels(false);
      })
      .finally(() => {
        setDisabledSearch(false);
        setLoadingSkeleton(false);
        setLoadingChannels(false);
      });
  };

  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  useEffect(() => {
    handleRender();
  }, []);

  const handleSearch = (searchText) => {
    setEventsData([...data?.filter((group) => group?.name.toLowerCase()?.includes(searchText.toLowerCase()))]);
  };

  return (
    <div>

        <div id="advanced-retribution-search" className="container special-mb">
          <div className="row">
                <TitleCustom level={1} class="title-section" text={t("advancedretributiontranslations:title")} />
          </div>

          <div className="row my-1 d-flex justify-content-between flex-row">
            <Search
              disabled={disabledSearch}
              onSearch={(e) => handleSearch(e)}
              className='search'
            />
            <ButtonsCustom
                id="btn-retribution"
                className="btn-primary"
                type="primary"
                class="button-agent-register-movil"
                onClick={() => setVisibleDrawer((prevState) => !prevState)}
                disabled={disabledSearch}
                size={null}
                content={t("advancedretributiontranslations:create-group")}
              />
          </div>
        </div>
        
        <Skeleton active loading={loadingSkeleton}>
          <div id="advanced-retribution" className="container special-mb">
            
            {data?.length ? (
              eventsData?.map((group, i) => (
                <NewGroup setAddNewGroup={setAddNewGroup} data={group} key={i} reload={handleRender} />
              ))
            ) : (
              <div className="row">
                <div className="button-container box w-100 d-flex justify-content-center align-items-center">
                <Result
                  title={t("advancedretributiontranslations:no-records")}
                />
                </div>
              </div>
            )}
          </div>
          <DrawerCustom
              title={
                <header className="d-flex justify-content-between align-items-center">
                  <span className="d-block">{t(`advancedretributiontranslations:create-group`)}</span>
                </header>
              }
              visible={visibleDrawer}
              setVisible={setVisibleDrawer}
              width={320}
              info={""}
              placement="right"
              closable={true}
              onClose={() => setVisibleDrawer(false)}
              content={<AddRetribution setVisible={setVisibleDrawer} setNewGroup={setAddNewGroup} reload={handleRender} />}
            />
        </Skeleton>
    </div>
  );
};
