import React, { useState, useEffect, useContext } from "react";
import "../../../i18n";
import { useTranslation } from "react-i18next";

import "./structureListReservation.sass";
import {
    faUser,
    faCalendarAlt,
    faHotel,
    faGlobe,
    faBuildingUser,
    faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import {
    FontIconsCustom,
    ButtonsCustom,
    TagInfoCustom,
    PopoverCustom,
    DrawerCustom,
    TooltipCustom,
    ParagraphCustom,
} from "../../custom";
import {
    ExclamationCircleOutlined,
    ClockCircleOutlined,
    MinusCircleOutlined,
    CheckCircleOutlined,
    LoadingOutlined,
    InfoCircleTwoTone,
    FileSearchOutlined,
    FileExclamationOutlined,
    IssuesCloseOutlined
} from "@ant-design/icons";
import moment from "moment";
import { getLocaleDatePicker } from "../../../utils/datePicker";
import { PickerLocale } from "antd/es/date-picker/generatePicker";
import { StateReservations } from "../../../constants/enums";
import { LangContext } from "../../../hooks/context/lang";
import { StructureShowInfoReservation } from "../structureShowInfoReservation/structureShowInfoReservation";
import { FormattedNumber } from "react-intl";
import { ModalRegularization } from "../modalRegularization/modalRegularization";
import { Pagination } from "../../../models/pagination/pagination";
import { Reservation } from "../interfaces/reservation";
import { ReservationInfo } from "../interfaces/reservationInfo";
import { LanguageManagement } from "../../../models/language/languageUserManagement";
import { RestApiClientContext } from "../../../hooks/context/pageContext";
import { Drawer, Spin, Tag, Tooltip } from "antd";
import GetInfo from "src/utils/howItWasCalculated";

interface ReservationData {
    data: Reservation
    setPageResults: (pagination: Pagination) => void
    onchange:any
}

export const StructureListReservation = (props: ReservationData) => {
    const restApiClient = useContext(RestApiClientContext);

    const { data, setPageResults } = props;

    const { t } = useTranslation([
        "contact",
        "seo",
        "urls",
        "agentsRegister",
        "regularization",
        "howItWasCalculated",
    ]);

    const [visible, setVisible] = useState<boolean>(false);
    const [visibleModalChangeStatus, setvisibleModalChangeStatus] = useState<boolean>(false);
    const [reservation, setReservation] = useState<ReservationInfo>();

    const lng: LanguageManagement = useContext(LangContext);
    const lngState: string = lng.GetLangContext();

    const local: PickerLocale = getLocaleDatePicker();
    const [iconTagInfo, setIconTagInfo] = useState<any>(null)
    const [typeTagInfo, setTypeTagInfo] = useState<string>("")


    useEffect(() => {
        switch (data.status.id) {
            case StateReservations.Duplicated:
            case StateReservations.Rejected:
            case StateReservations.Cancelled:
                setIconTagInfo(<MinusCircleOutlined />);
                setTypeTagInfo("error");
                break
            case StateReservations.Incomplete:
                setIconTagInfo(<ExclamationCircleOutlined />);
                setTypeTagInfo("warning");
                break
            case StateReservations.Regularized:
            case StateReservations.Pending:
                setIconTagInfo(<ClockCircleOutlined />);
                setTypeTagInfo(data.status.id === StateReservations.Regularized ? "processing" : "default");
                break

            default:
                setIconTagInfo(<CheckCircleOutlined />);
                setTypeTagInfo("success");
                break

        }
    }, []);

    const [loading, setLoading] = useState<boolean>(false);
    const showInfoReservation = (idBooking, section) => {
        setLoading(true);
        restApiClient.getBooking(idBooking, lngState)
            .then((data) => {
                setReservation(data)
                if (section === "info") {
                    setVisible(true)
                } else {
                    setvisibleModalChangeStatus(true)
                }
            })
            .finally(()=>{
                setLoading(false);
            })
    };

    const onCloseDrawer = () => {
        setVisible(false);
    };


    const [showHowItWasCalculatedDrawer, setShowHowItWasCalculatedDrawer] = useState<boolean>(false);
	const [howItWasCalculated, setHowItWasCalculated] = useState<any>();
	const [roomsNumbers, setRoomsNumbers] = useState<number[]>([]);
	const showHowItWasCalculated = () : void => {
		setHowItWasCalculated(JSON.parse(data.howItWasCalculated));
	}
	
	const unique = (value, index, self) => {
		return self.indexOf(value) === index
	  }

    const getStatusReasonTag = (statusReason : string) : JSX.Element => {
        var realStatusReason = statusReason.split("-");
        var coincidences = [];

        if (realStatusReason.length > 1){
            coincidences = realStatusReason[1].split("|");
        }


        if (statusReason && statusReason.length > 0){
            let icon : JSX.Element = <></>
            switch(realStatusReason[0]){
                case 'NOT_FOUND':
                    icon = <FileSearchOutlined />
                break;
                case 'WRONG_DATA':
                case 'WRONG_DATA_INVALID_DATES':
                case 'WRONG_DATA_INVALID_ROOM_TYPE':
                case 'WRONG_DATA_INVALID_MEAL_TYPE':
                case 'WRONG_DATA_INVALID_HOTEL':
                case 'WRONG_DATA_MULTIPLE_COINCIDENCE':
                    icon = <FileExclamationOutlined />
                break;
            }

            return <Tooltip title={ coincidences.length > 0 ? coincidences.join(', ') : '' } >
                        <Tag className="padding-inf-tag text-ellipse" style={{width: "auto", marginTop: "-5px", marginLeft: "5px", backgroundColor:"#fffbe6", borderColor:"#ffe58f"}}>
                            {icon} {t(`regularization:booking-status-reason.${realStatusReason[0]}`)}
                            
                        </Tag>
                   </Tooltip>
        }else{
            return <></>
        }

        
    }

	useEffect(()=>{
		if (howItWasCalculated && howItWasCalculated != null){
			setRoomsNumbers(howItWasCalculated.ResumeByDay.map(rby => rby.RoomNumber).filter(unique));
			setShowHowItWasCalculatedDrawer(true);
		}
	},[howItWasCalculated])

    return (
        <>
            <Drawer
                title={t('howItWasCalculated:title')}
                closable={true}
                onClose={()=>{setShowHowItWasCalculatedDrawer(false)}}
                visible={showHowItWasCalculatedDrawer}
                >
                    {
                        howItWasCalculated ? GetInfo(roomsNumbers, howItWasCalculated, t, data.currency) : 'N/D'
                    }
			</Drawer>
            <div className="col-12 p-0">
                <div className="col-12 p-0 row m-0">
                    <div className="col-12 col-md-7 p-0 order-12 order-md-1">
                        <div className="display-data-info-flex">
                            <TooltipCustom
                                title={t("regularization:tooltip.date")}
                                info={
                                    <div>
                                        <FontIconsCustom
                                            classIcon={"icons-list-show"}
                                            icon={faCalendarAlt}
                                        />
                                    </div>
                                }
                                bgcolor="black"
                                placement={"bottom"}
                            />
                            <ParagraphCustom
                                class={"m-0"}
                                text={
                                    <div>
                                        <strong>
                                            {moment(data.checkIn).format(
                                                local.dateFormat
                                            ) +
                                                " - " +
                                                moment(data.checkOut).format(
                                                    local.dateFormat
                                                )}
                                        </strong>
                                        {
                                            data.creationDate ? <small style={{display: "block"}}>{t("regularization:creation-date")}: {moment(data.creationDate).format(local.dateFormat)}</small> : <></>
                                        }
                                        {
                                            data.updateDate && !data.updateDate.startsWith("0001-01-01") ? <small style={{display: "block"}}>{t("regularization:update-date")}: {moment(data.updateDate).format(local.dateTimeFormat)}</small> : <></>
                                        }
                                    </div>
                                }
                            />
                        </div>
                        <div className="display-data-info-flex mt-1">
                            <TooltipCustom
                                title={t("regularization:tooltip.name")}
                                info={
                                    <div>
                                        <FontIconsCustom
                                            classIcon={"icons-list-show"}
                                            icon={faUser}
                                        />
                                    </div>
                                }
                                bgcolor="black"
                                placement={"bottom"}
                            />
                            <ParagraphCustom
                                class={"m-0"}
                                text={data.nameSurnameClient}
                            />
                        </div>
                        <div className="display-data-info-flex mt-1">
                            <TooltipCustom
                                title={t("regularization:tooltip.establishment")}
                                info={
                                    <div>
                                        <FontIconsCustom
                                            classIcon={"icons-list-show"}
                                            icon={faHotel}
                                        />
                                    </div>
                                }
                                bgcolor="black"
                                placement={"bottom"}
                            />
                            <ParagraphCustom
                                class={"m-0"}
                                text={
                                    <strong>
                                        {data.establishmentName}
                                    </strong>
                                }
                            />
                        </div>
                        <div className="display-data-info-flex mt-1">
                            <TooltipCustom
                                title={t("regularization:tooltip.agency")}
                                info={
                                    <div>
                                        <FontIconsCustom
                                            classIcon={"icons-list-show"}
                                            icon={faGlobe}
                                        />
                                    </div>
                                }
                                bgcolor="black"
                                placement={"bottom"}
                            />
                            <ParagraphCustom
                                class={"m-0"}
                                text={
                                    <>
                                        {(data.agencyName?.length > 0 ? (data.agencyName) : '')}
                                        {(data.agencyBrandName?.length > 0 ? (<small style={{display:"block"}}>{data.agencyBrandName}</small>) : '')}
                                        {(data.agencyLocation?.length > 0 ? <small style={{display:"block"}}>{data.agencyLocation}</small> : '')}
                                    </>
                                }
                            />
                        </div>
                        <div className="display-data-info-flex mt-1">
                            <TooltipCustom
                                title={t("regularization:tooltip.agent-name")}
                                info={
                                    <div>
                                        <FontIconsCustom
                                            classIcon={"icons-list-show"}
                                            icon={faBuildingUser}
                                        />
                                    </div>
                                }
                                bgcolor="black"
                                placement={"bottom"}
                            />
                            <ParagraphCustom
                                class={"m-0"}
                                text={<>
                                        {data.agentName} 
                                        <small style={{display: "block"}}>
                                            {data.agentEmail}
                                        </small>
                                      </>}
                            />
                        </div>
                    </div>
                    <div className="col-12 p-0 col-md-5 display-elements-list-reservation order-1 order-md-1">
                        <div className="col-12 col-md-11 p-md-0 row options-box display-elements-locator">
                            <div className="col-6 col-md-6 pl-0 align-info-reservation-r">
                                <ParagraphCustom
                                    class={"m-0 label-res"}
                                    text={t("regularization:list.locator")}
                                />
                                <ParagraphCustom
                                    class={"m-0"}
                                    text={
                                        <strong>
                                            {data.bookingReference}
                                        </strong>
                                    }
                                />
                            </div>
                            {data.ownReference &&
                                <div className="col-6 col-md-6 pl-0 align-info-reservation-r order-12 order-md-6">
                                    <ParagraphCustom
                                        class={"m-0 label-res"}
                                        text={t("regularization:list.own-locator")}
                                    />
                                    <ParagraphCustom
                                        class={"m-0"}
                                        text={
                                            <strong>
                                                {data.ownReference}
                                            </strong>
                                        }
                                    />
                                </div>
                            }
                            {(data.reward !== null && data.reward > 0 && (
                                data.status.id === StateReservations.Cancelled ||
                                data.status.id === StateReservations.Validated ||
                                data.status.id === StateReservations.Regularized
                            )
                            ) ?
                                (
                                    <div className="col-6 col-md-12 p-0 align-info-reservation-l order-6 order-md-12">
                                        <ParagraphCustom
                                            class={"m-0 label-res"}
                                            text={t("regularization:list.reward")}
                                        />
                                        <ParagraphCustom
                                            class={"m-0"}
                                            text={
                                                <>
                                                    <strong>
                                                        <FormattedNumber
                                                            value={data.reward}
                                                            style="currency"
                                                            currency={data.currency}
                                                        />
                                                    </strong>
                                                    {
                                                        data.howItWasCalculated ? <InfoCircleTwoTone onClick={showHowItWasCalculated} style={{marginLeft: "5px"}}/> : ''
                                                    }
                                                </>
                                            }
                                        />
                                        
                                    </div>
                                ) :
                                (<></>)}
                        </div>
                        <div className="col-md-1 p-0 display-popover">
                            <PopoverCustom
                                classBody={"position-float-right"}
                                body={
                                    <ButtonsCustom
                                        id={
                                            "btn-validate-agent-" +
                                            data.id
                                        }
                                        class={"button-list-agents"}
                                        htmlType={null}
                                        disabled={false}
                                        size={null}
                                        content={
                                            <FontIconsCustom
                                                classIcon={
                                                    "icons-list-reservation-register"
                                                }
                                                icon={faEllipsisV}
                                            />
                                        }
                                    />
                                }
                                content={
                                    <div
                                        className="link-menu-popover"
                                        onClick={() =>
                                            showInfoReservation(
                                                data.id,
                                                "info"
                                            )
                                        }
                                    >
                                        {t(
                                            "regularization:list.popover-menu.show-info"
                                        )}
                                    </div>
                                }
                                placement={"bottom"}
                                trigger="focus"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 p-0 mt-3 display-button-tag-list">
                    <div className="col-md-6 col-12 p-0" style={{display: "flex", alignItems: "center", justifyContent: "left"}}>
                        <TooltipCustom
                                title={data.status.description}
                                info={
                                    <div className="position-box-info-tooltip">
                                        <TagInfoCustom
                                            class={
                                                "padding-inf-tag text-ellipse"
                                            }
                                            icon={iconTagInfo}
                                            type={typeTagInfo}
                                            text={data.status.name}
                                        />
                                    </div>
                                }
                                bgcolor="black"
                                placement={"bottomLeft"}
                            />
                            {
                                data.statusReason ? getStatusReasonTag(data.statusReason) : ''
                            }
                            {
                                data?.automationSkipped && (data.automationSkipped+'') === "True" || data.automationSkipped === true ? 
                                    <Tag className="padding-inf-tag text-ellipse" 
                                         style={{width: "auto", marginTop: "-5px", marginLeft: "5px", backgroundColor:"#fffbe6", borderColor:"#ffe58f"}}>
                                            <IssuesCloseOutlined /> {t(`regularization:booking-status-reason.skipped`)}
                                    </Tag> 
                                : 
                                    ''
                            }
                        
                    </div>
                    <div className="col-md-6 col-12 p-0">
                        {(data.status.id === StateReservations.Pending ||
                            data.status.id === StateReservations.Regularized ||
                            data.status.id === StateReservations.Incomplete) && (
                                <ButtonsCustom
                                    id={
                                        "btn-validate-agent-" +
                                        data.status.id
                                    }
                                    class={
                                        "primary-button button-validate-agent-register"
                                    }
                                    type=""
                                    htmlType={null}
                                    onClick={() =>
                                        showInfoReservation(
                                            data.id,
                                            "changeStatus"
                                        )
                                    }
                                    disabled={false}
                                    size={null}
                                    content={<>{loading ? <LoadingOutlined style={{marginRight: "5px"}}/>: ''}{t("regularization:button-list")}</>}
                                />
                            )}
                    </div>
                </div>
            </div>
            {visible && (
                <DrawerCustom
                    title={t("regularization:info-reservation.title", { 0: reservation.status.name })}
                    visible={visible}
                    setVisible={setVisible}
                    info={""}
                    placement="right"
                    closable={true}
                    onClose={onCloseDrawer}
                    content={
                        <StructureShowInfoReservation
                            id={data.id}
                            reservation={reservation}
                        />
                    }
                />
            )}
            {visibleModalChangeStatus && (
                <ModalRegularization onchange={props.onchange} idReservation={data.id} reservation={reservation} setvisibleModalChangeStatus={setvisibleModalChangeStatus} setPageResults={setPageResults} />
            )}
        </>
    );
};
