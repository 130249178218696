import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Skeleton } from "antd";
import { FormCustom, ButtonsCustom, InputCustom, SwitchCustomPro } from "src/components/custom";
import { RestApiClientContext } from "src/hooks/context/pageContext";


import "./editChannel.sass";

interface EditChannelProps {
  id: string
  setVisible: (state: boolean) => void
  reload: () => void
}
interface DataChannel {
	identityCode: string;
	name: string;
	isActive: boolean;
	favorite: boolean;
	id?: string
}

const defaultData: DataChannel = {
  identityCode: '',
  name: '',
  isActive: true,
  favorite: false
}

export const EditChannel = (props: EditChannelProps) => {
  const { t } = useTranslation(["config"]);
  const { id, setVisible, reload } = props;
  const [fieldsData, setFieldsData] = useState(defaultData);
  const [isDisabled, setIsDisabled] = useState(false)
  const restApiClient = useContext(RestApiClientContext);
  const [form] = Form.useForm();

  const [loadingData, setLoadingData] = useState<boolean>(false);

  const resetForm = () : void => {
    form.resetFields();
    setFieldsData(defaultData);
  }

  useEffect(() => {
    if (id) {
      resetForm();
      setLoadingData(true);
      restApiClient.getChannelById(id).then((data) => setFieldsData(data)).finally(()=>{setLoadingData(false);});
    }else{
      resetForm();
    }
  }, [id]);

  useEffect(() => {
    if (fieldsData) {
      form.setFieldsValue({
        "name": fieldsData?.name,
        "identityCode": fieldsData?.identityCode,
        "isActive": fieldsData?.isActive
      } as DataChannel);
    }else{
      resetForm();
    }
  }, [fieldsData]);

  const handleOnChange = e => {
    setFieldsData({ ...fieldsData, [e[0].name]: e[0].value });
  };

  const handleSave = async () => {
    try {
      if (id) {
        const body: DataChannel = {
          "id": id,
          "name": form.getFieldValue("name"),
          "identityCode": form.getFieldValue("identityCode"),
          "isActive": form.getFieldValue("isActive"),
          "favorite": fieldsData?.favorite
        };
        await restApiClient.updateChannel(id, body).then((r:any)=>{
          resetForm();
        });
      } else {
        const body: DataChannel = {
          "name": form.getFieldValue("name"),
          "identityCode": form.getFieldValue("identityCode"),
          "isActive": form.getFieldValue("isActive"),
          "favorite": false
        };
        await restApiClient.createChannel(body).then((r:any)=>{
          resetForm();
        });
      }
    } finally {
      setVisible(false)
      reload()
    }
  };

  const handleCancel = () : void => {
    setFieldsData(defaultData);
    form.resetFields();
    setVisible(false);
  }

  return (
    <Skeleton active loading={loadingData}>
        <FormCustom
          onFinish={handleSave}
          form={form}
          layout="vertical"
          id="edit-channel"
          onFieldsChange={handleOnChange}
        >
          <Form.Item
            className="col form-item"
            label={t("channels.drawer-name")}
            name="name"
            rules={[
              {
                whitespace: true,
                message: t("validations.name"),
                required: true,
              },
            ]}
          >
            <InputCustom placeholder={t("channels.drawer-placeholder")} />
          </Form.Item>
          <Form.Item
            className="col form-item"
            label={t("channels.drawer-code")}
            name="identityCode"
            rules={[
              {
                whitespace: true,
                message: t("validations.code"),
              },
            ]}
          >
            <InputCustom placeholder={t("channels.drawer-placeholder")} />
          </Form.Item>
          <Form.Item
            name="isActive"
            valuePropName="checked"
            label={t("channels.drawer-isActive")}
          >
            <SwitchCustomPro
              checked
              checkedChildren={t("channels.drawer-yes")}
              unCheckedChildren={t("channels.drawer-no")}
              onChange={() => setFieldsData({ ...fieldsData, isActive: !fieldsData?.isActive })}
            />
          </Form.Item>
          <Form.Item className="form-item">
            <div className="d-flex buttons justify-content-end">
            <ButtonsCustom
                className="btn-secondary"
                htmlType="button"
                onClick={handleCancel}
                content={<span>{t("channels.drawer-cancel")}</span>}
              />
              <ButtonsCustom
                disabled={isDisabled}
                className="btn-primary"
                type="primary"
                htmlType="submit"
                content={<span>{t("channels.drawer-save")}</span>}
              />
            </div>
          </Form.Item>
        </FormCustom>
    </Skeleton>
  );
};
