import React, { useState } from "react";
import "../../../i18n";
import { Modal } from "antd";

// Componente de modal sin footer
export const ModalCustomComponent = (param) => {
	const [visible, setVisible] = useState(true);

	// Cerrar modal
	const handleCancel = (e) => {
		setVisible(false);
		param.setVisible(false);
	};

	return (
		<Modal
			title={param.title}
			className={param.class}
			visible={visible}
			closable={param.closable}
			footer={null}
			onCancel={handleCancel}
		>
			{param.content}
		</Modal>
	);
};

// Componente de modal sin footer
export const ModalCustomPruComponent = (param) => {
	return (
		<Modal
			title={param.title}
			className={param.class}
			visible={param.visible}
			closable={param.closable}
			footer={null}
			onCancel={param.onCancel}
		>
			{param.content}
		</Modal>
	);
};

// Componente de modal con botones en el footer
export const ModalCustomButtonsComponent = (props) => {
	const [visible, setVisible] = useState(true);

	const handleOk = (e) => {
		setVisible(false);
		props.onOk();
	};

	const handleCancel = (e) => {
		setVisible(false);
		props.onCancel();
  };

	return (
		<Modal
			{...props}
			onOk={handleOk}
			onCancel={handleCancel}
			visible={visible}
		>
			{props.children}
		</Modal>
	);
};

// Componente de modal con botones en el footer
export const ModalCustomProComponent = (props) => {

	return (
		<Modal
			{...props}
		>
			{props.children}
		</Modal>
	);
};
