import React, { useState, useContext, useEffect } from "react";
import * as AjaxAPI from "../../../api/ajaxApi";
import "./legal-advice.sass";
import { Helmet } from "react-helmet-async";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../hooks/context/config";
import ReactHtmlParser from "react-html-parser";
import scrollLib from "react-scroll";
import { URL_BASE } from "../../../constants/constants";
import { showNotification } from "../../../utils/notificationsGeneric";
import { ClientDataModel } from "../../../models/config/clientData";
import { TitleCustom } from "../../custom";
import { useLegalTexts } from "../legalTexts";

const scroll = scrollLib.animateScroll;

// Componente de información de Política de privacidad de la app
export const LegalAdvice = () => {
	const infoClient = useContext(ConfigContext); // TODO: Actualizar cuando obtengamos los datos de infoClient confirmados
	const { t } = useTranslation(["legalAdvice", "seo", "urls"]);

	const clientData = useLegalTexts(t)

	useEffect(() => {

		scroll.scrollToTop();

	}, []);

	return (
		<>
			<Helmet>
				<title>
					{infoClient.incentiveSiteName} |{" "}
					{t("seo:title.legal-advice")}
				</title>
				<meta
					name="description"
					content={t("seo:description.legal-advice", {
						0: infoClient.incentiveSiteName,
					})}
				/>
				<meta name="robots" content="noindex,nofollow" />
				<link
					rel="canonical"
					href={URL_BASE + t("urls:public.legal-advice")}
				/>
				<link
					rel="icon"
					type="image/ico"
					href={infoClient.favicon}
					sizes="32x32"
				/>
				<link
					href={URL_BASE + t("urls:public.legal-advice")}
					hrefLang="es-ES"
					rel="alternate"
				/>
			</Helmet>

			<div className="container mt-3 special-mb">
				<TitleCustom
					level={4}
					class="main-title-color"
					text={t("legalAdvice:main-title")}
				/>

				{ReactHtmlParser(t("legalAdvice:section.title.1"))}
				{ReactHtmlParser(
					t("legalAdvice:section.paragraph.1", {
						0: clientData.ownURLPrivacyPolicy,
						1: clientData.socialName,
					})
				)}
				{ReactHtmlParser(
					t("legalAdvice:section.paragraph.1-1", {
						0: clientData.socialName,
						1: clientData.incentiveSiteName,
						2: clientData.identityCode,
						3: clientData.socialAddress,
						4: clientData.mercantileRegistry,
					})
				)}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.1-2"))}
				{ReactHtmlParser(
					t("legalAdvice:section.paragraph.1-3", {
						0: clientData.telephone,
						1: clientData.emailContact,
					})
				)}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.1-4"))}

				{ReactHtmlParser(t("legalAdvice:section.title.2"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.2"))}

				{ReactHtmlParser(t("legalAdvice:section.title.3"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.3-1"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.3-2"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.3-3"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.3-4"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.3-5"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.3-6"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.3-7"))}

				{ReactHtmlParser(t("legalAdvice:section.title.4"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.4"))}
				{ReactHtmlParser(
					t("legalAdvice:section.paragraph.4-1", {
						0: clientData.identityCode,
					})
				)}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.4-2"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.4-3"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.4-4"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.4-5"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.4-6"))}
				{ReactHtmlParser(
					t("legalAdvice:section.paragraph.4-7", {
						0: clientData.socialAddress,
						1: clientData.emailContact,
					})
				)}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.4-8"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.4-9"))}
				{ReactHtmlParser(
					t("legalAdvice:section.paragraph.4-10", {
						0: t("urls:public.privacy-policies"),
					})
				)}

				{ReactHtmlParser(t("legalAdvice:section.title.5"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.5"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.5-1"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.5-2"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.5-3"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.5-4"))}

				{ReactHtmlParser(t("legalAdvice:section.title.6"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.6"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.6-1"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.6-2"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.6-3"))}

				{ReactHtmlParser(t("legalAdvice:section.title.7"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.7"))}

				{ReactHtmlParser(t("legalAdvice:section.title.8"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.8"))}

				{ReactHtmlParser(t("legalAdvice:section.title.9"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.9"))}

				{ReactHtmlParser(t("legalAdvice:section.title.10"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.10"))}

				{ReactHtmlParser(t("legalAdvice:section.title.11"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.11"))}

				{ReactHtmlParser(t("legalAdvice:section.title.12"))}
				{ReactHtmlParser(t("legalAdvice:section.paragraph.12"))}
			</div>
		</>
	);
};

