  // Emparejamiento de las routes con sus respectivos enlaces
const baseStrings = {
  /** Routes */
  'routes.home': '/',
  'routes.Faqs': '/preguntas-frecuentes',
  'routes.contact': '/contacto',
  'routes.recoverPassword':'/recuperar-password',
  'routes.privacyPolicies': '/politicas-privacidad',
  'routes.cookiesPolicy': '/politica-cookies',
  'routes.legalAdvice': '/aviso-legal',
  'routes.termConditions': '/terminos-condiciones',
  'routes.homePrivate':'/app',
  'routes.privacyPoliciesPrivate': '/app/politicas-privacidad',
  'routes.cookiesPolicyPrivate': '/app/politica-cookies',
  'routes.legalAdvicePrivate': '/app/aviso-legal',
  'routes.termConditionsPrivate': '/app/terminos-condiciones',
  'routes.agentsRegister': '/app/registro-agentes',
  'routes.regularization': '/app/regularizacion',
  'routes.news': '/app/gestion-noticias',
  'routes.rewards': '/app/recompensas',
  'routes.rewardsPerNight': '/app/recompensas-noche',
  'routes.news.create': '/app/nueva-noticia',
  'routes.news.edit': '/app/editar-noticia/:id',
  'routes.FaqsPrivate': '/app/preguntas-frecuentes',
  'routes.news.detail': '/app/detalle-noticia/:id',
  'routes.config': '/app/configuracion',
  'routes.advanced': '/app/retribucion-avanzada',
  'routes.statistics': '/app/estadísticas',
  'routes.targets':'/app/targets/',
  'routes.billing':'/app/facturacion',
};

export type LanguageStrings = typeof baseStrings;
export const es = baseStrings;
