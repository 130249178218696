import React from "react";
import "../../../i18n";
import "./list-news.sass";
import { URL_IMAGE } from "../../../constants/constants";
import {
	ListCustomPro,
	ListItemCustomPro,
	ListItemMetaCustomPro,
	AvatarListMetaCustom,
} from "../../custom";
import { CustomPagination } from "../../../components/utils/pagination/pagination";
import { TotalElementsTableList } from "../../../components/utils/totalElementsTableList/totalElementsTableList";
import { Pagination } from "../../../models/pagination/pagination";
import { ListItem } from "../../../components/custom/lists/interfaceLists";

export const ListNews = (param) => {

	// Función que se dispara en el evento de cambiar de página
	const onChangeCallApi = (pagination: Pagination) => {
		param.setPageResults(pagination);
	};

	const getRandomKeyForImage = () : number => {
		return Math.random();
	}

	return (
		<div className="col-12 content-list-news">
			<ListCustomPro
				itemLayout={"horizontal"}
				dataSource={param.data}
				renderItem={(item: ListItem) => (
					<ListItemCustomPro className={"p-0 news-element-block"}>
						<ListItemMetaCustomPro
							className={"box-list-news"}
							avatar={
								<AvatarListMetaCustom
									shape="square"
									className={"avatar-list-news"}
									src={item.avatar ? `${item.avatar}?id=${getRandomKeyForImage()}` : URL_IMAGE + "no-image.jpg"}
								/>
							}
							title={item.title}
							description={item.description}
						/>
					</ListItemCustomPro>
				)}
			/>
			<div className="col-12 p-0 pagination-container">
				<CustomPagination
					setPageResults={param.setPageResults}
					pageResults={param.pageResults}
					countData={param.count}
					onChangeCallApi={onChangeCallApi}
				/>
			</div>
			<div className="col-12 p-0">
				<TotalElementsTableList total={param.count} />
			</div>
		</div>
	);
};
