import { RewardOwn, RewardsConfigurationOwn, RewardSupplierDto } from 'src/models/rewards/Reward';
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { showNotification } from '../../utils/notificationsGeneric';
import { Skeleton } from "antd";
import { ParagraphCustom, TitleCustom, ModalCustomButtons, InputCustom } from "../custom";
import { RewardsOwnEdit } from './rewardOwnEdit';
import { RewardsOwn } from './rewardsOwn';
import { RewardsSupplierList } from './rewardsSupplierList';
import { useContext } from 'react';
import { ConfigContext } from "../../hooks/context/config";
import { URL_BASE } from "../../constants/constants";
import { DefaultPagination, Pagination } from '../../models/pagination/pagination';
import { RestApiClientContext } from "../../hooks/context/pageContext";

export const Rewards = () => {
    const { t } = useTranslation(["contact", "seo", "urls", "rewards"]);
    const infoClient = useContext(ConfigContext);

    const [loading, setLoading] = useState<boolean>(true);
    const [loadingRewardsSuppliers, setLoadingRewardsSupplier] = useState<boolean>(true);
    const [pageResults, setPageResults] = useState<Pagination>(DefaultPagination);

    const [rewardsOwn, setRewardsOwn] = useState<RewardsConfigurationOwn | undefined>();
    const [monthsValidity, setMonthsValidity] = useState<number>();
    const [rewardsOwnEdits, setRewardsOwnEdits] = useState<RewardOwn[]>([]);

    const [rewardsSuppliers, setRewardsSuppliers] = useState<RewardSupplierDto[]>([]);
    const [totalRewardsSuppliers, setTotalRewardsSuppliers] = useState<number>();

    const [visibleModalEditRewardsOwn, setVisibleModalEditRewardsOwn] = useState<boolean>(false);
    const restApiClient = useContext(RestApiClientContext);

    useEffect(() => {
        restApiClient.GetRewardsOwn()
            .then((data) => {
                if (data) {
                    setRewardsOwn(data);
                    setMonthsValidity(data.monthsValidity);
                    setRewardsOwnEdits(data.ownRewards);
                }
                setLoading(false);
            })
    }, [loading]);

    useEffect(() => {

        setLoadingRewardsSupplier(true);

        restApiClient.GetRewardsSuppliers(pageResults.resultPage, pageResults.page )
            .then((data) => {
                if (data) {
                    setRewardsSuppliers(data.suppliersRewards);
                    setTotalRewardsSuppliers(data.totalRecord);
                    setLoadingRewardsSupplier(false);
                }
                setLoading(false);
            })

    }, [pageResults]);

    const saveOwnReward = () => {

        const rewardsConfigurationUpdate: any[] = [];
        rewardsOwnEdits.map(r => {
            rewardsConfigurationUpdate.push({
                id: r.id,
                minimum: r.minimumAmount,
                maximum: r.maximumAmount,
                increment: r.increment
            });
        });

        restApiClient.UpdateOwnReward(monthsValidity, rewardsConfigurationUpdate )
        .then((data) => {
            if (data) {
                showNotification("success", t("rewards:notification.saved-title"), t("rewards:notification.saved-message"), "topRight");
            } else {
                showNotification("error", t("rewards:notification.error-title"), t("rewards:notification.error-message"), "topRight");
            }
        })
    }

    const editRewardsOwn = () => {
        setVisibleModalEditRewardsOwn(true);
    }

    const saveRewardsOwnEdits = () => {
        saveOwnReward();
        setVisibleModalEditRewardsOwn(false);
        setLoading(true);
    }

    const cancelRewardsOwnEdits = () => {
        setMonthsValidity(rewardsOwn.monthsValidity);
        setRewardsOwnEdits(rewardsOwn.ownRewards);
        setLoading(true);
        setVisibleModalEditRewardsOwn(false);
    }

    const changeReward = (reward: RewardOwn) => {
        const newRewardsOwnEdits = rewardsOwnEdits;
        const index = newRewardsOwnEdits.findIndex(r => r.id === reward.id);
        if (index > -1) {
            newRewardsOwnEdits[index] = reward;
        }
        setRewardsOwnEdits(newRewardsOwnEdits);
    }

    return (
        <>
            <Helmet>
                <title>
                    {infoClient.incentiveSiteName} | {t("seo:title.contact")}
                </title>
                <meta
                    name="description"
                    content={t("seo:description.contact", {
                        0: infoClient.incentiveSiteName,
                    })}
                />
                <meta name="robots" content="index,follow" />
                <link
                    rel="canonical"
                    href={URL_BASE + t("urls:public.contact")}
                />
                <link
                    rel="icon"
                    type="image/ico"
                    href={infoClient.favicon}
                    sizes="32x32"
                />
                <link
                    href={URL_BASE + t("urls:public.contact")}
                    hrefLang="es-ES"
                    rel="alternate"
                />
            </Helmet>
            {
                loading ? (
                    <div className="container margin-bottom-8 d-flex justify-content-center align-items-center cont-skeleton">
                        <Skeleton paragraph={{ rows: 10 }} active />
                    </div>
                ) :
                (
                    <>
                        <div className="row special-mb">
                            <div className="col-12">
                                <TitleCustom
                                    level={1}
                                    class="title-section"
                                    text={t("rewards:title")}
                                />
                                <ParagraphCustom
                                    class={"p-0"}
                                    text={t("rewards:subtitle")}
                                />
                                <RewardsOwn
                                    rewardsOwn={rewardsOwn}
                                    editRewards={editRewardsOwn}
                                />
                                {
                                    loadingRewardsSuppliers ? (
                                        <Skeleton
                                            paragraph={{ rows: 10 }}
                                            active
                                            className="cont-skeleton"
                                        />
                                    ) :
                                    (
                                        <RewardsSupplierList
                                            rewardsSuppliers={rewardsSuppliers}
                                            totalElements={totalRewardsSuppliers}
                                            pageResults={pageResults}
                                            setPageResults={setPageResults}
                                        />
                                    )
                                }
                            </div>
                        </div>

                        {
                            visibleModalEditRewardsOwn &&
                            <ModalCustomButtons
                                class="modal-bo"
                                title={t("rewards:owners.modal-title")}
                                setVisible={cancelRewardsOwnEdits}
                                onCancel={cancelRewardsOwnEdits}
                                cancelText={t("rewards:buttons.cancel")}
                                onOk={saveRewardsOwnEdits}
                                okText={t("rewards:buttons.modal-save")}
                                closable={true}>
                                <div>
                                    {
                                        rewardsOwn.ownRewards.map(reward => <RewardsOwnEdit rewardOwn={reward} onChangeReward={changeReward} />)
                                    }
                                    <div className="display-elements-list bg-validity-rewards">
                                        <div className="col-12 p-0">
                                            <ParagraphCustom
                                                text={
                                                    <strong>
                                                        {
                                                            t("rewards:owners.modal-validate")
                                                        }
                                                    </strong>
                                                }
                                            />
                                            <InputCustom
                                                value={monthsValidity}
                                                id={`months-validity`}
                                                placeholder=""
                                                className="main-input"
                                                classIcon={"search-agency-icon-input"}
                                                prefix={false}
                                                allowClear={false}
                                                onChange={e => e.target.value ? setMonthsValidity(parseInt(e.target.value, 10)) : setMonthsValidity(0)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </ModalCustomButtons>
                        }
                    </>
                )
            }
        </>
    )
}
