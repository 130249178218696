// #region Variables de entorno
export const API_URL_TEST: string = process.env.REACT_APP_API_URL!;
export const API_ENV: string = process.env.REACT_APP_CUSTOM_NODE_ENV;
export const URL_BASE: string =  process.env.REACT_APP_URL_BASE;
// #endregion

// #region Gestión de idioma
export const DEFAULT_LANGUAGE: string = "es-ES";
export const LANGUAGE_EN_US: string = "en-US";
export const LANGUAGE_SUPPORT_SYSTEM: string[] = ["es", "en"];
// #endregion

// #region cookies
export const LIST_COOKIES_DEFAULT: string[] = ["user","analytical"];
// //#endregion

// #region cookies
export const URL_COOKIES_DISCARD_ES: string = "politica-cookies";
export const URL_COOKIES_DISCARD_US: string = "cookies-policy";
export const DURATION_COOKIE: number = 63072000000; // por 2 años
// #endregion

// #region Media queries
export const xs: number = 575;  // Si está por debajo de xs => versión móvil pequeño
export const sm: number = 767;  // Si está entre xs y sm => versión móvil grande
export const md: number = 991;  // Si está entre sm y md => versión tablet
export const lg: number = 1199; // Si está entre md y lg => versión desktop pequeña. Superior a lg desktop grande
// #endregion

// #region Links
export const LINKS_MOBILE_PRIVATE: string[] = [
    "start", 
    "agents-register", 
    "regularization", 
    "statistics", 
    "targets",
    "rewards-night", 
    "advanced-retribution", 
    "rewards", 
    "news",
    "billing", 
    "config", 
    "faqs", 
    
];
export const LINKS_LAYOUT_PRIVATE: string[] = [
    "start", 
    "agents-register", 
    "regularization", 
    "statistics", 
    "targets" , 
    "rewards-night", 
    "advanced-retribution", 
    "rewards", 
    "news", 
    "billing",
    "config", 
    "faqs", 
];
export const LINKS_MOBILE_PUBLIC: string[] = ["home", "faqs", "contact"];
export const LINKS_DESKTOP_PUBLIC: string[] = ["home", "faqs"];
// #endregion

// #region Relative URL
// TODO : revisar URL DINAMICA?
export const URL_IMAGE: string = "https://cdn.pro.api.beds2b.com/images/backoffice/";
// #endregion

// #region ValueKey de idiomas
export const LANGS = [
    { url: "es", lang: "es-ES" },
    { url: "en", lang: "en-US" },
    { url: "gb", lang: "en-GB" }
];
// #endregion

// #region Paginación
export const CURRENT_PAGE: number = 1;
export const PAGE_SIZE: number = 6;
// #endregion

//#region Reservation
export const ID_TTOO: string = "TO"
//#endregion

// #region Rewards Configurations
export const DIRECT_SALE_PERCENTAGE: string = "P"
export const DIRECT_SALE_FIXED_VALUE: string = "F"
export const EUR_CURRENCY: string = "EUR"
// #endregion

// #Timeless calendar
export const DATE_INIT_DEFAULT_TIMELESS : string = "01/01/2000"
export const DATE_END_DEFAULT_TIMELESS: string = "01/02/2000"
export const YEAR_DEFAULT_TIMELESS: number = 2000

// #endregion
