import React, { useContext, useEffect, useState } from 'react'
import "../../i18n";
import { useTranslation } from "react-i18next";
import { ParagraphCustom, TitleCustom } from '../custom';

import './TargetsComponent.sass'
import SearchTargetsComponent from './search/SearchTargetsComponent';
import TargetsListComponent from './list/TargetsListComponent';
import * as TargetComponents from 'src/models/targets/TargetModels';
import { RestApiClientContext } from "src/hooks/context/pageContext";
import { Drawer } from 'antd';
import TargetsAddComponent from './add/TargetsAddComponent';
import { UserContext } from 'src/hooks/context/userInfo';
import { Country } from 'src/models/countries/CountriesModels';

interface TargetsComponentProps{}

export const TargetsComponent = (props: TargetsComponentProps) : JSX.Element => {
    const { t } = useTranslation(["targets"]);
    const userContext = useContext(UserContext);
    
    const restApiClient = useContext(RestApiClientContext);

    const [page, setPage] = useState<number>(0);
    const [resultsByPage, setResultsByPage] = useState<number>(10);
    const [description, setDescription] = useState<string>('');
    //El valor por defecto de la variable status debe coincidir con el valor por defecto que hay en el select del formulario (1 = true 0 = false -1 = todos)
    const [status, setStatus] = useState<number>(-1);
    const [entityIds, setEntityIds] = useState<string[]>([]);

    const [showTargetsAdd, setShowTargetsAdd] = useState<boolean>(false);

    const [loadingTargets, setLoadingTargets] = useState<boolean>(false);
    const [targetList, setTargetList] = useState<TargetComponents.TargetDto[]>([])

    const [loadingCountries, setLoadingCountries] = useState<boolean>(false);
    const [availableCountries, setAvailableCountries] = useState<Country[]>([]);
    const getAvailableCountries = () : void => {

        setLoadingCountries(true);
        restApiClient.getCountries(userContext.language ?? 'es-ES').then((r: any)=>{
            setAvailableCountries(r);
        }).finally(()=>{
            setLoadingCountries(false);
        })

    }

    const getTargets = () : void => {

        const filter = {
            description: description, 
            page: page, 
            resultPage: resultsByPage, 
            isActive: status, 
            entityIds: entityIds
        }
      
        setLoadingTargets(true);
        restApiClient.getTargets(filter).then((data) => {
            setTargetList(data);
        }).finally(()=>{
            setLoadingTargets(false);
        });
    }

    const onSearch = () : void => {
        getTargets();
    }

    const onSetParameters = (description: string, status: number, entityIds: string[]) : void =>{
        setDescription(description);
        setStatus(status);
        setEntityIds(entityIds);

    }

    useEffect(()=>{
        onSearch();
        getAvailableCountries();
    },[])
   
    return (
        <div className="targets-component">
            <TitleCustom
                        level={1}
                        class="title-section"
                        text={t('main-title')}
                    />
            <ParagraphCustom
                class={"p-0"}
                text={t('subtitle')}
            />

            <Drawer visible={showTargetsAdd} title={t("titles.drawer-add-title")} closable onClose={()=>{setShowTargetsAdd(false)}}>
                <TargetsAddComponent onFinish={()=>{
                    setShowTargetsAdd(false);
                    getTargets();
                }} isClosed={showTargetsAdd}/>
            </Drawer>

            <SearchTargetsComponent isLoading={loadingTargets || loadingCountries} onCreate={()=>{setShowTargetsAdd(true)}} onSearch={onSearch} onSetParameters={onSetParameters} availableContries={availableCountries}/>

            <TargetsListComponent onRefresh={getTargets} list={targetList} isLoading={loadingTargets || loadingCountries} availableContries={availableCountries}/>
        </div>
    )
}
