import { ParagraphCustom, SelectCustom } from '../../../components/custom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchData } from '../interfaces/configSelect';

export const ConfigSelectRewardsPerNight = (props: SearchData) => {

    const { listOptions, optionSelectedInput, setOptionSelectedInput } = props;
    const { t } = useTranslation(["rewardsPerNight", "translation"]);

    const handleChangeSelected = (e:any) => {
        setOptionSelectedInput(JSON.parse(e));
    };

    return (
        <div className="col-12 p-0">
            <ParagraphCustom
                text={
                    <strong>
                        {t('rewardsPerNight:select-configuration')}
                    </strong>
                }
            />
            <SelectCustom
                id="state"
                className="col-12 main-input"
                options={listOptions}
                values={optionSelectedInput}
                onChange={handleChangeSelected}
            />
        </div>
    );
}
