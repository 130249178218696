import EndPointConfig from '../../models/api/endPointConfig';

const controller = 'Balances';

/// Define all EndPoints for contact section.
export const ExchangeEP : EndPointConfig[] = [
    // Envio de formulario a la API

    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetAvailableBalances'),

    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setPattern('{currency}/bankDetails')
        .setAlias('GetBankDetailsByCurrency'),
];