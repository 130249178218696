// Modelo noticias
export interface News {
    creationDate?: Date,
    featured: boolean,
    id: string,
    idStatus?: string,
    urlImage?: string,
    contents: NewsContent[],
    startDate: Date,
    imageContentType: string,
    imageName: string,
    base64Image: any,
    draft: boolean,

    // cuando pasamos de borrador a activa => no se informa y se pone startDate la fecha de hoy
    // cuando pasamos de activa a activa o de inactiva a activa => tenemos en cuenta el bool si la fecha de startDate se ha modificado
    // si está marcado programada entonces también se pone modifyStartDate = true
    modifyStartDate?: boolean,
}

export interface NewsContent {
    idLanguage: string,
    title: string,
    description: string
}

// Asociación de valores por defecto de los atributos de la paginación
export const DefaultNews: News = {
    id: '',
    featured: false,
    imageContentType: '',
    imageName: '',
    base64Image: null,
    draft: false,
    startDate: new Date(),
    contents: [],
    modifyStartDate: false,
}
