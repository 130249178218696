import React, { useEffect, useState, useContext } from "react";
import { AutoComplete, Divider, Select, Skeleton, Tooltip } from "antd";
import "../../../i18n";
import "../../agentsRegister/searchAgent/searchAgent.sass";
import "./searchReservation.sass";
import { useTranslation } from "react-i18next";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  InputCustom,
  SelectCustom,
  ButtonsCustom,
  ParagraphCustom,
  CollapseCustom,
  PanelsCustom,
  DatePickerCustom
} from "../../custom";
import { DefaultPagination } from "../../../models/pagination/pagination";
import { LangContext } from "../../../hooks/context/lang";
import { PickerLocale } from "antd/es/date-picker/generatePicker";
import { getLocaleDatePicker } from "../../../utils/datePicker";
import moment from "moment";
import { LanguageManagement } from "../../../models/language/languageUserManagement";
import { SearchReservation } from "../interfaces/searchReservation";
import { Status } from "../interfaces/statusReservation";
import { Establishments } from "../interfaces/establishments";
import { RestApiClientContext } from "../../../hooks/context/pageContext";

export const SearchReservationFilter = (props: SearchReservation) => {
  const restApiClient = useContext(RestApiClientContext);

  const {
    searchTermLocatorInput,
    searchTermOwnLocatorInput,
    searchTermAgentEmailInput,
    searchTermStateInput,
    searchTermCheckinDateInput,
    searchTermCheckoutDateInput,
    searchTermAgencyInput,
    searchTermNameInput,
    searchTermEstablishmentInput,
    setSearchTermLocatorInput,
    setSearchTermOwnLocatorInput,
    setSearchTermStateInput,
    setSearchTermCheckinDateInput,
    setSearchTermCheckoutDateInput,
    setSearchTermAgentEmailInput,
    setSearchTermAgencyInput,
    setSearchTermNameInput,
    setSearchTermEstablishmentInput,
    setPageResults,
    setSearching,
    elementFormSearch
  } = props;

  const { t } = useTranslation(["regularization", "translation"]);

  const userLangContext = useContext<LanguageManagement>(LangContext);

  const local: PickerLocale = getLocaleDatePicker();

  const [loader, setLoader] = useState<boolean>(true);

  const [listOptions, setListOptions] = useState<Status[]>([]);
  const [listOptionsEstablishments, setListOptionsEstablishments] = useState<Establishments[]>([]);

  const [selectValue, setSelectValue] = useState<Status>(null);
  const [selectValueEstablishment, setSelectValueEstablishment] = useState<Establishments>(null);

  const [lng, setLng] = useState<string>(userLangContext.GetLangContext());

  const [collapse, setCollapse] = useState<boolean>(true);

  // States de los elementos de búsqueda del formulario
  const [searchTermLocator, setSearchTermLocator] = useState<string>(searchTermLocatorInput);
  const [searchTermOwnLocator, setSearchTermOwnLocator] = useState<string>(searchTermOwnLocatorInput);
  const [searchTermAgentEmail, setSearchTermAgentEmail] = useState<string>(searchTermAgentEmailInput);
  
  const [searchTermState, setSearchTermState] = useState<string>(searchTermStateInput);
  const [searchTermCheckinDate, setSearchTermCheckinDate] = useState<string>(searchTermCheckinDateInput);
  const [searchTermCheckoutDate, setSearchTermCheckoutDate] = useState<string>(searchTermCheckoutDateInput);
  const [searchTermAgency, setSearchTermAgency] = useState<string>(searchTermAgencyInput);
  const [searchTermName, setSearchTermName] = useState<string>(searchTermNameInput);
  const [searchTermEstablishment, setSearchTermEstablishment] = useState<string>(searchTermEstablishmentInput);
  const [agenciesList, setAgenciesList] = useState(null);


  const [location, setLocation] = useState<string>('');

  const searchCriteria = () => {
    setSearching(true);
    setSearchTermLocatorInput(searchTermLocator);
    setSearchTermOwnLocatorInput(searchTermOwnLocator);
    setSearchTermStateInput(searchTermState !== "" ? JSON.parse(searchTermState).id : "");
    setSearchTermCheckinDateInput(searchTermCheckinDate);
    setSearchTermCheckoutDateInput(searchTermCheckoutDate);
    setSearchTermAgencyInput(searchTermAgency);
    setSearchTermNameInput(searchTermName);
    setSearchTermAgentEmailInput(searchTermAgentEmail);
    setSearchTermEstablishmentInput(searchTermEstablishment !== "" ? JSON.parse(searchTermEstablishment).id : "");

    setPageResults(DefaultPagination);
  };

  // Manejadores de los elementos del formulario de búsqueda
  const handleChangeLocator = (e) => {
    setSearchTermLocator(e.target.value);
  };
  const handleChangeOwnLocator = (e) => {
    setSearchTermOwnLocator(e.target.value);
  };

  const handleChangeAgentEmail = (e) => {
    
    setSearchTermAgentEmail(e.target.value);
  }
  


  const handleChangeState = (e) => {
    setSearchTermState(e);
    setSelectValue(JSON.parse(e));
  };
  const handleChangeCheckinDate = (e) => {
    if (e !== null) {
      setSearchTermCheckinDate(moment(e._d).format("YYYY/MM/DD"));
    } else {
      setSearchTermCheckinDate("");
    }
  };
  const handleChangeCheckoutDate = (e) => {
    if (e !== null) {
      setSearchTermCheckoutDate(moment(e._d).format("YYYY/MM/DD"));
    } else {
      setSearchTermCheckoutDate("");
    }
  };
  const handleChangeAgency = (e) => {
    if (e && e.target){
      setSearchTermAgency(e.target.value);
    }else{
      setSearchTermAgency('');
    }
  };
  const handleChangeName = (e) => {
    setSearchTermName(e.target.value);
  };
  const handleChangeEstablishment = (e) => {
    setSearchTermEstablishment(e);
    setSelectValueEstablishment(JSON.parse(e));
  };

  useEffect(() => {
    if (userLangContext.GetLangContext() !== lng) {
      setLng(userLangContext.GetLangContext());
    }
  });

  const [agencyName, setAgencyName] = useState<string>('');
  const onSearch = async (searchText, results = 20) => {
    setAgencyName(searchText);
    await getAgenciesList(searchText, results);
  };

  const getStatusBookingSelect = (lng) => {
    restApiClient.getBookingsStatus(lng).then((data) => {
      const optionsState: Status[] = [
        {
          id: "ALL",
          name: t("regularization:options-state.ALL"),
          description: ""
        }
      ];

      data.map((e, i) => {
        optionsState.push({
          id: e.id,
          name: e.name,
          description: ""
        });
      });

      optionsState.push({
        id: "NOT_FOUND",
        name: `${t('regularization:booking-status-reason.NOT_FOUND')} (${t('regularization:booking-status-reason.automation')})`,
        description: t('regularization:booking-status-reason.NOT_FOUND')
      })

      optionsState.push({
        id: "WRONG_DATA",
        name: `${t('regularization:booking-status-reason.WRONG_DATA')} (${t('regularization:booking-status-reason.automation')})`,
        description: t('regularization:booking-status-reason.WRONG_DATA')
      })

      optionsState.push({
        id: "WRONG_DATA_INVALID_DATES",
        name: `${t('regularization:booking-status-reason.WRONG_DATA_INVALID_DATES')} (${t('regularization:booking-status-reason.automation')})`,
        description: t('regularization:booking-status-reason.WRONG_DATA_INVALID_DATES')
      })

      optionsState.push({
        id: "WRONG_DATA_INVALID_ROOM_TYPE",
        name: `${t('regularization:booking-status-reason.WRONG_DATA_INVALID_ROOM_TYPE')} (${t('regularization:booking-status-reason.automation')})`,
        description: t('regularization:booking-status-reason.WRONG_DATA_INVALID_ROOM_TYPE')
      })

      optionsState.push({
        id: "WRONG_DATA_INVALID_MEAL_TYPE",
        name: `${t('regularization:booking-status-reason.WRONG_DATA_INVALID_MEAL_TYPE')} (${t('regularization:booking-status-reason.automation')})`,
        description: t('regularization:booking-status-reason.WRONG_DATA_INVALID_MEAL_TYPE')
      })

      optionsState.push({
        id: "WRONG_DATA_INVALID_HOTEL",
        name: `${t('regularization:booking-status-reason.WRONG_DATA_INVALID_HOTEL')} (${t('regularization:booking-status-reason.automation')})`,
        description: t('regularization:booking-status-reason.WRONG_DATA_INVALID_HOTEL')
      })

      optionsState.push({
        id: "WRONG_DATA_MULTIPLE_COINCIDENCE",
        name: `${t('regularization:booking-status-reason.WRONG_DATA_MULTIPLE_COINCIDENCE')} (${t('regularization:booking-status-reason.automation')})`,
        description: t('regularization:booking-status-reason.WRONG_DATA_MULTIPLE_COINCIDENCE')
      })

      optionsState.push({
        id: "SKIPPED",
        name: `${t('regularization:booking-status-reason.skipped')} (${t('regularization:booking-status-reason.automation')})`,
        description: t('regularization:booking-status-reason.skipped')
      })

      setListOptions(optionsState);

      const selected = searchTermStateInput
        ? optionsState.find((e) => e.id === searchTermStateInput)
        : {
            id: optionsState[0].id,
            name: optionsState[0].name,
            description: ""
          };
      setSelectValue({
        id: selected.id,
        name: selected.name,
        description: ""
      });
      setLoader(false);
    });
  };

  const GetEstablishmentsSelect = (lng) => {
    restApiClient.getEstablishments().then((data) => {
      const optionsEstablishments: Establishments[] = [
        {
          id: "ALL",
          name: t("regularization:options-state.ALL")
        }
      ];

      data.map((e, i) => {
        optionsEstablishments.push({
          id: e.id,
          name: e.name
        });
      });

      setListOptionsEstablishments(optionsEstablishments);

      const selected = searchTermStateInput
        ? optionsEstablishments.find((e) => e.id === searchTermStateInput)
        : {
            id: optionsEstablishments[0].id,
            name: optionsEstablishments[0].name
          };

      setSelectValueEstablishment({
        id: selected.id,
        name: selected.name
      });

      setLoader(false);
    });
  };

  const getAgenciesList = (name?, results = 20) => {
    const filter = !name
      ? { page: 1, resultPage: results, language: lng, address: location }
      : { page: 1, resultPage: results, name: name, language: lng, address: location };
    restApiClient.getAgencies(filter).then((data) => {
      setAgenciesList(data);
      setLoader(false);
    });
  };

  useEffect(() => {
    getStatusBookingSelect(userLangContext.GetLangContext());
    GetEstablishmentsSelect(userLangContext.GetLangContext());
  }, [lng]);

  const changeHandle = () => {
    if (collapse) {
      setCollapse(false);
    } else {
      setCollapse(true);
    }
  };

  useEffect(()=>{
    getAgenciesList(agencyName);
  },[location])

  return (
    <>
      {loader ? (
        <Skeleton active />
      ) : (
        <div className="col-12 mt-4 mb-4">
          <div className="row pr-3 pl-3 form-search-agency">
            <div className="col-sm-12 col-lg-6 label-left-search-lg">
              {
                //Locator - Booking Reference
              }
              <ParagraphCustom text={<strong>{t("regularization:searchReservationRegister.labels.label-1")}</strong>} />
              <InputCustom
                id={"locator"}
                value={searchTermLocator}
                placeholder=""
                className="main-input"
                prefix={false}
                allowClear={true}
                classIcon={"search-agency-icon-input"}
                icon={faSearch}
                autoFocus={true}
                onChange={handleChangeLocator}
                disabled={elementFormSearch}
              />
            </div>
            <div className="col-sm-12 col-lg-6 label-left-search label-right-search">
              {
                //Status
              }
              <ParagraphCustom text={<strong>{t("regularization:searchReservationRegister.labels.label-2")}</strong>} />
              <SelectCustom
                id="state"
                className="col-12 main-input"
                options={listOptions}
                values={selectValue}
                onChange={handleChangeState}
                disabled={elementFormSearch}
              />
            </div>
            <div className="col-sm-12 col-lg-6 label-left-search-lg">
              {
                //Checkin
              }
              <ParagraphCustom text={<strong>{t("regularization:searchReservationRegister.labels.label-3")}</strong>} />
              <DatePickerCustom
                format={local.dateFormat}
                locale={local}
                placeholder={local.lang.dateFormat}
                className="col-12 main-select"
                onChange={handleChangeCheckinDate}
                disabled={elementFormSearch}
              />
            </div>
            <div className="col-sm-12 col-lg-6 label-left-search-lg no-padding-right">
              {
                //Checkout
              }
              <ParagraphCustom text={<strong>{t("regularization:searchReservationRegister.labels.label-checkout")}</strong>} />
              <DatePickerCustom
                format={local.dateFormat}
                locale={local}
                placeholder={local.lang.dateFormat}
                className="col-12 main-select"
                onChange={handleChangeCheckoutDate}
                disabled={elementFormSearch}
              />
            </div>
            <div className="col-sm-12 col-lg-6 label-left-search-lg">
              {
                //Location
              }
              <ParagraphCustom text={<strong>{t("agentsRegister:searchAgentsRegister.labels.label-4")}</strong>} />
              <InputCustom
                value={location}
                id={"localization"}
                placeholder=""
                className="main-input"
                classIcon={"search-agency-icon-input"}
                prefix={false}
                allowClear={true}
                icon={faSearch}
                onChange={(v)=>{
                  setLocation(v.target.value)
                }}
                disabled={elementFormSearch}
              />
            </div>
            <div className="col-sm-12 col-lg-6 label-left-search label-right-search">
              {
                //Agency
              }
              <ParagraphCustom text={<strong>{t("regularization:searchReservationRegister.labels.label-4")}</strong>} />
              <AutoComplete
                showSearch
                allowClear
                optionFilterProp="children"
                className="col-12 main-select"
                onSearch={(e) => onSearch(e, 20)}
                disabled={elementFormSearch}
                onChange={(v)=>{
                  if (!v || v.length == 0){
                    handleChangeAgency('');
                  }else{
                    setSearchTermAgency(v);
                  }
                }}
                onSelect={(e, opt) => {
                  setSearchTermAgency(opt?.key)
                }}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <span style={{ margin: "6px", display: "block" }}>{`${t(
                      "regularization:searchReservationRegister.tooltip",
                      {
                        0: agenciesList?.totalRecord - 20 < 0 ? 0 : agenciesList?.totalRecord
                      }
                    )}`}</span>
                  </div>
                )}
              >
                {agenciesList?.agencies.map((opt) => (
                  <Select.Option value={opt?.name} key={opt?.id}>
                    {opt?.name}
                    <Tooltip title={opt?.address}>
                      <small style={{display:"block"}}>{opt?.address}</small>
                    </Tooltip>
                  </Select.Option>
                ))}
              </AutoComplete>
            </div>
            <div className="col-12 p-0">
              <CollapseCustom
                onChange={changeHandle}
                expandIconPosition="right"
                className="collapse-search"
                bordered={false}
              >
                <PanelsCustom header={t("regularization:searchReservationRegister.header")} key={1}>
                  <div className="row">
                    <div className="col-sm-12 col-lg-6 label-left-search-lg">
                      <ParagraphCustom
                        text={<strong>{t("regularization:searchReservationRegister.labels.label-5")}</strong>}
                      />
                      <InputCustom
                        value={searchTermName}
                        id="name"
                        placeholder=""
                        className="main-input"
                        classIcon={"search-agency-icon-input"}
                        prefix={false}
                        allowClear={true}
                        icon={faSearch}
                        onChange={handleChangeName}
                        disabled={elementFormSearch}
                      />
                    </div>
                    <div className="col-sm-12 col-lg-6 label-left-search label-right-search">
                      {listOptionsEstablishments.length > 0 && (
                        <>
                          <ParagraphCustom
                            text={<strong>{t("regularization:searchReservationRegister.labels.label-6")}</strong>}
                          />
                          <SelectCustom
                            id="establishment"
                            className="col-12 main-input"
                            disabled={elementFormSearch}
                            options={listOptionsEstablishments}
                            values={selectValueEstablishment}
                            onChange={handleChangeEstablishment}
                          />
                        </>
                      )}
                    </div>
                    <div className="col-sm-12 col-lg-6 label-left-search-lg">
                      <ParagraphCustom
                        text={<strong>{t("regularization:searchReservationRegister.labels.label-7")}</strong>}
                      />
                      <InputCustom
                        id={"ownLocator"}
                        value={searchTermOwnLocator}
                        placeholder=""
                        className="main-input"
                        prefix={false}
                        allowClear={true}
                        classIcon={"search-agency-icon-input"}
                        icon={faSearch}
                        onChange={handleChangeOwnLocator}
                        disabled={elementFormSearch}
                      />
                    </div>
                    <div className="col-sm-12 col-lg-6 label-left-search-lg">
                      <ParagraphCustom
                        text={<strong>{t("regularization:searchReservationRegister.labels.label-agent-email")}</strong>}
                      />
                      <InputCustom
                        id={"agentEmail"}
                        value={searchTermAgentEmail}
                        placeholder=""
                        className="main-input"
                        prefix={false}
                        allowClear={true}
                        classIcon={"search-agency-icon-input"}
                        icon={faSearch}
                        onChange={handleChangeAgentEmail}
                        disabled={elementFormSearch}
                      />
                    </div>
                  </div>
                </PanelsCustom>
              </CollapseCustom>
            </div>
            <div className="col-sm-12 col-lg-6 label-right-search button-search">
              <ButtonsCustom
                id="search-agency"
                class={"primary-button col-12"}
                type="Large"
                htmlType={null}
                onClick={searchCriteria}
                disabled={elementFormSearch}
                size={null}
                content={
                  <span>
                    <strong>{t("regularization:searchReservationRegister.button")}</strong>
                  </span>
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
