import EndPointConfig from '../../models/api/endPointConfig';

const controller: string = 'RewardsConfiguration';

export const RewardsEP: EndPointConfig[] = [
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('own')
        .setAlias('GetRewardsOwn'),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('suppliers')
        .setAlias('GetRewardsSuppliers'),
    new EndPointConfig()
        .setMethod('PUT')
        .setController(controller)
        .setAlias('UpdateOwnReward')
        .setActionName('own'),
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller)
        .setAlias('ChangeStatusReward')
        .setPattern('suppliers/{id}/status'),
    new EndPointConfig()
        .setMethod('PATCH')
        .setController(controller)
        .setAlias('UpdateSupplierReward')
        .setPattern('suppliers/{id}'),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetLimitAmountRewards')
        .setActionName("GetLimitAmountRewards"),
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setAlias('UpdateLimitAmountRewards')
        .setActionName("UpdateLimitAmountRewards"),
];