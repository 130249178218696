import EndPointConfig  from '../../models/api/endPointConfig';

const controller = 'Locations';

/// Define all EndPoints for login agents methods.
export const LocationsEP : EndPointConfig[] = [
    // Get datos para el token de usuario
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('')
        .setAlias('GetLocations'),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('GetCountries')
        .setAlias('GetCountries'),
];
