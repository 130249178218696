import React, { useEffect, useState } from "react";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import "./structureShowInfoReservation.sass";
import { ParagraphCustom, TagInfoCustom } from "../../custom";
import moment from "moment";
import { getLocaleDatePicker } from "../../../utils/datePicker";
import { PickerLocale } from "antd/es/date-picker/generatePicker";
import { StateReservations } from "../../../constants/enums";
import { InfoCircleTwoTone } from '@ant-design/icons'
import {
	MinusCircleOutlined,
	ExclamationCircleOutlined,
	ClockCircleOutlined,
	CheckCircleOutlined,
} from "@ant-design/icons";
import { InfoReservation } from "../interfaces/reservationInfo";
import { FormattedNumber } from "react-intl";
import { Divider, Drawer, Timeline } from "antd";
import GetInfo from "src/utils/howItWasCalculated";

export const StructureShowInfoReservation = (props: InfoReservation) => {

	const { id, reservation } = props;

	const { t } = useTranslation([
		"contact",
		"seo",
		"urls",
		"agentsRegister",
		"translation",
		"regularization",
		"howItWasCalculated",
	]);


	const [iconTagInfo, setIconTagInfo] = useState<any>(null)
	const [typeTagInfo, setTypeTagInfo] = useState<string>("")

	useEffect(() => {

        switch(reservation.status.id){
			case StateReservations.Duplicated:
			case StateReservations.Rejected:
			case StateReservations.Cancelled:
                setIconTagInfo(<MinusCircleOutlined />);
                setTypeTagInfo("error");
                break
            case StateReservations.Incomplete:
                setIconTagInfo(<ExclamationCircleOutlined />);
                setTypeTagInfo("warning");
                break
			case StateReservations.Regularized:
			case StateReservations.Pending:
                setIconTagInfo(<ClockCircleOutlined />);
                setTypeTagInfo(reservation.status.id === StateReservations.Regularized ? "processing": "default");
                break

            default:
                setIconTagInfo(<CheckCircleOutlined />);
                setTypeTagInfo("success");
                break

        }
    }, []);

	const [showHowItWasCalculatedDrawer, setShowHowItWasCalculatedDrawer] = useState<boolean>(false);
	const [howItWasCalculated, setHowItWasCalculated] = useState<any>();
	const [roomsNumbers, setRoomsNumbers] = useState<number[]>([]);
	const showHowItWasCalculated = () : void => {
		setHowItWasCalculated(JSON.parse(reservation.howItWasCalculated));
	}

	const unique = (value, index, self) => {
		return self.indexOf(value) === index
	  }

	useEffect(()=>{
		if (howItWasCalculated && howItWasCalculated != null){
			setRoomsNumbers(howItWasCalculated.ResumeByDay.map(rby => rby.RoomNumber).filter(unique));
			setShowHowItWasCalculatedDrawer(true);
		}
	},[howItWasCalculated])

	const local: PickerLocale = getLocaleDatePicker();

	return (
		<>
			<Drawer
			title={t('howItWasCalculated:title')}
			closable={true}
			onClose={()=>{setShowHowItWasCalculatedDrawer(false)}}
			visible={showHowItWasCalculatedDrawer}
			>
				{
					GetInfo(roomsNumbers, howItWasCalculated, t, reservation.currencyId)
				}
			</Drawer>
			<div className="display-elements-list">
				<div className="col-12 p-0">
					<div className="col-12 p-0 display-status-reservation">
						<div className="col-6 p-0">
							<ParagraphCustom
								class="mb-0 text-title-info-reservation"
								text={t(
									"regularization:info-reservation.locator"
								)}
							/>
							<ParagraphCustom
								class="mb-3"
								text={
									<strong className="color-text-info">
										{reservation.reference}
									</strong>
								}
							/>
						</div>
						<div className="col-6 p-0">
							<TagInfoCustom
								class={"padding-inf-tag-regularization text-ellipse"}
								icon={iconTagInfo}
								type={typeTagInfo}
								text={reservation.status.name}
							/>
						</div>
					</div>
					{reservation.ownReference && (
						<>
							<ParagraphCustom
								class="mb-0 text-title-info-reservation"
								text={t(
									"regularization:info-reservation.own-locator"
								)}
							/>
							<ParagraphCustom
								class="mb-3"
								text={
									<strong className="color-text-info">
										{reservation.ownReference}
									</strong>
								}
							/>
						</>
					)}
					<ParagraphCustom
						class="mb-0 text-title-info-reservation"
						text={t("regularization:info-reservation.client")}
					/>
					<ParagraphCustom
						class="mb-3"
						text={
							<strong className="color-text-info">
								{reservation.nameSurnameClient}
							</strong>
						}
					/>
					<ParagraphCustom
						class="mb-0 text-title-info-reservation"
						text={t(
							"regularization:info-reservation.date-ch-in-out"
						)}
					/>
					<ParagraphCustom
						class="mb-3"
						text={
							<strong className="color-text-info">
								{moment(reservation.checkIn).format(
									local.dateFormat
								) +
									" - " +
									moment(
										reservation.checkOut
									).format(local.dateFormat)}
							</strong>
						}
					/>
					<ParagraphCustom
						class="mb-0 text-title-info-reservation"
						text={t(
							"regularization:info-reservation.establishment"
						)}
					/>
					<ParagraphCustom
						class="mb-3"
						text={
							<strong className="color-text-info">
								{reservation.establishmentName}
							</strong>
						}
					/>
					<ParagraphCustom
						class="mb-0 text-title-info-reservation"
						text={t("regularization:info-reservation.agent")}
					/>
					<ParagraphCustom
						class={"mb-3"}
						text={
							<strong className="color-text-info">
								{reservation.nameSurnameAgent}
							</strong>
						}
					/>
					<ParagraphCustom
						class="mb-0 text-title-info-reservation"
						text={reservation.isFromIndependentAgent ? t("agentsRegister:list.independent") : t("regularization:info-reservation.agency")}
					/>
					<ParagraphCustom
						class={"mb-3"}
						text={
							<strong className="color-text-info">
								{(reservation.agencyName?.length > 0 ? ( reservation.agencyName+ ", ") : '') + reservation.agencyLocation}

							</strong>
						}
					/>
					<ParagraphCustom
						class={"mb-0 text-title-info-reservation"}
						text={t("regularization:info-reservation.channel")}
					/>
					<ParagraphCustom
						class={"mb-3"}
						text={
							<strong className="color-text-info">
								{reservation.bookingWay.name}
							</strong>
						}
					/>
					<ParagraphCustom
						class={"mb-0 text-title-info-reservation"}
						text={t(
							"regularization:info-reservation.date-register"
						)}
					/>
					<ParagraphCustom
						class={"mb-3"}
						text={
							<strong className="color-text-info">
								{moment(reservation.creationDate).format(
									local.dateFormat
								)}
							</strong>
						}
					/>

					<ParagraphCustom
						class={"mb-0 text-title-info-reservation"}
						text={t(
							"regularization:info-reservation.roomsNumber"
						)}
					/>
					<ParagraphCustom
						class={"mb-3"}
						text={
							<strong className="color-text-info">
								{reservation.roomsNumber}
							</strong>
						}
					/>

					{
						reservation.bookingRoomsConfiguration && reservation.bookingRoomsConfiguration.length > 0 ?
							<>
								<ParagraphCustom
									class={"mb-0 text-title-info-reservation"}
									text={t(
										"regularization:info-reservation.roomsExtraInfo"
									)}
								/>
								<ParagraphCustom
									class={"mb-3"}
									text={
										<ul>
											{
												reservation.bookingRoomsConfiguration.map((bookingRoomConfiguration: any, i: number)=>{
													return <li>{bookingRoomConfiguration.roomType}  - {bookingRoomConfiguration.regimen}</li>
												})
											}
										</ul>
									}
								/>
								
							</>
						: 
						'Sin datos adicionales'
					}
					
					{ 
					reservation.reward && reservation.reward > 0 ? 
						<>
							<ParagraphCustom
								class={"mb-0 text-title-info-reservation"}
								text={t("regularization:info-reservation.reward")}
							/>
							<ParagraphCustom
								class={"mb-3"}
								text={
									<>
									<strong className="color-text-info">
										<FormattedNumber
											value={reservation.reward}
											style="currency"
											currency={reservation.currencyId}
										/>
										{
											reservation.howItWasCalculated ? <InfoCircleTwoTone onClick={showHowItWasCalculated} style={{marginLeft: "5px"}}/> : ''
										}
									</strong>
									
									</>
								}
							/>
						</>
						:
						<></>
					}
				</div>
			</div>
		</>
	);
};
