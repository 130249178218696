import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../header.sass";
import "./desktopMenu.sass";
import { MenuPop } from "../../menuPopProfile/menuPop";
import { MenuLanguages } from "../../../../languageSelector/comboLanguage";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { ConfigContext } from "../../../../../hooks/context/config";
import { LinkActiveContext } from "../../../../../hooks/context/linkActive";
import { Wallet } from "../../../../wallet/wallet";
import { Notifications } from "./notifications/notifications";

// Menú visible para versiones escritorio (de MD para arriba)
export const DesktopMenu = (params) => {
  // Libreria i18n para traducir
  const { t } = useTranslation(["urls", "linksNames", "translation"]);

  // Hook Context de la configuracion
  const theme = useContext(ConfigContext);
  const linkActive = useContext(LinkActiveContext);

  // Estado para mostrar u ocultar modal cookie
  const [modalLogin, setmodalLogin] = useState(false);

  return (
    <>
      <div className="col-12 box-header-private">
        <div className="col-4 left-part-header padding-left-header">
          <div className="col-lg-1 links-row padding-icon-collapsed">
            <div className="align-center-links">
              {React.createElement(
                params.collapsedState ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: params.toggle,
                }
              )}
            </div>
          </div>
          <div className="col-lg-3 p-0 links-row">
            <div className="align-center-links">
              <Link
                id="main-logo"
                to={t("urls:private.start")}
                onClick={() =>
                  linkActive.updateValueUrl(t("urls:private.start"))
                }
                disabled={
                  window.location.pathname + window.location.hash === t("urls:private.start")
                }
              >
                <img
                  className="logo"
                  src={theme.logo}
                  title={theme.brandName}
                  alt={theme.brandName}
                />
              </Link>
            </div>
          </div>
          <div className="col-9 links-row"></div>
        </div>
        <div className="d-none d-lg-block col-lg-4">
          <Wallet />
        </div>
        <div className="col-4 right-part-header padding-right-header">
          <div className="col-12 p-0 links-row">
            <div className="align-center-links float-link">
              <div className="content-notifications">
                <Notifications />
              </div>
              <div className="padding-link-desktop secondary-links-svg align-center-links user-header">
                <MenuPop type="desktop" />
              </div>
              <div className="padding-top-flag">
                <MenuLanguages key="1" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="aux-div-header"></div>
    </>
  );
};
