import { notification } from 'antd';

export function showNotification(type, title, message, position) {
    notification[type]({
        message: title,
        description: message,
        placement: position,
        className: "notification-button-cancel"
    });
}
