import { InputCustom, ParagraphCustom } from "../../components/custom";
import { RewardOwn } from "src/models/rewards/Reward";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider } from 'antd';

interface Props {
    rewardOwn: RewardOwn;
    onChangeReward: (reward: RewardOwn) => void;
}

export const RewardsOwnEdit = (props: Props) => {
    const { rewardOwn, onChangeReward } = props;
    const { t } = useTranslation(["contact", "seo", "urls", "rewards"]);

    const [reward, setReward] = useState<RewardOwn>(rewardOwn);

    const setValue = (propName: keyof RewardOwn, value: any) => {
        const newReward = {
            ...reward,
            [propName]: value ? parseInt(value, 10) : 0,
        } as RewardOwn;

        onChangeReward(newReward);
        setReward(newReward);
    }

    return (
        <>
            <div>
                <ParagraphCustom
                    text={
                        <>
                            <span className="table-col-currency">{reward.currency.id}</span> <span className="table-col-symbol">({reward.currency.symbol})</span>
                        </>
                    }
                />
            </div>
            <div className="col-12 p-0 display-elements-list">
                <div className="col-6 p-0">
                    <ParagraphCustom
                        text={
                            <>
                                <strong>{t("rewards:owners.modal-increment")}</strong> <span className='text-increment'>({t("rewards:owners.percentage")})</span>
                            </>
                        }
                    />
                    <InputCustom
                        value={reward.increment}
                        id={`increment-${reward.id}`}
                        placeholder=""
                        className="main-input"
                        classIcon={"search-agency-icon-input"}
                        prefix={false}
                        suffix={"%"}
                        allowClear={false}
                        onChange={e => setValue("increment", e.target.value)}
                    />
                </div>
                <div className="col-sm">
                    <ParagraphCustom
                        text={
                            <strong>
                                {
                                    t("rewards:suppliers.minimum")
                                }
                            </strong>
                        }
                    />
                    <InputCustom
                        value={reward.minimumAmount}
                        id={`minimum-amount-${reward.id}`}
                        placeholder=""
                        className="main-input"
                        classIcon={"search-agency-icon-input"}
                        prefix={false}
                        suffix={reward.currency.symbol}
                        allowClear={false}
                        onChange={e => setValue("minimumAmount", e.target.value)}
                    />
                </div>
                <div className="col-sm p-0">
                    <ParagraphCustom
                        text={
                            <strong>
                                {
                                    t("rewards:suppliers.maximum")
                                }
                            </strong>
                        }
                    />
                    <InputCustom
                        value={reward.maximumAmount}
                        id={`maximum-amount-${reward.id}`}
                        placeholder=""
                        className="main-input"
                        classIcon={"search-agency-icon-input"}
                        prefix={false}
                        suffix={reward.currency.symbol}
                        allowClear={false}
                        onChange={e => setValue("maximumAmount", e.target.value)}
                    />
                </div>
            </div>
            <Divider
                className="edit-rewards-separator"
            />
        </>
    )
}
