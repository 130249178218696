import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import "./structureChangeStatus.sass";
import { AllLanguages } from "../../../hooks/context/lang";
import { InputCustom, ParagraphCustom, TextAreaCustomPro } from "../../../components/custom";
import { StateReservations } from "../../../constants/enums";
import { Radio } from "../interfaces/radioInfo";
import { Email } from "../interfaces/emailsReservation";
import { AllLanguagesManagement } from "src/models/language/allLanguagesManagement";
import { ID_TTOO } from '../../../constants/constants';
import { Alert } from "antd";

interface ChangeStatus {
    id: string;
    ownReference: string;
    langAgent: string;
    agency: string;
    statusRadioSelectCheck: Radio;
    emailVisualCheck: Email[];
    setConstentBodyMail: (text: string) => void;
    setOwnReferenceState: (text: string) => void;
    futureRewards?:any
}

export const StructureChangeStatus = (props: ChangeStatus) => {

    const {
        id,
        ownReference,
        langAgent,
        agency,
        statusRadioSelectCheck,
        emailVisualCheck,
        setConstentBodyMail,
        setOwnReferenceState,
        futureRewards
    } = props;

    const { t } = useTranslation([
        "regularization",
    ]);

    const allLang = useContext<AllLanguagesManagement>(AllLanguages);

    const pru: Email[] = emailVisualCheck.filter(opt =>
        opt.idStatusBooking === statusRadioSelectCheck.id)

    const [valueBody, setValueBody] = useState<string>(pru.length > 0 ? pru[0].body : "");
    const [statusByEmail, setStatusByEmail] = useState<string>("");

    const [, setOwnReference] = useState<string>("");


    const changeBodyEmail = (e) => {
        setValueBody(e.target.value);
        setConstentBodyMail(e.target.value)
    }

    const changeOwnReference = (e) => {
        setOwnReference(e.target.value);
        setOwnReferenceState(e.target.value)
    }

    useEffect(() => {
        if (statusByEmail !== statusRadioSelectCheck.id && pru.length > 0) {
            setValueBody(pru.length > 0 ? pru[0].body : "")
            setStatusByEmail(pru[0].idStatusBooking)
            setConstentBodyMail(pru.length > 0 ? pru[0].body : "")

        }
    })

    return (
        <>
            <div className="mt-1">
                {statusRadioSelectCheck.description}
            </div>
            { (
                (   statusRadioSelectCheck.id === StateReservations.Regularized ||
                    statusRadioSelectCheck.id === StateReservations.Duplicated ||
                    statusRadioSelectCheck.id === StateReservations.Validated ||
                    statusRadioSelectCheck.id === StateReservations.Cancelled)
            )
                ?
                (
                    <>
                        <ParagraphCustom
                            class={"mt-3"}
                            text={<strong>{t("modal.add-locator")}</strong>}
                        />
                        <div>
                            <InputCustom
                                id={"name"}
                                placeholder=""
                                className="main-input"
                                prefix={false}
                                allowClear={true}
                                classIcon={"col-12 main-input mb-4"}
                                autoFocus={true}
                                onChange={changeOwnReference}
                            />
                            <div>
                                {
                                    (statusRadioSelectCheck.id === StateReservations.Regularized || statusRadioSelectCheck.id === StateReservations.Validated)
                                    ?
                                        <Alert style={{marginTop: "5px"}} message={t("modal.future-reward-alert", {0 : futureRewards})} type="info" showIcon />
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                    </>
                ) : (<></>)
            }
            {pru.length > 0 &&
                <div>
                     {
                        allLang.GetLanguage().filter(e => e.id === langAgent)[0]?.name ? 
                        <ParagraphCustom
                            class={"mt-3 add-message"}
                            text={<><strong className="text-strong">{t("modal.add-message")}</strong> {t("modal.lang-agent", { 0: allLang.GetLanguage().filter(e => e.id === langAgent)[0]?.name })}</>}
                        />:
                        ''
                    }
                    <ParagraphCustom
                        text={pru[0].greetings}
                    />
                    <TextAreaCustomPro
                        onChange={changeBodyEmail}
                        id={id}
                        rows={6}
                        className={"col-12 main-input mb-4"}
                        placeholder={""}
                        defaultValue={valueBody}
                        value={valueBody}
                    />
                    <ParagraphCustom
                        text={pru[0].goodbye}
                    />
                </div>
            }
           
        </>
    );
}
