import React, { useContext, useEffect, useState } from "react";
import "./channels.sass";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import {
  ButtonsCustom,
  DrawerCustom,
  ParagraphCustom,
  TableCustom,
  TitleCustom
} from "src/components/custom";
import { LoadingOutlined } from "@ant-design/icons";
import { Skeleton, TableProps } from "antd";
import { faUpDownLeftRight, faArrowDownAZ, faPlus } from "@fortawesome/free-solid-svg-icons";
import { SortableTable } from "../../custom/SortableTable/SortableTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotResults } from "src/components/noResultGeneric/notResults";
import { RestApiClientContext } from "src/hooks/context/pageContext";
import { SortItem } from "src/components/custom/SortableTable/interfaceSort";
import { EditChannel } from "./editChannel/editChannel";
import { ActionsCell } from "./ActionsCell/ActionsCell";
import Search from "antd/lib/input/Search";

interface SortChannelItem extends SortItem {
  id: string;
  name: string;
  identityCode: number;
  isActive: string;
  actions: JSX.Element;
  isDraggable: boolean;
}

export const Channels = () => {
  const { t } = useTranslation("config");



  const restApiClient = useContext(RestApiClientContext);

  const scrollLib = require("react-scroll");
  const scroll = scrollLib.animateScroll;

  const [loadingSkeleton, setLoadingSkeleton] = useState<boolean>(true);
  const [loadingChannels, setLoadingChannels] = useState<boolean>(true);
  const [disabledSearch, setDisabledSearch] = useState<boolean>(false);
  const [messageApi, setMessageApi] = useState<string>("");
  const [searching, setSearching] = useState<boolean>(false);
  const [modeSort, setModeSort] = useState(false);
  const [dataChannels, setDataChannels] = useState<SortChannelItem[]>([]);
  const [searchTermNameInput, setSearchTermNameInput] = useState<string>("");
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [selectedChannelId, setSelectedChannelId] = useState(null);
  const [order, setOrder] = useState(dataChannels);

  const handleAddChannel = () => {
    setSelectedChannelId(null);
    setVisibleDrawer(true);
  };

  const sortAtoZ = () => {
    const newOrder = [...dataChannels].sort((a, b) => {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    setOrder(newOrder);
  };

  const [savingOrder, setSavingOrder] = useState<boolean>(false);
  const handleSort = async () => {
    const idList = [...order].map((o) => o.id);
    try {
      setSavingOrder(true);
      restApiClient.updateOrderChannels(idList)
      .then((r)=>{
        if (r){
          handleRender();
          setModeSort(!modeSort)
        }
      })
      .finally(() => {
        setSavingOrder(false);
      });
    } catch (err) {
      console.error(err);
    }
  };


  const handleRender = (filter = "") => {
    setLoadingChannels(true);
    setDisabledSearch(true);
    setMessageApi("");
    restApiClient
      .getChannelsFiltered(filter)
      .then((data) => {
        if (data && data.length > 0) {

          setFilterNames(data.map((e) => {
            return {
              value: e.name,
              text: e.name
            };
          }));

          setDataChannels(
            data.map((e) => {
              return {
                id: e.id,
                name: e.name,
                identityCode: e.identityCode,
                isActive: e.isActive
                  ? t("channels.table.active")
                  : t("channels.table.inActive"),
                isDraggable: !e.favorite,
                actions: (
                  <ActionsCell
                    reload={() => handleRender(filter)}
                    setChannelId={setSelectedChannelId}
                    setVisibleDrawer={setVisibleDrawer}
                    cell={e}
                  />
                ),
                index: e.id,
                key: e.id
              };
            })
          );
        } else {
          setDataChannels([]);
          setMessageApi("not-result");
        }
      })
      .catch((reason: any) => {
        console.error(reason);
        setLoadingChannels(false);
      })
      .finally(() => {
        setDisabledSearch(false);
        setLoadingSkeleton(false);
        setLoadingChannels(false);
      });
  };

  const [filterNames, setFilterNames] = useState<any[]>([]);
  let sorteableColumns = [
    {
      title: t("channels.table.name"),
      dataIndex: "name",
      key: "name",
      className: "drag-visible",
      filters:filterNames,
      onFilter: (value: string, record) => record.name.indexOf(value) === 0,
    },
    {
      title: t("channels.table.identityCode"),
      dataIndex: "identityCode",
      key: "identityCode"
    }
  ];

  let filterColumns = [
    ...sorteableColumns,
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 140
    }
  ];

  useEffect(()=>{
        setDisabledSearch(false);
        setLoadingSkeleton(false);
        setLoadingChannels(false);
  },[filterNames])

  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  useEffect(() => {
    setOrder(dataChannels);
  }, [dataChannels]);

  useEffect(() => {
    const filter = !modeSort ? searchTermNameInput : "";
    handleRender(filter);
  }, [searchTermNameInput, modeSort]);

  return (
    <>
        <div id="channels" className="special-mb config-tab">
          <div className="row col-12">
            <TitleCustom level={4} class="title-section" text={t("channels.main-title")} />
          </div>
          <div className="row">
            <div className="col-12">
              <ParagraphCustom class={"p-0"} text={t(`channels.main-description-${modeSort ? "sort" : "filter"}`)} />
            </div>
          </div>
          {!modeSort ? (
            <div className="row my-1">
              <div className="col col-md-8 my-1">
                <Search
                  disabled={disabledSearch}
                  placeholder={t("rooms.search-channel.placeholder")}
                  onSearch={(e) => {setSearchTermNameInput(e)}}
                  allowClear
                />
              </div>
              <div className="col align-self-end buttons my-1">
                <ButtonsCustom
                  class={"secondary-button"}
                  htmlType={null}
                  onClick={() => setModeSort(!modeSort)}
                  size={null}
                  content={
                    <span>
                      <strong>{t("channels.button-sort")}</strong>
                    </span>
                  }
                />
                <ButtonsCustom
                  class={"primary-button"}
                  htmlType={null}
                  onClick={handleAddChannel}
                  size={null}
                  content={
                    <span>
                      <FontAwesomeIcon icon={faPlus} />
                      <strong>{t("channels.add-channel")}...</strong>
                    </span>
                  }
                />
              </div>
            </div>
          ) : (
            <div className="row sort-actions my-1">
              <div className="col" />
              <div className="col col-md-4 align-self-end">
                <div className="buttons">
                  <ButtonsCustom
                    id="btn-search-channels"
                    class={"secondary-button"}
                    htmlType={null}
                    onClick={sortAtoZ}
                    size={null}
                    content={
                      <span>
                        <FontAwesomeIcon icon={faArrowDownAZ} />
                      </span>
                    }
                  />
                  <ButtonsCustom
                    class={"primary-button"}
                    htmlType={null}
                    onClick={() => handleSort()}
                    size={null}
                    disabled={!order.length || savingOrder}
                    content={
                      <span>
                        <strong>{ savingOrder ? <LoadingOutlined/> : ''} {t("channels.save-order")}</strong>
                      </span>
                    }
                  />
                  <ButtonsCustom
                    class={"secondary-button"}
                    htmlType={null}
                    onClick={() => setModeSort(!modeSort)}
                    size={null}
                    content={
                      <span>
                        <strong>{t("channels.cancel-order")}</strong>
                      </span>
                    }
                  />
                </div>
              </div>
            </div>
          )}
          <Skeleton active loading={loadingSkeleton || loadingChannels}>
            {dataChannels?.length < 1 || messageApi !== "" ? (
              <div className="row">
                <NotResults section="channels" dataSearch={searching} messageApi={messageApi} />
              </div>
            ) : (
              <div className="row">
                {modeSort ? (
                  <SortableTable
                    handle={
                      <div className="grab-button">
                        <FontAwesomeIcon icon={faUpDownLeftRight} />
                      </div>
                    }
                    dataSource={order}
                    setDataSource={setOrder}
                    columns={sorteableColumns}
                    className="table-channels col-12 my-4"
                    pagination={false}
                    bordered
                  />
                ) : (
                  <TableCustom
                    className="table-channels col-12 my-4"
                    columns={filterColumns}
                    dataSource={dataChannels}
                    bordered
                  />
                )}
              </div>
            )}
          </Skeleton>
        </div>
        <DrawerCustom
          title={<header>{t(`channels.add-${selectedChannelId ? "edit" : "new"}`)}</header>}
          visible={visibleDrawer}
          setVisible={setVisibleDrawer}
          width={520}
          info={""}
          placement="right"
          closable={true}
          onClose={() => setVisibleDrawer(false)}
          content={<EditChannel reload={() => handleRender('')} id={selectedChannelId} setVisible={setVisibleDrawer}/>}
        />
    </>
  );
};
