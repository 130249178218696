import React, { useState, useEffect, useContext } from "react";
import "../../../i18n";
import "../news.sass";
import { useTranslation } from "react-i18next";
import * as AjaxAPI from "../../../api/ajaxApi";
import { useHistory } from 'react-router-dom';
import { StatusNews } from "../../../constants/enums";
import { Form, Select } from 'antd'

import {
    UploadCustom,
    FormCustom,
    InputCustom,
    FontIconsCustom,
    FormItemsContact,
    CheckBoxCustom,
    DatePickerCustom,
    ButtonsCustom,
    ModalCustomPro,
    ParagraphCustom,
    PopoverCustom,
    TagInfoCustom
} from "../../custom";

import {
    faStar,
    faEllipsisV,
    faCheckCircle,
    faBan,
    faTrash,
    faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";

import { CheckCircleOutlined, ClockCircleOutlined, LeftOutlined, MinusCircleOutlined } from "@ant-design/icons";

// https://ckeditor.com/docs/ckeditor5/latest/api/index.html
import { Editor } from '@tinymce/tinymce-react'



import moment from 'moment';
import { getLocaleDatePicker } from "../../../utils/datePicker";
import { PickerLocale } from "antd/es/date-picker/generatePicker";

import { News, NewsContent, DefaultNews } from "../../../models/news/news";
import Language from "../../../models/language/languagesManagement";


import "./cuNews.sass";

import { RestApiClientContext } from "../../../hooks/context/pageContext";
import { LangContext } from "src/hooks/context/lang";

const { Option } = Select;

interface Properties { id?: string }

export const CreateUpdateNews = (properties : Properties) => {
    const language = useContext(LangContext);
    const local: PickerLocale = getLocaleDatePicker();

      
    const restApiClient = useContext(RestApiClientContext);

    const AllowedImagesType = 'image/jpg,image/png,image/svg,image/gif';

    const [ form ] = Form.useForm();
    const history = useHistory();

    const { t } = useTranslation(["news", "urls", "translation"]);

    const [scheduleDate, setScheduleDate] = useState<any>(null);

    const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false);
    const [showConfirmSchedule, setShowConfirmSchedule] = useState<boolean>(false);
    const [showDeleteNews, setShowDeleteNews] = useState<boolean>(false);
    const [showRePublishConfirm, setShowRePublishConfirm] = useState<boolean>(false);
    const [showSavePublishConfirm, setShowSavePublishConfirm] = useState<boolean>(false);
    const [showUnpublishConfirm, setShowUnpublishConfirm] = useState<boolean>(false);

    const [makeChanges, setMakeChanges] = useState<boolean>(false);
    const [news, setNews] = useState<News>(DefaultNews)
    const [languages, setLanguages] = useState<Language[]>([]);
    const [defaultFormValue, setDefaultFormValue] = useState<string>('es-ES');
    const [selectLanguageValue, setSelectLanguageValue] = useState<string>(defaultFormValue);

    const [imageForUpload, setImageForUpload] = useState<any>(null);
    const [mainImage, setMainImage] = useState<any>(null);
    const [imageName, setImageName] = useState<string>('');
    const [imageContentType, setImageContentType] = useState<string>('');

    const [isPublishDisabled, setIsPublishDisabled] = useState<boolean>(true);
    const [isSchedulePublishChecked, setIsSchedulePublishChecked] = useState<boolean>(false);
    const [isFeaturedChecked, setIsFeaturedChecked] = useState<boolean>(false);
    const [isEditingNews, setIsEditingNews] = useState<boolean>(false);

    const [showDraftButton, setShowDraftButton] = useState<boolean>(false);
    const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
    const [showRePublishButton, setShowRePublishButton] = useState<boolean>(false);
    const [showPublishButton, setShowPublishButton] = useState<boolean>(false);

    const [forms, setForms] = useState<any[]>([]);

    const onChangeScheduleDate = (v : any) => {
        setScheduleDate(v);
    }

    const setButtonStates = () : void => {

        setShowSaveButton(false);
        setShowRePublishButton(false);
        setShowPublishButton(false);
        setShowDraftButton(false);

        const isScheduleNews = news.idStatus === StatusNews.Scheduled && news.startDate && moment().isBefore(news.startDate);
        const isPublishedNews = news.idStatus === StatusNews.Published && news.startDate && moment().isSameOrAfter(news.startDate);

        if (isPublishedNews){
            setShowRePublishButton(true);
        } else if (isScheduleNews){
            setShowPublishButton(true);
        }

        if (news && news.idStatus === StatusNews.Draft){
            setIsPublishDisabled(false);
            setShowDraftButton(true);
            setShowPublishButton(true);
        } else if (news && news.idStatus === StatusNews.Published){
            setShowSaveButton(true);
        } else if (news && news.idStatus === StatusNews.NotPublished){
            setShowPublishButton(true);
        }

        if (!news.id){
            setShowPublishButton(true);
            setShowDraftButton(true);
        } else {
            setMakeChanges(true);
        }

    }

    const markScheduleNews = (e: any) : void => {
        if (e.target.checked){
            const today = new Date();
            const tomorrow = moment(today).add(1, "day");
            setScheduleDate(tomorrow);
        }else if(news && news.idStatus === StatusNews.Published){
            setScheduleDate(null);
        }else{
            setScheduleDate(null);
        }

        setIsSchedulePublishChecked(e.target.checked);
    }


    const noDeleteNews = () : void => {
        setShowDeleteNews(false);
    }

    const deleteNews = () : void => {
        restApiClient.deleteNews(news.id).then(r => {
            history.push(t("urls:private.news"))
        });
    }

    const changePublished = () => {
		restApiClient.changeStatusNews(news.id, false).then((r: any)=>{
            getNewsData(properties.id);
        });
	};

    const noSchedule = () : void => {
        setShowConfirmSchedule(false);
    }

    // Check if all inputs are empty in all forms
    const isEmptyForms = () : boolean => {
        let emptyForms = true;

        const formValues = form.getFieldsValue();

        Object.keys(formValues).forEach(v => {
            if (formValues[v] && formValues[v].trim && formValues[v].trim().length > 0){
                // This input have value
                emptyForms = false;
            }else if(formValues[v].level && formValues[v].level.content && formValues[v].level.content.trim().length > 0){
                emptyForms = false;
            }
        });

        if (emptyForms){
            setIsPublishDisabled(true);
        }

        return emptyForms;
    }

    const publishNews = (isDraftMode: boolean = false, modifyStartDate: boolean = false) : void => {

        const newsData : News = {
            id : news && news.id.length > 0 ? news.id : null,
            featured: isFeaturedChecked,
            imageName: imageName,
            imageContentType: imageContentType,
            base64Image: mainImage ? mainImage.result : null,
            draft : isDraftMode,
            startDate: scheduleDate ? scheduleDate.toDate() : null,
            contents: [],
            modifyStartDate: modifyStartDate || isSchedulePublishChecked
        }

        const formsData = form.getFieldsValue();

        languages.forEach((l : Language) => {
            const content : NewsContent = {
                idLanguage: null,
                title: '',
                description: ''
            };

            Object.keys(formsData).forEach(k => {
                const splitData : string[] = k.split("||");
                const key = splitData[0];
                const idLanguage = splitData[1];

                if (idLanguage === l.id){
                    content.idLanguage = idLanguage;

                    switch(key){
                        case "title":
                            content.title = formsData[k];
                            break;
                        case "description":
                            if(formsData[k].target){
                                content.description = formsData[k].target.getContent();
                            }else{
                                content.description = formsData[k];
                            }

                            break;
                    }
                }
            });

            if (content && content.idLanguage && content.title && content.description){
                newsData.contents.push(content);
            }
        });

        if (newsData.contents.length > 0) {
            if (isEditingNews) {
                restApiClient.updateNews(newsData).then(r => {
                    history.push(t("urls:private.news"));
                })
            } else {
                restApiClient.addNews(newsData).then(r => {
                    history.push(t("urls:private.news"));
                })
            }
        }
    }

    const handleChange = (info : any) : void => {
        const file : any = info.fileList[info.fileList.length-1].originFileObj;

        const reader = new FileReader();

        reader.onload = e => {
            setImageForUpload(e.target.result);

        }

        reader.readAsDataURL(file);

        setMainImage(reader);
    }

    const beforeUpload = (file: any) : boolean => {
        setImageName(file.name);
        setImageContentType(file.type);
        // to prevent the automatic upload file
        return false;
    }

    const onPublishNews = (e: any, isDraftMode: boolean = false, modifyStartDate : boolean = false) : void =>{

        if (!isEmptyForms()){
            form.validateFields().then(values => {
                if (isSchedulePublishChecked){
                    setShowConfirmSchedule(true);
                } else {
                    publishNews(isDraftMode, modifyStartDate);
                }
            }).catch( x =>{
                // Some errors in validations
                if (x.errorFields){
                    x.errorFields.map(e => {
                        const fieldName = e.name.pop().split("||");
                        const language = `${fieldName[fieldName.length-1]}`

                        // Change select value
                        setSelectLanguageValue(language);
                        // Show form with errors
                        onChangeLanguage(language);
                    });
                } else {
                    console.error(x);
                }
            });
        }
    }

    const noCancelPublish = () : void => {
        setShowConfirmCancel(false);
    }

    const goNewsList = () : void => {
        history.push(t("urls:private.news"));
    }

    const onCancelPublish = () : void => {
        if (makeChanges){
            // Confirm exit
            setShowConfirmCancel(true);
        }else{
            // Go to list
            history.push(t("urls:private.news"))
        }
    }

    const onChangeLanguage = (value: string) : void => {
        setSelectLanguageValue(value);
        forms.map(f => {
            // TODO : Preguntar a Juan Antonio si existe alguna manera de hacerlo con React...
            const element = document.getElementById(f.props.id);
            if (f.props.id === `cu-form-data-${value}`){
                element.className = "cu-news-form-data visible";
            } else {
                element.className = "cu-news-form-data hidden";
            }
        });
    }

    const getNewsData = (newsId : string) : void => {

        restApiClient.getNews(newsId).then(data =>{
            if (data){
                setNews(data);

                setIsFeaturedChecked(data.featured);
            }
        })
    }

    const generateForms = () : any => {
        const forms = languages.map((l: Language, i: number) => {
            const textName : string = `description||${l.id}`;
            const titleName : string = `title||${l.id}`
            const formId : string = `cu-form-data-${l.id}`;


            const defaultData : NewsContent = news && news.id ? news.contents.find((c : NewsContent) => c.idLanguage === l.id) : null;

            return (<FormCustom
                        id={formId}
                        form={form}
                        name={formId}
                        className={`cu-news-form-data ${l.id === defaultFormValue ? 'visible' : 'hidden' }`}
                        key={formId}

                    >
                        <FormItemsContact
                            label={ t('news:create.title')}
                            className="row-form-item"
                            name={titleName}
                            initialValue={defaultData? defaultData.title : ''}
                            onChange={ () => { setMakeChanges(true); form.validateFields(); isEmptyForms();}}
                            rules={
                                [
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            const textValue = getFieldValue(textName);

                                            if (textValue  !== undefined && textValue.length > 0 && (value === undefined || value.length === 0)) {
                                                // yo también soy obligatorio
                                                return Promise.reject(t('news:create.mandatory-field'));
                                            }

                                            return Promise.resolve();
                                        },
                                    })
                                ]
                            }
                        >
                            <InputCustom
                                className="main-input"
                                id={`news-title||${l.id}`}
                            />
                        </FormItemsContact>
                        <Form.Item 
                            label={ t('news:create.text') }
                            className="row-form-item"
                            rules={
                                [
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            const titleValue = getFieldValue(titleName);

                                            if (titleValue  !== undefined && titleValue.length > 0 && (value === undefined || value.length === 0)){
                                                // yo también soy obligatorio
                                                return Promise.reject(t('news:create.mandatory-field'));
                                            }

                                            return Promise.resolve();

                                        },
                                    })
                                ]
                            }
                            initialValue={defaultData? defaultData.description : 'no-content'}
                            name={textName} 
                            getValueProps={(value: any): any=> {
                            return value?.target?.getContent();
                        }}>
                            <Editor
                                apiKey="l85gt2wufrhxg3vn2nhvz3nwru0at7o39z36y0ub3ylbz37q"
                                initialValue={defaultData? defaultData.description : ''}
                                init={
                                    {
                                        language: language && language.lang && language.lang === 'es-ES' ? 'es' : 'en_US',
                                        height: 500,
                                        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                    }
                                }
                                onChange={ () => { setMakeChanges(true); form.validateFields(); }}
                            />
                        </Form.Item>
                    </FormCustom>);
        });

        setForms(forms);
    }

    const getLanguages = () : void => {

        restApiClient.getAgentLanguages()
            .then((r)=>{
                if (r){
                    setLanguages(r);
                }
            });
    }

    const disabledDate = (current: any) : any =>  {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }


    const tagIconSwitch = (val) => {
		let result = <></>;

		switch (val) {
			case StatusNews.Published:
			case StatusNews.Scheduled:
				if (moment().isBefore(news.startDate)){
					result = <ClockCircleOutlined className="d-none d-sm-block margin-icon-status" />
				}else{
					result = <CheckCircleOutlined className="d-none d-sm-block margin-icon-status" />
				}
				break;
			case StatusNews.NotPublished:
				result = <MinusCircleOutlined className="d-none d-sm-block margin-icon-status" />;
				break;
			case StatusNews.Draft:
			default:
				result = <ClockCircleOutlined className="d-none d-sm-block margin-icon-status" />;
				break;
		}

		return result;
	};

	const tagTypeSwitch = (val) : string => {
		let result : string = 'default';

		switch (val) {
			case StatusNews.Published:
			case StatusNews.Scheduled:
				if (moment().isBefore(news.startDate)){
					result = 'processing'
				}else{
					result = 'success'
				}
				break;
			case StatusNews.NotPublished:
				return "error";
			case StatusNews.Draft:
			default:
				return "default";
		}

		return result;
	};

	const tagTextSwitch = (val) : string => {
		let result : string = '';
		switch (val) {
			case StatusNews.Published:
			case  StatusNews.Scheduled:
				if (moment().isBefore(news.startDate)){
					result = t("news:status.SCH")
				}else{
					result = t("news:status.PUB")
				}
				break;
			default:
				result = t("news:status." + val);
		}

		return result;
	};

    const getDateInfo = () : JSX.Element => {
        if (isEditingNews && [StatusNews.Published.toString(), StatusNews.Scheduled.toString()].indexOf(news.idStatus)!== -1 &&  news.startDate){
            return <div className="col-12 col-md-12 date-content">
                            <ParagraphCustom
                                class="label-date-news"
                                text={
                                    moment().isSameOrAfter(news.startDate)
                                        ? t("news:publish-date")
                                        : t("news:future-publish-date")
                                }
                            />
                            <ParagraphCustom
                                class
                                text={moment(news.startDate).format(local.dateFormat)}
                        />
                    </div>
        }else{
            return <></>
        }
    }

    useEffect(() => {
        if (news && news.id){

            if (news.urlImage){
                setImageForUpload(`${news.urlImage}?id=${Math.random()}`)
            }
            generateForms();

            setIsFeaturedChecked(news.featured);

            if (news.startDate && moment().isBefore(news.startDate)){
                setIsSchedulePublishChecked(true);
                setScheduleDate(moment(news.startDate));
            }

            setIsEditingNews(true);
            setIsPublishDisabled(false);
            setButtonStates();
        }
    }, [news])


    useEffect(() => {
        // Init ajax API
        AjaxAPI.init(t);

        // Get Languages
        getLanguages();
        setButtonStates();
    }, [])

    useEffect(() => {
         // If properties.id is set => is edit mode => retrieve the news data
        if (properties.id){
            getNewsData(properties.id);
        }
    }, [properties.id]);

    useEffect(() => {
        // When retrieve or pudate the languages generate the news forms
        if (!properties.id){
            generateForms();
        }
    }, [languages]);

    useEffect(() => {
        if (makeChanges){
            setIsPublishDisabled(false);
        }
    }, [makeChanges]);

        return (
            <div className='cu-news row'>
                <div className="col-12">
                    <div className="row">
                        <div className="col-8 col-sm-12 cu-come-back">
                                <LeftOutlined onClick={onCancelPublish} className="cu-left-arrow"/>
                                <span onClick={onCancelPublish}>{t('news:create.return-list')}</span>
                        </div>
                        {
                            isEditingNews ?
                            <div className="col-4 col-sm-12 cu-news-actions">
                            <FontIconsCustom
                                classIcon={`star-list-news ${isFeaturedChecked ? 'active' : ''}`}
                                icon={faStar}
                                onClick={()=>{setIsFeaturedChecked(!isFeaturedChecked)}}
                            />
                            <TagInfoCustom
									class={"tag-status-news"}
									icon={tagIconSwitch(news.idStatus)}
									type={tagTypeSwitch(news.idStatus)}
									text={tagTextSwitch(news.idStatus)}
								/>
                            <PopoverCustom
                                classBody={"position-float-right"}
                                body={
                                    <ButtonsCustom
                                        id={"btn-option-title"}
                                        class={"btn-option"}
                                        htmlType={null}
                                        disabled={false}
                                        size={null}
                                        content={
                                            <FontIconsCustom
                                                classIcon={
                                                    "icon-ellipsis-opts"
                                                }
                                                icon={faEllipsisV}
                                            />
                                        }
                                    />
                                }
                                content={
                                    <div className="content-menu-option-news">
                                        {
                                            news.idStatus === StatusNews.Published
                                            ?
                                                <div
                                                    className="element-option-news pl-3 pr-3 p-2"
                                                    onClick={()=>{ setShowUnpublishConfirm(true); }}
                                                >
                                                    <FontIconsCustom
                                                        classIcon={
                                                            "icons-list-options"
                                                        }
                                                        icon={
                                                            news?.idStatus !== 'ACT'
                                                                ? faCheckCircle
                                                                : faBan
                                                        }
                                                    />
                                                    { t("news:action.unpublish") }
                                                </div>
                                            :
                                            ''
                                        }

                                        <div
                                            className="element-option-news pl-3 pr-3 p-2"
                                            onClick={() =>
                                                setShowDeleteNews(true)
                                            }
                                        >
                                            <FontIconsCustom
                                                classIcon={
                                                    "icons-list-options"
                                                }
                                                icon={faTrash}
                                            />{" "}
                                            {t("news:action.delete")}
                                        </div>
                                    </div>
                                }
                                placement={"bottomLeft"}
                                title=""
                                trigger="focus"
                            />
                        </div>
                        :''
                        }

                        {
                            getDateInfo()
                        }

                    </div>
                </div>

                <div className="col-12 col-md-3 cu-image">
                    <UploadCustom
                        accept={AllowedImagesType}
                        name="uploadFile"
                        listType='picture-card'
                        width= "230px"
                        height="166px"
                        className='avatar-uploader cu-upload'
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        handleChange={handleChange}
                        image={imageForUpload}
                    />
                    <span className="cu-format-advisor">
                        {t('news:create.valid-image-formats')}
                    </span>
                </div>
                <div className="col-12 col-md-9">
                    <div className="col-12 cu-content">
                        <label>{t("news:create.language")}</label>
                        <Select
                            className="language-selector"
                            defaultValue={defaultFormValue}
                            onChange={onChangeLanguage}
                            value={selectLanguageValue}
                            >
                            { languages.map(opt=> {
                                return <Option value={opt.id} key={opt.id}>
                                    {`${opt.name}`}
                                </Option>
                            })}
                        </Select>

                        <div className="forms">
                            {forms.map(form => {
                                return form;
                            })}
                        </div>

                    </div>
                    <div className="col-12 cu-schedule-publish">
                        <div className="row">
                            <div className="col-12 col-md-8">
                                <CheckBoxCustom
                                    checked={isSchedulePublishChecked}
                                    content={<label style={{fontWeight:"bold"}}>{t('news:create.schedule-publish-date.title')}</label>}
                                    onChange={markScheduleNews}></CheckBoxCustom>
                                <span className="advertisement">
                                    {t('news:create.schedule-publish-date.advertisement')}
                                </span>
                            </div>
                            <div className="col-12 col-md-4 publish-date">
                                <label>{t('news:create.schedule-publish-date.publish-date')}</label>
                                <DatePickerCustom
                                    disabledDate={disabledDate}
                                    placeholder={t('translation:lang.dateFormat')}
                                    disabled={!isSchedulePublishChecked} className='col-11 col-md-12'
                                    onChange={onChangeScheduleDate}
                                    value={scheduleDate}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 cu-mark">
                        <div className="row">
                            <div className="col-12">
                                <CheckBoxCustom
                                    checked={isFeaturedChecked}
                                    content={<label style={{fontWeight:"bold"}}>{t('news:create.featured.title')}</label>}
                                    onChange={(e)=>{ setIsFeaturedChecked(e.target.checked) }}
                                >

                                </CheckBoxCustom>
                                <span className="advertisement">
                                    {t('news:create.featured.advertisement')}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="cu-actions">
                        <div className="cu-cancel">
                            <ButtonsCustom
                                id='cr-btn-cancel'
                                content={t('news:action.cancel')}
                                onClick={onCancelPublish}
                                class='ant-btn'
                            />
                        </div>
                        <div className="cu-other-actions">
                                <ButtonsCustom
                                    id='cr-btn-draft'
                                    type='primary'
                                    disabled={isPublishDisabled}
                                    onClick={(e: any)=> {onPublishNews(e, true)}}
                                    className={`ant-btn ant-btn-primary ${makeChanges && showDraftButton  ? '' : 'hidden'}`}
                                    content={t('news:action.save-draft')}
                                />

                                <ButtonsCustom
                                    id='cr-btn-save'
                                    type='primary'
                                    onClick={(e: any) => {setShowSavePublishConfirm(true)}}
                                    className={`ant-btn ant-btn-primary ${showSaveButton && !isSchedulePublishChecked ? '' : 'hidden'}`}
                                    content={t('news:action.save')}
                                />

                                <ButtonsCustom
                                    id='cr-btn-schedule'
                                    type='primary'
                                    onClick={(e: any)=> {onPublishNews(e, false)}}
                                    disabled={isPublishDisabled}
                                    className={`ant-btn ant-btn-primary ${isSchedulePublishChecked ? '' : 'hidden'}`}
                                    content={t('news:action.schedule')}
                                />

                                <ButtonsCustom
                                    id='cr-btn-re-publish'
                                    type='primary'
                                    disabled={isPublishDisabled}
                                    onClick={(e: any) => {setShowRePublishConfirm(true); }}
                                    className={`ant-btn ant-btn-primary ${showRePublishButton && !isSchedulePublishChecked ? '' : 'hidden'}`}
                                    content={t('news:action.re-publish')}
                                />

                                <FontIconsCustom
                                    tooltip={t("news:edit.re-publish-advertisement")}
                                    classIcon={`icons-color-menu icons-color-marging q-icon ${showRePublishButton && !isSchedulePublishChecked ? '' : 'hidden'}`}
                                    icon={faExclamationCircle}
                                />

                                <ButtonsCustom
                                    id='cr-btn-publish'
                                    type='primary'
                                    disabled={isPublishDisabled}
                                    onClick={(e: any) => {onPublishNews(e, false)}}
                                    className={`ant-btn ant-btn-primary ${showPublishButton && !isSchedulePublishChecked ? '' : 'hidden'}`}
                                    content={t('news:action.publish')}
                                />









                        </div>
                    </div>
                </div>

                {
                    // Modal create news confirm
                }
                <ModalCustomPro
					className="modal-bo"
					title={t("news:create.cancel-modal.title")}
					visible={showConfirmCancel}
                    closable={true}
                    afterClose={noCancelPublish}
                    cancelText={t("news:create.cancel-modal.cancel")}
                    okText={t("news:create.cancel-modal.accept")}
                    onCancel={noCancelPublish}
                    onOk={goNewsList}

				>
                    <ParagraphCustom
								class="text-white"
								text={t("news:create.cancel-modal.message")}
							/>
                </ModalCustomPro>

                {
                    // Modal re-publish news confirm
                }
                <ModalCustomPro
					className="modal-bo"
					title={t("news:edit.re-publish-modal.title")}
					visible={showRePublishConfirm}
                    closable={true}
                    afterClose={() => {setShowRePublishConfirm(false)}}
                    cancelText={t("news:edit.re-publish-modal.cancel")}
                    okText={t("news:edit.re-publish-modal.accept")}
                    onCancel={() => {setShowRePublishConfirm(false)}}
                    onOk={(e: any) => {onPublishNews(e, false, true); setShowRePublishConfirm(false)}}
				>
                    <ParagraphCustom
								class="text-white"
								text={t("news:edit.re-publish-modal.message")}
							/>
                </ModalCustomPro>

                {
                    // Modal save changes of news confirm
                }
                <ModalCustomPro
					className="modal-bo"
					title={t("news:edit.save-modal.title")}
					visible={showSavePublishConfirm}
                    closable={true}
                    afterClose={()=>{setShowSavePublishConfirm(false)}}
                    cancelText={t("news:edit.save-modal.cancel")}
                    okText={t("news:edit.save-modal.accept")}
                    onCancel={()=>{setShowSavePublishConfirm(false)}}
                    onOk={(e: any)=>{onPublishNews(e, false); setShowSavePublishConfirm(false)}}

				>
                    <ParagraphCustom
								class="text-white"
								text={t("news:edit.save-modal.message")}
							/>
                </ModalCustomPro>

                {
                    // Modal save changes of news confirm
                }
                <ModalCustomPro
					className="modal-bo"
					title={t("news:unpublished.title")}
					visible={showUnpublishConfirm}
                    closable={true}
                    afterClose={()=>{setShowUnpublishConfirm(false)}}
                    cancelText={t("news:unpublished.button.cancel")}
                    okText={t("news:unpublished.button.accept")}
                    onCancel={()=>{setShowUnpublishConfirm(false)}}
                    onOk={(e: any)=>{changePublished(); setShowUnpublishConfirm(false)}}

				>
                    <ParagraphCustom
								class="text-white"
								text={t("news:unpublished.description")}
							/>
                </ModalCustomPro>

                {
                    // Modal Schedule news confirm
                }
                <ModalCustomPro
					className="modal-bo"
					title={t("news:create.schedule.title")}
					visible={showConfirmSchedule}
                    closable={true}
                    afterClose={noSchedule}
                    cancelText={t("news:create.schedule.cancel")}
                    okText={t("news:create.schedule.accept")}
                    onCancel={noSchedule}
                    onOk={(e: any)=>{publishNews(false); setShowConfirmSchedule(false)}}

				>
                    <ParagraphCustom
								class="text-white"
								text={t("news:create.schedule.message")}
							/>
                </ModalCustomPro>

                {
                    // Modal Delete news confirm.
                }
                <ModalCustomPro
					className="modal-bo"
					title={t("news:edit.delete.title")}
					visible={showDeleteNews}
                    closable={true}
                    afterClose={noDeleteNews}
                    cancelText={t("news:edit.delete.cancel")}
                    okText={t("news:edit.delete.accept")}
                    onCancel={noDeleteNews}
                    onOk={(e: any)=>{deleteNews(); setShowDeleteNews(false)}}

				>
                    <ParagraphCustom
								class="text-white"
								text={t("news:edit.delete.body")}
							/>
                </ModalCustomPro>
            </div>
        )
};
