import React, { useState } from "react";
import "./limitAmountConfig.sass";
import {
    ParagraphCustom,
    TitleCustom,
    TextCustom,
} from "../../custom";
import { useTranslation } from "react-i18next";
import { FormattedNumber } from "react-intl";
import { DirectSales, Amount, DataLimitAmounts, LimitRewardAmount } from '../interfaces/directSalesConfigInterface';
import { DIRECT_SALE_FIXED_VALUE } from '../../../constants/constants';
import { DrawerLimitAmount } from "./drawerLimitAmount";
import { EditOutlined } from "@ant-design/icons";
import { useEffect } from "react";

export const LimitAmountConfig = (props: DataLimitAmounts) => {

    const {
        limitAmounts,
        onchange,
    } = props

    const { t } = useTranslation(["rewardsPerNight"]);

    const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
    const [values, setValues] = useState<LimitRewardAmount>({...limitAmounts});


    const openDrawerLimitAmount = () => {
        setVisibleDrawer(true);
    }

    useEffect(()=>{
        setValues(props.limitAmounts);
    },[props])

    return (
        <>
            <div className="row limit-amount">
                
                <div className="col-12">
                    <div
                        className="direct-sale-values"
                        onClick={openDrawerLimitAmount}
                    >
                        <TextCustom
                            class="direct-sale-label"
                            text={t('rewardsPerNight:label-limit-amount')}
                        />
                        
                        <TextCustom
                            class="direct-sale-amount"
                            text={
                                <>
                                {
                                    values.amounts && (values.amounts.length === 0 || values.amounts.findIndex(a => a.limitAmount !== 0) == -1) ? t('rewardsPerNight:no-limit-amount-configured') : values.amounts.map((e, i) => {
                                        return (
                                            <span key={i}>
                                                <FormattedNumber
                                                    key={i}
                                                    value={e.limitAmount}
                                                    style="currency"
                                                    currency={e.currencyId}
                                                />
                                                {(i < values.amounts.length - 1) &&
                                                    <span> - </span>
                                                }
                                            </span>
                                        );
                                    })
                                }
                                &nbsp;<EditOutlined /></>
                            }
                        />
                    </div>
                </div>
            </div>
            <DrawerLimitAmount
                visible={visibleDrawer}
                handleVisible={setVisibleDrawer}
                onchange={onchange}
                limitedamounts={limitAmounts}
            />
        </>
    );
};
