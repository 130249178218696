// #region Tipo Media para la parte multimedia de Init
export enum MediaType {
    Image = "Image",
    Video = "Video",
}
// #endregion

// #region Estados del registro del usuario
export enum StateUser {
    Inactive = 1,
    oK,
    IncorrectData,
    PendingConfirmation,
    PendingValidation,
}
// #endregion

// #region Tipo de dato
export enum IncreaseInfo {
    percent = 0,
    currency = 1
}
// #endregion

// #region Tipos de tiempo
export enum TimeElapsed {
    Year = "YE",
    Month = "MO",
    Day = "DA",
    Hour = "HO",
    Minutes = "MI",
}
// #endregion

// #region Tipos de notificaciones
export enum NotificationsType {
    Agent = "AG",
    Reservation = "BO",
}
// #endregion

// #region  Tipos de estados del agente
export enum StatesAgent {
    All = "ALL",
    Active = "ACT",
    Disable = "INA",
    AccessDenied = "NVA",
    PendingConfirmation = "PEN",
    PendingValidation = "PVA",
    EmailVerification = "VEM"
}
// #endregion

// #region Estado de una reserva
export enum StateReservations {
    All = "ALL",
    Pending = "PEN",
    Regularized = "REG",
    Validated = "VAL",
    Cancelled = "CAN",
    Duplicated = "DUP",
    Rejected = "REJ",
    Incomplete = "INC"
}
// #endregion

// #region Estados de una noticia
export enum StatusNews {
    All = "ALL",
    Published = "ACT",
    // La diferencia entre Published y Scheduled es que la Scheduled su estado es ACT pero viene informada la fecha en startDate
    Scheduled = "ACT",
    NotPublished = "INA",
    Draft = "DRA"
}
// #endregion


//#region AjaxApi response types
export enum ApiResponseStatus{
    NoShow = "NoShow",
    Success = "Success",
    Error = "Error",
    Warning = "Warning",
    Information = "Information",
    Validation = "Validation"
}

