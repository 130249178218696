import EndPointConfig from '../../models/api/endPointConfig';

const controller = 'Customizations';

/// Define all EndPoints for Client Data.
export const ClientDataEP : EndPointConfig[] = [
    // Petición de datos de cliente a la API

    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetCustomizations'),

        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('contractnumber')
        .setAlias('GetContractNumber'),

    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('backoffice')
        .setAlias('GetClientDetailsBackOffice'),
];
