import { Alert, Button, Col, Input, Row, Select, Skeleton, Switch, Tag, Typography } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { ListCustomPro } from 'src/components/custom'
import * as TargetModels from 'src/models/targets/TargetModels'

import { CheckSquareOutlined, LoadingOutlined, SaveOutlined, CloseSquareOutlined, EditOutlined } from '@ant-design/icons'

import './TargetsListComponent.sass'

import { Country } from 'src/models/countries/CountriesModels';
import { RestApiClientContext } from 'src/hooks/context/pageContext';
import { ModalConfirmationsActions } from 'src/components/rewardsPerNight/modalConfirm/modalConfirm';
import { EntityDto } from 'src/models/targets/TargetModels';

interface TargetsListComponentProps{
    list: TargetModels.TargetDto[],
    isLoading: boolean,
    availableContries: Country[],
    onRefresh: () => void
}

const TargetsListComponent = (props: TargetsListComponentProps) : JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false);
    const [list, setList] = useState<TargetModels.TargetDto[]>([]);
    const [availableCountries, setAvailableCountries] = useState<Country[]>([]);

    useEffect(()=>{
        if (props.availableContries){
            setAvailableCountries(props.availableContries);
        }else{
            setAvailableCountries([]);
        }
    },[props.availableContries])

    useEffect(()=>{
        setLoading(props.isLoading);
    },[props.isLoading])

    useEffect(()=>{
        if (props.list){
            setList(props.list);
        }else{
            setList([]);
        }
    },[props.list])

    return(
        <div className="targets-list-component">
            <Skeleton active loading={loading}>
                <ListCustomPro
                    itemLayout={"horizontal"}
                    dataSource={list}
                    renderItem={(item: TargetModels.TargetDto, index: number) => <TargetBox key={index} availableCountries={availableCountries} item={item} onUpdateList={props.onRefresh}/>}
                ></ListCustomPro>
                {
                    list.length == 0 ? '' : ''
                }


            
            </Skeleton>
        </div>
    )
}

interface TargetBoxProps {
    item: TargetModels.TargetDto
    availableCountries: Country[],
    onUpdateList: () => void
}

const TargetBox = (props: TargetBoxProps) : JSX.Element => {
    const { t } = useTranslation(["targets"]);
    const restApiClient = useContext(RestApiClientContext);

    const {availableCountries} = props;

    const [item, setItem] = useState<TargetModels.TargetDto>();
    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
    const [selectetTargetId, setSelectetTargetId] = useState<number>();
    const [newName, setNewName] = useState<string>('');
    const [editingName, setEditingName] = useState<boolean>(false);
    
    const [changingStatus, setChangingStatus] = useState<boolean>(false);
    const changeTargetStatus = (targetId: number, isActive: boolean) : void => {
        
        const params : any = {
            id: targetId,
            isActive: isActive
        }

        setChangingStatus(true);
        restApiClient.changeTargetStatus(params)
        .finally(()=>{setChangingStatus(false);});
    }

    const [deletingData, setDeletingData] = useState<boolean>(false);
    const onDeleteTarget = (targetId: number) : void => {
        setDeletingData(true);
        restApiClient.deleteTarget({id: targetId}).then((r: any)=>{
            if (r && r == true){
                setShowConfirmDelete(false);
                setSelectetTargetId(undefined);
                props.onUpdateList();
            }
        }).finally(()=>{
            setDeletingData(false);
        })
    }

    useEffect(()=>{
        if(props.item){
            setItem(props.item);
            setSelectedCountries(props.item.entities.map((entity: TargetModels.EntityDto)=>{
                return entity.idEntity
            }))
            setNewName(props.item.description);
        }
    },[props.item])

    const [selectedCountries, setSelectedCountries] = useState<string[]>();
    const onSelectCountries = (newValues: any) : void => {
        setSelectedCountries(newValues);
    }

    const [savingData, setSavingData] = useState<boolean>(false);
    const [warningMessage, setWarningMessage] = useState<string[]>([]);
    const saveChanges = (closeEditingName: boolean = false) : void => {
        var countries : EntityDto[] = [];
        selectedCountries.forEach((countryId: string)=>{
            let entityData : EntityDto = {
                id: 0,
                idEntity: countryId,
                idTarget: item.id,
                type: "country"
            };
            countries.push(entityData);
        })

        item.entities = countries;
        item.description = newName;

        setSavingData(true);
        restApiClient.updateTarget(item).then((r: any)=>{
            if (r && r.length > 0){
                setWarningMessage(r.map(r => r.description));
            }else{
                setWarningMessage([]);
                setItem(item);
            }
        }).finally(()=>{
            setSavingData(false);

            if (closeEditingName){
                setEditingName(false);
            }
        });
    }

    
    
    return <Skeleton active loading={!item}>
                {
                    showConfirmDelete && selectetTargetId
                    ? 
                        <ModalConfirmationsActions
                            title={t('titles.target-delete')}
                            description={t('descriptions.target-delete')}
                            id={'algo'}
                            functionModal={()=>{onDeleteTarget(selectetTargetId) }}
                            setShowModalConfirmAction={() => {
                                setShowConfirmDelete(false);
                                setSelectetTargetId(undefined);
                            }}
                            buttonOk={t('button-labels.yes')}
                            buttonCancel={t('button-labels.cancel')}
                            />
                    : 
                        ''
                }
                
                <div className='target-box'>
                        <div className='header'>
                            {
                                editingName ? 
                                                <Input onChange={(v: any)=>{setNewName(v.target.value);}} value={newName} 
                                                suffix={<>
                                                            <SaveOutlined style={{fontSize:"20px"}} onClick={()=>{saveChanges(true)}}/>
                                                            <CloseSquareOutlined style={{fontSize:"20px"}} onClick={()=>{setEditingName(false)}}/>
                                                        </>}/>  

                                :
                                <span className="description">
                                    <EditOutlined onClick={()=>{setEditingName(true)}}/> {item?.description} 
                                </span>
                            }
                            
                            <div className="actions">
                                <Switch defaultChecked={item?.isActive} onChange={(value: any)=>{
                                    changeTargetStatus(item.id, value);
                                }}/>
                                <Button disabled={savingData || changingStatus || deletingData} type="primary" className="" onClick={()=>{saveChanges()}}>
                                    {
                                        (savingData || changingStatus || deletingData) ? <LoadingOutlined /> : ''
                                    }
                                    {t('button-labels.save')}
                                </Button>
                                <Button disabled={savingData || changingStatus || deletingData} danger onClick={()=>{
                                    setSelectetTargetId(item.id);
                                    setShowConfirmDelete(true);
                                }}>{t('button-labels.delete')}</Button>
                            </div>
                        </div>
                        <div className="body">
                            <Typography.Title level={5} style={{marginTop: "10px"}}>
                                {t('text-labels.countries')}
                            </Typography.Title>
                            <Input.Group size="large">
                                <Row gutter={0}>
                                    <Col span={23}>
                                        <Select 
                                                filterOption={(inputValue, option)=>{
                                                    if (option && option.children && option.children.length >= 2 && option.children[1]){
                                                        return option.children[1].toUpperCase().indexOf(inputValue?.toUpperCase()) != -1;
                                                    }else{
                                                        return false;
                                                    }
                                                }}
                                                className="country-selector"
                                                onChange={onSelectCountries} 
                                                value={selectedCountries} 
                                                allowClear 
                                                onClear={()=>{setSelectedCountries([])}}
                                                mode="multiple" 
                                                maxTagCount={6} 
                                                defaultValue={item?.entities?.filter(entity => entity.type == "country").map(entity => entity.idEntity)}>
                                            {
                                                availableCountries.map((country: Country, index: number)=>{
                                                    return <Select.Option key={index} value={country.id}>
                                                        <img style={{maxWidth: "16px", marginRight: "5px"}} src={`https://flagsapi.com/${country.code}/flat/24.png`}/>{country.name}
                                                    </Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Col>
                                    <Col span={1}>
                                        <Button disabled={!availableCountries || availableCountries.length == 0} className="btn-select-all-items" onClick={()=>{
                                            setSelectedCountries(availableCountries.map((country: Country)=>country.id));
                                        }}>
                                            <CheckSquareOutlined />
                                        </Button>
                                    </Col>
                                </Row>
                            </Input.Group>
                            {
                                warningMessage && warningMessage.length > 0 
                                ?
                                <Alert message={t('warnings.targets-coincidences')} style={{marginTop: "15px"}} type='warning' showIcon description={
                                    warningMessage.map((target: string)=>{
                                        return <Tag>{target}</Tag>
                                    })
                                } />
                                :
                                ''
                            }
                            {
                                /*
                                <Typography.Title level={5} style={{marginTop: "10px"}}>
                                    {t('text-labels.agencies')}
                                </Typography.Title>
                                {
                                    item?.entities?.filter(entity => entity.type == "agency").map((entity)=>{
                                        return <Tag className="entity-selector">
                                            {entity.idEntity} - 
                                            {entity.type}
                                        </Tag>
                                    })
                                }*/
                            }
                            
                        </div>
                        <div className="footer">
                            <Typography.Title level={5} style={{marginTop: "10px"}}>
                                {t('text-labels.configurations')} ({item?.groups?.length ?? 0})
                            </Typography.Title>
                            {
                                !item || item?.groups?.length == 0 ? <Alert message={t('warnings.targets-no-configurations-assoc')} type='info' showIcon/> : ''
                            }
                            {
                                item?.groups?.map((group: TargetModels.GroupDto)=> {
                                    return <Tag>{group.name}</Tag>
                                })
                            }
                        </div>
                    </div>
            </Skeleton>
}

export default TargetsListComponent;