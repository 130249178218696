import { RestApiClientContext } from "../../hooks/context/pageContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfigSelectRewardsPerNight } from "./configSelect/configSelect";
import { ConfigData, ConfigSelected } from "./interfaces/configSelect";
import { LanguageManagement } from "../../models/language/languageUserManagement";
import { LangContext } from "../../hooks/context/lang";
import { FontIconsCustom, ParagraphCustom, PopoverCustom, TitleCustom } from "../../components/custom";
import { EstablishmentShow } from "./infoShowEstablishment/infoShowEstablishment";
import { Alert, Button, Divider, Drawer, Skeleton, Spin, Tabs } from "antd";
import { faCoins, faEdit, faClone, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import "./rewardsPerNight.sass";
import { LoadingOutlined } from "@ant-design/icons";
import { RemunerationGraphic } from "./graphic/graphic";
import { TableAndListShowInfo } from "./showListTable";
import { DirectSalesConfig } from "./directSalesConfig/directSalesConfig";
import { DirectSales, LimitRewardAmount } from "./interfaces/directSalesConfigInterface";
import { AddConfiguration } from "./addConfiguration/addConfiguration";
import { EditConfiguration } from "./editConfiguration/editConfiguration";
import { ModalConfirmationsActions } from "./modalConfirm/modalConfirm";
import { AddSeasons } from "./addSeasons/addSeasons";
import { TargetDto } from "src/models/targets/TargetModels";
import CloneConfigurationComponent from "./cloneConfiguration/CloneConfigurationComponent";

export const RewardsPerNight = () => {
  const { t } = useTranslation(["rewardsPerNight", "translation", "targets", "cloneConfiguration"]);

  const restApiClient = useContext(RestApiClientContext);
  const userLangContext = useContext<LanguageManagement>(LangContext);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [lng, setLng] = useState<string>(userLangContext.GetLangContext());
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingResult, setLoadingResult] = useState<boolean>(true);
  const [showDrawerAddConfiguration, setShowDrawerAddConfiguration] = useState<boolean>(false);
  const [showDrawerAddSeasons, setShowDrawerAddSeasons] = useState<boolean>(false);
  const [showDrawerEditConfiguration, setShowDrawerEditConfiguration] = useState<boolean>(false);
  const [optionSelectedInput, setOptionSelectedInput] = useState<ConfigSelected>(null);
  const [listOptions, setListOptions] = useState<ConfigSelected[]>([]);
  const [dataConfiguration, setDataConfiguration] = useState<ConfigData>();
  const [updateInfo, setUpdateInfo] = useState<boolean>(false);
  const [dataDirectSales, setDataDirectSales] = useState<DirectSales>();
  const [dataLimitAmounts, setDataLimitAmounts] = useState<LimitRewardAmount>({amounts: []});
  const [changeNewConfig, setChangeNewConfig] = useState<boolean>(false);
  const [showModalConfirmDeleteConfiguration, setShowModalConfirmDeleteConfiguration] = useState<boolean>(false);
  const [updateLimits, setUpdateLimits] = useState<boolean>(false);

  const getConfigurations = () : void => {
    setLoading(true);
    restApiClient.getConfigurations(selectedTargetId).then((data) => {
      if (data) {
        setListOptions(data);
        setOptionSelectedInput(data[0]);
      }
    }).finally(()=>{
      setLoading(false);
    });
  }



  const getLimits = () : void => {
    restApiClient.getLimitAmounts().then((data) => {
      if (data) {
        const d : LimitRewardAmount = {
          amounts: data
        }
        setDataLimitAmounts(d);
      }
    }).finally(()=>{
    });
  }

  useEffect(()=> {
    getLimits();
  }, [])

  useEffect(()=>{
    if (updateLimits){
      getLimits();
    }
  },[updateLimits]);

  useEffect(() => {
    if (userLangContext.GetLangContext() !== lng) {
      setLng(userLangContext.GetLangContext());
    }
  });

  const reloadData = () : void => {
    setLoadingResult(true);
    if (optionSelectedInput) {
      restApiClient.GetConfiguration(optionSelectedInput.id).then((data) => {
        if (data) {
          setDataConfiguration(data);
          setLoadingResult(false);
        }
        setLoading(false);
      });
    }
  }

  useEffect(() => {
    reloadData();
  }, [optionSelectedInput, updateInfo]);

  const openDrawerAddConfiguration = () => {
    setShowDrawerAddConfiguration(true);
  };

  const [showDrawerCloneConfiguration, setShowDrawerCloneConfiguration] = useState<boolean>();
  const openDrawerCloneConfiguration = () => {
    setShowDrawerCloneConfiguration(true);
  };

  const openDrawerEditConfiguration = () => {
    setShowDrawerEditConfiguration(true);
  };

  const deleteConfiguration = () => {
    setShowModalConfirmDeleteConfiguration(true);
  };

  const openDrawerAddSeasons = () => {
    setShowDrawerAddSeasons(true);
  };

  const confirmDelete = (id: string) => {
    setLoading(true);

    restApiClient.DeleteConfiguration(id).then((data) => {
      if (data) {
        setChangeNewConfig(!changeNewConfig);
        setShowDrawerEditConfiguration(false);
        setShowModalConfirmDeleteConfiguration(false);
      }

      setLoading(false);
    });
  };

  const updateViewAddOrEditSeason = () => {
    setLoadingResult(true);

    if (optionSelectedInput) {
      restApiClient.GetConfiguration(optionSelectedInput.id).then((data) => {
        if (data) {
          setDataConfiguration(data);
          setLoadingResult(false);
        }

        setLoading(false);
      });
    }
  };

  const [targets, setTargets] = useState<TargetDto[]>([]);

  const getTargets = () => {

    var params = {
      description: '', 
      page: 0, 
      resultPage: 99999, 
      isActive: 1, 
      entityIds: []
    };

    restApiClient.getTargets(params).then((result: any)=>{
      setTargets(result);
    })
  }

  const [selectedTargetId, setSelectedTargetId] = useState<string>('');
  const onChangeTarget = (activeTarget: string) : void => {
    setSelectedTargetId(activeTarget);
  }

  useEffect(()=>{
    getTargets();
  },[])

  useEffect(() => {
    getConfigurations();
  }, [lng, changeNewConfig, selectedTargetId]);

  const onCloseCloneConfiguration = () : void => {
    setShowDrawerCloneConfiguration(false);
  }

  return (
    <>
        <br/>
        <Drawer visible={showDrawerCloneConfiguration} closable onClose={onCloseCloneConfiguration} title={t('cloneConfiguration:title')}>
          <CloneConfigurationComponent onFinish={(newTargetId: number)=>{
            setSelectedTargetId(newTargetId+'');
            onCloseCloneConfiguration();
          }} onCancel={onCloseCloneConfiguration} configurationData={optionSelectedInput}/>
        </Drawer>
        <div>
          <DirectSalesConfig
            data={dataDirectSales}
            limitAmounts={dataLimitAmounts}
            changeValuesLimits={getLimits}
          />
        </div>
        {
          targets.length > 0 
          ?
            <Tabs onChange={onChangeTarget} activeKey={selectedTargetId}>
              <Tabs.TabPane tab={t('targets:tabs.general')} key="" active={(selectedTargetId === (''))}>
              </Tabs.TabPane>
              {
                targets.map((target: TargetDto, index: number)=> { return <Tabs.TabPane active={(selectedTargetId === (target.id+''))} tab={target.description} key={`${target.id}`}>
                                                                          </Tabs.TabPane>; 
                                                  })
              }
            </Tabs>
        :
          <div style={{marginBottom: "20px"}}></div>
        }
        
        
        <div className="add-configuration">
          <Button type="primary" className="primary-button" onClick={openDrawerAddConfiguration}>
            <span><FontIconsCustom classIcon={"margin-top-plus"} icon={faPlus} /> {t("rewardsPerNight:add-configuration")}</span>
          </Button>
        </div>
        {showDrawerAddConfiguration && (
                      <AddConfiguration
                        targetId={selectedTargetId}
                        setShowDrawerAddConfiguration={setShowDrawerAddConfiguration}
                        addNewConfig={changeNewConfig}
                        setAddNewConfig={setChangeNewConfig}
                      />
                    )}
        <Skeleton active={true} loading={loading}>
          {
            !listOptions || listOptions.length == 0 ? <Alert style={{marginTop: "10px"}} message={t('targets:warnings.no-rewards-by-night')} type="warning" showIcon /> : ''
          }
          {
            listOptions && listOptions.length > 0 ? 
            <div className="container special-mb">
                
                
                    <div className="col-12 p-0 row box-get-config-add-config">
                      <div className="col-md-8 p-0 col-12 order-12 order-md-6">
                        <ConfigSelectRewardsPerNight
                          listOptions={listOptions}
                          optionSelectedInput={optionSelectedInput}
                          setOptionSelectedInput={setOptionSelectedInput}
                        />
                      </div>
                      
                    </div>
                    {loadingResult && optionSelectedInput != undefined? (
                      <Spin className="loading-reward" indicator={antIcon} />
                    ) : (
                      <div className="box-data-select-show">
                        <div className="col-12 p-0 box-title-and-button-display">
                          <div className="col-11 p-0">
                            <TitleCustom
                              class="title-configuration"
                              text={t("rewardsPerNight:name-configuration", { 0: optionSelectedInput?.name })}
                            />
                          </div>
                          <div className="col-1 p-0 align-menu">
                            <PopoverCustom
                              classBody={"position-float-right"}
                              body={
                                <Button style={{backgroundColor: "transparent !important", border: "none"}} htmlType={null} id={"btn-validate-agent-22"} type="ghost" className="button-list-agents">
                                  <FontIconsCustom classIcon={"ellipsis-icons"} icon={faPlus} />
                                </Button>
                              }
                              content={
                                <>
                                  <div className="selection-popover link-menu-popover" onClick={openDrawerAddSeasons}>
                                    <FontIconsCustom icon={faCoins} />
                                    <ParagraphCustom class={"mb-0 ml-1"} text={t("rewardsPerNight:created-period")} />
                                  </div>
                                  <div className="selection-popover link-menu-popover" onClick={openDrawerEditConfiguration}>
                                    <FontIconsCustom icon={faEdit} />
                                    <ParagraphCustom class={"mb-0 ml-1"} text={t("rewardsPerNight:edit-configuration")} />
                                  </div>
                                  {!optionSelectedInput?.isDefault && (
                                    <div className="selection-popover link-menu-popover" onClick={deleteConfiguration}>
                                      <FontIconsCustom icon={faTrashAlt} />
                                      <ParagraphCustom class={"mb-0 ml-1"} text={t("rewardsPerNight:delete-configuration")} />
                                    </div>
                                  )}
                                  <Divider style={{margin: "0"}}/>
                                  <div className="selection-popover link-menu-popover" onClick={openDrawerCloneConfiguration}>
                                    <FontIconsCustom icon={faClone} />
                                    <ParagraphCustom class={"mb-0 ml-1"} text={t("rewardsPerNight:clone-configuration")} />
                                  </div>
                                  
                                </>
                              }
                              placement={"bottom"}
                            />
                          </div>
                        </div>
                        <div className="col-12 p-0 mt-5 mb-5">
                          <EstablishmentShow dataConfiguration={dataConfiguration} />
                        </div>
                        <div className="col-12 p-0 mt-5">
                          <RemunerationGraphic
                            dataConfiguration={dataConfiguration}
                            updateViewAddOrEditSeason={updateViewAddOrEditSeason}
                          />
                        </div>
                        <div className="col-12 p-0 mt-5">
                          <TableAndListShowInfo
                            dataConfiguration={dataConfiguration}
                            updateViewAddOrEditSeason={updateViewAddOrEditSeason}
                          />
                        </div>
                      </div>
                    )}
                    
                    {showDrawerEditConfiguration && (
                      <EditConfiguration
                        optionSelectedInput={optionSelectedInput}
                        setShowDrawerEditConfiguration={setShowDrawerEditConfiguration}
                        editConfig={updateInfo}
                        setEditNewConfig={setUpdateInfo}
                        deleteConfiguration={deleteConfiguration}
                      />
                    )}
                    {showModalConfirmDeleteConfiguration && (
                      <ModalConfirmationsActions
                        title={t("rewardsPerNight:delete-configuration-modal.title", { 0: optionSelectedInput.name })}
                        description={t("rewardsPerNight:delete-configuration-modal.description")}
                        id={optionSelectedInput.id}
                        functionModal={() => confirmDelete(optionSelectedInput.id)}
                        setShowModalConfirmAction={() => setShowModalConfirmDeleteConfiguration(false)}
                        buttonOk={t("rewardsPerNight:delete")}
                        buttonCancel={t("rewardsPerNight:cancel")}
                      />
                    )}
                    {showDrawerAddSeasons && (
                      <AddSeasons
                        updateViewAddOrEditSeason={updateViewAddOrEditSeason}
                        configSelect={optionSelectedInput.id}
                        setShowDrawerSeasons={setShowDrawerAddSeasons}
                      />
                    )}
              </div>
            :
            ''
          }
        </Skeleton>
    </>
  );
};
