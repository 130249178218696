import React from "react";
import "../../../i18n";
import { Table } from "antd";

// Componente de table
export const TableCustomComponent = (param) => {
    return (
        <Table
            className={param.className}
            columns={param.columns}
            dataSource={param.dataSource}
            pagination={param.pagination}
            scroll={param.scroll}
            bordered={param.bordered}
            {...param}
        />
    );
};
