import React, { useState, useEffect } from "react";
import moment from "moment";
import "./styles.sass";
import { DatepickerOwn } from "./datepickerOwn";
import { AlternateComponent } from "../alternateComponent/alternateComponent";
import { DATE_END_DEFAULT_TIMELESS, DATE_INIT_DEFAULT_TIMELESS } from "../../../constants/constants";

interface DefaultValueCalendar {
  datesInitDefault: any;
  handleChangeDate: (day: any) => void;
}

export const DatepickerDistribution = (props: DefaultValueCalendar) => {

  const { datesInitDefault,handleChangeDate } = props;
  const [selectedDates, setSelectedDates] = useState(moment(datesInitDefault[0]).isValid()?
    [
      moment(datesInitDefault[0]),
      moment(datesInitDefault[0]).add(1,"month")
    ] : [
      moment(DATE_INIT_DEFAULT_TIMELESS),
      moment(DATE_END_DEFAULT_TIMELESS),
    ]
  );
  const [selectedDate, setSelectedDate] = useState(moment(datesInitDefault[0]).isValid() ? [moment(datesInitDefault[0])] : [moment(DATE_INIT_DEFAULT_TIMELESS)] );
  const [input, setInput] = useState(moment(datesInitDefault[0]).isValid() ?[moment(datesInitDefault[0]), moment(datesInitDefault[1])]:[moment(null), moment(null)]);

  const dateSelectedChange = (day) => {
    setSelectedDates(day);
    setSelectedDate([day[0]]);
  };
  const dateInputChange = (day) => {
    setInput([day[0], day[day.length -1]]);
    handleChangeDate([day[0], day[day.length -1]])
  };

  const desktopVersion = () => {
    return (
      <DatepickerOwn
        dateInputChange={dateInputChange}
        input={input}
        value={selectedDates}
        onChange={dateSelectedChange}
      />
    );
};

const tabletVersion = () => {
    return desktopVersion();
};

const mobileVersion = () => {
    return (
      <DatepickerOwn
      dateInputChange={dateInputChange}
      input={input}
      value={selectedDate}
      onChange={dateSelectedChange}
    />
    );
};

  return (
    <AlternateComponent
      desktopVersionLarge={desktopVersion()}
      tabletVersion={tabletVersion()}
      mobileVersionLarge={mobileVersion()}
  />
  );
};
