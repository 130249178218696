import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClientDataModel } from "../../models/config/clientData";
import { RestApiClientContext } from "../../hooks/context/pageContext";

// Componente de información de los textos legales de la página
export const useLegalTexts = (x: any): ClientDataModel => {
	const [clientData, setClientData] = useState<ClientDataModel>(
		new ClientDataModel()
	);

    const restApiClient = useContext(RestApiClientContext);

    useEffect(() => {
        restApiClient.getCustomizations()
            .then(a => setClientData(a))
    }, [])

    return clientData
};
