import * as React from "react";
import { useContext, useEffect } from "react";
import { Route, withRouter } from "react-router-dom";
import { Home } from "../../components/home/home";
import { Faqs } from "../../components/faqs/faqs";
import { CookiesPolicy } from "../../components/legalTexts/cookiesPolicy/cookiesPolicy";
import { LegalAdvice } from "../../components/legalTexts/legalAdvice/legalAdvice";
import { TermConditions } from "../../components/legalTexts/termConditions/termConditions";
import { PrivacyPolicies } from "../../components/legalTexts/privacyPolicies/privacyPolicies";
import { Contact } from "../../components/contact/contact";
import { AppRoute } from "../appLanguages";
import { LocalizedSwitch } from "../localizedSwitch";
import { LangContext } from "../../hooks/context/lang";
import { useTranslation } from "react-i18next";
import { LANGS } from "../../constants/constants";

const RoutesContent = ({ history }) => {
    const language = useContext(LangContext);
    const { i18n } = useTranslation();

    useEffect(() => {
        history.listen(() => {
            // Recargamos la pagina en caso de navegacion hacia delante o hacia atras
            if (history.action === "POP") {
                const languageP = LANGS.find((e) => {
                    return e.url === history.location.pathname.split("/")[1];
                });
                const langP = languageP.lang;
                language.UpdateLangContext(langP);
                i18n.changeLanguage(langP);
            }
        });
    }, []);

    return (
        <LocalizedSwitch>
            <Route
                exact
                path={AppRoute.Home}
                component={Home}
            />
            <Route
                exact
                path={AppRoute.CookiesPolicyR}
                component={CookiesPolicy}
            />
            <Route
                exact
                path={AppRoute.LegalAdvice}
                component={LegalAdvice}
            />
            <Route
                exact
                path={AppRoute.TermConditionsR}
                component={TermConditions}
            />
            <Route
                exact
                path={AppRoute.PrivacyPolicies}
                component={PrivacyPolicies}
            />
            <Route
                exact
                path={AppRoute.Faqs}
                component={Faqs}
            />
            <Route
                exact
                path={AppRoute.Contact}
                component={Contact}
            />
            <Route
                component={Home}
            />
        </LocalizedSwitch>
    );
};

export default withRouter(RoutesContent);
