import EndPointConfig from '../../models/api/endPointConfig';

const controller = 'Currencies';

/// Define all EndPoints for contact section.
export const CurrenciesEP: EndPointConfig[] = [
    // Envio de formulario a la API

    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('conversion')
        .setAlias('GetCurrencyConversion'),

        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetListCurrency'),
];