import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import "../../../i18n";
import { useTranslation } from 'react-i18next';
import { InputCustom, ParagraphCustom } from 'src/components/custom';

import { LoadingOutlined } from '@ant-design/icons'


import './SearchTargetsComponent.sass'
import { Country } from 'src/models/countries/CountriesModels';

interface SearchTargetsComponentProps{
    onSearch: () => void,
    onCreate: () => void,
    onSetParameters: (description: string, status: number, entityIds: string[]) => void,
    isLoading: boolean,
    availableContries: Country[]
}
const SearchTargetsComponent = (props: SearchTargetsComponentProps): JSX.Element => {

    const { t } = useTranslation(["targets"]);

    const [form] = Form.useForm();

    const onChange = () : void => {
        var values = form.getFieldsValue();

        let entityIds : string[] = [];

        if(values.agency){
            entityIds.push(values.agency);
        }

        if(values.country){
            entityIds.push(values.country);
        }

        props.onSetParameters(values.description, values.status, entityIds);
    }

    const onFinish = () : void => {
        props.onSearch();
    }

    const [availableContries, setAvailableContries] = useState<Country[]>([])
    useEffect(()=>{
        if (props.availableContries){
            setAvailableContries(props.availableContries);
        }
    },[props.availableContries])

    return (
        <div className='search-targets-component'>
            <Form name="search-agent" id="search-agent" form={form}  onFinish={onFinish} autoComplete="off">
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={12}>
                        <ParagraphCustom text={<strong>{t("text-labels.description")}</strong>} />
                        <Form.Item name="description">
                            <Input className='main-input' onChange={onChange} allowClear/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <ParagraphCustom text={<strong>{t("text-labels.status")}</strong>} />
                        <Form.Item name="status" initialValue={-1}>
                            <Select onChange={onChange}>
                                <Select.Option value={-1}>{t("select-labels.status.all")}</Select.Option>
                                <Select.Option value={1}>{t("select-labels.status.active")}</Select.Option>
                                <Select.Option value={0}>{t("select-labels.status.inactive")}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={0}>
                        <ParagraphCustom text={<strong>{t("text-labels.agency")}</strong>} />
                        <Form.Item name="agency">
                            <Input className='main-input' onChange={onChange} allowClear/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <ParagraphCustom text={<strong>{t("text-labels.country")}</strong>} />
                        <Form.Item name="country">
                            <Select onChange={onChange} allowClear showSearch
                            filterOption={(inputValue, option)=>{
                                                    if (option && option.children && option.children.length >= 2){
                                                        return option.children[1].toUpperCase().indexOf(inputValue.toUpperCase()) != -1;
                                                    }else{
                                                        return false;
                                                    }
                                                }}>
                                {availableContries.map((country: Country, index: number) => {
                                    return <Select.Option key={index} value={country.id}>
                                        <img style={{maxWidth: "16px", marginRight: "5px"}} src={`https://flagsapi.com/${country.code}/flat/24.png`}/>{country.name}
                                    </Select.Option>
                                })}
                            </Select>
                        </Form.Item> 
                    </Col>
                    <Col 
                        xs={{span: 24, offset: 0}} 
                        sm={{span: 24, offset: 0}} 
                        md={{span: 12, offset: 0}}
                        lg={{span: 6, offset: 0}}
                        xl={{span: 6, offset: 0}}
                        xxl={{span: 6, offset: 0}}
                    >
                        <ParagraphCustom text={<>&nbsp;</>} />
                        <Button disabled={props.isLoading} htmlType='submit' type="primary" className="primary-button col-12 btn-target-actions">
                            <span>
                                <strong>{props.isLoading ? <LoadingOutlined /> : ''} {t("button-labels.search")}</strong>
                            </span>
                        </Button>
                    </Col>
                    <Col 
                        xs={{span: 24, offset: 0}} 
                        sm={{span: 24, offset: 0}} 
                        md={{span: 12, offset: 0}}
                        lg={{span: 6, offset: 0}}
                        xl={{span: 6, offset: 0}}
                        xxl={{span: 6, offset: 0}}
                    >
                        <ParagraphCustom text={<>&nbsp;</>} />
                        <Button disabled={props.isLoading} onClick={props.onCreate} type="primary" className="primary-button col-12 btn-target-actions">
                            <span>
                                <strong>{t("button-labels.create")}</strong>
                            </span>
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}


export default SearchTargetsComponent;