import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { URL_BASE } from "../../constants/constants";
import "./config.sass";
import { ConfigContext } from "../../hooks/context/config";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { TitleCustom } from "../custom/index";
import { Tabs } from "antd";
import { Channels } from "./channels/channels";
import { Rooms } from "./rooms/rooms";
import { Regimens } from "./regimens/regimens";
import { Establishments } from "./hotels/establishments";
import { Params } from "./params/params";
import { ManageLegalTexts } from "./legal-texts/ManageLegalTexts";

const { TabPane } = Tabs;

export const Config = () => {
  const { t } = useTranslation(["config, seo, urls"]);
  const [filter, setFilter] = useState(0);
  const infoClient = useContext(ConfigContext);

  return (
    <>
      <Helmet>
        <title>
          {infoClient.incentiveSiteName} | {t("seo:title.config")}
        </title>
        <meta
          name="description"
          content={t("seo:description.config", {
            0: infoClient.incentiveSiteName
          })}
        />
        <meta name="robots" content="index,follow" />
        <link rel="canonical" href={URL_BASE + t("urls:public.config")} />
        <link rel="icon" type="image/ico" href={infoClient.favicon} sizes="32x32" />
        <link href={URL_BASE + t("urls:public.config")} hrefLang="es-ES" rel="alternate" />
      </Helmet>
      <div id="config" className="container special-mb">
        <div className="row">
          <TitleCustom level={1} class="title-section" text={t("config:title")} />
        </div>
        <div className="row">
          <Tabs
            className="col-12 col-xxl-9"
            defaultActiveKey="0"
            onChange={(newFilter) => {
              setFilter(parseInt(newFilter));
            }}
          >
            <TabPane key={0} tab={<div className="tab-header p-0 px-md-2 px-lg-4">{t("config:tabs.channels")}</div>}>
              <Channels />
            </TabPane>
            <TabPane key={1} tab={<div className="tab-header p-0 px-md-2 px-lg-4">{t("config:tabs.rooms")}</div>}>
              <Rooms />
            </TabPane>
            <TabPane key={2} tab={<div className="tab-header p-0 px-md-2 px-lg-4">{t("config:tabs.regimens")}</div>}>
              <Regimens />
            </TabPane>
            <TabPane key={3} tab={<div className="tab-header p-0 px-md-2 px-lg-4">{t("config:tabs.hotels")}</div>}>
              <Establishments />
            </TabPane>
            <TabPane key={4} tab={<div className="tab-header p-0 px-md-2 px-lg-4">{t("config:tabs.params")}</div>}>
              <Params />
            </TabPane>
            <TabPane key={5} tab={<div className="tab-header p-0 px-md-2 px-lg-4">{t("config:tabs.legal-texts")}</div>}>
              <ManageLegalTexts />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};
