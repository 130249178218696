import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormattedNumber } from 'react-intl';
import { getLocaleDatePicker } from '../../../utils/datePicker';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import moment from 'moment';
import './graphic.sass';
import locale from 'antd/lib/date-picker/locale/en_US';
import roundTo from 'round-to';
import { Amount, ConfigData, Periods } from '../interfaces/configSelect';
import { FontIconsCustom, OptionsCustom, ParagraphCustom, SelectCustomPro, TooltipProCustom } from '../../../components/custom';
import { faChartBar, faEdit } from "@fortawesome/free-solid-svg-icons";
import { EditSeasons } from '../editSeasons/editSeasons';
import { DataGraphic } from '../interfaces/graphic';

export const RemunerationGraphic = (props: DataGraphic) => {
    const { dataConfiguration, updateViewAddOrEditSeason } = props;


    const { t } = useTranslation('rewardsPerNight');
    const local: PickerLocale = getLocaleDatePicker();
    const months = [];

    const [currencySelect, setCurrencySelect] = useState<Amount>(dataConfiguration.periods.length > 0 ? dataConfiguration.periods[0]?.amounts[0] : null);
    const [showDrawerEditPeriod, setShowDrawerEditPeriod] = useState<boolean>(false);
    const [periodSelected, setPeriodSelected] = useState<Periods>();

    // Formato forzado para recoger correctamente la fecha para su posterior uso
    const year = moment(dataConfiguration.periods[0]?.startDate, "MM/DD/YYYY").year();

    for (let i = 1; i <= 12; i++) {
        months.push(t("rewardsPerNight:graphic.month-list." + i));
    }

    const contentTooltip = (data: Periods, editButon: boolean = false) => {

        return (
            <div className="box-tooltip">
                <ParagraphCustom
                    class="color-text-tooltip mb-0 mt-2"
                    text={<strong>{data.name}</strong>}
                />

                <ParagraphCustom
                    class="color-text-tooltip mb-0"
                    text={moment(data?.startDate).format(t("translation:lang.monthAndDayFormat")) + " - " + moment(data.endDate).format(t("translation:lang.monthAndDayFormat"))}
                />
                <ParagraphCustom
                    class="color-text-tooltip mb-0"
                    text={t("rewardsPerNight:min-nights-resume", {0:data.minNightsAllowed})}
                />

                <ParagraphCustom
                    class="color-text-tooltip mb-2"
                    text={
                        <strong>
                            <FormattedNumber
                                value={data?.amounts.filter(e => e.currencyId === currencySelect.currencyId)[0].value}
                                style="currency"
                                currency={currencySelect.currencyId}
                            />
                        </strong>
                    }
                />
                {editButon &&
                    <FontIconsCustom
                        className="icons-edit"
                        icon={faEdit}
                        onClick={() => openEditPeriod(data)}
                    />
                }
            </div>
        )
    }

    const handleChangeSelected = (e:any) => {
        setCurrencySelect(JSON.parse(e));
    }

    const ListTotalAmount: Amount[] = [];
    dataConfiguration.periods.map((v) => {
        if (v?.amounts.length > 0) {
            ListTotalAmount.push(...v?.amounts.filter(e => e));
        }
    });
    const maxValueOfY: number = Math.max(...ListTotalAmount.filter(elements => elements.currencyId === currencySelect.currencyId).map(o => o.value));

    const openEditPeriod = (data: Periods) => {
        setPeriodSelected(data);
        setShowDrawerEditPeriod(true);
    }

    return (
        <>
            <div className="col-12 p-0 ">
                <div className="col-12 p-0 icons-section">
                    <div className="width-icons-configuration-reward">
                        <FontIconsCustom
                            icon={faChartBar}
                        />
                    </div>
                    <div className="ml-2">
                        <ParagraphCustom
                            class="margin-b"
                            text={t("rewardsPerNight:graphic-section.title")}
                        />
                    </div>
                </div>
                <div className="col-12 p-0 display-currency-selected">
                    <div className="col-12 col-sm-10 p-0">
                        <ParagraphCustom
                            text={t("rewardsPerNight:graphic-section.description")}
                        />
                    </div>
                    <div className="col-12 col-sm-2 p-0">
                        <SelectCustomPro
                            key={"select-currency"}
                            defaultValue={JSON.stringify(currencySelect)}
                            onChange={handleChangeSelected}
                            className={"col-12 main-input"}
                        >
                            {dataConfiguration.periods[0]?.amounts.map((element, index) => {
                                return (
                                    <OptionsCustom
                                        key={index}
                                        value={JSON.stringify(element)}
                                    >
                                        {element.currencyId}
                                    </OptionsCustom>
                                );
                            })}
                        </SelectCustomPro>
                    </div>
                </div>
            </div>
            <div className="col-12 p-0 table-remuneration">
                <div className="col-md-1"></div>
                <div className="col-12 p-0 graphic-box">
                    <div className="bars-graphic">
                        {dataConfiguration.periods.map((y: Periods, i) => {
                            const dateCi = i > 0 ? moment(y?.startDate, locale.dateFormat).subtract(1, "days") : moment(y?.startDate, locale.dateFormat);
                            const dateCo = moment(y.endDate, locale.dateFormat);
                            const diff = dateCo.diff(dateCi, 'days');
                            const percent = roundTo.up((diff * 100) / 365, Infinity);
                            const percentCalcaulate: number = (y?.amounts.length > 0 && y?.amounts.filter(elements => elements.currencyId === currencySelect.currencyId)[0].value) ? (y?.amounts.filter(elements => elements.currencyId === currencySelect.currencyId)[0].value / maxValueOfY) * 100 : 0;
                            const percentBefore = 100 - percentCalcaulate;

                            return (
                                <>
                                    <div
                                        key={i}
                                        style={
                                            {
                                                width: percent + "%",
                                                height: 100 + "%"
                                            }
                                        }
                                    >
                                        {percent > 20 && percentCalcaulate === 0 ? (
                                            <div
                                                className={i === 0 ?
                                                    "padding-bars-before border-graphics-right " :
                                                    i === (dataConfiguration.periods.length - 1) ?
                                                        "padding-bars-before border-graphics-left" :
                                                        "padding-bars-before border-graphics"
                                                }
                                                style={
                                                    {
                                                        width: 100 + "%",
                                                        height: percentBefore + "%"
                                                    }
                                                }
                                            >
                                                <div className="box-tooltip">
                                                    <ParagraphCustom
                                                        class="mb-0 mt-2"
                                                        text={y.name}
                                                    />

                                                    <ParagraphCustom
                                                        class="mb-0"
                                                        text={
                                                            moment(y?.startDate).format('D') + " " + t("rewardsPerNight:graphic.month-list-large." + (moment(y?.startDate).month() + 1))
                                                            + " - " +
                                                            moment(y.endDate).format('D') + " " + t("rewardsPerNight:graphic.month-list-large." + (moment(y.endDate).month() + 1))
                                                        }
                                                    />

                                                    <ParagraphCustom
                                                        class="mb-2"
                                                        text={
                                                            <FormattedNumber
                                                                value={y?.amounts.filter(e => e.currencyId === currencySelect.currencyId)[0].value}
                                                                style="currency"
                                                                currency={currencySelect.currencyId}
                                                            />
                                                        }
                                                    />
                                                    <FontIconsCustom
                                                        icon={faEdit}
                                                        onClick={() => openEditPeriod(y)}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                                <TooltipProCustom
                                                    id="tooltip-remuneration"
                                                    title={() => contentTooltip(y, true)}
                                                    key={`gr-temp-${i}`}
                                                >
                                                    <div
                                                        className={i === 0 ?
                                                            "padding-bars-before border-graphics-right " :
                                                            i === (dataConfiguration.periods.length - 1) ?
                                                                "padding-bars-before border-graphics-left" :
                                                                "padding-bars-before border-graphics"
                                                        }
                                                        style={
                                                            {
                                                                width: 100 + "%",
                                                                height: percentBefore + "%"
                                                            }
                                                        }
                                                    ></div>
                                                </TooltipProCustom>
                                            )
                                        }
                                        <TooltipProCustom
                                            id="tooltip-remuneration"
                                            title={() => contentTooltip(y, true)}
                                            key={i}
                                        >
                                            <div
                                                className={i === 0 ?
                                                    "padding-bars border-graphics-right" :
                                                    i === (dataConfiguration.periods.length - 1) ?
                                                        "padding-bars border-graphics-left" :
                                                        "padding-bars border-graphics"
                                                }
                                                style={{ width: 100 + "%", height: percentCalcaulate + "%" }}>
                                            </div>
                                        </TooltipProCustom>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                    <div className="col-12 bar-grey"></div>
                    <div className="months-vertical-season">
                        {
                            months.map((month: string, x) => {
                                const numberDaysForMonth = moment(year + "/" + (x + 1), "YYYY/MM").daysInMonth();
                                const percentBoxMonth = roundTo.up((numberDaysForMonth * 100) / 365, Infinity);

                                return (
                                    <div style={{ width: percentBoxMonth + "%" }} key={x}>
                                        { month }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="col-md-1"></div>
            </div>
            {showDrawerEditPeriod && (
                <EditSeasons
                    configurationSelect={dataConfiguration.id}
                    periodSelected={periodSelected}
                    setShowDrawerSeasons={setShowDrawerEditPeriod}
                    updateViewAddOrEditSeason={updateViewAddOrEditSeason}
                />
            )}
        </>
    )
}
