import * as React from "react";
import { Collapse } from "antd";
import "./collapses.sass";

const { Panel } = Collapse;

export const CollapseCustomComponent = (props) => {
    return(
		<Collapse
			{...props}
		>
			{props.children}
		</Collapse>
    )
}

export const PanelsCustomComponent = (props) => {
	return(
		<Panel
			{...props}
		>
			{props.children}
		</Panel>
	);
}
