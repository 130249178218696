import React, { useState, useContext ,useEffect} from "react";
import { LangContext, LangUrlContext } from "../../../hooks/context/lang";
import "./login.sass";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { UserContext, LoginContext } from "../../../hooks/context/userInfo";
import { LanguageManagement } from "../../../models/language/languageUserManagement";
import { LanguageUrlManagement } from "../../../models/language/languageUrlManagment";
import { CookiePopUp } from "../../../hooks/context/cookies";
import { AllLanguages } from "../../../hooks/context/lang";
import {
    DURATION_COOKIE,
    LIST_COOKIES_DEFAULT,
} from "../../../constants/constants";
import UserModelSendDataLogin from "../../../models/user/userModelSendDataLogin";
import { ResetPass } from "./resetPass/resetPass";
import {
    FormCustom,
    InputCustom,
    FormItemsContact,
    InputPasswordCustom,
    ButtonsCustom,
    ModalCustom,
} from "../../../components/custom";
import { RestApiClientContext } from "../../../hooks/context/pageContext";
import { useUserLang } from 'src/utils/languages';
import { LinkActiveContext } from "src/hooks/context/linkActive";
import { useIntl } from "react-intl";
import {  useLocation } from "react-router-dom";


// Componente login
export const Login = (params) => {
    // Idioma para los textos
    const { t } = useTranslation(["resetPass", "login","urls"]);
    const { i18n } = useTranslation();
    const restApiClient = useContext(RestApiClientContext);

    // Contexto de información de usuario y login
    const userContext = useContext(UserContext);
    const language = useContext(LangContext);
    const languageUrl = useContext(LangUrlContext);

    const loginContext = useContext(LoginContext);
    // Context para mostrar o ocultar el componente popup
    const popupCookies = useContext(CookiePopUp);

    // Estado para mostrar u ocultar alert warning
    const [visibleAlertwarning, setVisibleAlertwarning] = useState(false);
    // Estado para mostrar u ocultar alert error
    const [visibleAlertError, setVisibleAlertError] = useState(false);
    // Estado para los mensajes a mostrar en los alert
    const [messageAlert, setMessageAlert] = useState({
        message: "",
        description: "",
    });
    // Estado con la lista de cookies aceptadas por el sistema
    const [cookiesSave] = useState(LIST_COOKIES_DEFAULT);
    // Estado para mostrar u ocultar modal
    const [visibleFormPassword, setVisibleFormPassword] = useState(false);

    // Cookie comprobador si se aceptaron o no las cookies
    const [, setCookies] = useCookies(["cookies"]);
    // Lista de cookies que se pueden modificar en la aplicacion
    const [, setCookiesList] = useCookies(["cookiesList"]);
    // Cookie token usuario
    const [, setUserToken] = useCookies(["userToken"]);

    // Funcion Abrir modal contraseña
    const openModalPassword = () => {
        setVisibleFormPassword(true);
    };

    // Manejador si el usuario le da a cancelar en la modal
    const handleCancel = () => {
        setVisibleFormPassword(false);
    };

    const linkActive = useContext(LinkActiveContext);

    
	const allLang = useContext(AllLanguages);
	// Filtro para marcar en el combo el idioma seleccionado por el usuario
	
    const  getUserInfoMock =   () => {
        restApiClient.getUserName()
            .then(async (name) => {
                if (name) {
                    const idioma = await  restApiClient.getUserLanguage()
                    	
                    if(idioma == null || idioma == undefined) {
                        userContext.UpdateUserContext(
                            name,
                            languageUrl.GetURLLangContext(),
                            language.GetLangContext()
                        );
                    }else{
                        
                       await i18n.changeLanguage(idioma);
                        language.UpdateLangContext(idioma)
                        languageUrl.UpdateUrlLangContext(idioma.substring(0,2))
                        userContext.UpdateUserContext(
                            name,
                            idioma.substring(0,2),
                            idioma
                        );
                        
                        let langComboUser=  allLang.GetLanguage().filter((e) => e.id.includes(idioma))
                        let  nuevaURL = window.location.origin + "/" + idioma.substring(0,2) + "/app";
                        window.history.pushState({}, '', nuevaURL);
                        linkActive.updateValueUrl( // esto no hace nada 
                            t("urls:private.home")
                        )
                    }   

                    // Ocultar el popupCookies al hacer login correctamente
                    popupCookies.UpdateVisible(false);
                    // Determina si el login ha ido correctamente, almacenando su valor booleano en el contexto
                    loginContext.updateValue(true);                
                } else {
                    setVisibleAlertError(true);
                    setMessageAlert({
                        message: t(
                            "login:list-errors.title-data-errors"
                        ),
                        description: t("login:list-errors.data-errors"),
                    });
                }
            })
    };

    // Submit form
    const onFinish = async (values) => {
        const userData = {
            UserData: values.username,
            Password: values.password,
        } as UserModelSendDataLogin;

        restApiClient.loginUser(userData)
            .then((token) => {
                if (token) {
                    const date = Date.now() + DURATION_COOKIE;

                    // Almacenamos el token en sessionStorage y cookies
                    sessionStorage.setItem("token", token);
                    setUserToken("userToken", token, {
                        path: "/",
                        expires: new Date(date),
                    });

                    // Determina si el login ha ido correctamente, almacenando sus valores en cookies
                    setCookies("cookies", "true", {
                        path: "/",
                        expires: new Date(date),
                    });
                    setCookiesList("cookiesList", cookiesSave, {
                        path: "/",
                        expires: new Date(date),
                    });

                    // Obtenemos info del usuario
                    getUserInfoMock();
                } else {
                    setVisibleAlertError(true);
                    setMessageAlert({
                        message: t("login:list-errors.title-data-errors"),
                        description: t("login:list-errors.data-errors"),
                    });
                }
             
            })
    };

    return (
        <>

            <FormCustom onFinish={onFinish}>
                <FormItemsContact
                    label={t("login:first-data")}
                    name="username"
                    className="row-form-item"
                    rules={[
                        {
                            required: true,
                            message: t("login:list-errors.error-user-empty"),
                        },
                        {
                            whitespace: true,
                            message: t("login:list-errors.error-user-empty"),
                        },
                        {
                            max: 50,
                            message: t("login:list-errors.max-number-username"),
                        },
                    ]}
                >
                    <InputCustom className="main-input" id="name" />
                </FormItemsContact>
                <FormItemsContact
                    label={t("login:second-data")}
                    className="row-form-item"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: t(
                                "login:list-errors.error-password-empty"
                            ),
                        },
                        {
                            max: 25,
                            message: t("login:list-errors.max-number-pass"),
                        },
                    ]}
                >
                    <InputPasswordCustom className="main-input" id="password" />
                </FormItemsContact>
                <div className="align-text-link-right link-password mt-5">
                    {/*<span onClick={openModalPassword}>
                        {t("login:link-forgotten-password")}
                </span>*/}
                </div>
                {visibleAlertwarning && (
                    <div className="alert-message">
                        <Alert
                            message={messageAlert.message}
                            description={messageAlert.description}
                            type="warning"
                            showIcon
                        />
                    </div>
                )}
                {visibleAlertError && (
                    <div className="alert-message">
                        <Alert
                            message={messageAlert.message}
                            description={messageAlert.description}
                            type="error"
                            showIcon
                        />
                    </div>
                )}
                <FormItemsContact>
                    <ButtonsCustom
                        id="btn-form-login"
                        class="col-12 primary-button"
                        type="primary"
                        size="large"
                        htmlType="submit"
                        content={t("login:first-button")}
                    />
                </FormItemsContact>
            </FormCustom>
            {visibleFormPassword && (
                <ModalCustom
                    class="modal-bo"
                    title={t("resetPass:form.title")}
                    onCancel={handleCancel}
                    setVisible={setVisibleFormPassword}
                    footer={null}
                    key="2"
                    centered
                    maskClosable={false}
                    content={
                        <ResetPass
                            setVisibleFormPassword={setVisibleFormPassword}
                            setmodalLogin={params.setmodalLogin}
                        />
                    }
                />
            )}

        </>
    
    );
};
