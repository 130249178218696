import React from "react";
import "./wallet.sass";
import "../../i18n";
import { useTranslation } from "react-i18next";
import {
	ParagraphCustom,
	TextCustom,
} from "../custom/index";

// Componente de información de Condiciones termes de la app
export const DetailBank = (param) => {

    const { t } = useTranslation(["wallet"]);

	return (
		<>
			<ParagraphCustom
				text={
					<strong className="step-one">
						{t("wallet:step")} 1
					</strong>
				}
			/>
			<ParagraphCustom
				class="detail-step-wallet"
				text={t("wallet:do-transfer")}
			/>

            <TextCustom
                class="text-data-bank"
                text={t("wallet:data-transfer")}
            />

			<ParagraphCustom
				class="data-bank"
				text={
					<>
                        <TextCustom
                            class="label-bank"
                            text={t("wallet:bank")}
                        />
						<ParagraphCustom
                            copyable={true}
							class="detail-bank"
							text={param.data.bankName}
						/>
						<TextCustom
                            class="label-bank"
                            text={t("wallet:bank-address")}
                        />
						<ParagraphCustom
                            copyable={true}
							class="detail-bank"
							text={param.data.bankAddress}
						/>

                        <TextCustom
                            class="label-bank"
                            text={t("wallet:associated-account")}
                        />
						<ParagraphCustom
                            copyable={true}
							class="detail-bank"
							text={param.data.bankAccount}
						/>

						<TextCustom
                            class="label-bank"
                            text={t("wallet:bic")}
                        />
						<ParagraphCustom
                            copyable={true}
							class="detail-bank"
							text={param.data.swiftBic}
						/>

						<TextCustom
                            class="label-bank"
                            text={t("wallet:bic-intermediary")}
                        />
						<ParagraphCustom
                            copyable={true}
							class="detail-bank"
							text={param.data.swiftBicInternational}
						/>

                        <TextCustom
                            class="label-bank"
                            text={t("wallet:beneficiary")}
                        />
						<ParagraphCustom
                            copyable={true}
							class="detail-bank"
							text={param.data.payee}
						/>

						<TextCustom
                            class="label-bank"
                            text={t("wallet:beneficiary-address")}
                        />
						<ParagraphCustom
                            copyable={true}
							class="detail-bank"
							text={param.data.payeeAddress}
						/>

                        <TextCustom
                            class="label-bank"
                            text={t("wallet:concept")}
                        />
						<ParagraphCustom
                            copyable={true}
							class="detail-bank last-data"
							text={t("concept-text", {
								0: param.data.contractNumber,
								1: param.data.currency
							  })}
						/>
						
					</>
				}
			/>
			<ParagraphCustom
				text={
					<strong className="step-two">
						{t("wallet:step")} 2
					</strong>
				}
			/>
			<ParagraphCustom
				class="detail-step-wallet"
				text={
					<>
						{t("wallet:send-doc")}
						<TextCustom
                            copyable={true}
							class="email-send-wallet"
							text={param.data.sendProofTo}
						/>
					</>
				}
			/>
            <TextCustom
                class="annotation-text"
                text={t("wallet:extra-info")}
            />
		</>
	);
};
