import { LanguageStrings } from './base-strings';

// Inglés Britanico cuando se añada al sistema
export const gb: LanguageStrings = {
  /** Routes */
  'routes.home': '/',
  'routes.Faqs': '/faqs',
  'routes.contact': '/contact',
  'routes.recoverPassword':'/recover-password',
  'routes.privacyPolicies': '/privacy-policies',
  'routes.cookiesPolicy': '/cookies-policy',
  'routes.legalAdvice': '/legal-advice',
  'routes.termConditions': '/term-conditions',
  'routes.homePrivate':'/app',
  'routes.privacyPoliciesPrivate': '/app/privacy-policies',
  'routes.cookiesPolicyPrivate': '/app/cookies-policy',
  'routes.legalAdvicePrivate': '/app/legal-advice',
  'routes.termConditionsPrivate': '/app/term-conditions',
  'routes.agentsRegister': '/app/agents-register',
  'routes.regularization': '/app/regularization',
  'routes.news': '/app/manage-news',
  'routes.rewards': '/app/rewards',
  'routes.rewardsPerNight': '/app/rewards-night',
  'routes.news.create': '/app/create-news',
  'routes.news.edit': '/app/edit-news/:id',
  'routes.FaqsPrivate': '/app/faqs',
  'routes.news.detail': '/app/detail-news/:id',
  'routes.config': '/app/config',
  'routes.advanced': '/app/advanced-retribution',
  'routes.statistics': '/app/statistics',
  'routes.targets':'/app/targets/',
  'routes.billing':'/app/billing',
};
